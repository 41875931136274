import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import {
  StyledLabel,
  StyledOptionsBox,
  StyledOptionType,
  StyledSelectType,
} from "./components";

const StyledSelectMultiplyChoice = ({
  options,
  LAYER,
  handleCheckboxChange,
  disabled,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectValue, setSelectValue] = useState();
  useEffect(() => {
    let count = 0;
    options.forEach((item) => item.name !== LAYER && item.checked && count++);

    if (options.find((item) => item.name === LAYER && item.checked)) {
      setSelectValue(LAYER);
    } else if (count > 1) {
      setSelectValue(
        <>
          {options.find((item) => item.checked)?.name}{" "}
          <b style={{ color: "#909491" }}> + {count - 1}</b>
        </>
      );
    } else setSelectValue(options.find((item) => item.checked)?.name);
  }, [options, LAYER]);

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <OutsideClickHandler onOutsideClick={() => setShowOptions(false)}>
        <StyledSelectType
          disabled={disabled}
          showOptions={showOptions}
          onClick={() => setShowOptions((prev) => !prev)}
        >
          {selectValue || "Choose options"}
        </StyledSelectType>
        {showOptions && (
          <StyledOptionsBox>
            {options.map((item) => (
              <StyledOptionType key={item.id}>
                <StyledLabel htmlFor={item.id}>
                  <input
                    style={{ width: "18px" }}
                    type="checkbox"
                    id={item.id}
                    name={item.name}
                    checked={item.checked}
                    onChange={() => {
                      handleCheckboxChange(item.id, item);
                    }}
                    disabled={disabled}
                  />
                  <div style={{ color: "#909491" }}>{item.name}</div>
                </StyledLabel>
              </StyledOptionType>
            ))}
          </StyledOptionsBox>
        )}
      </OutsideClickHandler>
    </div>
  );
};

export default StyledSelectMultiplyChoice;
