import styled from "styled-components";

export const ModalContentForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 35px;
`;

export const UploadLogoButton = styled.div`
  background: #ffffff;
  border: 2px solid #0686d8;
  border-radius: 16px;
  width: 42.4rem;
  height: 6.4rem;
  color: #030a1b;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FileInputInput = styled.input`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
`;

export const FileInputLabel = styled.label`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  border-radius: 1.5rem;
`;

export const RadioTitle = styled.div`
  color: #0686d8;
  margin-left: 15px;
  font-weight: 500;
  font-size: 24px;
`;

export const RadioInput = styled.input`
  width: 20px;
  height: 20px;
`;

export const LabelText = styled.div`
  margin-left: 10px;
  font-size: 19px;
  color: #030a1b;
`;
