import React from "react";
import DrawCanvas from "./DrawCanvas";
import { ComponentWrapper } from "../admin-page/environment-setup/components";
import {
  FleetRulesList,
  RobotDiv,
  RobotsListWrapper,
  StyledArrowLeft,
  StyledArrowRight,
  StyledDrawCanvasWrapper,
  StyledUnderLine,
  StyleFLHeaderWraper,
  MapWrapperTitle,
  SwitchIcon,
  SwitchToMapWrapper,
} from "./components";

import { MAPLOCATIONENUM } from "../../enums";
import { useFleetRules } from "./useFleetRules";
import ICONS from "../../constants/icons";
import StyledSelectMultiplyChoice from "../../components/styled-select-multiply-choice/StyledSelectMultiplyChoice";
import { useState } from "react";
import SearchableSelect from "../../components/searchable-select/SearchableSelect";
import { useSelector } from "react-redux";
import { setChosenBuilding, setChosenFloor } from "../../redux/maps";
import { useDispatch } from "react-redux";

const Map = () => {
  const { floorOptions, buildingOptions, chosenFloor, chosenBuilding } =
    useSelector((state) => state.maps);
  const {
    robots,
    chosenRobot,
    visibleCanvasItems,
    chosenLayer,
    LAYERS,
    handleLayerChange,
    handleRobotClick,
    canvasAreas,
    chosenrouteInd,
    setChosenRouteInd,
    showRobotsPage,
    setShowRobotsPage,
    handlePageChange,
    LAYER,
    ...rest
  } = useFleetRules();

  const [showRobots, setShowRobots] = useState(null);
  const dispatch = useDispatch();
  return (
    <ComponentWrapper>
      <div className="">
        <StyleFLHeaderWraper>
          <h1>Fleet rules setup</h1>
          <div style={{ display: "flex", gap: "20px" }}>
            <div style={{ width: "180px", marginTop: "10px" }}>
              <SearchableSelect
                value={chosenBuilding}
                handleChange={(val) => dispatch(setChosenBuilding(val))}
                options={buildingOptions}
              />
            </div>
            <div style={{ width: "180px", marginTop: "10px" }}>
              <SearchableSelect
                value={chosenFloor}
                options={floorOptions}
                handleChange={(val) => dispatch(setChosenFloor(val))}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <SwitchToMapWrapper>
              <MapWrapperTitle>Show robots</MapWrapperTitle>
              <SwitchIcon
                src={showRobots ? ICONS.ToggleOn : ICONS.ToggleOff}
                onClick={() => setShowRobots((prev) => !prev)}
              />
            </SwitchToMapWrapper>
            <StyledSelectMultiplyChoice
              LAYER={LAYER}
              options={chosenLayer}
              handleCheckboxChange={handleLayerChange}
            />
          </div>
        </StyleFLHeaderWraper>
        <StyledDrawCanvasWrapper>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <RobotsListWrapper>
              {chosenLayer === MAPLOCATIONENUM.ROUTE ? (
                canvasAreas.route.map((_, ind) => (
                  <RobotDiv key={"route" + ind} index={ind}>
                    <FleetRulesList
                      chosen={chosenrouteInd === ind}
                      onClick={() => setChosenRouteInd(ind)}
                    >
                      {ind}
                    </FleetRulesList>
                  </RobotDiv>
                ))
              ) : (
                <>
                  <FleetRulesList onClick={() => handlePageChange(-1)}>
                    <StyledArrowLeft src={ICONS.ArrowDown} />
                  </FleetRulesList>
                  {robots
                    .map((robot, index) => (
                      <RobotDiv key={robot + index} index={index}>
                        {index !== 0 && <StyledUnderLine />}
                        <FleetRulesList
                          chosen={
                            robot.id === chosenRobot.id ||
                            chosenLayer === MAPLOCATIONENUM.WORKINGAREA
                          }
                          key={robot.id}
                          onClick={() => handleRobotClick(robot)}
                        >
                          {robot.name}
                        </FleetRulesList>
                      </RobotDiv>
                    ))
                    .filter(
                      (el, ind) =>
                        ind <= showRobotsPage * 3 - 1 &&
                        (showRobotsPage - 1) * 3 <= ind
                    )}
                  <FleetRulesList onClick={() => handlePageChange(1)}>
                    <StyledArrowRight src={ICONS.ArrowDown} />
                  </FleetRulesList>
                </>
              )}
            </RobotsListWrapper>
          </div>

          <DrawCanvas
            visibleCanvasItems={visibleCanvasItems}
            showRobots={showRobots}
            robots={robots}
            chosenRobot={chosenRobot}
            {...rest}
          />
        </StyledDrawCanvasWrapper>
      </div>
    </ComponentWrapper>
  );
};

export default Map;
