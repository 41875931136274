import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import OutlineNonAuthorised from "../../components/outline-non-authorised/OutlineNonAuthorised";
import CustomButton from "../../components/button/CustomButton";
import InputText from "../../components/input/InputText";
import STRINGS from "../../constants/strings";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";

import {
  AddMemberContentWrapper,
  FormContentWrapper,
  Title,
} from "./components";
import acceptInvite from "../../APIs/auth/acceptInvite";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import useRedirect from "../../hooks/useRedirect";
import { getCurrentUserFunction } from "../../redux/clients";
import TermsAndConditions from "../../components/terms-and-conditions/TermsAndConditions";

const AddMember = () => {
  const { goToDashboard } = useRedirect();

  const { token } = useParams();
  const dispatch = useDispatch();
  const [errMessage, setErrMessage] = useState("");
  const [inputType, setInputType] = useState("password");
  const [isAccepted, setIsAccepted] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const handleSubmit = async () => {
    console.log("submit");
    try {
      const data = await acceptInvite({
        password: formValues.password,
        token: token,
      });
      localStorage.setItem("token", data.data.token);
      dispatch(getCurrentUserFunction());
      goToDashboard();
    } catch (error) {
      setErrMessage(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("selected_client_id");
  }, []);

  return (
    <OutlineNonAuthorised>
      {!isAccepted ? (
        <AddMemberContentWrapper>
          <Title style={{ fontSize: "4rem", color: "#0096C6", width: "100%" }}>
            {STRINGS.welcome_to_orchestra}
          </Title>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values) => {
              setFormValues(values);
              setIsAccepted(true);
            }}
            initialValues={{
              password: "",
              repassword: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.password) {
                errors.password = "Please enter your password";
              }
              if (values.password !== values.repassword) {
                errors.repassword = "Passwords do not match";
              }
              return errors;
            }}
          >
            {({ setFieldValue, errors, touched, isSubmitting }) => (
              <Form>
                <FormContentWrapper
                  style={{
                    width: "36.8rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  disabled={isSubmitting}
                >
                  <Field id="password" name="password">
                    {({ field }) => (
                      <InputText
                        type={inputType}
                        height="5.6rem"
                        margin="1.8rem 0"
                        placeholder={STRINGS.please_type_your_password}
                        handleChange={(v) => {
                          setFieldValue(field.name, v);
                          setErrMessage("");
                        }}
                        handleIconClick={() =>
                          setInputType((prev) =>
                            prev === "text" ? "password" : "text"
                          )
                        }
                        name={"new password"}
                        value={field.value}
                        error={
                          errMessage ||
                          (touched[field.name] && errors[field.name])
                        }
                        icon={ICONS.PasswordEye}
                      />
                    )}
                  </Field>
                  <Field id="repassword" name="repassword">
                    {({ field }) => (
                      <InputText
                        type={inputType}
                        handleIconClick={() =>
                          setInputType((prev) =>
                            prev === "text" ? "password" : "text"
                          )
                        }
                        height="5.6rem"
                        margin="1.8rem 0"
                        placeholder={STRINGS.please_type_your_password_again}
                        handleChange={(v) => setFieldValue(field.name, v)}
                        name={STRINGS.repeat_password}
                        value={field.value}
                        error={touched[field.name] && errors[field.name]}
                        icon={ICONS.PasswordEye}
                      />
                    )}
                  </Field>
                  <CustomButton
                    type="submit"
                    backgroundColor={COLORS.secondary.ncs}
                    width="100%"
                    padding="0 7.2rem"
                    height="5.8rem"
                    margin="2rem 0 1.4rem"
                    shadow
                    capitalize
                  >
                    {STRINGS.log_in}
                  </CustomButton>
                </FormContentWrapper>
              </Form>
            )}
          </Formik>
        </AddMemberContentWrapper>
      ) : (
        <AddMemberContentWrapper style={{ padding: "66px 33px 55px" }}>
          <TermsAndConditions
            handleSubmit={handleSubmit}
            handleDecline={() => {
              setIsAccepted(false);
              setFormValues(null);
            }}
          />
        </AddMemberContentWrapper>
      )}
    </OutlineNonAuthorised>
  );
};

export default AddMember;
