import React, { useEffect, useState } from "react";

const KeepMounted = ({ children, chosen }) => {
  const [components, setComponents] = useState({});

  useEffect(() => {
    if (chosen) {
      const filteredChildren = children.filter(
        (component) => component.key === chosen
      );
      setComponents((prev) => {
        return {
          ...prev,
          [chosen]: filteredChildren,
        };
      });
    }
  }, [chosen, children]);

  return Object.entries(components).map(([key, value]) => (
    <div
      style={{ display: key === chosen ? null : "none", height: "100%" }}
      key={key}
    >
      {value}
    </div>
  ));
};

export default KeepMounted;
