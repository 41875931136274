import Tool from "./tool";
export const Robot = "Robot";

const robot = { ...Tool };

robot.name = "Robot";

robot.onMouseDown = function onMouseDown(start, options, callback) {
  if (!this.state) {
    this.state = {
      ...this.state,
      initialCircle: this.createStartPoint(start, 5),
      startPoint: start,
    };
  }
  if (!this.state.pathData) {
    this.state.pathData = [];
  }
  this.setInitSettings({ start, options });
  callback(this.state);
  this.resetState();
};

robot.draw = function drawPoint(start, position, isInitSet, data) {
  if (isInitSet === false) {
    this.setInitSettings({ start, options: data.options || {} }, true);
  }
  this.ctx.beginPath();
  this.ctx.strokeStyle = "transparent";
  this.ctx.moveTo(start.x, start.y);
  this.ctx.arc(start.x, start.y, 5, 0, Math.PI * 2, true);
  this.ctx.lineTo(position.x, position.y);
  this.ctx.stroke();
};

robot.drawRobot = function ({ position, name, pseudoPositions }) {
  this.ctx.font = "14px Georgia";
  this.ctx.beginPath();
  this.ctx.fillStyle = "black";
  this.ctx.fillText(
    name.length === 1 ? name : name.slice(0, 1) + name.slice(-1),
    name.length === 1 ? position.x - 5 : position.x - 8,
    position.y + 3
  );

  this.ctx.arc(position.x, position.y, 10, 0, 2 * Math.PI, false);
  this.ctx.strokeStyle = "black";
  this.ctx.stroke();

  if (pseudoPositions?.length) {
    pseudoPositions.forEach((pseudoPosition) => {
      this.ctx.font = "14px Georgia";
      this.ctx.beginPath();
      this.ctx.fillStyle = "red";
      this.ctx.fillText(
        name.length === 1 ? name : name.slice(0, 1) + name.slice(-1),
        name.length === 1 ? pseudoPosition.x - 5 : pseudoPosition.x - 8,
        pseudoPosition.y + 3
      );

      this.ctx.arc(
        pseudoPosition.x,
        pseudoPosition.y,
        10,
        0,
        2 * Math.PI,
        false
      );
      this.ctx.strokeStyle = "red";
      this.ctx.stroke();
    });
  }
};

robot.onMouseMove = function onMouseMove() {};

robot.onMouseUp = function onMouseUp(position, callback) {
  if (!this.state) return;
  // NOTE: This state data is just to avoid draw in
  // the first mouse up
  this.state.data.push([position.x, position.y]);
  if (this.state.data.length > 1) {
    const data = [
      [this.state.firstMouseDown.x, this.state.firstMouseDown.y],
      [position.x, position.y],
    ];
    const start = this.state.start;
    const options = this.state.options;
    this.drawCrossDirection(this.state.data, 10);
    this.resetState();
    callback();
    return {
      data: data,
      canvas: {
        start,
        end: position,
        options,
      },
    };
  }
};

function getCrossPath(point, size, direction) {
  const path = new Path2D();
  const startHorizontalLine = { x: point.x - size, y: point.y };
  const endHorizontalLine = { x: point.x + size, y: point.y };
  const startVerticalLine = { x: point.x, y: point.y - size };
  const endVerticalLine = { x: point.x, y: point.y + size };

  path.moveTo(startHorizontalLine.x, startHorizontalLine.y);
  path.lineTo(endHorizontalLine.x, endHorizontalLine.y);
  path.moveTo(startVerticalLine.x, startVerticalLine.y);
  path.lineTo(endVerticalLine.x, endVerticalLine.y);
  return path;
}

robot.drawCrossDirection = function (points, pixelDistance) {
  const x1 = points[0][0];
  const x2 = points[1][0];
  const y1 = points[0][1];
  const y2 = points[1][1];

  const xCoord = (x1 + x2) / 2 + pixelDistance * Math.sign(y2 - y1);
  const yCoord = (y1 + y2) / 2 - pixelDistance * Math.sign(x2 - x1);

  const crossPath = getCrossPath({ x: xCoord, y: yCoord }, 6);
  this.ctx.strokeStyle = "#ff0000";
  this.ctx.stroke(crossPath);
  this.ctx.strokeStyle = "#555";
};

export default robot;
