import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ResponsiveProvider } from "./contexts/ResponsiveContext";
import { ThemeProvider } from "styled-components";
import Theme from "./theme";
import { QueryClient, QueryClientProvider } from "react-query";
import "./utils/extensions/index";
import { Provider } from "react-redux";
import { store } from "./redux/store";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={Theme}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ResponsiveProvider>
          <App />
        </ResponsiveProvider>
      </Provider>
    </QueryClientProvider>
  </ThemeProvider>
);
