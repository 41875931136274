import React, { useEffect, useState } from "react";
import getChosenApplication from "../../../APIs/application/getChosenApplication";
import { useParams } from "react-router-dom";
import URLS from "../../../constants/urls";
import STRINGS from "../../../constants/strings";
import ICONS from "../../../constants/icons";
import {
  BackArrow,
  BackToListText,
  HeaderRightSide,
  RobotDetailsHeader,
} from "../../robots/components";
import {
  AssignmentDetailHeader,
  AssignmentDetailsWrapperComp,
  AssignmentName,
  ContentText,
  IconAndInfo,
  IconWrapper,
  StyledIcon,
  TableWrapper,
} from "../../robots/tasks-in-assignments/components";
import { StatusToggleIcon } from "../../robots/assignments/components";
import ApplicationDetailsParameters from "./ApplicationDetailsParameters";
import updateApplication from "../../../APIs/application/updateApplication";
import { v4 as uuidv4 } from "uuid";
import AddApplicationModal from "../../../components/add-application-modal/AddApplicationModal";
import ConfirmChangeModal from "../../../components/agenda-assignment-details-modal/assignment-details-modals/ConfirmChangeModal";
import DiscardChangesModalContent from "../../../components/agenda-assignment-details-modal/assignment-details-modals/DiscardChangesModalContent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ApplicationDetails = () => {
  const history = useHistory();
  const [applicationDetails, setApplicationDetails] = useState({});
  const [parametersBeforeChanges, setParametersBeforeChanges] = useState();
  const [isDiscardModal, setIsDiscardModal] = useState(false);
  const [newApplicationForm, setNewApplicationForm] =
    useState(applicationDetails);
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    getChosenApplication(id).then((data) => {
      setApplicationDetails(data);
    });
  }, [id]);
  const endTime = applicationDetails?.parameters?.find(
    (par) => par.type === "endtime"
  );
  const handleEndTime = async () => {
    let updatedApp = {};
    if (endTime) {
      updatedApp = {
        ...applicationDetails,
        parameters: applicationDetails.parameters.filter(
          (par) => par.type !== "endtime"
        ),
      };
    } else {
      updatedApp = {
        ...applicationDetails,
        parameters: [
          ...applicationDetails.parameters,
          {
            id: uuidv4(),
            parameter_name: "End After",
            parameter: "end_time",
            type: "endtime",
            default: 0,
          },
        ],
      };
    }
    await updateApplication(updatedApp);
    setApplicationDetails(updatedApp);
  };
  const handleEdit = () => {
    setIsOpen(true);
  };

  const handleBackClick = () => {
    if (
      parametersBeforeChanges &&
      JSON.stringify(
        applicationDetails.parameters.filter((item) => item.type !== "endtime")
      ) !==
        JSON.stringify(
          parametersBeforeChanges.filter((item) => item.type !== "endtime")
        )
    ) {
      setIsDiscardModal(true);
    } else {
      history.push(URLS.application);
    }
  };
  return (
    <React.Fragment>
      <RobotDetailsHeader>
        <div
          onClick={handleBackClick}
          style={{ display: "flex", cursor: "pointer" }}
        >
          <BackArrow src={ICONS.ChevronLeft} />
          <BackToListText>{STRINGS.back_to_all_applications}</BackToListText>
        </div>
      </RobotDetailsHeader>
      <TableWrapper>
        <AssignmentDetailHeader
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <AssignmentName>{applicationDetails?.aliasName}</AssignmentName>
          <HeaderRightSide>
            <IconWrapper
              background={"rgba(6, 134, 216, 0.1)"}
              onClick={handleEdit}
            >
              <StyledIcon
                style={{ cursor: "pointer" }}
                src={ICONS.PencilDraw}
                alt="Pencil"
              />
            </IconWrapper>
          </HeaderRightSide>
        </AssignmentDetailHeader>
        <AssignmentDetailsWrapperComp>
          <IconAndInfo>
            <IconWrapper background={"#fff"} onClick={handleEndTime}>
              <StatusToggleIcon
                style={{
                  marginLeft: "unset",
                  width: "36px",
                }}
                src={endTime ? ICONS.ToggleActive : ICONS.ToggleOff}
                alt="ToggleIcon"
              />
            </IconWrapper>
            <ContentText>{"Apply end time"}</ContentText>
          </IconAndInfo>

          <IconAndInfo>
            <IconWrapper background={"rgba(59, 129, 26, 0.05)"}>
              <StyledIcon src={ICONS.ColoredStatus} alt="Pencil" />
            </IconWrapper>
            <ContentText>{applicationDetails?.technicalName}</ContentText>
          </IconAndInfo>

          <IconAndInfo>
            <IconWrapper background={"rgba(255, 168, 0, 0.05)"}>
              <StyledIcon src={ICONS.ClockIcon} alt="Pencil" />
            </IconWrapper>
            <ContentText>{applicationDetails?.exp} min</ContentText>
          </IconAndInfo>
        </AssignmentDetailsWrapperComp>
      </TableWrapper>
      <ApplicationDetailsParameters
        applicationDetails={applicationDetails}
        setApplicationDetails={setApplicationDetails}
        setParametersBeforeChanges={setParametersBeforeChanges}
      />

      {isOpen && (
        <AddApplicationModal
          newApplicationForm={newApplicationForm}
          setNewApplicationForm={setNewApplicationForm}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          currentModalData={applicationDetails}
          setApplicationDetails={setApplicationDetails}
        />
      )}
      <ConfirmChangeModal
        isOpen={isDiscardModal}
        onRequestClose={() => setIsDiscardModal(false)}
      >
        <DiscardChangesModalContent
          handleSubmit={() => {
            history.push(URLS.application);
            setIsDiscardModal(false);
          }}
          setIsOpen={setIsDiscardModal}
        />
      </ConfirmChangeModal>
    </React.Fragment>
  );
};

export default ApplicationDetails;
