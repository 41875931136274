import React from "react";
import { useSelector } from "react-redux";
import ToastMessageComponent from "./ToastMessageComponent";

const ToastMessagesWrapper = () => {
  const { toastMessage } = useSelector((state) => state.toast);

  return (
    <React.Fragment>
      {toastMessage && <ToastMessageComponent {...toastMessage} />}
    </React.Fragment>
  );
};

export default ToastMessagesWrapper;
