import React, { useEffect } from "react";

import STRINGS from "../../constants/strings";
import COLORS from "../../constants/colors";

import OutlineNonAuthorised from "../../components/outline-non-authorised/OutlineNonAuthorised";
import CustomButton from "../../components/button/CustomButton";

import { SubTitle, Title, VerifyEmailImg } from "./components";
import { useMutation } from "react-query";
import verifyEmail from "../../APIs/auth/verifyEmail";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { getCurrentUserFunction } from "../../redux/clients";
import useRedirect from "../../hooks/useRedirect";

const UserVerification = () => {
  const { token } = useParams();
  const { goToDashboard } = useRedirect();
  const dispatch = useDispatch();
  const {
    mutate: handleUserVerification,
    // data: userVerification,
    error: userVerificationError,
    isLoading: userVerificationLoading,
  } = useMutation("verify-email", verifyEmail);

  useEffect(() => {
    localStorage.removeItem("emailToVerify");
    if (token) {
      handleUserVerification(token, {
        onSuccess: (data) => {
          if (!data.error) {
            localStorage.setItem("token", data.token);
          }
        },
      });
    }
  }, [handleUserVerification, token, dispatch]);
  return (
    <OutlineNonAuthorised doubleBackground>
      <VerifyEmailImg />
      <Title style={{ margin: "1.6rem 0" }}>{STRINGS.user_verification}</Title>
      {userVerificationError ? (
        <SubTitle style={{ width: "32.2rem", marginBottom: "6rem" }}>
          Something went wrong <br /> <br /> {userVerificationError.message}
        </SubTitle>
      ) : userVerificationLoading ? (
        <SubTitle style={{ width: "32.2rem", marginBottom: "6rem" }}>
          {STRINGS.loading}...
        </SubTitle>
      ) : (
        <>
          <SubTitle style={{ width: "32.2rem", marginBottom: "6rem" }}>
            You have successfully verified email
          </SubTitle>
          <CustomButton
            backgroundColor={COLORS.secondary.ncs}
            onClick={() => {
              dispatch(getCurrentUserFunction());
              goToDashboard();
            }}
          >
            Go to dashboard
          </CustomButton>
        </>
      )}
    </OutlineNonAuthorised>
  );
};

export default UserVerification;
