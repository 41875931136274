import React from "react";
import ICONS from "../../constants/icons";
import { NotFoundContent, NotFoundWrapper } from "./components";

const NotFound = () => {
  return (
    <NotFoundWrapper>
      <img src={ICONS.NoResult} alt="icon" />
      <NotFoundContent>No Result Found</NotFoundContent>
    </NotFoundWrapper>
  );
};

export default NotFound;
