import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ICONS from "../../constants/icons";
import {
  AgendaHeaderWrapper,
  AgendaTitle,
  CalendarWrapper,
  StyledDate,
  StyledDay,
  StyledDayWrapper,
} from "./components";
import Calendar from "react-calendar";
import moment from "moment";
import momentTimezone from "moment-timezone";
import getTasksFromArchive from "../../APIs/tasks-archive/getTasksArchive";
import {
  handleAssignmentDetailsUpdate,
  setAssignmentEditMode,
  setAssignmentsRedux,
  setAgendaCalendarDate,
  setIsLoading,
} from "../../redux/dashboard";
import OutsideClickHandler from "react-outside-click-handler";
import { ROLESENUM } from "../../enums";
import { StyledIconButton } from "../../components/agenda-assignment-details-modal/components";
import { AssignmentsContext } from "../../contexts/AssignmentsContext";
import { useAgendaData } from "../map/hooks/useAgendaData";
import ProgressBar from "../../components/porgressBar/ProgressBar";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
const AgendaHeader = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const { chosenClient, currentRole } = useSelector((state) => state.clients);
  const { calendarDate, refreshAgendaState, isLoading } = useSelector(
    (state) => state.dashboard
  );
  const { isMobile, isTablet } = useContext(ResponsiveContext);
  const { assignmentTemplate } = useContext(AssignmentsContext);
  const dispatch = useDispatch();
  const { handleAgendaDataBinding } = useAgendaData();
  const handleDayChange = (action) => {
    const time = new Date(
      new Date(calendarDate)?.setDate(
        new Date(calendarDate)?.getDate() + action
      )
    );
    dispatch(setAgendaCalendarDate(time.toISOString()));
  };

  useEffect(() => {
    if (chosenClient) {
      dispatch(setIsLoading(true));
      getTasksFromArchive(chosenClient, calendarDate, momentTimezone.tz.guess())
        .then(({ oldTasks: tasks, futureAssignments }) => {
          const bindedData = handleAgendaDataBinding(
            tasks,
            futureAssignments,
            calendarDate
          );
          dispatch(setAssignmentsRedux(bindedData));
          dispatch(setIsLoading(false));
        })
        .catch(() => {
          dispatch(setIsLoading(false));
        });
    }
  }, [
    calendarDate,
    chosenClient,
    dispatch,
    refreshAgendaState,
    handleAgendaDataBinding,
  ]);

  return (
    <div
      style={{
        borderBottom: "0.5px solid rgba(144, 148, 145, 0.4)",
        paddingBottom: "5px",
        position: "relative",
      }}
    >
      <AgendaHeaderWrapper style={{ position: "relative" }} isMobile={isMobile}>
        <AgendaTitle>Agenda</AgendaTitle>
        <CalendarWrapper isMobile={isMobile} isTablet={isTablet}>
          <img
            src={ICONS.DoubleArrowLeft}
            alt="left"
            onClick={() => handleDayChange(-1)}
          />
          <StyledDate onClick={() => setShowCalendar((prev) => !prev)}>
            {moment(new Date(calendarDate)).format("ddd, MMM D")}
          </StyledDate>
          <img
            src={ICONS.DoubleArrowRight}
            alt="right"
            onClick={() => handleDayChange(+1)}
          />
          {showCalendar && (
            <div
              style={{
                position: "absolute",
                zIndex: "99",
                top: "50px",
                left: "-80px",
              }}
            >
              <OutsideClickHandler
                onOutsideClick={() => setShowCalendar(false)}
              >
                <Calendar
                  onChange={(value) => {
                    setShowCalendar(false);
                    dispatch(setAgendaCalendarDate(value));
                  }}
                  value={new Date(calendarDate)}
                />
              </OutsideClickHandler>
            </div>
          )}
        </CalendarWrapper>
        <StyledDayWrapper
          onClick={() =>
            dispatch(setAgendaCalendarDate(new Date().toISOString()))
          }
        >
          <StyledDay>Today</StyledDay>
        </StyledDayWrapper>
      </AgendaHeaderWrapper>
      {currentRole !== ROLESENUM.VIEWER && (
        <StyledIconButton
          className="clickAllow clickRevert"
          onClick={() => {
            dispatch(
              handleAssignmentDetailsUpdate({
                ...assignmentTemplate,
                justCreated: true,
                executionTime: calendarDate,
              })
            );
            dispatch(setAssignmentEditMode(true));
          }}
          style={{ width: "34px", height: "34px", marginLeft: "24px" }}
        >
          <img src={ICONS.AddIcon} alt="Add" />
        </StyledIconButton>
      )}
      {isLoading && <ProgressBar />}
    </div>
  );
};

export default AgendaHeader;
