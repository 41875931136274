import React, { useContext, useMemo, useState } from "react";
import {
  StyledSubtitle,
  StyledThreeBtn,
  StyledTitle,
  WidgetHeader,
  WidgetTypeWrapper,
  WidgetWraper,
} from "./components";
import ICONS from "../../../constants/icons";
import NumberWidget from "./widget-types/NumberWidget";
import {
  EditItem,
  StyledEditBox,
  StyledEditIcon,
} from "../environment-setup/components";
import OutsideClickHandler from "react-outside-click-handler";
import deleteAnalyticsConfig from "../../../APIs/analytics/deleteAnalyticsConfig";
import { useDispatch } from "react-redux";
import { setAnalyticsData, setWidgetModalData } from "../../../redux/analytics";
import { useSelector } from "react-redux";
import PieWidget from "./widget-types/PieWidget";
import ChartWidget from "./widget-types/ChartWidget";
import GraphWidget from "./widget-types/GraphWidget";
import { ResponsiveContext } from "../../../contexts/ResponsiveContext";
import { ROLESENUM } from "../../../enums";

const AnalyticsWidget = ({ item }) => {
  const { analyticsData } = useSelector((state) => state.analytics);
  const { currentRole } = useSelector((state) => state.clients);
  const dispatch = useDispatch();

  const { isMobile } = useContext(ResponsiveContext);
  const [showEdit, setShowEdit] = useState(false);

  const dataType = useMemo(() => {
    switch (item.type) {
      case "Number":
        return <NumberWidget config={item} analytics={item.analytics} />;
      case "Pie":
        return <PieWidget config={item} analytics={item.analytics} />;
      case "Chart":
        return <ChartWidget config={item} analytics={item.analytics} />;
      case "Graph":
        return <GraphWidget config={item} analytics={item.analytics} />;
      default:
        return null;
    }
  }, [item]);

  const handleDelete = async () => {
    await deleteAnalyticsConfig(item.id);
    const filteredData = analyticsData.filter((el) => el.id !== item.id);
    dispatch(setAnalyticsData(filteredData));
  };

  const handleEdit = () =>
    dispatch(setWidgetModalData({ ...item, isCreated: true }));

  return (
    <WidgetWraper isMobile={isMobile}>
      <WidgetHeader>
        <StyledTitle isMobile={isMobile}>{item.title}</StyledTitle>
        {!isMobile && currentRole === ROLESENUM.ADMIN && (
          <StyledThreeBtn
            src={ICONS.MenuVertical}
            onClick={() => setShowEdit(true)}
          />
        )}
        {showEdit && (
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowEdit(false);
            }}
          >
            <StyledEditBox>
              <EditItem
                onClick={(e) => {
                  e.preventDefault();
                  handleEdit();
                  setShowEdit(false);
                }}
              >
                <StyledEditIcon src={ICONS.PencilDraw} alt="Pencil" />
                Edit
              </EditItem>
              <EditItem
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete();
                  setShowEdit(false);
                }}
              >
                <StyledEditIcon src={ICONS.DeleteIcon} alt="DeleteIcon" />
                Delete
              </EditItem>
            </StyledEditBox>
          </OutsideClickHandler>
        )}
      </WidgetHeader>
      {!isMobile && <StyledSubtitle> {item?.description}</StyledSubtitle>}
      <WidgetTypeWrapper>{dataType}</WidgetTypeWrapper>
    </WidgetWraper>
  );
};

export default AnalyticsWidget;
