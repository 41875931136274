import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import stopTask from "../../../APIs/tasks-archive/stopTask";
import {
  EditAssignmentButton,
  EditAssignmentButtonsWrapper,
  StyledChangesContentWrapper,
  StyledContentTitle,
  StyledModalTitleWrapper,
} from "../components";
import { setStopTaskData } from "../../../redux/dashboard";
import Loader from "../../loader/Loader";

const StopTaskModal = ({ onRequestClose }) => {
  const { stopTaskData } = useSelector((state) => state.dashboard);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const handleStopTask = async () => {
    setLoading(true);
    // returns updated task
    await stopTask(stopTaskData.id);
    dispatch(setStopTaskData(null));
    setLoading(false);
  };

  return (
    <StyledChangesContentWrapper className="clickAllow">
      <StyledModalTitleWrapper>
        <StyledContentTitle>
          Are you sure you want to stop - <strong>{stopTaskData?.name}?</strong>
        </StyledContentTitle>
      </StyledModalTitleWrapper>
      <EditAssignmentButtonsWrapper style={{ marginTop: "20px" }}>
        <EditAssignmentButton
          background={"rgba(213, 47, 57, 0.1)"}
          color={"#D42F39"}
          onClick={handleStopTask}
          disabled={loading}
        >
          {loading ? (
            <Loader height={20} noText noMargin padding={0} />
          ) : (
            "Stop"
          )}
        </EditAssignmentButton>
        <EditAssignmentButton
          background={"#fff"}
          color={"#0686D8"}
          border={"1px solid #0686D8"}
          onClick={onRequestClose}
        >
          Cancel
        </EditAssignmentButton>
      </EditAssignmentButtonsWrapper>
    </StyledChangesContentWrapper>
  );
};

export default StopTaskModal;
