export const termsText = [
  {
    subtitle: "1. Acceptance of Terms",
    text: "By signing in or using the Orchestra system, you agree to abide by these terms and conditions. If you do not agree with any part of these terms, you may not use the system.",
  },
  {
    subtitle: "2. System Usage",
    a: "a. The Orchestra system is intended for authorized users only.",
    b: "b. Users are responsible for maintaining the confidentiality of their login credentials.",
  },
  {
    subtitle: "3. Responsibilities",
    a: "a. Users are responsible for their actions while using the system.",
    b: "b. Unauthorized access, use, or sharing of system resources is strictly prohibited",
  },
  {
    subtitle: "4. Data and Privacy",
    a: "a. The system may collect and process user data. By using the system, you consent to such data processing.",
    b: "b. User data will be handled in accordance with our Privacy Policy.",
  },
  {
    subtitle: "5. Intellectual Property",
    a: "a. The Orchestra system and its components are protected by intellectual property laws. Users agree not to reproduce, modify, or distribute any part of the system without permission.",
  },
  {
    subtitle: "6. Limitation of Liability",
    a: `a. The system is provided "as is," and we make no warranties regarding its functionality or availability.`,
    b: "b. We are not liable for any direct, indirect, incidental, or consequential damages arising from the use of the system.",
  },
  {
    subtitle: "7. Termination",
    a: "We reserve the right to terminate or suspend user accounts at our discretion, without notice, for any violation of these terms.",
  },
  {
    subtitle: "8. Modifications to Terms",
    a: "We reserve the right to modify these terms at any time. Users will be notified of significant changes, and continued use of the Orchestra system constitutes acceptance of the modified terms.",
  },
  {
    subtilte: "9. Governing Law",
    a: "These terms are governed by and construed in accordance with the law",
  },
];
