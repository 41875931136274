import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import COLORS from "../../constants/colors";
import STRINGS from "../../constants/strings";
import { ModalContentForm } from "../add-client-modal/components";
import CustomButton from "../button/CustomButton";
import { ModalFooter } from "../modal-outline/components";
import ModalOutline from "../modal-outline/ModalOutline";
import { InputWrapper } from "../input/components";
import InputText from "../input/InputText";
import addLocation from "../../APIs/locations/addLocation";
import updateLocation from "../../APIs/locations/updateLocation";
import SearchableSelect from "../searchable-select/SearchableSelect";
import { setChosenBuilding, setChosenFloor } from "../../redux/maps";
import { useDispatch } from "react-redux";
import StyledSelectForStreaming from "../../pages/robots/StyledSelectForStreaming";
import { InputTitle } from "../../pages/robots/tasks-in-assignments/components";
import { setUsersData } from "../../redux/userManagement";
import getClientMembersAdmin from "../../APIs/user-management/getClientMembersAdmin";
import { ROLESENUM } from "../../enums";

const AddLocationModal = ({
  isOpen,
  setIsOpen,
  currentModalData,
  setNewLocationForm,
  newLocationForm,
  locations,
  setLocations,
  handleAddLocationFromFleet,
  fleetRules,
  locationsData,
  setLocationsData,
}) => {
  const { chosenClient, client, currentRole } = useSelector(
    (state) => state.clients
  );
  const { usersData } = useSelector((state) => state.userManagement);
  const {
    floorOptions,
    buildingOptions,
    chosenBuilding,
    chosenFloor,
    chosenMap,
  } = useSelector((state) => state.maps);
  const [errorValidations, setErrorValidations] = useState({
    name: "",
    direction: "",
    x: "",
    y: "",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    Object.keys(currentModalData).length &&
      setNewLocationForm(currentModalData);
  }, [currentModalData, setNewLocationForm]);

  useEffect(() => {
    if (client?.id) {
      if (currentRole === ROLESENUM.ADMIN) {
        getClientMembersAdmin(client.id).then((data) => {
          dispatch(setUsersData(data));
        });
      }
    }
  }, [client.id, dispatch, currentRole]);

  const handleChange = (inputValue, fieldName) => {
    setNewLocationForm({
      ...newLocationForm,
      [fieldName]: inputValue,
    });
    setErrorValidations((prev) => ({ ...prev, [fieldName]: "" }));
  };

  const handleAddLocation = async () => {
    const location = await addLocation({
      clientId: chosenClient,
      location: [
        +newLocationForm.x,
        +newLocationForm.y,
        +newLocationForm.direction,
      ],
      name: newLocationForm.name,
      direction: +newLocationForm.direction,
      mapId: chosenMap.id,
      ownerId: newLocationForm.owners[0].id,
    });
    const owners = [usersData.find((el) => el.id === location.owners[0].id)];
    setLocations([
      ...locations,
      {
        ...location,
        owners: owners,
      },
    ]);
    setLocationsData([
      ...locationsData,
      {
        ...location,
        owners: owners,
      },
    ]);
    setIsOpen(false);
    setErrorValidations({
      name: "",
      direction: "",
      x: "",
      y: "",
    });
  };

  const handleUpdate = async () => {
    const { data } = await updateLocation({
      id: newLocationForm.id,
      name: newLocationForm.name,
      location: [
        +newLocationForm.x,
        +newLocationForm.y,
        +newLocationForm.direction,
      ],
      direction: +newLocationForm.direction,
      mapId: chosenMap.id,
      ownerId: newLocationForm.owners[0].id,
    });

    const owners = [usersData.find((el) => el.id === data.owners[0].id)];

    setLocations(
      locations.map((item) =>
        item.id === newLocationForm.id ? { ...data, owners: owners } : item
      )
    );
    setLocationsData(
      locationsData.map((item) =>
        item.id === newLocationForm.id ? { ...data, owners: owners } : item
      )
    );
    setIsOpen(false);
  };

  const handleSubmit = () => {
    Object.keys(newLocationForm).forEach((key) => {
      if (!newLocationForm[key]) {
        setErrorValidations((prev) => ({
          ...prev,
          [key]: "This field can not be empty",
        }));
      }
    });
    if (!newLocationForm.owners.length)
      setErrorValidations((prev) => ({
        ...prev,
        owners: "This field can not be empty",
      }));
    if (
      newLocationForm.name &&
      newLocationForm.x &&
      newLocationForm.y &&
      newLocationForm.direction &&
      newLocationForm.owners.length
    ) {
      if (handleAddLocationFromFleet) {
        handleAddLocationFromFleet();
      } else {
        Object.keys(currentModalData).length
          ? handleUpdate()
          : handleAddLocation();
      }
    }
  };

  return (
    <ModalOutline
      isOpen={isOpen}
      heading={
        Object.keys(currentModalData).length
          ? STRINGS.edit_location
          : STRINGS.create_new_location
      }
      onRequestClose={() => {
        setIsOpen(false);
        setErrorValidations({
          name: "",
          direction: "",
          x: "",
          y: "",
        });
      }}
      buttonText={STRINGS.create}
    >
      <ModalContentForm>
        <InputWrapper>
          <InputText
            // width="42.4rem"
            height="6.4rem"
            borderColor={COLORS.secondary.water_green}
            placeholder={STRINGS.location_name}
            handleChange={(e) => handleChange(e, "name")}
            name={STRINGS.name}
            value={newLocationForm?.name}
            error={errorValidations.name}
            backgroundColor="#fff"
          />
        </InputWrapper>
        <InputWrapper style={{ justifyContent: "space-between" }}>
          <InputText
            type="number"
            width="15.4rem"
            height="6.4rem"
            borderColor={COLORS.secondary.water_green}
            placeholder={STRINGS.direction}
            handleChange={(e) => handleChange(e, "direction")}
            name={STRINGS.direction}
            value={newLocationForm?.direction}
            backgroundColor="#fff"
            error={errorValidations.direction}
          />
          <InputText
            type="number"
            width="15.4rem"
            height="6.4rem"
            borderColor={COLORS.secondary.water_green}
            placeholder={"Coordinates X"}
            name={"Coordinates X"}
            handleChange={(e) => handleChange(e, "x")}
            value={newLocationForm?.x}
            error={errorValidations.x}
            backgroundColor="#fff"
          />
          <InputText
            type="number"
            width="15.4rem"
            height="6.4rem"
            borderColor={COLORS.secondary.water_green}
            placeholder={"Coordinates Y"}
            handleChange={(e) => handleChange(e, "y")}
            name={"Coordinates Y"}
            value={newLocationForm?.y}
            error={errorValidations.y}
            backgroundColor="#fff"
          />
        </InputWrapper>

        <InputWrapper style={{ flexDirection: "column", alignItems: "unset" }}>
          <InputTitle style={{ color: "#030A1B" }}>Owner</InputTitle>
          <StyledSelectForStreaming
            color={"#909491"}
            width={"100%"}
            height="5.6rem"
            options={usersData.map((el) => el.username)}
            name="owners"
            chosenValue={
              (newLocationForm?.owners &&
                newLocationForm?.owners[0]?.username) ||
              "Choose owner"
            }
            setChosenValue={(val) => {
              const owner = usersData.find((el) => el.username === val);
              handleChange([owner], "owners");
            }}
            error={errorValidations.owners}
          />
        </InputWrapper>

        <InputWrapper style={{ gap: "20px" }}></InputWrapper>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <div
            style={{
              width: "20.4rem",
              marginTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <SearchableSelect
              value={chosenBuilding}
              width="20.4rem"
              handleChange={(val) => dispatch(setChosenBuilding(val))}
              options={buildingOptions}
            />
          </div>
          <div style={{ width: "20.4rem", marginTop: "10px" }}>
            <SearchableSelect
              value={chosenFloor}
              width="20.4rem"
              options={floorOptions}
              handleChange={(val) => dispatch(setChosenFloor(val))}
            />
          </div>
        </div>
      </ModalContentForm>

      <ModalFooter>
        <CustomButton
          backgroundColor={COLORS.secondary.ncs}
          type="submit"
          capitalize
          onClick={() => {
            handleSubmit(currentModalData);
          }}
        >
          {fleetRules
            ? STRINGS.create
            : Object.keys(currentModalData).length
            ? STRINGS.update_client
            : STRINGS.create}
        </CustomButton>
      </ModalFooter>
    </ModalOutline>
  );
};
export default AddLocationModal;
