import styled from "styled-components";

export const StyledSidebarWrapper = styled.div`
  position: fixed;
  height: 100%;
  background-color: #fff;
  z-index: 9999;
  width: 250px;
  box-shadow: ${(props) =>
    props.isOpen && "16px 4px 24px 0px rgba(0, 0, 0, 0.25)"};
  transition: left 0.3s ease-in-out; /* Animation duration and easing */
  top: 0;
  left: ${(props) => (props.isOpen ? "0" : "-250px")};
`;

export const SidebarHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 2.2rem 0px 2.2rem;
`;

export const StyledLogoutWrapper = styled.div`
  padding: 3rem;
  > div {
    display: flex;
    align-items: center;
    gap: 10px;
    width: auto;
  }
`;

export const StyledSidebarContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  > div {
    &:last-child {
      margin-top: auto;
    }
  }
`;
