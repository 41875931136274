import React, { useContext, useEffect, useState } from "react";
import { InfoModalTypes } from "../../../constants/types";
import {
  ComponentHeader,
  ComponentTitle,
} from "../environment-setup/components";
import RotateDeviceAlert from "../../../components/rotate-device-alert/RotateDeviceAlert";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import STRINGS from "../../../constants/strings";
import {
  handleDeleteUser,
  setDeleteUserData,
  setEditUserData,
  setIsSuccessModal,
  setUsersData,
} from "../../../redux/userManagement";
import { HeaderStyledButton } from "../../robots/assignments/components";
import UserAdministrationTable from "./UserAdministrationTable";
import { AdminTableContext } from "../../../contexts/AdminTableContext";
import InfoModal from "../../../components/info-modal/InfoModal";
import deleteClientUser from "../../../APIs/user-management/deleteMember";
import TeamMemberModal from "../../../components/user-management/user-management-modals/TeamMemberModal";
import MemberSuccessModal from "../../../components/user-management/user-management-modals/MemberSuccessModal";
import getClientMembersAdmin from "../../../APIs/user-management/getClientMembersAdmin";
import { ResponsiveContext } from "../../../contexts/ResponsiveContext";

const UserAdministration = () => {
  const { isTablet, isLandscape } = useContext(ResponsiveContext);
  const [isInvitationModalOpen, setIsINvitationModalOpen] = useState(false);
  const { clients } = useContext(AdminTableContext);
  const { deleteUserData, isSuccessModal, editUserData } = useSelector(
    (state) => state.userManagement
  );
  const [chosenClient, setChosenClient] = useState(clients[0]);

  const dispatch = useDispatch();

  useEffect(() => {
    chosenClient?.id &&
      getClientMembersAdmin(chosenClient.id).then((data) => {
        dispatch(setUsersData(data));
      });
  }, [chosenClient?.id, dispatch]);

  useEffect(() => {
    clients?.length && setChosenClient(clients[0]);
  }, [clients, dispatch]);

  return isTablet && !isLandscape ? (
    <RotateDeviceAlert />
  ) : (
    <div>
      <ComponentHeader>
        <ComponentTitle>{`${STRINGS.user_administration}`}</ComponentTitle>
        <HeaderStyledButton
          onClick={() => setIsINvitationModalOpen(true)}
        >{`${STRINGS.invite_team_member}`}</HeaderStyledButton>
      </ComponentHeader>

      <UserAdministrationTable
        chosenClient={chosenClient}
        setChosenClient={setChosenClient}
      />
      <TeamMemberModal
        chosenClient={chosenClient}
        isOpen={isInvitationModalOpen || editUserData}
        onRequestClose={() => {
          setIsINvitationModalOpen(false);
          dispatch(setEditUserData(null));
        }}
      />

      <MemberSuccessModal
        isOpen={isSuccessModal}
        onRequestClose={() => dispatch(setIsSuccessModal(false))}
      />

      <InfoModal
        style={{ maxWidth: "52.6rem" }}
        isOpen={deleteUserData}
        type={InfoModalTypes.WARNING}
        title="Warning!"
        subTitle="You are sure you want to delete this user? This action cannot be undone."
        // noOuterClickClosing
        cancelAction={() => {
          dispatch(setDeleteUserData(null));
        }}
        submitAction={async () => {
          await deleteClientUser(chosenClient.id, deleteUserData.id);
          dispatch(handleDeleteUser());
          dispatch(setDeleteUserData(null));
        }}
        noOuterClickClosing
        cancelText={"Cancel"}
        submitText={"Yes Delete"}
        disabled={false}
      />
    </div>
  );
};

export default UserAdministration;
