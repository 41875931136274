import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

const FloatingElement = ({ parentRef, children }) => {
  const [containerRect, setContainerRect] = useState(null);

  useEffect(() => {
    if (parentRef?.current) {
      const rect = parentRef.current.getBoundingClientRect();
      setContainerRect(rect);
    }
  }, [parentRef]);

  if (!containerRect) {
    return null; // Ensure that rect is calculated before rendering the popup
  }

  return ReactDOM.createPortal(
    <div
      style={{
        position: "absolute",
        zIndex: 999,
        top: containerRect.top + window.scrollY + 60, // adjust position
        left: containerRect.left + window.scrollX + 20, // adjust position
        width: `${containerRect.width}px`,
        height: `${containerRect.height}px`,
      }}
    >
      {children}
    </div>,
    document.body // Always use a valid DOM element
  );
};

export default FloatingElement;
