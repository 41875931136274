/* eslint-disable react/no-unused-prop-types */
import styled from "styled-components";
import { StyledIconWrapper } from "./components";

// @ts-ignore
const Wrapper = styled("div")(({ backgroundColor, size }) => ({
  display: "flex",
  border: "none",
  borderRadius: "50%",
  boxShadow: "0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28)",
  cursor: "pointer",
  outline: "none",
  padding: "0",
  WebkitUserDrag: "none",
  fontWeight: "bold",
  justifyContent: "center",
  alignItems: "center",
  width: size,
  height: size,
  backgroundColor,
  marginLeft: "auto",
}));

const MainButton = ({ iconResting, iconActive, isOpen, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <StyledIconWrapper isOpen={isOpen}>
        {isOpen ? iconActive : iconResting}
      </StyledIconWrapper>
    </Wrapper>
  );
};

export default MainButton;
