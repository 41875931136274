import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StyledOption } from "../components/agenda-assignment-details-modal/components";
import { InputError } from "../components/input/components";

export const useChooseRobot = () => {
  const [chosenRobot, setChosenRobot] = useState(null);
  const { robotsData } = useSelector((state) => state.robots);
  const [robotsList, setRobotsList] = useState([]);

  useEffect(() => {
    if (robotsData.length !== robotsList.length) {
      setRobotsList(robotsData);
    }
  }, [robotsData, robotsList]);

  const SelectRobotComp = useCallback(
    ({
      component: Component,
      errorDivRef,
      isError,
      onChange,
      item,
      defaultChosenRobotId,
      ...rest
    }) => {
      const deafultRobotId =
        defaultChosenRobotId &&
        robotsList.find((el) => el.robotId === defaultChosenRobotId);

      return (
        <div style={{ position: "relative" }}>
          <Component
            onClick={(e) => e.stopPropagation()}
            style={{ width: "100%" }}
            {...rest}
            disabled={item?.disabled}
            value={chosenRobot?.id || item?.liableRobotId || deafultRobotId?.id}
            onChange={(e) => {
              const robot = robotsList.find((el) => el.id === e.target.value);
              setChosenRobot(robot);
              onChange(robot);
            }}
          >
            <StyledOption value={null}>Choose robot</StyledOption>
            {robotsList?.map((el) => (
              <StyledOption value={el.id} key={el.id}>
                {el.name}
              </StyledOption>
            ))}
          </Component>
          {isError && <InputError className="error">* {isError}</InputError>}
        </div>
      );
    },
    [robotsList, chosenRobot]
  );

  return {
    chosenRobot,
    SelectRobotComp,
  };
};
