import io from "socket.io-client";

let room = "";

const socket = io(process.env.REACT_APP_API_URL, {
  auth: {
    token: localStorage.getItem("token"),
  },
});

socket.on("connect", () => {
  console.log("socket connected");
  room && joinSocketRoom(room);
});

socket.on("disconnect", () => {
  console.log("socket disconnected");
});

export const joinSocketRoom = (roomName) => {
  socket.emit("joinRoom", roomName);
  room = roomName;
};

export default socket;
