import styled from "styled-components";
import ICONS from "../../constants/icons";

export const DevicesSelect = styled.select`
  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 16px;
  width: 48.4rem;
  height: 6.4rem;
  padding: 0px 24px 0px 24px;
  appearance: none;
  background-image: url(${ICONS.ChevronDown});
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 20px;
  :focus-visible {
    border: unset;
    outline: 2px solid #0094c6;
  }
`;

export const InputTitleWrapper = styled.div`
  width: 100%;
`;
export const InputTitle = styled.div`
  color: #030a1b;
  font-weight: 400;
  font-size: 16px;
  line-height: 118%;
  margin-left: 20px;
  margin-bottom: 7px;

  > span {
    opacity: 0.4;
  }
`;

export const UnderLine = styled.div`
  width: 100%;
  height: 0px;
  border-bottom: 1px solid #909491;
  /* margin: 30px 0px; */
  opacity: 20%;
  @media screen and (max-width: 1300px) {
    margin: ${(props) => props.assignmentEditMode && "0 0 16px 0"} !important;
  }
`;

export const RadioWrapper = styled.div`
  width: 100%;
  /* margin-top: 10px; */
`;
export const RadioTitle = styled.div`
  color: #0686d8;
  margin-left: 15px;
  font-weight: 500;
  font-size: 24px;
`;

export const RadioInput = styled.input`
  width: 20px;
  height: 20px;
`;

export const LabelText = styled.div`
  margin-left: 10px;
  font-size: 19px;
  color: #030a1b;
`;

export const StyledRadioLabel = styled.label`
  display: flex;
  align-items: center;
`;
export const DateTitle = styled.div`
  margin-left: 15px;
  color: #030a1b;
  font-weight: 500;
  font-size: 18px;
`;

export const DateAndTimeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
  width: 100%;
  gap: 20px;
`;
export const DateBox = styled.div`
  width: 100%;
  height: 64px;
  color: #030a1b;
  font-weight: 400;
  font-size: 16px;
  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: ${(props) => props.showCalendar && "2px solid #0686D8"};
`;
// export const TimeBox = styled.div``;

export const ButtonWrapper = styled.div`
  margin: 44px 0px;
`;
export const StyledButton = styled.div`
  background: #0096c6;
  border-radius: 48px;
  border: unset;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  padding: 12px 70px;
  cursor: pointer;
`;

export const WeekDaysWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

export const WeekDay = styled.div`
  background: rgba(144, 148, 145, 0.2);
  border-radius: 100px;
  width: 52px;
  height: 52px;
  font-weight: 400;
  font-size: 24px;
  opacity: ${(props) => (props.active ? "1" : "0.5")};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.active ? "#0686D8" : "rgba(144, 148, 145, 0.2)"};
  color: ${(props) => (props.active ? "#fff" : "#030a1b")};
  cursor: pointer;

  @media screen and (max-width: 1300px) {
    width: 40px;
    height: 40px;
  }
`;

export const StyledEndTimeDate = styled.div`
  width: 100%;
  padding: 5px 24px;
  border: ${(props) =>
    props.disabled ? "2px solid #fff" : "2px solid #c4c4c4"};
  border-radius: 16px;
  background: ${(props) =>
    props.disabled ? "rgba(144, 148, 145, 0.20)" : "#fff"};
  color: ${(props) => (props.disabled ? "#5c605d" : "#030A1B")};
  font-size: 12px;
`;
export const StyledAfterDate = styled.select`
  width: 100%;
  padding: 5px 24px;
  border: ${(props) =>
    props.disabled ? "2px solid #fff" : "2px solid #c4c4c4"};
  border-radius: 16px;
  background: ${(props) =>
    props.disabled ? "rgba(144, 148, 145, 0.20)" : "#fff"};
  color: ${(props) => (props.disabled ? "#5c605d" : "#030A1B")};
  font-size: 12px;
`;
