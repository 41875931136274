import React, { useState } from "react";
import moment from "moment";
import { TASKLOGSTATUSENUM } from "../../enums";
import {
  StyledTaskLogWrapper,
  StyledInput,
  StyledParamsWrapper,
  StyledStatusLog,
  StyledLogsWrapper,
  StyledTitleWrapper,
} from "./components";
import StatusIcon from "./StatusIcon";
import { useMemo } from "react";
import NotFound from "./NotFound";
import StyledSelectComponent from "../styled-select/StyledSelectComponent";

const options = ["All", ...Object.values(TASKLOGSTATUSENUM)];

const TaskLog = ({ item }) => {
  const [chosenStatus, setChosenStatus] = useState("All");
  const [message, setMessage] = useState("");

  const itemToShow = useMemo(() => {
    const newArr = item?.statusHistory?.filter(
      (log) =>
        log?.message?.toLowerCase().includes(message?.toLowerCase()) &&
        (chosenStatus === "All" || log?.status === chosenStatus)
    );

    return newArr?.reverse();
  }, [chosenStatus, message, item]);

  return (
    <StyledTaskLogWrapper>
      <StyledTitleWrapper>
        <h3>Activity log</h3>
      </StyledTitleWrapper>
      <StyledParamsWrapper>
        <StyledSelectComponent
          width={"45%"}
          options={options}
          chosenValue={chosenStatus}
          setChosenValue={setChosenStatus}
        />

        <StyledInput
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Search"
        />
      </StyledParamsWrapper>
      <StyledLogsWrapper>
        {itemToShow?.length ? (
          itemToShow?.map((log) => (
            <StyledStatusLog key={"tasklog" + log.id}>
              <StatusIcon status={log.status} width={20} height={20} />
              <div style={{ width: "10%" }}>
                {moment(log.updated_at).format("HH:mm")}
              </div>
              <div style={{ width: "85%" }}>{log.message}</div>
            </StyledStatusLog>
          ))
        ) : (
          <NotFound />
        )}
      </StyledLogsWrapper>
    </StyledTaskLogWrapper>
  );
};

export default TaskLog;
