import { useCallback } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import URLS from "../constants/urls";

const useRedirect = () => {
  const history = useHistory();

  const goToDashboard = useCallback(
    () => history.push(URLS.dashboard),
    [history]
  );
  return { goToDashboard };
};

export default useRedirect;
