import styled from "styled-components";

export const StyledListParameterButton = styled.button`
  display: flex;
  align-items: center;
  height: 5.4rem;
  width: 100%;
  color: rgb(203, 213, 207);
  background: ${(props) =>
    props.disabled ? "rgba(237, 237, 237, 0.5)" : "#fff"};
  padding: 0px 2.4rem;
  border-radius: 1.6rem;
  border: ${(props) =>
    props.disabled ? "unset" : "2px solid rgb(203, 213, 207)"};
  cursor: pointer;
  color: rgb(3, 10, 27);
  gap: 5px;
`;
