import OutlineBack from "../assets/OutlineBack.svg";
import LogoHorizontal from "../assets/LogoHorizontal.svg";
import Search from "../assets/Search.svg";
import LogoRoundBackground from "../assets/LogoRoundBackground.svg";
import BurgerMenu from "../assets/BurgerMenu.svg";
import LogOut from "../assets/LogOut.svg";
import NavDashboard from "../assets/NavDashboard.svg";
import NavApps from "../assets/NavApps.svg";
import NavSettings from "../assets/NavSettings.svg";
import NavTeam from "../assets/NavTeam.svg";
import NavProfile from "../assets/NavProfile.svg";
import Email from "../assets/Email.svg";
import PasswordEye from "../assets/PasswordEye.svg";
import ForgotPasswordImg from "../assets/ForgotPasswordImg.svg";
import VerifyEmailImg from "../assets/VerifyEmailImg.svg";
import DashboardCardProfile from "../assets/DashboardCardProfile.svg";
import DashboardCardKnowledge from "../assets/DashboardCardKnowledge.svg";
import DashboardCardApp from "../assets/DashboardCardApp.svg";
import DashboardCardDemo from "../assets/DashboardCardDemo.svg";
import VerticalDots from "../assets/VerticalDots.svg";
import GrayTrash from "../assets/GrayTrash.svg";
import GrayEdit from "../assets/GrayEdit.svg";
import CloseGray from "../assets/CloseGray.svg";
import UserProfile from "../assets/UserProfile.svg";
import DropdownIndicator from "../assets/DropdownIndicator.svg";
import CheckWhite from "../assets/CheckWhite.svg";
import CircleWhite from "../assets/CircleWhite.svg";
import AppDefaultLogo from "../assets/AppDefaultLogo.png";
import AppCardDropdown from "../assets/AppCardDropdown.svg";
import AppStatusPending from "../assets/AppStatusPending.svg";
import AppStatusInReview from "../assets/AppStatusInReview.svg";
import AppStatusLive from "../assets/AppStatusLive.svg";
import AppStatusDraft from "../assets/AppStatusDraft.svg";
import Impressions from "../assets/Impressions.svg";
import Installs from "../assets/Installs.svg";
import Sales from "../assets/Sales.svg";
import Rating from "../assets/Rating.svg";
import Info from "../assets/Info.svg";
import WarningModalIcon from "../assets/WarningModalIcon.svg";
import AttentionModalIcon from "../assets/AttentionModalIcon.svg";
import SuccessModalIcon from "../assets/SuccessModalIcon.svg";
import CloseWhite from "../assets/CloseWhite.svg";
import AppReleaseInReview from "../assets/AppWaitingReview.svg";
import AppReleaseCancelled from "../assets/AppReleaseCancelled.svg";
import AppReleaseLive from "../assets/AppReleased.svg";
import NoReleaseIcon from "../assets/NoReleaseIcon.svg";
import NoDashboardIcon from "../assets/NoDashboardIcon.svg";
import ArrowRightGreenBox from "../assets/ArrowRightGreenBox.svg";
import RedirectIconEditApp from "../assets/RedirectIconEditApp.svg";
import RedirectIconCreateRelease from "../assets/RedirectIconCreateRelease.svg";
import DragAndDrop from "../assets/DragAndDrop.svg";
import UploadFileCloud from "../assets/UploadFileCloud.svg";
import DeleteBin from "../assets/DeleteBin.svg";
import Loader from "../assets/Loader.svg";
import LoaderGIF from "../assets/loading.gif";
import ArrowRight from "../assets/ArrowRight.svg";
import KnowledgeCenter from "../assets/KnowledgeCenter.svg";
import ExploreDemos from "../assets/ExploreDemos.svg";
import EditSimpleWhite from "../assets/EditSimpleWhite.svg";
import AddNewApp from "../assets/AddNewApp.svg";
import AddIcon from "../assets/AddIcon.svg";
import CheckBlue from "../assets/CheckBlue.svg";
import Scheduler from "../assets/Scheduler.svg";
import MenuVertical from "../assets/MenuVertical.svg";
import PencilDraw from "../assets/PencilDraw.svg";
import DeleteIcon from "../assets/DeleteIcon.svg";
import ArrowUp from "../assets/ArrowUp.svg";
import ArrowDown from "../assets/ArrowDown.svg";
import ChevronDown from "../assets/ChevronDown.svg";
import ChevronUp from "../assets/ChevronUp.svg";
import ToggleOff from "../assets/ToggleOff.svg";
import ToggleOn from "../assets/ToggleOn.svg";
import BusyRobot from "../assets/BusyRobot.svg";
import OutOfServiceRobot from "../assets/OutOfServiceRobot.svg";
import FreeRobot from "../assets/FreeRobot.svg";
import ChargingRobot from "../assets/ChargingRobot.svg";
import OffRobot from "../assets/OffRobot.svg";
import Twenty from "../assets/battery/BatteryBefore20.svg";
import Forty from "../assets/battery/BatteryBefore40.svg";
import Sixty from "../assets/battery/BatteryBefore60.svg";
import Eighty from "../assets/battery/BatteryBefore80.svg";
import Hundred from "../assets/battery/BatteryBefore100.svg";
import ChevronLeft from "../assets/ChevronLeft.svg";
import RobotBackground from "../assets/RobotBackground.png";
import ToggleActive from "../assets/ToggleActive.svg";
import VerticalWhiteDots from "../assets/VerticalWhiteDots.svg";
import Document from "../assets/Document.svg";
import ColoredDocument from "../assets/ColoredDocument.svg";
import ColoredStatus from "../assets/ColoredStatus.svg";
import DeviceIcon from "../assets/DeviceIcon.svg";
import ClockIcon from "../assets/ClockIcon.svg";
import SaveDisk from "../assets/SaveDisk.svg";
import DoubleArrowLeft from "../assets/DoubleArrowLeft.svg";
import DoubleArrowRight from "../assets/DoubleArrowRight.svg";
import SendToIcon from "../assets/SendToIcon.svg";
import BringToIcon from "../assets/BringToIcon.svg";
import PatrolIcon from "../assets/PatrolIcon.svg";
import AssignTaskIcon from "../assets/AssignTaskIcon.svg";
import CurrentTimeLine from "../assets/CurrentTimeLine.svg";
import CheckGreen from "../assets/CheckGreen.svg";
import InfoIcon from "../assets/InfoIcon.svg";
import PlusCircle from "../assets/PlusCircle.svg";
import AssignmentIcon from "../assets/AssignmentIcon.svg";
import WaitingClock from "../assets/WaitingClock.svg";
import RedCross from "../assets/RedCross.svg";
import NoResult from "../assets/NoResult.svg";
import BlueInfoIcon from "../assets/BlueInfoIcon.svg";
import FailedIcon from "../assets/FailedIcon.svg";
import WarningIcon from "../assets/WarningIcon.svg";
import DoneIcon from "../assets/DoneIcon.svg";
import UpcomingIcon from "../assets/UpcomingIcon.svg";
import BackArrowBlue from "../assets/BackArrowBlue.svg";
import BlackCross from "../assets/BlackCross.svg";
import WarningHexagonIcon from "../assets/WarningHexagonIcon.svg";
import WarningCrossedCircle from "../assets/WarningCrossedCircle.svg";
import ErrorRobotIcon from "../assets/ErrorRobotIcon.svg";
import GreyCross from "../assets/GreyCross.svg";
import Checked from "../assets/checked.svg";
import ClockProgress from "../assets/clock-progress.svg";
import WarningTriangle from "../assets/WarningTriangle.svg";
import AbortIcon from "../assets/AbortIcon.svg";
import StoppedIcon from "../assets/StoppedIcon.svg";
import AnswerCall from "../assets/AnswerCall.svg";
import DeclineCall from "../assets/DeclineCall.svg";
import Minus from "../assets/Minus.svg";
import FullscreenIcon from "../assets/FullscreenIcon.svg";
import FullscreenWhite from "../assets/FullscreenWhite.svg";
import MinimizeFullscreenWhite from "../assets/MinimizeFullscreen.svg";
import MicrophoneOff from "../assets/MicrophoneOff.svg";
import MicrophoneOn from "../assets/MicrophoneOn.svg";
import CameraOff from "../assets/CameraOff.svg";
import CameraOn from "../assets/CameraOn.svg";
import StoppedOrangeIcon from "../assets/StoppedOrangeIcon.svg";
import LocationIcon from "../assets/locationIcon.svg";
import UsersGroup from "../assets/UsersGroup.svg";
import HoldCall from "../assets/HoldCall.svg";
import ListIcon from "../assets/ListIcon.svg";
import ButtonArrow from "../assets/ButtonArrow.svg";
import Whatsapp from "../assets/Whatsapp.svg";
import EmailIcon from "../assets/EmailIcon.svg";
import EmptyPaper from "../assets/EmptyPaper.svg";
import DoubleEmptyPaper from "../assets/DoubleEmptyPaper.svg";
import CalendarIcon from "../assets/CalendarIcon.svg";
import Camera from "../assets/Camera.svg";
import LocationPin from "../assets/locationPin.svg";
import WhiteBurgerMenu from "../assets/WhiteBurgerMenu.svg";
import LogoutBlue from "../assets/LogoutBlue.svg";
import LogoutRed from "../assets/LogoutRed.svg";
import SquareBlocksBlue from "../assets/SquareBlocksBlue.svg";
import MapIconGrey from "../assets/MapIconGrey.svg";
import MapIconBlue from "../assets/MapIconBlue.svg";
import SquareBlocksGrey from "../assets/SquareBlocksGrey.svg";
import turn360 from "../assets/turn360.svg";
import OutlineBlueWave from "../assets/OutlineBlueWave.svg";
import SearchGrey from "../assets/SearchGrey.svg";
import WhiteCross from "../assets/WhiteCross.svg";
import UploadImage from "../assets/UploadImage.svg";
import ChargingIcon from "../assets/ChargingIcon.svg";
import ErrorRobot from "../assets/ErrorRobot.svg";
import Pie from "../assets/Pie.svg";
import Chart from "../assets/Chart.svg";
import Graph from "../assets/Graph.svg";
import NumberOneIcon from "../assets/NumberOneIcon.svg";
import TaskCompleted from "../assets/TaskCompleted.svg";
import Analytics from "../assets/Analytics.svg";
import GreyArrow from "../assets/GreyArrow.svg";
import AlarmIcon from "../assets/AlarmIcon.svg";

const ICONS = {
  OutlineBack,
  LogoHorizontal,
  Search,
  LogoRoundBackground,
  BurgerMenu,
  LogOut,
  NavDashboard,
  NavApps,
  NavSettings,
  NavTeam,
  NavProfile,
  Email,
  PasswordEye,
  ForgotPasswordImg,
  VerifyEmailImg,
  DashboardCardProfile,
  DashboardCardKnowledge,
  DashboardCardApp,
  DashboardCardDemo,
  VerticalDots,
  GrayTrash,
  GrayEdit,
  CloseGray,
  UserProfile,
  DropdownIndicator,
  CheckWhite,
  CircleWhite,
  AppDefaultLogo,
  AppCardDropdown,
  AppStatusPending,
  AppStatusInReview,
  AppStatusLive,
  AppStatusDraft,
  Impressions,
  Installs,
  Sales,
  Rating,
  Info,
  WarningModalIcon,
  AttentionModalIcon,
  SuccessModalIcon,
  CloseWhite,
  AppReleaseCancelled,
  AppReleaseInReview,
  AppReleaseLive,
  NoReleaseIcon,
  NoDashboardIcon,
  ArrowRightGreenBox,
  RedirectIconEditApp,
  RedirectIconCreateRelease,
  DragAndDrop,
  UploadFileCloud,
  DeleteBin,
  Loader,
  LoaderGIF,
  ArrowRight,
  KnowledgeCenter,
  ExploreDemos,
  EditSimpleWhite,
  AddNewApp,
  AddIcon,
  CheckBlue,
  Scheduler,
  MenuVertical,
  PencilDraw,
  DeleteIcon,
  ArrowUp,
  ArrowDown,
  ChevronDown,
  ToggleOff,
  ToggleOn,
  BusyRobot,
  OutOfServiceRobot,
  FreeRobot,
  ChargingRobot,
  OffRobot,
  Twenty,
  Forty,
  Sixty,
  Eighty,
  Hundred,
  ChevronLeft,
  RobotBackground,
  ToggleActive,
  VerticalWhiteDots,
  Document,
  ColoredDocument,
  ColoredStatus,
  DeviceIcon,
  ClockIcon,
  SaveDisk,
  DoubleArrowRight,
  DoubleArrowLeft,
  SendToIcon,
  BringToIcon,
  PatrolIcon,
  AssignTaskIcon,
  CurrentTimeLine,
  CheckGreen,
  InfoIcon,
  PlusCircle,
  AssignmentIcon,
  WaitingClock,
  RedCross,
  NoResult,
  BlueInfoIcon,
  FailedIcon,
  WarningIcon,
  DoneIcon,
  UpcomingIcon,
  BackArrowBlue,
  BlackCross,
  WarningHexagonIcon,
  WarningCrossedCircle,
  ErrorRobotIcon,
  GreyCross,
  Checked,
  ClockProgress,
  WarningTriangle,
  AbortIcon,
  StoppedIcon,
  AnswerCall,
  DeclineCall,
  HoldCall,
  Minus,
  FullscreenIcon,
  FullscreenWhite,
  MinimizeFullscreenWhite,
  MicrophoneOff,
  MicrophoneOn,
  CameraOff,
  CameraOn,
  StoppedOrangeIcon,
  LocationIcon,
  ChevronUp,
  UsersGroup,
  ListIcon,
  ButtonArrow,
  Whatsapp,
  EmailIcon,
  EmptyPaper,
  DoubleEmptyPaper,
  CalendarIcon,
  LocationPin,
  Camera,
  WhiteBurgerMenu,
  LogoutBlue,
  LogoutRed,
  SquareBlocksBlue,
  SquareBlocksGrey,
  MapIconGrey,
  MapIconBlue,
  turn360,
  OutlineBlueWave,
  SearchGrey,
  WhiteCross,
  UploadImage,
  ChargingIcon,
  ErrorRobot,
  Pie,
  Chart,
  Graph,
  NumberOneIcon,
  TaskCompleted,
  Analytics,
  GreyArrow,
  AlarmIcon,
};

export default ICONS;
