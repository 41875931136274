import React, { useRef } from "react";
import GetElementCoordinates from "../get-element-coordinates/GetElementCoordinates";
import { ElipsisAndTooltipTextWrapper, StyledContent } from "./components";

const ElipsisAndTooltipText = ({
  tooltipPosition = "top",
  children,
  ...rest
}) => {
  const textRef = useRef(null);

  return (
    <ElipsisAndTooltipTextWrapper>
      <GetElementCoordinates
        disabled={
          textRef?.current?.offsetWidth >= textRef?.current?.scrollWidth
        }
        styles={{
          background: "#FFFFFF",
          color: "#08183F",
          whiteSpace: "nowrap",
          border: "1px solid #fff",
          boxShadow: "0px 0px 14px 0px rgba(0, 0, 0, 0.25)",
        }}
        type={tooltipPosition}
        text={children}
      >
        <StyledContent ref={textRef} {...rest}>
          {children}
        </StyledContent>
      </GetElementCoordinates>
    </ElipsisAndTooltipTextWrapper>
  );
};

export default ElipsisAndTooltipText;
