import React, { useContext, useMemo } from "react";
import Modal from "react-modal";
import COLORS from "../../constants/colors";
import robotPNG from "../../assets/png/robot.png";
import {
  ITMWrapper,
  LeftLoading,
  LeftError,
  LeftDone,
  ModalCloseX,
  StyledRobotImg,
} from "./components";
import Loader from "../loader/Loader";
import { NotAllowedSVG } from "../../assets/NotAllowedSVG";
import ICONS from "../../constants/icons";
import { useSelector } from "react-redux";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";

const ImmediateTaskModal = ({ onRequestClose }) => {
  const { ITModal } = useSelector((state) => state.dashboard);
  const { isMobile } = useContext(ResponsiveContext);
  const customStyles = {
    overlay: {
      backgroundColor: `${COLORS.primary.oxford}80`,
      zIndex: "999",
    },

    content: {
      zIndex: "999",
      width: isMobile ? "80%" : "649px",
      height: "320px",
      overflow: "unset",
      top: "50%",
      left: "50%",
      right: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "16px",
      border: "none",
      padding: "0 ",
    },
  };

  const renderContent = useMemo(() => {
    switch (ITModal?.isOpen) {
      case "loading":
        return (
          <LeftLoading isMobile={isMobile}>
            <h1>Searching for a robot</h1>
            <Loader height={0} noText noMargin padding={0} />
            {!isMobile && (
              <p>Please wait a moment while we search for an available robot</p>
            )}
            {/* <button onClick={onRequestClose}>OK</button> */}
          </LeftLoading>
        );

      case "error":
        return (
          <LeftError isMobile={isMobile}>
            <h1>No robots available at the moment</h1>
            <NotAllowedSVG />
            {!isMobile &&
              (ITModal?.error ? (
                <p>{ITModal?.error}</p>
              ) : (
                <p>Please try again later</p>
              ))}
            <button onClick={onRequestClose}>OK</button>
          </LeftError>
        );

      case "done":
        return (
          <LeftDone isMobile={isMobile}>
            <h1>
              Great! <br /> Robot {ITModal?.robotName} found!
            </h1>
            <img src={ICONS.Checked} alt="Done" />
            {!isMobile && <p>Your task will start now</p>}
            <button onClick={onRequestClose}>OK</button>
          </LeftDone>
        );

      case "busy":
        return (
          <LeftDone isMobile={isMobile}>
            <img src={ICONS.ClockProgress} alt="Busy" />
            <h1>Robot {ITModal?.robotName} is currently busy</h1>
            {!isMobile && (
              <p>Please wait a few moments for it to become available</p>
            )}
            <button onClick={onRequestClose}>OK</button>
          </LeftDone>
        );

      case "emergency":
        return (
          <LeftDone isMobile={isMobile}>
            <img src={ICONS.AlarmIcon} alt="alarm" />
            <h1>Emergency button pressed</h1>
            {!isMobile && <p>{ITModal?.robotName}</p>}
            <button onClick={onRequestClose}>OK</button>
          </LeftDone>
        );
      default:
        break;
    }
  }, [
    ITModal?.isOpen,
    ITModal?.error,
    onRequestClose,
    ITModal?.robotName,
    isMobile,
  ]);

  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={!!ITModal?.isOpen}
      style={customStyles}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    >
      <ITMWrapper>
        {renderContent}
        <StyledRobotImg isMobile={isMobile} src={robotPNG} alt="robot" />
      </ITMWrapper>
      <ModalCloseX onClick={onRequestClose} />
    </Modal>
  );
};

export default ImmediateTaskModal;
