import React, { useCallback, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import OutlineNonAuthorised from "../../components/outline-non-authorised/OutlineNonAuthorised";
import ICONS from "../../constants/icons";
import STRINGS from "../../constants/strings";
import URLS from "../../constants/urls";
import {
  AddMemberContentWrapper,
  ForgotPassword,
  FormContentWrapper,
  GeneralError,
  Title,
} from "./components";
import InputText from "../../components/input/InputText";
import CustomButton from "../../components/button/CustomButton";
import { Link, useHistory } from "react-router-dom";
import COLORS from "../../constants/colors";
import { userSignIn } from "../../redux/clients";
import { useDispatch, useSelector } from "react-redux";
import { LoaderSign } from "../../components/loader/components";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export const SignIn = () => {
  const { sign_error } = useSelector((state) => state.clients);
  const { token } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const goToDashboard = useCallback(
    () => history.push(URLS.dashboard),
    [history]
  );

  useEffect(() => {
    if (sign_error === "user is not verified") {
      history.push(URLS.resend_verification);
    }
  }, [history, sign_error]);

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      goToDashboard();
    }
  }, [token, goToDashboard]);

  return (
    <OutlineNonAuthorised>
      <AddMemberContentWrapper>
        <Title style={{ fontSize: "4rem", color: "#0096C6", width: "100%" }}>
          {STRINGS.welcome_to_orchestra}
        </Title>

        <Formik
          onSubmit={(values, setErrors) => {
            let vars = { ...values, email: values.email.trim() };
            dispatch(userSignIn({ vars, setErrors, goToDashboard }));
          }}
          initialValues={{ email: "", password: "" }}
        >
          {({ setFieldValue, errors, touched, setErrors, isSubmitting }) => (
            <Form>
              <FormContentWrapper
                style={{
                  width: "40.8rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                disabled={isSubmitting && !sign_error}
              >
                <Field id="email" name="email">
                  {({ field }) => (
                    <InputText
                      height="5.6rem"
                      margin="1.8rem 0"
                      placeholder={STRINGS.please_type_your_email}
                      handleChange={(v) => setFieldValue(field.name, v)}
                      name={STRINGS.email}
                      value={field.value}
                      // error={touched[field.name] && errors[field.name]}
                      icon={ICONS.Email}
                    />
                  )}
                </Field>
                <Field id="password" name="password">
                  {({ field }) => (
                    <InputText
                      type="password"
                      height="5.6rem"
                      margin="1.8rem 0"
                      placeholder={STRINGS.type_your_pass}
                      handleChange={(v) => setFieldValue(field.name, v)}
                      name={STRINGS.password}
                      value={field.value}
                      // error={touched[field.name] && errors[field.name]}
                      icon={ICONS.PasswordEye}
                    />
                  )}
                </Field>
                {sign_error && (
                  <GeneralError className="error">
                    {`* ${sign_error}
                  `}
                  </GeneralError>
                )}
                <CustomButton
                  type="submit"
                  backgroundColor={
                    isSubmitting && !sign_error ? "#fff" : COLORS.secondary.ncs
                  }
                  width="100%"
                  padding="0 7.2rem"
                  height="5.8rem"
                  margin="1rem 0 1.4rem"
                  shadow
                >
                  {isSubmitting && !sign_error ? (
                    <LoaderSign
                      noMargin
                      style={{ width: "5.4rem", height: "5.4rem" }}
                    />
                  ) : (
                    STRINGS.log_in
                  )}
                </CustomButton>
                <Link style={{ height: "5rem" }} to={URLS.forgot_password}>
                  <ForgotPassword>{STRINGS.forgot_password}</ForgotPassword>
                </Link>
              </FormContentWrapper>
            </Form>
          )}
        </Formik>
      </AddMemberContentWrapper>
    </OutlineNonAuthorised>
  );
};
