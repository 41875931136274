export const ASSIGNMENTSTATUSENUM = {
  Active: "active",
  Off: "off",
  ENDED: "ended",
  EXPIRED: "expired",
};

export const ASSIGNMENTPROGRESSTEXTENUM = {
  WAITING: "Waiting",
  INPROGRESS: "In progress",
  DONE: "Done",
  FAILED: "Failed",
  STOPPED: "Stopped",
  STOPPED_ASSIGNMENT: "Stopped_assignment",
  UPCOMING: "Upcoming",
};

export const MAPLOCATIONENUM = {
  CHECKPOINT: "checkpoint",
  WORKINGAREA: "working-area",
  SHAREDZONE: "shared-zone",
  LOCATION: "location",
  ROUTE: "route",
};

export const POLYGONENUMS = {
  SHAREDZONE: "Polygon_sharedZones",
  WORKINGAREA: "Polygon_workingAreas",
};

export const ZONEIDENUM = {
  CHHECKPOINT: "checkpoint",
  SHAREDZONE: "sharedZones",
  WORKINGAREA: "workingAreas",
  LOCATION: "location",
  ROUTE: "route",
};

export const CHECKPOINTDIRECTIONENUM = {
  WTS: "WTS",
  STS: "STS",
  STW: "STW",
  WTW: "WTW",
};

export const ROBOTSTATUSENUM = {
  OFF: "Off",
  PROGRESS: "in progress",
  BUSY: "Busy",
  FREE: "Free",
  CHARGING: "Charging",
  ERROR: "ERROR",
  PAUSED: "PAUSED",
};

export const ROBOTSTATUSENUMALIAS = {
  Off: "Off",
  inprogress: "in progress",
  Busy: "Busy",
  Free: "Available",
  Charging: "Charging",
  ERROR: "ERROR",
  PAUSED: "Paused",
};

export const ROBOTTASKSTATUSENUM = {
  SENTTOROBOT: "Sent to robot",
  INPROGRESS: "In progress",
  DONE: "Done",
  DELAYED: "Delay",
  ERROR: "Error occured during task assign",
  Failed: "Failed",
  TIMEOUT: "Failed to send task to the robot within application expire time",
  OFFLINE: "Robot is offline",
  DEFAULT: "Upcoming",
  STOPPED: "Stopped",
  STOPPED_ASSIGNMENT: "Stopped_assignment",
  EMERGENCY: "emergency",
};

export const AGENDAASSIGNMENTSSTATUSENUM = {
  DELAYED: "Delay",
  INPROGRESS: "In progress",
  DONE: "Done",
  FAILED: "Failed",
  DEFAULT: "Upcoming",
  STOPPED: "Stopped",
  STOPPED_ASSIGNMENT: "Stopped_assignment",
};

export const APIRESPONSEENUM = {
  ERR_BAD_REQUEST: "ERR_BAD_REQUEST",
};

export const TASKLOGSTATUSENUM = {
  INFO: "Info",
  WARNING: "Warning",
  SUCCESS: "Success",
  ERROR: "Error",
};

export const ROLESENUM = {
  ADMIN: "Admin",
  MANAGER: "Manager",
  USER: "User",
  VIEWER: "Viewer",
  DEVELOPER: "Developer",
};

export const CAMERAALIASES = {
  nav_top: "Top Camera",
  nav_bottom: "Bottom Camera",
  cv_top: "Upper Camera",
  back: "Back Camera",
};

export const MAPTYPESENUM = {
  DASHBOARD_MAP: "mapFile",
  FLEET_RULES_MAP: "editedMapFile",
};

export const CHARINGMETHODSENUM = {
  CHARING_AVAILABILITY: "Charging Availability",
  PREDEFINED_TIMES: "Predefined Times",
};
