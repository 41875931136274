import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import useComponentVisible from "../../hooks/useComponentVisible";
import { handleAssignmentDetailsUpdate } from "../../redux/dashboard";
import { detectChanges } from "../../utils/detectChanges";
import {
  ModalCloseXRight,
  ModalHeaderright,
  DrawerWrapper,
  StyledOverlay,
} from "./components";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";

const Drawer = ({
  heading,
  // onRequestClose = () => {},
  children,
  isOpen,
  setIsDiscardModal,
  currentModalData,
  newAssignmentForm,
  calendar,
  leftSide,
  setDrawerRef,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const { isAssignmentEditMode } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const drawerWrapperRef = useRef();
  const { isMobile } = useContext(ResponsiveContext);
  const {
    ref,
    isComponentVisible: insideClick,
    setIsComponentVisible,
  } = useComponentVisible(true);

  const handleMouseEnter = useCallback(() => {
    setShowOverlay(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setShowOverlay(false);
  }, []);
  const handleCloseDrawer = useCallback(() => {
    const changes = detectChanges(currentModalData, newAssignmentForm);
    if (changes && isAssignmentEditMode) {
      setIsDiscardModal(true);
    } else {
      dispatch(handleAssignmentDetailsUpdate(null));
      setIsComponentVisible(true);
    }
  }, [
    dispatch,
    setIsComponentVisible,
    currentModalData,
    newAssignmentForm,
    setIsDiscardModal,
    isAssignmentEditMode,
  ]);

  useEffect(() => {
    if (!insideClick && !isAssignmentEditMode) handleCloseDrawer();
  }, [insideClick, isAssignmentEditMode, handleCloseDrawer]);

  useEffect(() => {
    if (drawerWrapperRef.current) {
      setDrawerRef && setDrawerRef(drawerWrapperRef);
    }
  }, [drawerWrapperRef, setDrawerRef]);
  return (
    <React.Fragment>
      <DrawerWrapper
        style={{
          width: calendar && !isAssignmentEditMode && "100%",
        }}
        leftSide={leftSide}
        calendar={calendar && !isAssignmentEditMode}
        isOpen={isOpen}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        mobile={isMobile}
      >
        <div ref={drawerWrapperRef}>
          <ModalHeaderright className="clickAllow">
            {heading}
            <ModalCloseXRight
              className="clickRevert"
              onClick={handleCloseDrawer}
            />
          </ModalHeaderright>
          <div
            ref={ref}
            style={{
              height:
                calendar && !isAssignmentEditMode
                  ? "auto"
                  : "calc(100% - 70px)",
              overflow: "auto",
              display: "flex",
              background: "#fff",
              flexDirection: "column",
            }}
          >
            {children}
          </div>
        </div>
      </DrawerWrapper>
      {showOverlay && isOpen && !calendar && <StyledOverlay />}
    </React.Fragment>
  );
};

export default Drawer;
