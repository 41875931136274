import React from "react";
import { RadioTitle } from "../add-assignment-modal/components";
import { CheckBoxWrapper } from "./components";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUsersData } from "../../redux/userManagement";
import getClientMembers from "../../APIs/user-management/getClientMembers";
import { useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import MultiUserSelect from "./MultiUserSelect";

const AssignmentNotifications = ({
  newAssignmentForm,
  setNewAssignmentForm,
}) => {
  const { client } = useSelector((state) => state.clients);
  const dispatch = useDispatch();
  const [showEmailNots, setShowEmailNots] = useState(false);
  const [showEmailOptions, setShowEmailOptions] = useState(false);
  const [showPhoneOptions, setShowPhoneOptions] = useState(false);

  const [showPhoneNots, setShowPhoneNots] = useState(false);
  const { usersData } = useSelector((state) => state.userManagement);
  useEffect(() => {
    client?.id &&
      getClientMembers(client.id).then((data) => {
        dispatch(setUsersData(data));
      });
  }, [client.id, dispatch]);
  const handleCheckboxChange = (e, user, arrName) => {
    if (user.id === "All" && e.target.checked) {
      setNewAssignmentForm((prev) => ({
        ...prev,
        [arrName]: usersData,
      }));
    } else if (user.id === "All" && !e.target.checked) {
      setNewAssignmentForm((prev) => ({
        ...prev,
        [arrName]: [],
      }));
    } else {
      const isChosen = newAssignmentForm[arrName]?.find(
        (el) => el.id === user.id
      );
      setNewAssignmentForm((prev) => ({
        ...prev,
        [arrName]: isChosen
          ? prev[arrName].filter((item) => item.id !== user.id)
          : [...(prev[arrName] || []), user],
      }));
    }
  };
  const handleCheckBoxClick = (name) => {
    if (name === "emailNots") {
      if (showEmailNots) {
        setNewAssignmentForm((prev) => ({ ...prev, emailNots: [] }));
        setShowEmailNots(false);
      } else setShowEmailNots(true);
    }
    if (name === "phoneNots") {
      if (showPhoneNots) {
        setNewAssignmentForm((prev) => ({ ...prev, phoneNots: [] }));
        setShowPhoneNots(false);
      } else setShowPhoneNots(true);
    }
  };
  const emailSelectName = !newAssignmentForm?.emailNots?.length
    ? "Select Users"
    : newAssignmentForm?.emailNots?.length === 1
    ? newAssignmentForm?.emailNots[0]?.firstName +
      " " +
      newAssignmentForm?.emailNots[0]?.lastName
    : `${newAssignmentForm?.emailNots?.length} Users`;
  const phoneSelectName = !newAssignmentForm?.phoneNots?.length
    ? "Select Users"
    : newAssignmentForm?.phoneNots?.length === 1
    ? newAssignmentForm?.phoneNots[0]?.firstName +
      " " +
      newAssignmentForm?.phoneNots[0]?.lastName
    : `${newAssignmentForm?.phoneNots?.length} Users`;
  return (
    <div style={{ width: "100%" }}>
      <RadioTitle>Notification</RadioTitle>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <CheckBoxWrapper>
          <input
            type="checkbox"
            id="email"
            checked={showEmailNots || !!newAssignmentForm?.emailNots?.length}
            onChange={() => handleCheckBoxClick("emailNots")}
          />
          <label htmlFor="email"> Notify By Email</label>
        </CheckBoxWrapper>
        {(showEmailNots || !!newAssignmentForm?.emailNots?.length) && (
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowEmailOptions(false);
            }}
          >
            <MultiUserSelect
              newAssignmentForm={newAssignmentForm}
              notificationType={"emailNots"}
              handleCheckboxChange={handleCheckboxChange}
              showOptions={showEmailOptions}
              usersData={usersData}
              text={
                (usersData?.length === newAssignmentForm?.emailNots?.length &&
                  "All Users") ||
                emailSelectName
              }
              handleClick={(e) => {
                e.preventDefault();
                setShowEmailOptions((prev) => !prev);
              }}
            />
          </OutsideClickHandler>
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <CheckBoxWrapper>
          <input
            type="checkbox"
            id="phoneNots"
            checked={showPhoneNots || !!newAssignmentForm?.phoneNots?.length}
            onChange={() => handleCheckBoxClick("phoneNots")}
          />
          <label htmlFor="phoneNots">Notify By Whatsapp/SMS</label>
        </CheckBoxWrapper>
        {(showPhoneNots || !!newAssignmentForm?.phoneNots?.length) && (
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowPhoneOptions(false);
            }}
          >
            <MultiUserSelect
              newAssignmentForm={newAssignmentForm}
              notificationType={"phoneNots"}
              handleCheckboxChange={handleCheckboxChange}
              showOptions={showPhoneOptions}
              usersData={usersData}
              text={
                (usersData?.length === newAssignmentForm?.phoneNots?.length &&
                  "All Users") ||
                phoneSelectName
              }
              handleClick={(e) => {
                e.preventDefault();
                setShowPhoneOptions((prev) => !prev);
              }}
            />
          </OutsideClickHandler>
        )}
      </div>
    </div>
  );
};

export default AssignmentNotifications;
