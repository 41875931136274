import styled from "styled-components";

export const ModalContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalWarningHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalWarningTitle = styled.div`
  color: #030a1b;
  font-size: 32px;
  line-height: 118%;
  margin-top: 12px;
`;

export const CloseIconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

export const ModalWarningText = styled.div`
  text-align: center;
  font-size: 18px;
  color: #000;
  margin-top: 12px;
  width: 100%;
`;

export const ResponseModalContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`;

export const ResponseModalText = styled.div`
  color: #030a1b;
  font-size: 36px;
  margin-top: 12px;
`;

export const ResponseModalParagraph = styled.div`
  font-size: 16px;
  color: #000;
  text-align: center;
  margin-top: 30px;
  line-height: 160%;
`;
