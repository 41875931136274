import axios from "axios";

const getAnalyticsLogs = async (clientId, params) => {
  const res = await axios.get(
    `/analytics/logs/${clientId}?${params.toString()}`
  );
  return res.data;
};

export default getAnalyticsLogs;
