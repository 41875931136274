import styled from "styled-components";

export const StyledTooltipWrapper = styled.div`
  position: fixed;
  transform: translate(-50%, -100%);
  border-radius: 8px;
  top: 0;
  left: 0;
  z-index: 9999;
`;
export const StyledTooltipBox = styled.div`
  border: ${(props) => props.border || "1px solid #000"};
  z-index: 1;
  padding: 10px 12px;
  background: #fff;
  white-space: nowrap;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  border-radius: ${(props) => props.borderRadius || "8px"};
  font-weight: 400;
  width: ${(props) => props.width || "auto"};
`;

export const StyledTooltipTriangle = styled.div`
  height: 10px;
  width: 10px;
  transform: rotate(45deg);
  position: absolute;
  border-bottom: ${(props) => props.border || "1px solid #000"};
  border-right: ${(props) => props.border || "1px solid #000"};
  border-left: unset;
  border-top: unset;
  background: ${(props) => props.background};
  border-bottom-right-radius: 3px;
  z-index: 5;
  /* left: 75px; */
  bottom: -4.5px;
`;
