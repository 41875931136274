import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import getLocations from "../../APIs/locations/getLocations";
import getTasksFromArchiveById from "../../APIs/tasks-archive/getTaskArchiveById";
import { AGENDAASSIGNMENTSSTATUSENUM, ROBOTTASKSTATUSENUM } from "../../enums";
import { setLocations } from "../../redux/maps";
import AssignmentDetails from "./AssignmentDetails";
import Loader from "../loader/Loader";
import Drawer from "../Drawer/Drawer";

const MobileLink = () => {
  const { assignmentId } = useParams();
  const [assignmentDetails, setAssignmentDetails] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getTasksFromArchiveById(assignmentId).then((data) => {
      if (data.length === 1) setAssignmentDetails(data[0]);
      else if (data.length > 1) {
        let assignments = [];

        data.forEach((item) => {
          let index = false;
          const found = assignments.find((el, ind) => {
            if (el.assignmentId === item.assignment.id) {
              index = ind;
              return true;
            }
            return false;
          });
          if (!found) {
            assignments = [
              ...assignments,
              {
                id: item.assignment.id,
                assignmentId: item.assignment.id,
                launchId: item.launchId,
                assignment: true,
                executionTime: item.assignment.executionTime,
                name: item.assignment?.name,
                disposable: item.assignment.disposable,
                status: item.assignment.status,
                tasks: [
                  {
                    ...item,
                    task: item.task,
                    application: item.application,
                    robot: item.robot,
                    status: item.status,
                  },
                ],
              },
            ];
          } else {
            assignments[index].tasks = [
              ...assignments[index].tasks,
              {
                ...item,
                task: item.task,
                robot: item.robot,
                status: item.status,
                application: item.application,
              },
            ];
          }
        });

        const assignmentsWithStatuses = assignments.map((item) => {
          if (
            item.tasks.every(
              (item) => item.status === ROBOTTASKSTATUSENUM.DONE
            ) &&
            item.tasks.length > 0
          ) {
            return {
              ...item,
              agendaAssignmentStatus: AGENDAASSIGNMENTSSTATUSENUM.DONE,
            };
          } else if (
            item.tasks.find(
              (item) => item.status === ROBOTTASKSTATUSENUM.Failed
            )
          ) {
            return {
              ...item,
              agendaAssignmentStatus: AGENDAASSIGNMENTSSTATUSENUM.FAILED,
            };
          } else if (
            item.tasks.find(
              (item) => item.status === ROBOTTASKSTATUSENUM.DELAYED
            )
          ) {
            return {
              ...item,
              agendaAssignmentStatus: AGENDAASSIGNMENTSSTATUSENUM.DELAYED,
            };
          } else if (
            item.tasks.find(
              (item) => item.status === ROBOTTASKSTATUSENUM.INPROGRESS
            )
          ) {
            return {
              ...item,
              agendaAssignmentStatus: AGENDAASSIGNMENTSSTATUSENUM.INPROGRESS,
            };
          } else return item;
        });
        //update progress percentage
        const assignmentsWithPercentage = assignmentsWithStatuses.map(
          (item) => {
            const eachPercentage = Math.floor(100 / item?.tasks?.length);
            let totalPercentage = 0;
            item?.tasks?.forEach((el) => {
              if (el.status === ROBOTTASKSTATUSENUM.DONE) {
                totalPercentage += eachPercentage;
              }
              if (el.status === ROBOTTASKSTATUSENUM.INPROGRESS) {
                totalPercentage += Math.floor(eachPercentage / 2);
              }
            });
            return {
              ...item,
              progressPercentage: totalPercentage,
            };
          }
        );

        setAssignmentDetails(assignmentsWithPercentage[0]);
      }
    });
  }, [assignmentId]);

  useEffect(() => {
    const clientId = assignmentDetails?.client?.id;
    clientId &&
      getLocations(clientId).then((data) => dispatch(setLocations(data)));
  }, [dispatch, assignmentDetails?.client?.id]);

  return (
    <Drawer
      heading={
        assignmentDetails?.assignment
          ? "Assignment details"
          : "Custom task details"
      }
      mobile
      isOpen
    >
      {assignmentDetails ? (
        <AssignmentDetails assignmentDetails={assignmentDetails} isOpen />
      ) : (
        <Loader />
      )}
    </Drawer>
  );
};

export default MobileLink;
