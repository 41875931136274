import { css } from "styled-components";
import COLORS from "./constants/colors";

const screenSizes = {
  beforeDesktop: 1024,
};

const media = Object.keys(screenSizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${screenSizes[label]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

const Theme = {
  color: COLORS,
  ...media,
};

export default Theme;
