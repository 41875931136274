import styled from "styled-components";
import FONTS from "../../../constants/fonts";
export const ComponentWrapper = styled.div``;
export const ComponentHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ComponentTitle = styled.div`
  font-family: ${FONTS.UbuntuRegular};
  font-weight: ${(props) => (props.isMobile ? 600 : 500)};
  font-size: ${(props) => (props.isMobile ? "16px" : "32px")};
  line-height: 37px;
  color: #030a1b;
`;
export const HeaderStyledButton = styled.button`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)),
    #0096c6;
  padding: 10px 40px;
  border-radius: 48px;
  color: #ffffff;
  font-family: ${FONTS.UbuntuRegular};
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  width: 206px;
  height: 56px;
  border: unset;
  cursor: pointer;
`;

export const ClientsTable = styled.div`
  width: 100%;
  margin-top: 20px;
`;
export const TableHeader = styled.div`
  width: 100%;
  background: #0096c6;
  border-radius: 8px;
  display: flex;
  align-items: center;
`;
export const TableHeaderItems = styled.div`
  width: 100%;
  padding: 12px 0px;
  color: #fff;
  &:first-child {
    text-align: center;
  }
`;
export const TableRow = styled.div`
  margin-top: 10px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #fff;

  :hover {
    border-color: #0096c6;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  }
`;
export const TableRowLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
export const TableRowPhoto = styled.div`
  width: 60px;
  height: 60px;
  background: ${(props) => `url(${props.image})`};
  background-position: center;
  background-size: cover;
  border-radius: 50px;
`;
export const TableRowItem = styled.div`
  width: 100%;
  border: unset;
  height: 100%;
  border-radius: 8px;
  background-color: #fff;
  padding: 12px 8px 12px 8px;
  font-weight: 400;
`;
export const VertMenuWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

export const StyledEditBox = styled.div`
  position: absolute;
  right: 10px;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  z-index: 11;
`;
export const EditItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 20px;
  padding: 15px 14px 10px 0px;
  cursor: pointer;
  white-space: nowrap;
`;

export const StyledEditIcon = styled.img`
  margin-right: 10px;
`;

export const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
`;

export const ParametersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 200%;
`;
export const MultiplyWrapper = styled.div`
  display: flex;
`;

export const StyledLabel = styled.label`
  width: 20%;
  display: flex;
  justify-content: center;
`;
