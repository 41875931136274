import React from "react";
import {
  FileInputInput,
  FileInputLabel,
  IconDragNDropWrapper,
  InputFileContent,
  InputFileIcon,
  InputFileText,
  InputFileWrapper,
} from "./components";

const ImageUploader = ({ logo, handPhotoUpload }) => {
  return (
    <IconDragNDropWrapper style={{ width: "10rem", height: "10rem" }}>
      <InputFileWrapper>
        <FileInputInput
          type="file"
          id="file"
          onChange={(e) => {
            handPhotoUpload(e);
          }}
        />

        <FileInputLabel htmlFor="file">
          <InputFileContent
            selectedImage={logo}
            style={{ borderRadius: "50px" }}
          >
            <React.Fragment>
              {!logo && (
                <React.Fragment>
                  <InputFileIcon />
                  <InputFileText>Upload</InputFileText>
                </React.Fragment>
              )}
            </React.Fragment>
          </InputFileContent>
        </FileInputLabel>
      </InputFileWrapper>
    </IconDragNDropWrapper>
  );
};

export default ImageUploader;
