import styled, { keyframes } from "styled-components";

export const blink = keyframes`
    0% {
        background:#07193F;
    }
    50% {
        background: #0799FF;
    }
    100% {
        background:#07193F;
    }
`;

export const StyledLocator = styled.div`
  /* background: #b4ff9f; */
  border-radius: 50%;
  background-clip: padding-box;
  width: ${({ robotWidth }) => robotWidth + "px"};
  height: ${({ robotWidth }) => robotWidth + "px"};
  border: ${({ pointWidth }) => `${pointWidth}px solid transparent`};
  border-top: ${({ pointWidth }) =>
    `${pointWidth}px solid rgba(51, 202, 127, 1)`};
  border-bottom: ${({ pointWidth }) => `${pointWidth}px solid transparent`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

export const StyledNavMap = styled.div`
  position: relative;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
`;

export const StyledInteractiveMapWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 10px;
`;

export const StyledNavMapWrapper = styled.div`
  width: ${({ width }) => (width ? width + "px" : "915px")};
  height: ${({ heigth }) => (heigth ? heigth + "px" : "523px")};
  border-radius: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RobotIconWrapper = styled.div`
  position: relative;
  > span {
    display: none;
  }
  :hover {
    > span {
      display: block;
    }
  }
`;

export const RobotIdTooltip = styled.span`
  position: absolute;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #e5eae7;
  box-shadow: 0px 4px 10px rgba(0, 147, 198, 0.18);
  padding: 15px;
  color: #08183f;
  font-weight: 600;
  font-size: 15px;
  width: 110px;
`;

export const IconTextTooltip = styled.span`
  position: absolute;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #e5eae7;
  box-shadow: 0px 4px 10px rgba(0, 147, 198, 0.18);
  padding: 15px;
  color: #08183f;
  font-weight: 600;
  font-size: 15px;
`;

export const RobotTooltipText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
`;

export const ToolTipArrow = styled.div``;
export const TooltipTriangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 7px 0 7px;
  border-color: #ffffff transparent transparent transparent;
  position: absolute;
  left: Calc(50% - 7px);
  top: Calc(100% - 1px);
`;

export const StyleUploadMapImageWrapper = styled.div`
  background: #d9d9d9;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > input {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
  }

  > label {
    width: 204px;
    height: 44px;
    background: #0096c6;
    border-radius: 48px;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }
  > button {
    width: 204px;
    height: 44px;
    background: rgb(0, 150, 198);
    border-radius: 48px;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }
`;

export const StyledDeleteIcon = styled.img`
  position: absolute;
  right: 24px;
  top: 24px;
  transform: ${({ mapRotateAngle }) => "rotate(" + -mapRotateAngle + "deg)"};
  left: ${({ mapRotateAngle }) => (mapRotateAngle ? "24px" : "")};
  cursor: pointer;
`;
