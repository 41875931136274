import React, { useState } from "react";
import { useSelector } from "react-redux";
import updateMap from "../../../../APIs/maps/updateMap";
import Slider from "../../../../components/slider/Slider";
import {
  StyledAdjustMapWrapper,
  StyledMap,
  StyledDot,
  StyledAdjustMapOuterWrapper,
} from "./components";

const AdjustMap = () => {
  const { chosenMap } = useSelector((state) => state.maps);

  const [localMousePos, setLocalMousePos] = useState({});

  const [sliderValue, setSliderValue] = React.useState(
    chosenMap?.angleDif ? chosenMap?.angleDif + 50 : 50
  );
  const [sliderxValue, setSliderxValue] = React.useState(50);
  const [zoomValue, setZoomValue] = React.useState(50);
  const [verticalDif, setVerticalValue] = React.useState(
    chosenMap?.verticalDif ? chosenMap?.verticalDif : 50
  );

  // const [angle, setAngle] = useState(chosenMap?.angleDif || 0);

  const handleMouseMove = (event) => {
    const localX = event.clientX - event.target.offsetLeft;
    const localY = event.clientY - event.target.offsetTop;

    setLocalMousePos({
      x: localX,
      y: localY,
    });
  };

  const updateAngle = async () => {
    // setAngle(sliderValue - 50);
    await updateMap(chosenMap.id, {
      ...chosenMap,
      angleDif: sliderValue - 50,
      verticalDif,
    });
  };

  // useEffect(() => {
  //   setAngle(chosenMap?.angleDif || 0);
  // }, [chosenMap]);

  return (
    <StyledAdjustMapOuterWrapper>
      <StyledAdjustMapWrapper>
        <StyledMap
          style={{
            background: `transparent url("${chosenMap?.mapUrl}")`,
            width: "500px",
          }}
          onMouseMove={handleMouseMove}
        />
        <div
          style={{
            width: "500px",
            height: "500px",
            position: "absolute",
            top: "0",
            left: sliderxValue * 10 + "px",
          }}
        >
          <StyledMap
            style={{
              background: `transparent url("${chosenMap?.editedMapUrl}")`,
              width: "500px",
              height: "500px",
              transform: `rotate(${(sliderValue - 50) * 3.6}deg) scale(${
                (zoomValue - 40) / 10
              })`,
              opacity: 0.5,
              marginTop: verticalDif - 50 + "px",
            }}
          />
          <div
            style={{
              // transform: `rotate(${-angle * 3.6}deg) `,
              position: "absolute",
              width: "500px",
              height: "500px",
              top: "0",
            }}
          >
            <StyledDot localMousePos={localMousePos} />
          </div>
        </div>
      </StyledAdjustMapWrapper>
      <div>
        ({localMousePos.x}, {localMousePos.y})
      </div>
      Rotation
      <Slider value={sliderValue} setValue={setSliderValue} />
      Slide
      <Slider value={sliderxValue} setValue={setSliderxValue} />
      Zoom
      <Slider value={zoomValue} setValue={setZoomValue} />
      Vertical
      <Slider value={verticalDif} setValue={setVerticalValue} />
      <button onClick={updateAngle}>adjust angle</button>
    </StyledAdjustMapOuterWrapper>
  );
};

export default AdjustMap;
