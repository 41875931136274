import React, { useContext } from "react";
import { useState } from "react";
import AssignCustomTaskModal from "../../../components/assign-custom-task-modal/AssignCustomTaskModal";
import ICONS from "../../../constants/icons";
import {
  ShortCutButton,
  ShortcutIcon,
  ShortcutIconsBackground,
  ShortcutName,
} from "../components";
import { useSelector } from "react-redux";
import { ROLESENUM } from "../../../enums";
import { ResponsiveContext } from "../../../contexts/ResponsiveContext";

const AssignCustomTask = () => {
  const { isMobile } = useContext(ResponsiveContext);

  const [isOpen, setIsOpen] = useState(false);
  const { currentRole } = useSelector((state) => state.clients);

  const [chosenApp, setChosenApp] = useState();

  return (
    <React.Fragment>
      <ShortCutButton
        isMobile={isMobile}
        disabled={currentRole === ROLESENUM.VIEWER}
        onClick={() => setIsOpen(true)}
      >
        {!isMobile && (
          <ShortcutIconsBackground>
            <ShortcutIcon src={ICONS.AssignTaskIcon} alt="icon" />
          </ShortcutIconsBackground>
        )}

        <ShortcutName>Assign custom task</ShortcutName>
      </ShortCutButton>
      {isOpen && (
        <AssignCustomTaskModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          chosenApp={chosenApp}
          setChosenApp={setChosenApp}
        />
      )}
    </React.Fragment>
  );
};

export default AssignCustomTask;
