import React from "react";
import ICONS from "../../constants/icons";
import { IconBackgroundWrapper, StyledSwitchIconsWrapper } from "./components";

const SwitchIcons = ({ toggle, setToggle }) => {
  return (
    <StyledSwitchIconsWrapper background={!toggle && "#fff"}>
      <IconBackgroundWrapper toggle={toggle}>
        <img
          src={toggle ? ICONS.SquareBlocksBlue : ICONS.SquareBlocksGrey}
          alt=""
          onClick={() => setToggle(true)}
        />
      </IconBackgroundWrapper>
      <IconBackgroundWrapper toggle={!toggle}>
        <img
          src={toggle ? ICONS.MapIconGrey : ICONS.MapIconBlue}
          alt=""
          onClick={() => setToggle(false)}
        />
      </IconBackgroundWrapper>
    </StyledSwitchIconsWrapper>
  );
};

export default SwitchIcons;
