import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import assignRobot from "../../APIs/robots/assignRobot";
import updateRobot from "../../APIs/robots/updateRobot";
import STRINGS from "../../constants/strings";
import { RobotsDataContext } from "../../contexts/RobotsDataContext";
import {
  AddRobotContent,
  LabelText,
  RobotModalContent,
  RobotModalContentWrapper,
  StyledHeaderBtn,
} from "../../pages/robots/components";
import { setToastMessages } from "../../redux/toast";
import InputText from "../input/InputText";
import ModalOutline from "../modal-outline/ModalOutline";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";

const AddRobotModal = ({ isOpen, setIsOpen, editRobot, setEditRobot }) => {
  const { chosenClient } = useSelector((state) => state.clients);
  const { setRobotsData } = useContext(RobotsDataContext);
  const [robotModalForm, setRobotModalForm] = useState({
    robotId: "",
    ip: "",
    ggsRT: "",
    name: "",
    clientId: chosenClient,
  });
  const { isMobile } = useContext(ResponsiveContext);

  const [isError, setIsError] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    editRobot
      ? setRobotModalForm(editRobot)
      : setRobotModalForm({
          robotId: "",
          ip: "",
          ggsRT: "",
          name: "",
          clientId: chosenClient,
        });
  }, [editRobot, chosenClient, isOpen]);

  const handleChange = (inputVal, inputName) => {
    setRobotModalForm({
      ...robotModalForm,
      [inputName]: inputVal,
    });
  };

  const handleEdit = async () => {
    let obj = {
      ...robotModalForm,
      clientId: chosenClient,
      updatedAt: new Date().toISOString(),
      ip: robotModalForm.ip.trim(),
    };
    try {
      const { data: updatedRobot } = await updateRobot(obj);

      setRobotsData((prev) =>
        prev.map((item) =>
          item.id === updatedRobot.id
            ? {
                ...item,
                ...updatedRobot,
              }
            : item
        )
      );

      setIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddRobot = async () => {
    if (
      !robotModalForm.robotId ||
      !robotModalForm.ip ||
      !robotModalForm.ggsRT ||
      !robotModalForm.name
    ) {
      setIsError(true);
    } else {
      try {
        const robot = await assignRobot(robotModalForm);
        setRobotsData((prev) => [...prev, robot]);
        handleModalClose();
      } catch (error) {
        console.log("handleAddRobot ~ error", error);
        dispatch(
          setToastMessages({ text: error.response.data.message, type: "error" })
        );
      }
    }
  };
  const handleModalClose = () => {
    setIsOpen(false);
    setIsError(false);
    setEditRobot && setEditRobot(null);
    setRobotModalForm({
      robotId: "",
      ip: "",
      ggsRT: "",
      name: "",
      clientId: chosenClient,
    });
  };
  return (
    <React.Fragment>
      <ModalOutline
        isOpen={isOpen}
        heading={editRobot ? STRINGS.edit_robot_info : STRINGS.assign_new_robot}
        onRequestClose={() => handleModalClose()}
      >
        <RobotModalContent>
          <LabelText isMobile={isMobile}>Robot name: </LabelText>
          <RobotModalContentWrapper>
            <AddRobotContent>
              <InputText
                style={{ marginTop: "20px" }}
                value={robotModalForm.name || ""}
                handleChange={(e) => handleChange(e, "name")}
                error={
                  isError &&
                  !robotModalForm.name &&
                  "Robot name should not be empty"
                }
              />
            </AddRobotContent>
          </RobotModalContentWrapper>

          <LabelText isMobile={isMobile}>Robot ID: </LabelText>
          <RobotModalContentWrapper>
            <AddRobotContent>
              <InputText
                style={{ marginTop: "20px" }}
                value={robotModalForm.robotId || ""}
                handleChange={(e) => handleChange(e, "robotId")}
                error={
                  isError &&
                  !robotModalForm.robotId &&
                  "Robot ID should not be empty"
                }
              />
            </AddRobotContent>
          </RobotModalContentWrapper>

          <LabelText isMobile={isMobile}>Robot IP: </LabelText>
          <RobotModalContentWrapper>
            <AddRobotContent>
              <InputText
                style={{ marginTop: "20px" }}
                value={robotModalForm.ip || ""}
                handleChange={(e) => handleChange(e, "ip")}
                error={
                  isError &&
                  !robotModalForm.ip &&
                  "Robot IP should not be empty"
                }
              />
            </AddRobotContent>
          </RobotModalContentWrapper>

          <LabelText isMobile={isMobile}>
            Robot's gateway refresh token:{" "}
          </LabelText>
          <RobotModalContentWrapper>
            <AddRobotContent>
              <InputText
                style={{ marginTop: "20px" }}
                value={robotModalForm.ggsRT || ""}
                handleChange={(e) => handleChange(e, "ggsRT")}
                error={
                  isError &&
                  !robotModalForm.ggsRT &&
                  "Refresh token should not be empty"
                }
              />
            </AddRobotContent>
          </RobotModalContentWrapper>
        </RobotModalContent>
        <RobotModalContentWrapper>
          <StyledHeaderBtn
            style={{ margin: "30px 0" }}
            onClick={editRobot ? handleEdit : handleAddRobot}
          >
            {editRobot ? "Edit" : "Add"}
          </StyledHeaderBtn>
        </RobotModalContentWrapper>
      </ModalOutline>
    </React.Fragment>
  );
};

export default AddRobotModal;
