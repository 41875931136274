import React from "react";

export const NotAllowedSVG = () => {
  return (
    <svg
      width="46"
      height="45"
      viewBox="0 0 46 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.5583 7.89021C33.828 4.17293 28.6823 1.875 23 1.875C11.6091 1.875 2.375 11.1091 2.375 22.5C2.375 28.1873 4.67695 33.337 8.39996 37.0681M37.5583 7.89021C41.3058 11.6246 43.625 16.7915 43.625 22.5C43.625 33.8909 34.3909 43.125 23 43.125C17.2964 43.125 12.1336 40.8099 8.39996 37.0681M37.5583 7.89021L8.39996 37.0681"
        stroke="url(#paint0_linear_2815_7344)"
        strokeWidth="4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2815_7344"
          x1="23"
          y1="1.875"
          x2="23"
          y2="43.125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D61C1C" />
          <stop offset="1" stopColor="#F99928" />
        </linearGradient>
      </defs>
    </svg>
  );
};
