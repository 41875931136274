import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import momentTz from "moment-timezone";
import addAssignment from "../APIs/assignments/addAssignment";
import updateAssignment from "../APIs/assignments/updateAssignment";
import { AGENDAASSIGNMENTSSTATUSENUM, ASSIGNMENTSTATUSENUM } from "../enums";
import { parseAssignment } from "../utils/parseAssignments";
import { useCallback, useState } from "react";
import { setToastMessages } from "../redux/toast";
import {
  handleAssignmentDetailsUpdate,
  handleUpdateAssignmentFromAgenda,
  refreshAgenda,
  setAssignmentEditMode,
  updateAssignmentRedux,
} from "../redux/dashboard";
import addAssignmentException from "../APIs/assignments-exception/addAssignmentException";
import updateAssignmentException from "../APIs/assignments-exception/updateAssignmentException";

const dayToNum = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
};

const useAssignmentsModal = ({
  setAssignmentsData,
  setNewAssignmentForm,
  newAssignmentForm,
  setIsOpen,
  assignmentsData,
  setChosenAssignment,
  bottomRef,
  calendar,
}) => {
  const dispatch = useDispatch();
  const { chosenClient, currentUser } = useSelector((state) => state.clients);
  const {
    assignmentDetails,
    duplicateAssignment,
    chosenWeekDays,
    assignmentDetailsBeforeChange,
  } = useSelector((state) => state.dashboard);

  const [showCalendar, setShowCalendar] = useState(false);

  const [errorText, setErrorText] = useState({
    name: "",
    weekDays: "",
    validateDate: "",
    validateTime: "",
  });

  const handleChooseWeekday = (id) => {
    const copiedArray = { ...newAssignmentForm };
    copiedArray.weekDays = copiedArray.weekDays.map((item, index) => {
      if (id === index) {
        let obj = { ...item };
        obj.chosen = !obj.chosen;
        return obj;
      } else return item;
    });
    setNewAssignmentForm(copiedArray);
  };
  const handleNameChange = (inputValue, fieldName) => {
    let obj = { ...newAssignmentForm };
    obj[fieldName] = inputValue;
    setNewAssignmentForm(obj);
  };

  const createAssignmentDto = useCallback(
    (assignment, calendarDate) => {
      const { executionTime, time, weekDays } = assignment;
      const [hour, minutes] = time.split(":");
      let assignmentData;

      let date = moment(calendarDate || executionTime);
      date.set({
        hour: +hour,
        minute: +minutes,
      });
      if (assignment.disposable) {
        assignmentData = {
          ...assignment,
          clientId: chosenClient,
          status: ASSIGNMENTSTATUSENUM.Active,
          executionTime: date.toISOString(),
        };
      } else {
        assignmentData = {
          ...assignment,
          clientId: chosenClient,
          status: ASSIGNMENTSTATUSENUM.Active,
          executionTime: date.toISOString(),
          occurance: {
            hour,
            minutes,
            weekDays: weekDays
              .map((day) => day.chosen && dayToNum[day.day])
              .filter((day) => day === 0 || day)
              .join(","),
            timezone: momentTz.tz.guess(),
          },
        };
      }

      return assignmentData;
    },
    [chosenClient]
  );

  const handleModalClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleAddAssignment = useCallback(
    async (assignmentForm) => {
      const assignmentData = {
        ...createAssignmentDto(assignmentForm),
        userId: currentUser?.id,
        username: currentUser?.username,
      };

      try {
        const data = await addAssignment(assignmentData);
        const newAssignment = { ...assignmentData, ...parseAssignment(data) };
        if (assignmentData.duplicated) {
          const updatedArr = [...assignmentsData];
          updatedArr.splice(assignmentData.duplicated, 0, newAssignment);
          setAssignmentsData(updatedArr);
        } else {
          setAssignmentsData((prev) => [...prev, newAssignment]);
        }
        assignmentDetails &&
          dispatch(
            handleAssignmentDetailsUpdate({
              ...newAssignment,
              agendaAssignmentStatus: AGENDAASSIGNMENTSSTATUSENUM.DEFAULT,
              isCreated: true,
            })
          );
        dispatch(updateAssignmentRedux(newAssignment));
        assignmentDetails &&
          dispatch(
            handleUpdateAssignmentFromAgenda({
              ...newAssignment,
              agendaAssignmentStatus: AGENDAASSIGNMENTSSTATUSENUM.DEFAULT,
              isCreated: true,
            })
          );
        dispatch(setAssignmentEditMode(false));
        setNewAssignmentForm({ ...newAssignment, isCreated: true });
        handleModalClose();
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
        dispatch(
          setToastMessages({
            text: "Assignment added successfully",
            type: "success",
          })
        );
      } catch (error) {}
    },
    [
      bottomRef,
      assignmentDetails,
      createAssignmentDto,
      dispatch,
      handleModalClose,
      setAssignmentsData,
      setNewAssignmentForm,
      currentUser,
      assignmentsData,
    ]
  );

  const isValidAssignment = (newAssignmentForm, setIsModalOpen) => {
    const timePickerTime = newAssignmentForm.time;
    const currentTime = moment(new Date()).format("HH:mm");
    const calendarDate = moment(newAssignmentForm?.executionTime).format("L");
    const currentDate = moment(new Date().getTime()).format("L");

    if (!newAssignmentForm.name) {
      setErrorText((prev) => ({
        ...prev,
        name: "This field is required",
        validateDate: "",
        validateTime: "",
      }));
      setIsModalOpen && setIsModalOpen(false);
      return false;
    } else if (
      !newAssignmentForm.disposable &&
      !newAssignmentForm?.weekDays?.find((item) => item.chosen)
    ) {
      setErrorText((prev) => ({ ...prev, weekDays: "Please select days" }));
      setIsModalOpen && setIsModalOpen(false);
      return false;
    } else if (newAssignmentForm.disposable && currentDate > calendarDate) {
      setErrorText((prev) => ({
        ...prev,
        name: "",
        validateTime: "",
        validateDate: "Not validate date",
      }));
      setIsModalOpen && setIsModalOpen(false);
      return false;
    } else if (
      newAssignmentForm.disposable &&
      currentDate === calendarDate &&
      timePickerTime < currentTime
    ) {
      setErrorText((prev) => ({
        ...prev,
        name: "",
        validateDate: "",
        validateTime: "Not validate time",
      }));
      setIsModalOpen && setIsModalOpen(false);
      return false;
    } else {
      setErrorText((prev) => ({
        ...prev,
        name: "",
        validateDate: "",
        validateTime: "",
      }));
      return true;
    }
  };

  const handleUpdate = useCallback(
    async (updatedItem, chosenAssignment) => {
      const modifiedAssignment = createAssignmentDto(updatedItem);
      if (duplicateAssignment) {
        handleAddAssignment(
          createAssignmentDto(parseAssignment(duplicateAssignment))
        );
      }
      await updateAssignment(modifiedAssignment);
      dispatch(setAssignmentEditMode(false));
      chosenAssignment &&
        setChosenAssignment({ ...modifiedAssignment, isDragging: false });
      const updatedArray = assignmentsData.map((item) => {
        if (item.id === newAssignmentForm.id) {
          return newAssignmentForm;
        } else return item;
      });
      setAssignmentsData(updatedArray);
      assignmentDetails &&
        dispatch(
          handleAssignmentDetailsUpdate({
            ...modifiedAssignment,
            isDragging: false,
          })
        );
      dispatch(
        handleUpdateAssignmentFromAgenda({
          ...modifiedAssignment,
          isDragging: false,
        })
      );
      setIsOpen(false);
      dispatch(
        setToastMessages({
          text: "Assignment updated",
          type: "info",
        })
      );
    },
    [
      assignmentsData,
      createAssignmentDto,
      assignmentDetails,
      dispatch,
      newAssignmentForm,
      setAssignmentsData,
      setChosenAssignment,
      handleAddAssignment,
      duplicateAssignment,
      setIsOpen,
    ]
  );

  const handleSubmit = useCallback(
    async (
      newAssignmentForm,
      currentModalData,
      chosenAssignment,
      setIsModalOpen
    ) => {
      if (isValidAssignment(newAssignmentForm, setIsModalOpen)) {
        Object.keys(currentModalData).length
          ? await handleUpdate(newAssignmentForm, chosenAssignment)
          : await handleAddAssignment(newAssignmentForm);

        setErrorText({});
        setIsModalOpen && setIsModalOpen(false);
        calendar && dispatch(refreshAgenda());
      }
    },
    [handleAddAssignment, handleUpdate, calendar, dispatch]
  );

  const hadnleSubmitOTE = useCallback(
    async (calendarDate, closeModal, hibernate) => {
      const modifiedAssignment = createAssignmentDto(
        newAssignmentForm,
        calendarDate
      );

      if (newAssignmentForm?.exceptionId) {
        await updateAssignmentException(newAssignmentForm.exceptionId, {
          assignment: hibernate || modifiedAssignment,
          hibernate: !!hibernate,
        });
      } else {
        const exception = await addAssignmentException({
          assignment: hibernate || modifiedAssignment,
          hibernate: !!hibernate,
          calendarDate,
        });
        newAssignmentForm.exceptionId = exception?.id;
        modifiedAssignment.exceptionId = exception?.id;
      }
      if (hibernate) {
        dispatch(setAssignmentEditMode(false));
        dispatch(handleAssignmentDetailsUpdate(null));
      } else {
        dispatch(setAssignmentEditMode(false));
        dispatch(handleAssignmentDetailsUpdate(modifiedAssignment));
        // dispatch(handleUpdateAssignmentFromAgenda(modifiedAssignment));
      }
      dispatch(refreshAgenda());
      setNewAssignmentForm((prev) => ({ ...prev, exceptionId: null }));
      closeModal();
    },
    [newAssignmentForm, dispatch, createAssignmentDto, setNewAssignmentForm]
  );

  const createDuplicatedRunOn = useCallback(
    async (closeModal) => {
      const originalArrray = assignmentDetailsBeforeChange.weekDays;
      const modifiedArray = newAssignmentForm.weekDays;
      if (duplicateAssignment) {
        handleAddAssignment(
          createAssignmentDto(
            parseAssignment({
              ...duplicateAssignment,
              disposable: true,
              time:
                duplicateAssignment?.occurance?.hour +
                ":" +
                duplicateAssignment?.occurance?.minutes,
              executionTime: newAssignmentForm.agendaDate,
            })
          )
        );
      }
      for (let i = 0; i < originalArrray.length; i++) {
        if (originalArrray[i].chosen !== modifiedArray[i].chosen) {
          if (!modifiedArray[i].chosen) {
            await addAssignmentException({
              assignment: assignmentDetailsBeforeChange,
              calendarDate: chosenWeekDays[i],
              hibernate: true,
            });
          }
          if (modifiedArray[i].chosen) {
            const assignmentData = {
              ...createAssignmentDto({
                ...newAssignmentForm,
                executionTime: chosenWeekDays[i],
                disposable: true,
              }),
              userId: currentUser?.id,
              username: currentUser?.username,
            };

            await addAssignment(assignmentData);
          }
        }
      }
      dispatch(refreshAgenda());
      closeModal();
    },
    [
      chosenWeekDays,
      createAssignmentDto,
      assignmentDetailsBeforeChange,
      newAssignmentForm,
      dispatch,
      duplicateAssignment,
      handleAddAssignment,
      currentUser,
    ]
  );

  const handleRadioBtnChange = (type) => {
    const copiedArray = { ...newAssignmentForm };
    copiedArray.disposable = type;
    setNewAssignmentForm(copiedArray);
  };

  const handleTimeChange = (timeValue) => {
    if (newAssignmentForm.time !== timeValue) {
      let obj = { ...newAssignmentForm };
      obj.time = timeValue;
      setNewAssignmentForm(obj);
    }
  };

  const handleCalendarChange = (dateValue) => {
    let obj = { ...newAssignmentForm };
    obj.executionTime = dateValue;
    setNewAssignmentForm(obj);
    setShowCalendar(false);
  };

  return {
    handleChooseWeekday,
    handleNameChange,
    handleAddAssignment,
    handleUpdate,
    handleModalClose,
    handleRadioBtnChange,
    handleTimeChange,
    handleCalendarChange,
    handleSubmit,
    hadnleSubmitOTE,
    errorText,
    setErrorText,
    showCalendar,
    setShowCalendar,
    isValidAssignment,
    createDuplicatedRunOn,
  };
};
export default useAssignmentsModal;
