import React, { useState } from "react";
import {
  InputTextWrapper,
  InputIcon,
  InputContainer,
  InputName,
  InputInput,
  InputError,
} from "./components";

const InputText = ({
  height,
  width,
  backgroundColor,
  borderColor,
  textColor,
  padding,
  margin,
  icon,
  name,
  placeholder,
  value,
  handleChange,
  type = "text",
  error,
  errorStyle,
  disabled,
  noBorder,
  handleIconClick,
  hideInputName,
  ...rest
}) => {
  const [isFocused, setISFocused] = useState(false);
  return (
    <InputTextWrapper
      height={height}
      width={width}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      noBorder={noBorder}
      textColor={textColor}
      padding={padding}
      margin={margin}
      autoComplete="none"
      borderFocus={isFocused && !noBorder}
      isFocused={isFocused || !!value}
      disabled={disabled}
      {...rest}
    >
      {error && (
        <InputError className="error" style={errorStyle}>
          * {error}
        </InputError>
      )}
      {icon && <InputIcon icon={icon} onClick={handleIconClick} />}
      <InputContainer>
        {name && !hideInputName && (
          <InputName className="when-focused">{name}</InputName>
        )}
        <InputInput
          // name={name}
          placeholder={isFocused ? "" : placeholder}
          value={value}
          onChange={(e) => handleChange(e.target?.value, name)}
          type={type}
          onFocus={() => setISFocused(true)}
          onBlur={() => setISFocused(false)}
          disabled={disabled}
        />
      </InputContainer>
    </InputTextWrapper>
  );
};

export default InputText;
