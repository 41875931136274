import React from "react";
import {
  StyledFooterText,
  StyledModalFormWrapper,
  StyledSuccessModalWrapper,
  SuccessModalTitle,
} from "../components";
import ModalOutline from "../../modal-outline/ModalOutline";
import STRINGS from "../../../constants/strings";
import ICONS from "../../../constants/icons";
import { StyledButton } from "../../button/components";
import { useSelector } from "react-redux";

const MemberSuccessModal = ({ onRequestClose, isOpen }) => {
  const { inviteMemberValues } = useSelector((state) => state.clients);
  return (
    <ModalOutline
      width={"40%"}
      isOpen={isOpen}
      heading={STRINGS.add_new_team_member}
      onRequestClose={onRequestClose}
    >
      <StyledModalFormWrapper>
        <StyledSuccessModalWrapper>
          <img src={ICONS.DoneIcon} style={{ width: "52px" }} alt="" />
          <SuccessModalTitle>{STRINGS.new_member_added}</SuccessModalTitle>
          <SuccessModalTitle style={{ fontSize: "20px" }}>
            {inviteMemberValues?.sendEmail && STRINGS.request_sent_to_email}
          </SuccessModalTitle>
          <StyledButton
            borderColor={"#fff"}
            fontSize={"16px"}
            fontFamily={"unset"}
            padding={"8px 71px"}
            margin={"32px 0 0 0"}
            backgroundColor={"#0096C6"}
            onClick={() => onRequestClose()}
          >
            OK
          </StyledButton>
          <StyledFooterText>{`Please inform ${
            inviteMemberValues?.firstName + " " + inviteMemberValues?.lastName
          } to check their email and complete the setup process.`}</StyledFooterText>
        </StyledSuccessModalWrapper>
      </StyledModalFormWrapper>
    </ModalOutline>
  );
};

export default MemberSuccessModal;
