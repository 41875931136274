import Tool from "./tool";
import finalZoneSVG from "../../../assets/finalZone.svg";
export const ROUTE = "route";

const route = { ...Tool };

route.name = "route";

route.onMouseDown = function onMouseDown(start, options) {
  if (!this.state) {
    this.state = {
      ...this.state,
      initialCircle: this.createStartPoint(start, 5),
      startPoint: start,
    };
  }
  if (!this.state.pathData) {
    this.state.pathData = [];
  }
  this.setInitSettings({ start, options });
};

route.onMouseMove = function onMouseMove(position) {
  if (!this.state || !this.state.started) return;
  this.ctx.putImageData(this.imageData, 0, 0);
  this.draw(this.state.start, position);
};

route.fillGeometry = function fillGeometry(pathData, zoneColor) {};

route.onMouseUp = function onMouseUp(position) {
  if (!this.state) return;
  const data = [this.state.start.x, this.state.start.y];
  this.state.pathData.push(data);
  this.state.canvasData.push(data);
  const start = this.state.start;
  const options = this.state.options;
  return {
    data: data,
    canvas: {
      start,
      end: position,
      options,
    },
  };
};

function getCrossPath(point, size, direction) {
  const path = new Path2D();
  const startHorizontalLine = { x: point.x - size, y: point.y };
  const endHorizontalLine = { x: point.x + size, y: point.y };
  const startVerticalLine = { x: point.x, y: point.y - size };
  const endVerticalLine = { x: point.x, y: point.y + size };

  path.moveTo(startHorizontalLine.x, startHorizontalLine.y);
  path.lineTo(endHorizontalLine.x, endHorizontalLine.y);
  path.moveTo(startVerticalLine.x, startVerticalLine.y);
  path.lineTo(endVerticalLine.x, endVerticalLine.y);
  return path;
}

route.drawCrossDirection = function (points, pixelDistance, final) {
  const x1 = points[0][0];
  const x2 = points[1][0];
  const y1 = points[0][1];
  const y2 = points[1][1];

  const xCoord = (x1 + x2) / 2 + pixelDistance * Math.sign(y2 - y1);
  const yCoord = (y1 + y2) / 2 - pixelDistance * Math.sign(x2 - x1);

  const crossPath = getCrossPath({ x: xCoord, y: yCoord }, 6);
  this.ctx.strokeStyle = "#ff0000";
  this.ctx.stroke(crossPath);
  this.ctx.strokeStyle = "#555";
};

route.drawStartPoint = function (points, pixelDistance) {
  const x1 = points[0][0];
  const x2 = points[1][0];
  const y1 = points[0][1];
  const y2 = points[1][1];

  const xCoord = (x1 + x2) / 2 + pixelDistance * Math.sign(y2 - y1);
  const yCoord = (y1 + y2) / 2 - pixelDistance * Math.sign(x2 - x1);

  const path = new Path2D();
  path.arc(xCoord, yCoord, 5, 0, Math.PI * 26, true);

  this.ctx.fillStyle = "#0686D8";
  this.ctx.fill(path);
  this.ctx.strokeStyle = "#0686D8";
  this.ctx.stroke(path);
  this.ctx.strokeStyle = "#555";
};

route.drawEndPoint = function (points, pixelDistance) {
  const x1 = points[0][0];
  const x2 = points[1][0];
  const y1 = points[0][1];
  const y2 = points[1][1];

  const xCoord = (x1 + x2) / 2 + pixelDistance * Math.sign(y2 - y1);
  const yCoord = (y1 + y2) / 2 - pixelDistance * Math.sign(x2 - x1);

  const path = new Path2D();
  path.arc(xCoord, yCoord, 5, 0, Math.PI * 26, true);

  var img = new Image();
  img.onload = () => {
    this.ctx.drawImage(img, xCoord - 15, yCoord - 15, 30, 30);
  };
  img.src = finalZoneSVG;
};

export default route;
