import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import {
  StyledOptionsBox,
  StyledOptionType,
  StyledSelectType,
} from "./components";

const StyledSelectComponent = ({
  width,
  height,
  options,
  chosenValue,
  setChosenValue,
  color,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  return (
    <div style={{ position: "relative", width: width, height: height }}>
      <OutsideClickHandler onOutsideClick={() => setShowOptions(false)}>
        <StyledSelectType
          onClick={(e) => {
            e.preventDefault();
            setShowOptions((prev) => !prev);
          }}
          showOptions={showOptions}
          color={color}
        >
          {chosenValue}
        </StyledSelectType>
        {showOptions && (
          <StyledOptionsBox>
            {options.map((item) => (
              <StyledOptionType
                key={item}
                chosen={chosenValue === item}
                onClick={() => {
                  setChosenValue(item);
                  setShowOptions(false);
                }}
              >
                {item}
              </StyledOptionType>
            ))}
          </StyledOptionsBox>
        )}
      </OutsideClickHandler>
    </div>
  );
};

export default StyledSelectComponent;
