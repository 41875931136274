import React, { useContext, useState } from "react";
import AddClientModal from "../../../components/add-client-modal/AddClientModal";

import { AdminTableContext } from "../../../contexts/AdminTableContext";
import {
  BackDrop,
  ClientsTable,
  ComponentHeader,
  ComponentTitle,
  ComponentWrapper,
  EditItem,
  HeaderStyledButton,
  StyledEditBox,
  StyledEditIcon,
  TableHeader,
  TableHeaderItems,
  TableRow,
  TableRowItem,
  TableRowLogoWrapper,
  TableRowPhoto,
  VertMenuWrapper,
} from "./components";
import InfoModal from "../../../components/info-modal/InfoModal";
import { InfoModalTypes } from "../../../constants/types";
import EnvLogo from "../../../assets/png/env-logo.png";

import ICONS from "../../../constants/icons";
import STRINGS from "../../../constants/strings";
import deleteClient from "../../../APIs/clients/deleteClient";

const paramDefaultStates = {
  name: "",
  robot: "",
  application: "",
  parameters: "",
};

const EnvironmentSetup = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const [currentModalData, setCurrentModalData] = useState({});
  const [newClientForm, setNewClientForm] = useState(paramDefaultStates);
  const { clients, setClients } = useContext(AdminTableContext);

  const headerArr = [
    STRINGS.logo,
    STRINGS.client_name,
    STRINGS.client_phonenumber,
    STRINGS.client_email,
    STRINGS.client_address,
  ];

  const handleDelete = async (id) => {
    try {
      await deleteClient(id);
      const filteredArr = clients.filter((item) => {
        return item.id !== id;
      });
      setClients(filteredArr);
    } catch (error) {}
  };

  const handleEdit = (item) => {
    setCurrentModalData(item);
    setIsOpen(true);
  };

  const handleAddNewClient = () => {
    setIsOpen(true);
    setNewClientForm(paramDefaultStates);
    setCurrentModalData({});
  };

  return (
    <ComponentWrapper>
      <ComponentHeader>
        <ComponentTitle>{`${STRINGS.environment_setup}`}</ComponentTitle>
        <HeaderStyledButton
          onClick={handleAddNewClient}
        >{`${STRINGS.add_new_client}`}</HeaderStyledButton>
      </ComponentHeader>
      <AddClientModal
        newClientForm={newClientForm}
        setNewClientForm={setNewClientForm}
        isOpen={isOpen}
        currentModalData={currentModalData}
        setIsOpen={setIsOpen}
      />
      <ClientsTable>
        <TableHeader>
          {headerArr.map((item, index) => (
            <TableHeaderItems key={index}>{item}</TableHeaderItems>
          ))}
        </TableHeader>
        {clients.map((item, index) => (
          <TableRow key={index}>
            <TableRowLogoWrapper>
              <TableRowPhoto image={`${item.logo || EnvLogo}`} alt="Logo" />
            </TableRowLogoWrapper>
            <TableRowItem>{item.name}</TableRowItem>
            <TableRowItem>{item.phone}</TableRowItem>
            <TableRowItem>{item.email} </TableRowItem>
            <TableRowItem>{item.address}</TableRowItem>
            <VertMenuWrapper>
              <img
                src={ICONS.MenuVertical}
                alt="edit"
                onClick={() => setShowEdit((prev) => !prev && item.id)}
              />
              {showEdit === item.id && (
                <React.Fragment>
                  <StyledEditBox>
                    <EditItem
                      onClick={() => {
                        setShowEdit(false);
                        handleEdit(item);
                      }}
                    >
                      <StyledEditIcon src={ICONS.PencilDraw} alt="Pencil" />
                      Edit
                    </EditItem>
                    <EditItem onClick={() => setIsDeleteModal(true)}>
                      <StyledEditIcon src={ICONS.DeleteIcon} alt="DeleteIcon" />
                      Delete
                    </EditItem>
                  </StyledEditBox>
                  <InfoModal
                    isOpen={isDeleteModal}
                    setIsDeleteModal={setIsDeleteModal}
                    type={InfoModalTypes.ATTENTION}
                    itemId={item.id}
                    title="Are you sure to delete ?"
                    // content="We are processing your information. Please be patient, it might take a while."
                    noOuterClickClosing
                    cancelAction={() => setIsDeleteModal(false)}
                    submitAction={() => {
                      handleDelete(item.id);
                      setIsDeleteModal(false);
                      setShowEdit(false);
                    }}
                    cancelText={"No"}
                    submitText={"Yes"}
                    disabled={false}
                  />
                  <BackDrop onClick={() => setShowEdit(false)}></BackDrop>
                </React.Fragment>
              )}
            </VertMenuWrapper>
          </TableRow>
        ))}
      </ClientsTable>
    </ComponentWrapper>
  );
};

export default EnvironmentSetup;
