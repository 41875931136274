import React from "react";
import { StyledModalSubtitle } from "./components";
import { StyledOption, StyledSelect } from "../tasks-in-assignments/components";
import { CHARINGMETHODSENUM } from "../../../enums";
import ChargingAvailability from "./ChargingAvailability";
import PredefinedTimes from "./PredefinedTimes";
import STRINGS from "../../../constants/strings";

const ChargingMethod = ({ batterySettingForm, setBatterySettingForm }) => {
  const chargingMethods = [
    CHARINGMETHODSENUM.CHARING_AVAILABILITY,
    CHARINGMETHODSENUM.PREDEFINED_TIMES,
  ];
  return (
    <>
      <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <StyledModalSubtitle>{STRINGS.charging_method}:</StyledModalSubtitle>
        <StyledSelect
          value={batterySettingForm.charging_method}
          onChange={(e) =>
            setBatterySettingForm((prev) => ({
              ...prev,
              charging_method: e.target.value,
            }))
          }
        >
          {chargingMethods.map((el) => (
            <StyledOption value={el}>{el}</StyledOption>
          ))}
        </StyledSelect>
      </div>

      {batterySettingForm.charging_method ===
        CHARINGMETHODSENUM.CHARING_AVAILABILITY && (
        <ChargingAvailability
          batterySettingForm={batterySettingForm}
          setBatterySettingForm={setBatterySettingForm}
        />
      )}

      {batterySettingForm.charging_method ===
        CHARINGMETHODSENUM.PREDEFINED_TIMES && (
        <PredefinedTimes
          batterySettingForm={batterySettingForm}
          setBatterySettingForm={setBatterySettingForm}
        />
      )}
    </>
  );
};

export default ChargingMethod;
