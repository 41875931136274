import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ICONS from "../../constants/icons";
import STRINGS from "../../constants/strings";
import URLS from "../../constants/urls";
import {
  BackArrow,
  BackToListText,
  ComplitedTasksWrapper,
  CurrentTaskContent,
  CurrentTaskText,
  CurrentTaskWrapper,
  MapAndTasksWrapper,
  MapTaskStreamingWrapper,
  MapWrapper,
  RobotDetailsHeader,
  RobotDetailsWrapper,
  StreamingHeaderWrapper,
  StreamingWrapper,
  StyledTitle,
} from "./components";
import { RobotsDataContext } from "../../contexts/RobotsDataContext";
import { determineRobotStatusText } from "../../utils/robots/robotStatusText";
import MapComponent from "./MapComponent";
// import getNumRobotTasks from "../../APIs/robots/getNumOfRobotTasks";
import AddRobotModal from "../../components/add-robot-modal/AddRobotModal";
import { useDispatch, useSelector } from "react-redux";
import StyledSelectForStreaming from "./StyledSelectForStreaming";
import StreamingErrorScreen from "./StreamingErrorScreen";
import RobotVideos from "../../components/RobotVideos/RobotVideos";
import getRobotCameraList from "../../APIs/robots/getRobotCameraList";
import RobotGeneralInfo from "./RobotGeneralInfo";
import RobotGeneralInfoMobile from "./RobotGeneralInfoMobile";
import { CAMERAALIASES } from "../../enums";
import { GoToMap } from "../dashboard/components";
import MapModal from "../dashboard/robot-fleet/MapModal";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import { setBatterfyConfigurationData } from "../../redux/robots";
import ModalOutline from "../../components/modal-outline/ModalOutline";
import BatteryConfigurationModal from "./battery-configuration/BatteryConfigurationModal";
import SearchableSelect from "../../components/searchable-select/SearchableSelect";
import { setChosenBuilding, setChosenFloor } from "../../redux/maps";
import useMaps from "../../hooks/useMaps";
import { StyledButton } from "../../components/button/components";

const RobotDetails = () => {
  const { id } = useParams();
  const { isMobile, isTablet } = useContext(ResponsiveContext);
  const { streamPopup } = useSelector((state) => state.robots);
  const {
    mapsData,
    floorOptions,
    buildingOptions,
    chosenFloor,
    chosenBuilding,
  } = useSelector((state) => state.maps);
  const { RobotsData } = useContext(RobotsDataContext);
  const [isOpen, setIsOpen] = useState(false);
  // const [completedTasks, setCompletedTasks] = useState(0);
  const [editRobot, setEditRobot] = useState({});
  const [showCameraStream, setShowCameraStream] = useState(true);
  const [chosenCamera, setChosenCameraType] = useState("");
  const [mapModal, setMapModal] = useState();
  const [streamError, setStreamError] = useState(false);

  const [cameraList, setCameraList] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const _ = useMaps({ onlyRobotId: id });
  const dispatch = useDispatch();

  const currentRobot = useMemo(
    () => RobotsData.find((item) => item.id === id),
    [RobotsData, id]
  );
  const { batterfyConfigurationData } = useSelector((state) => state.robots);

  // useEffect(() => {
  //   getNumRobotTasks(id).then((num) => setCompletedTasks(num));
  // }, [id]);

  useEffect(() => {
    streamError && setShowCameraStream(false);
  }, [streamError]);

  useEffect(() => {
    id &&
      showCameraStream &&
      getRobotCameraList(id)
        .then((data) => {
          setCameraList(data);
          setChosenCameraType(data[0]);
        })
        .catch((err) => setStreamError(err));
  }, [id, showCameraStream, setStreamError]);

  const handleSetting = () => {
    setEditRobot(currentRobot);
    setIsOpen(true);
  };

  return (
    <RobotDetailsWrapper>
      <RobotDetailsHeader isMobile={isMobile}>
        <Link to={URLS.robots} style={{ display: "flex" }}>
          {!isMobile && <BackArrow src={ICONS.ChevronLeft} />}
          <BackToListText>
            {isMobile ? "<Back" : STRINGS.back_to_robots_list}
          </BackToListText>
        </Link>
        {isMobile && (
          <BackToListText style={{ margin: "0 auto" }}>
            {currentRobot?.name}
          </BackToListText>
        )}
      </RobotDetailsHeader>
      <div style={{ display: "flex", gap: "20px", marginTop: "30px" }}>
        {!isMobile && (
          <RobotGeneralInfo
            currentRobot={currentRobot}
            handleSetting={handleSetting}
          />
        )}
        {isMobile && (
          <RobotGeneralInfoMobile
            currentRobot={currentRobot}
            handleSetting={handleSetting}
          />
        )}
        {!isMobile && !isTablet && (
          <CurrentTaskWrapper>
            <CurrentTaskText>{STRINGS.current_task}</CurrentTaskText>
            <CurrentTaskContent>
              {determineRobotStatusText(currentRobot) ||
                STRINGS.no_current_task}
            </CurrentTaskContent>
          </CurrentTaskWrapper>
        )}
      </div>

      <MapTaskStreamingWrapper isMobile={isMobile}>
        {!isMobile && !isTablet && (
          <MapAndTasksWrapper>
            <MapWrapper>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                {mapsData?.length > 1 && (
                  <div style={{ width: "180px", marginTop: "10px" }}>
                    <SearchableSelect
                      value={chosenBuilding}
                      handleChange={(val) => dispatch(setChosenBuilding(val))}
                      options={buildingOptions}
                    />
                  </div>
                )}
                {(mapsData?.length > 1 || mapsData[0]?.floors?.length > 1) && (
                  <div style={{ width: "180px", marginTop: "10px" }}>
                    <SearchableSelect
                      value={chosenFloor}
                      options={floorOptions}
                      handleChange={(val) => dispatch(setChosenFloor(val))}
                    />
                  </div>
                )}
              </div>
              <MapComponent
                mapWidth={516}
                mapHeight={386}
                pointWidth={0}
                robotWidth={30}
                showToolTip={false}
                onlyRobotId={id}
              />
            </MapWrapper>
            <ComplitedTasksWrapper>
              {/* <StyledTitle>{STRINGS.completed_tasks}</StyledTitle>
              <StyledTitle style={{ color: "#2FD553" }}>
                {completedTasks}
              </StyledTitle> */}
              <StyledButton
                borderColor={"#0096C6"}
                padding="10px 40px"
                style={{ gap: "10px" }}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setBatterfyConfigurationData(currentRobot));
                }}
              >
                <img src={ICONS.NavSettings} alt="" />
                <span style={{ color: "#08183F", fontSize: "16px" }}>
                  Battery Settings
                </span>
              </StyledButton>
            </ComplitedTasksWrapper>
          </MapAndTasksWrapper>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
              // justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {!streamError && (
              <StreamingHeaderWrapper>
                <div>
                  <StyledTitle>{STRINGS.streaming}</StyledTitle>
                  {!isTablet ? (
                    <div>{CAMERAALIASES[chosenCamera] || chosenCamera}</div>
                  ) : (
                    <GoToMap onClick={() => setMapModal(true)}>
                      Map view
                    </GoToMap>
                  )}
                </div>
                <StyledSelectForStreaming
                  width={"190px"}
                  isMobile={isMobile}
                  options={cameraList}
                  chosenValue={chosenCamera}
                  setChosenValue={setChosenCameraType}
                />
              </StreamingHeaderWrapper>
            )}
            <StreamingWrapper
              isMobile={isMobile}
              style={{ minHeight: isTablet && "580px" }}
            >
              {!streamError && !streamPopup && (
                <RobotVideos
                  chosenCamera={chosenCamera}
                  currentRobot={currentRobot}
                  setChosenCameraType={setChosenCameraType}
                  cameraList={cameraList}
                />
              )}
              {!showCameraStream && streamError && (
                <StreamingErrorScreen
                  setShowCameraStream={setShowCameraStream}
                  setStreamError={setStreamError}
                />
              )}
            </StreamingWrapper>
          </div>
        </div>
      </MapTaskStreamingWrapper>

      <MapModal isOpen={mapModal} setIsOpen={setMapModal} onlyRobotId={id} />

      <AddRobotModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        editRobot={editRobot}
      />

      <ModalOutline
        headerBackground={"#0686D8"}
        width={"50%"}
        heading={`${batterfyConfigurationData?.name} Charging Preferences`}
        onRequestClose={() => dispatch(setBatterfyConfigurationData(null))}
        isOpen={!!batterfyConfigurationData}
      >
        <BatteryConfigurationModal />
      </ModalOutline>
    </RobotDetailsWrapper>
  );
};

export default RobotDetails;
