import React from "react";
import { useMemo } from "react";
import ICONS from "../../constants/icons";
import { ROBOTTASKSTATUSENUM, TASKLOGSTATUSENUM } from "../../enums";

const StatusIcon = ({ status, width, height, mt }) => {
  const icon = useMemo(() => {
    switch (status) {
      case ROBOTTASKSTATUSENUM.Failed:
      case TASKLOGSTATUSENUM.ERROR:
        return ICONS.FailedIcon;

      case ROBOTTASKSTATUSENUM.DONE:
      case TASKLOGSTATUSENUM.SUCCESS:
        return ICONS.DoneIcon;

      case ROBOTTASKSTATUSENUM.DELAYED:
        return ICONS.WaitingClock;
      case TASKLOGSTATUSENUM.WARNING:
        return ICONS.WarningIcon;
      case ROBOTTASKSTATUSENUM.INPROGRESS:
      case TASKLOGSTATUSENUM.INFO:
        return ICONS.BlueInfoIcon;
      case ROBOTTASKSTATUSENUM.STOPPED_ASSIGNMENT:
        return ICONS.StoppedIcon;
      case ROBOTTASKSTATUSENUM.STOPPED:
        return ICONS.StoppedIcon;
      case ROBOTTASKSTATUSENUM.DEFAULT:
        return ICONS.UpcomingIcon;

      default:
        return ICONS.InfoIcon;
    }
  }, [status]);

  return (
    <img
      src={icon}
      alt={status}
      style={{
        width: width ? `${width}px` : "100%",
        height: height ? `${height}px` : "100%",
        marginTop: mt && `${mt}px`,
        whiteSpace: "nowrap",
      }}
    />
  );
};

export default StatusIcon;
