import React, { useState, useEffect, useMemo, useCallback } from "react";
import { JaaSMeeting } from "@jitsi/react-sdk";
import { database, ref, set, onValue, off } from "../../services/firebase";
import updateTaskMessage from "../../APIs/tasks-archive/updateTaskMessage";
import Patients from "./Patients";
import ConfirmChangeModal from "../agenda-assignment-details-modal/assignment-details-modals/ConfirmChangeModal";
import {
  CloseIconWrapper,
  StyledMeetingFrameWrapper,
  StyledMeetingModalContent,
  StyledRobotControlWrapper,
  StyledRotationWrapper,
  StyledWall,
  StyledCallTitle1,
} from "./components";
import bellSound from "../../assets/sound/bell-notification.wav";
import ICONS from "../../constants/icons";
import { useSelector } from "react-redux";
import StyledButtonComp from "../styled-button/StyledButton";
import { TASKLOGSTATUSENUM } from "../../enums";
import EndCallModalContent from "./end-call-modal/EndCallModalContent";
import CustomModal from "../custom-modal/CustomModal";
import { checkPageStatus } from "../../utils/notifications";
import rotateRobot from "../../APIs/robots/rotateRobot";

const defaultState = {
  participantId: "",
  roomId: "",
  password: "",
};

const defaultStyles = {
  height: "80%",
  width: "65%",
  transform: "translate(-50%, -50%)",
  offsetx: -window.innerWidth * 0.65 * 0.5,
  offsety: -window.innerHeight * 0.8 * 0.5,
};
const Nurse = () => {
  const { client } = useSelector((state) => state.clients);
  const { tasksInProgress } = useSelector((state) => state.dashboard);
  const { locations } = useSelector((state) => state.maps);
  const [roomData, setRoomData] = useState(defaultState);
  const [isIncomingCallModal, setIsIncomingCallModal] = useState(false);
  const [endCallModal, setEndCallModal] = useState(false);
  const [meetingModalScreenLevel, setMeetingModalScreenLevel] = useState(2);
  const [ModalStyles, setModalStyles] = useState(defaultStyles);
  const [audio] = useState(new Audio(bellSound));

  const [patientList, setPatientList] = useState({});
  const [filteredPatientList, setFilteredPatientList] = useState({});

  const [inCallWith, setInCallWith] = useState(null);
  const [callerLocation, setCallerLocation] = useState(null);

  const [open, setOpen] = useState(false);

  const [mediaState, setMediaState] = useState({
    audio: true,
    video: true,
  });

  const [rotateLoading, setRotateLoading] = useState({
    left: false,
    right: false,
  });

  const notMinimized = meetingModalScreenLevel > 1;

  const onCallClose = () => {
    roomData.participantId &&
      set(
        ref(database, `requests/${client.name}/` + roomData.participantId),
        null
      );
    setOpen(false);
    setInCallWith(null);
    updateTaskMessage(
      client.name,
      roomData.participantId,
      TASKLOGSTATUSENUM.INFO,
      "Call ended"
    )
      .then()
      .catch();
  };

  const sendCommand = useCallback(
    async (command) => {
      if (command === "toggleVideo")
        setMediaState((prev) => ({
          audio: prev.audio,
          video: !prev.video,
        }));
      else
        setMediaState((prev) => ({
          audio: !prev.audio,
          video: prev.video,
        }));

      if (roomData.participantId) {
        set(
          ref(database, `requests/${client.name}/` + roomData.participantId),
          {
            command,
            commandId: Math.random(),
          }
        );
      }
    },
    [client.name, roomData.participantId]
  );

  useEffect(() => {
    let patientListener;
    if (roomData.participantId) {
      patientListener = ref(
        database,
        `requests/${client.name}/` + roomData.participantId
      );
      onValue(patientListener, (snapshot) => {
        const data = snapshot.val();
        if (!data) {
          setRoomData(defaultState);
          setOpen(false);
        }
      });
    }

    return () => {
      patientListener && off(patientListener);
      setModalStyles(defaultStyles);
      setMeetingModalScreenLevel(2);
    };
  }, [roomData, client.name]);

  useEffect(() => {
    const requests = ref(database, `requests/${client.name}/`);
    onValue(requests, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setPatientList(data);
        setIsIncomingCallModal(true);
      } else setPatientList({});
    });

    return () => {
      off(requests);
    };
  }, [client.name]);

  useEffect(() => {
    if (Object.keys(filteredPatientList).length && !open) {
      checkPageStatus(
        `You have incoming call from ${Object.keys(filteredPatientList)[0]}`,
        "https://fms-s3-dev.s3.eu-central-1.amazonaws.com/callNotification.png"
      );
      audio.play();
    } else audio.pause();
  }, [audio, filteredPatientList, open]);

  const handleModalSizeChange = (num) => {
    if (meetingModalScreenLevel === 3) {
      if (num === -1) {
        setModalStyles({
          height: "433px",
          width: "20%",
          transform: "translate(130%, -30%)",
          offsetx: window.innerWidth * 0.8 - 10,
          offsety: window.innerHeight - 440,
          outline: "rgba(0, 0, 0, 0.2) solid 1px",
        });
        setMeetingModalScreenLevel(1);
      } else {
        setModalStyles({
          height: "80%",
          width: "65%",
          transform: "translate(-50%, -50%)",
          offsetx: -window.innerWidth * 0.65 * 0.5,
          offsety: -window.innerHeight * 0.8 * 0.5,
        });
        setMeetingModalScreenLevel(2);
      }
      return;
    }

    if (
      meetingModalScreenLevel + num <= 3 &&
      meetingModalScreenLevel + num >= 1
    ) {
      if (meetingModalScreenLevel + num === 1) {
        setModalStyles({
          height: "400px",
          width: "300px",
          transform: "translate(-10%, -10%)",
          left: "auto",
          right: "0",
          bottom: "0px",
          outline: "rgba(0, 0, 0, 0.2) solid 1px",
          offsetx: window.innerWidth * 0.625 - 10,
          offsety: window.innerHeight * 0.9 - 440,
        });
      }
      if (meetingModalScreenLevel + num === 2) {
        setModalStyles({
          height: "80%",
          width: "65%",
          transform: "translate(-50%, -50%)",
          offsetx: -window.innerWidth * 0.65 * 0.5,
          offsety: -window.innerHeight * 0.8 * 0.5,
        });
      }
      if (meetingModalScreenLevel + num === 3) {
        setModalStyles({
          height: "100%",
          width: "100%",
          transform: "translate(-50%, -50%)",
          offsetx: -window.innerWidth * 0.5,
          offsety: -window.innerHeight * 0.5,
        });
      }
      setMeetingModalScreenLevel(meetingModalScreenLevel + num);
    }
  };

  const endCall = async () => {
    await sendCommand("hangup");
    onCallClose();
    setEndCallModal(false);
    setInCallWith(null);
  };

  useEffect(() => {
    const newList = { ...patientList };
    if (inCallWith) delete newList[inCallWith];
    Object.entries(patientList).forEach(([key, val]) => {
      if (val.status) delete newList[key];
    });
    setFilteredPatientList(newList);
  }, [patientList, inCallWith]);

  useEffect(() => {
    if (inCallWith) {
      tasksInProgress.forEach((task) => {
        if (task?.robot?.name === inCallWith) {
          if (task?.task?.parameters?.target_goal) {
            try {
              const { x, y } = task?.task?.parameters?.target_goal;
              locations.forEach((loc) => {
                if (
                  loc.pixelCoordinates[0] === x &&
                  loc.pixelCoordinates[1] === y
                )
                  setCallerLocation(loc.name);
              });
            } catch (error) {
              console.log(error);
            }
          }
        }
      });
    }
  }, [inCallWith, tasksInProgress, locations]);

  const headerText = useMemo(() => {
    if (notMinimized)
      return (
        <StyledCallTitle1>
          Start a call with {inCallWith} {callerLocation && <br />}
          {callerLocation && `Location ${callerLocation}`}
        </StyledCallTitle1>
      );
    else
      return (
        <StyledCallTitle1 withMargin={true}>{inCallWith}</StyledCallTitle1>
      );
  }, [notMinimized, inCallWith, callerLocation]);

  const handleRotation = useCallback(
    async (angle) => {
      if (rotateLoading.left || rotateLoading.right) return;
      if (angle > 0)
        setRotateLoading({
          right: true,
          left: false,
        });
      else
        setRotateLoading({
          right: false,
          left: true,
        });
      await rotateRobot(inCallWith, angle);
      setRotateLoading({
        right: false,
        left: false,
      });
    },
    [inCallWith, rotateLoading]
  );

  const footerButtons = useMemo(() => {
    if (notMinimized)
      return (
        <StyledRobotControlWrapper>
          Robot Control Panel
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "27px",
            }}
          >
            <StyledButtonComp
              style={{
                height: "44px",
                width: "142px",
                gap: "10px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              background={"#030A1B"}
              color="#fff"
              onClick={() => {
                sendCommand("toggleVideo");
              }}
            >
              <img
                style={{ width: "33px" }}
                src={mediaState.video ? ICONS.CameraOn : ICONS.CameraOff}
                alt=""
              />
              {notMinimized ? "Camera" : ""}
            </StyledButtonComp>

            <StyledWall />

            <StyledButtonComp
              style={{
                height: "44px",
                width: "142px",
                gap: "10px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                fontWeight: "bold",
              }}
              background={"#030A1B"}
              color="#fff"
              onClick={() => {
                sendCommand("toggleAudio");
              }}
            >
              <img
                style={{ width: "33px" }}
                src={
                  mediaState.audio ? ICONS.MicrophoneOn : ICONS.MicrophoneOff
                }
                alt=""
              />
              {notMinimized ? "Speaker" : ""}
            </StyledButtonComp>

            <StyledWall />

            <StyledRotationWrapper rotateLoading={rotateLoading}>
              <img
                src={ICONS.ButtonArrow}
                alt=""
                onClick={() => handleRotation(15)}
                style={{
                  background: rotateLoading.left
                    ? "rgba(212, 212, 212, 1)"
                    : "#0686d8",
                  cursor: "pointer",
                }}
              />
              Rotate
              <img
                src={ICONS.ButtonArrow}
                alt=""
                onClick={() => handleRotation(-15)}
                style={{
                  transform: "rotate(180deg)",
                  background: rotateLoading.right
                    ? "rgba(212, 212, 212, 1)"
                    : "#0686d8",
                  cursor: "pointer",
                }}
              />
            </StyledRotationWrapper>
          </div>
        </StyledRobotControlWrapper>
      );
    else
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "27px",
          }}
        >
          <StyledButtonComp
            style={{
              height: "44px",
              width: "142px",
              gap: "10px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              fontWeight: "bold",
              fontSize: "16px",
              padding: notMinimized ? "14px 40px" : "0",
            }}
            background={notMinimized ? "#030A1B" : "transparent"}
            color="#fff"
            onClick={() => {
              sendCommand("toggleVideo");
            }}
          >
            <img
              style={{ width: notMinimized ? "33px" : "44px" }}
              src={mediaState.video ? ICONS.CameraOn : ICONS.CameraOff}
              alt=""
            />
            {notMinimized ? "Camera" : ""}
          </StyledButtonComp>
          <StyledButtonComp
            style={{
              height: "44px",
              width: "142px",
              gap: "10px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              fontWeight: "bold",
              padding: notMinimized ? "14px 40px" : "0",
            }}
            background={notMinimized ? "#030A1B" : "transparent"}
            color="#fff"
            onClick={() => {
              sendCommand("toggleAudio");
            }}
          >
            <img
              style={{ width: notMinimized ? "33px" : "44px" }}
              src={mediaState.audio ? ICONS.MicrophoneOn : ICONS.MicrophoneOff}
              alt=""
            />
            {notMinimized ? "Speaker" : ""}
          </StyledButtonComp>
        </div>
      );
  }, [
    notMinimized,
    mediaState.audio,
    mediaState.video,
    sendCommand,
    handleRotation,
    rotateLoading,
  ]);

  return (
    <React.Fragment>
      <CustomModal
        isOpen={open}
        showBackground={notMinimized}
        width={ModalStyles.width}
        height={ModalStyles.height}
        transform={ModalStyles.transform}
        modalStyles={ModalStyles}
      >
        <StyledMeetingModalContent style={{ height: "90%" }}>
          <CloseIconWrapper style={{ gap: "45px" }}>
            <img
              src={ICONS.Minus}
              style={{ cursor: "pointer", padding: "15px 5px" }}
              alt=""
              onClick={() => handleModalSizeChange(-1)}
            />
            <img
              src={ICONS.FullscreenIcon}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => handleModalSizeChange(1)}
            />
            <img
              src={ICONS.BlackCross}
              style={{ cursor: "pointer" }}
              alt=""
              onClick={() => setEndCallModal(true)}
            />
          </CloseIconWrapper>

          {headerText}
          <StyledMeetingFrameWrapper callerLocation={callerLocation}>
            <div
              style={{
                width: notMinimized ? "80%" : "100%",
                height: notMinimized ? "90%" : "80%",
              }}
            >
              <JaaSMeeting
                appId={process.env.REACT_APP_JITSI_APP_ID}
                roomName={roomData.roomId}
                // jwt={jwt}
                configOverwrite={{
                  disableThirdPartyRequests: true,
                  disableLocalVideoFlip: true,
                  backgroundAlpha: 0.5,
                  prejoinPageEnabled: false,
                  startWithAudioMuted: false,
                  startWithVideoMuted: true,
                }}
                interfaceConfigOverwrite={{
                  VIDEO_LAYOUT_FIT: "nocrop",
                  MOBILE_APP_PROMO: false,
                  TILE_VIEW_MAX_COLUMNS: 4,
                  TOOLBAR_ALWAYS_VISIBLE: true,
                  SHOW_JITSI_WATERMARK: false,
                }}
                onApiReady={(externalApi) => {
                  externalApi.on("readyToClose", onCallClose);
                }}
                getIFrameRef={(iframeRef) => {
                  iframeRef.style.height = "100%";
                  iframeRef.style.width = "100%";
                }}
                userInfo={{
                  displayName: client?.name,
                }}
              />
            </div>
          </StyledMeetingFrameWrapper>
          {footerButtons}
        </StyledMeetingModalContent>
      </CustomModal>

      <ConfirmChangeModal
        isOpen={Object.keys(filteredPatientList).length && isIncomingCallModal}
        callIsOpen={open}
        animation
      >
        <Patients
          setOpen={setOpen}
          setRoomData={setRoomData}
          patientList={filteredPatientList}
          setFilteredPatientList={setFilteredPatientList}
          setIsIncomingCallModal={setIsIncomingCallModal}
          setInCallWith={setInCallWith}
          callIsOpen={open}
          inCallWith={inCallWith}
        />
      </ConfirmChangeModal>
      <ConfirmChangeModal
        isOpen={endCallModal}
        onRequestClose={() => setEndCallModal(false)}
      >
        <EndCallModalContent
          handleSubmit={endCall}
          handleCancel={() => {
            setEndCallModal(false);
          }}
        />
      </ConfirmChangeModal>
    </React.Fragment>
  );
};

export default Nurse;
