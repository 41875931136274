import React, { useCallback } from "react";
import { Field, Form, Formik } from "formik";
import OutlineNonAuthorised from "../../components/outline-non-authorised/OutlineNonAuthorised";
import { FormContentWrapper } from "./components";
import CustomButton from "../../components/button/CustomButton";
import InputText from "../../components/input/InputText";
import STRINGS from "../../constants/strings";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";
import { GeneralError, Title } from "./components";
import { useHistory } from "react-router-dom";
import URLS from "../../constants/urls";
import { userSignUp } from "../../redux/clients";
import { useDispatch, useSelector } from "react-redux";

const SignUp = () => {
  const history = useHistory();
  const { sign_error } = useSelector((state) => state.clients);

  const dispatch = useDispatch();
  const goToVerifyEmail = useCallback(
    () => history.push(URLS.verify_email),
    [history]
  );

  return (
    <OutlineNonAuthorised doubleBackground>
      <Title>{STRINGS.welcome_to_orchestra}</Title>
      <Formik
        onSubmit={(vars, { setErrors }) =>
          dispatch(userSignUp({ vars, setErrors, goToVerifyEmail }))
        }
        initialValues={{
          email: "",
          password: "",
        }}
      >
        {({ setFieldValue, errors, _, touched, isSubmitting }) => (
          <Form>
            <FormContentWrapper
              style={{
                width: "38rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              disabled={isSubmitting}
            >
              <Field id="email" name="email">
                {({ field }) => (
                  <InputText
                    height="5.6rem"
                    margin="1.8rem 0"
                    borderColor={COLORS.secondary.water_gray}
                    placeholder={STRINGS.please_type_your_email}
                    name={"email"}
                    handleChange={(v) => setFieldValue(field.name, v)}
                    value={field.value}
                    error={touched[field.name] && errors[field.name]}
                    icon={ICONS.Email}
                  />
                )}
              </Field>
              <Field id="password" name="password">
                {({ field }) => (
                  <InputText
                    height="5.6rem"
                    margin="1.8rem 0"
                    type="password"
                    borderColor={COLORS.secondary.water_gray}
                    placeholder={STRINGS.please_type_your_password}
                    handleChange={(v) => setFieldValue(field.name, v)}
                    value={field.value}
                    name={"password"}
                    error={touched[field.name] && errors[field.name]}
                    icon={ICONS.PasswordEye}
                  />
                )}
              </Field>
              <CustomButton
                type="submit"
                backgroundColor={COLORS.secondary.ncs}
                padding="0 7.2rem"
                height="5.8rem"
                margin="2rem 0"
                width="28.6rem"
                shadow
              >
                {STRINGS.create_an_account}
              </CustomButton>
              {sign_error && (
                <GeneralError className="error">
                  {`* ${sign_error}
                  `}
                </GeneralError>
              )}
            </FormContentWrapper>
          </Form>
        )}
      </Formik>
    </OutlineNonAuthorised>
  );
};

export default SignUp;
