import React, { useEffect } from "react";
import Modal from "react-modal";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";
import { EditAssignmentButton } from "../agenda-assignment-details-modal/components";
import {
  ResponseModalContentWrapper,
  ResponseModalParagraph,
  ResponseModalText,
} from "./components";
const ResponseModal = ({ isOpen, task, requestClose, type }) => {
  const customStyles = {
    overlay: {
      backgroundColor: `${COLORS.primary.oxford}80`,
      zIndex: "111",
    },
    content: {
      zIndex: "111",
      width: "40%",
      height: "fit-content",
      top: "50%",
      left: "50%",
      right: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
      padding: "0 ",
    },
  };
  useEffect(() => {
    let timeout;
    if (isOpen) {
      timeout = setTimeout(() => {
        requestClose();
      }, 15000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen, requestClose]);

  return (
    <Modal
      onRequestClose={requestClose}
      isOpen={!!isOpen}
      style={customStyles}
      ariaHideApp={false}
    >
      {type === "success" && (
        <ResponseModalContentWrapper>
          <img src={ICONS.DoneIcon} style={{ width: "54px" }} alt="d" />
          <ResponseModalText>
            {`Great! ${task?.robot?.name} is Waiting for you`}
          </ResponseModalText>
        </ResponseModalContentWrapper>
      )}
      {type === "aborted" && (
        <ResponseModalContentWrapper>
          <img
            src={ICONS.WarningCrossedCircle}
            style={{ width: "54px" }}
            alt="d"
          />
          <ResponseModalText style={{ color: "#D4B02F", marginTop: "12px" }}>
            Task Aborted
          </ResponseModalText>
          <ResponseModalParagraph>
            {`${task?.robot?.name} is now finishing the current task and returning to his home position. Please wait while he completes this process. Thank you!`}
          </ResponseModalParagraph>
          <EditAssignmentButton
            style={{ marginTop: "17px" }}
            background={"rgba(6, 134, 216, 0.1)"}
            color={"#0686D8"}
            onClick={requestClose}
          >
            OK
          </EditAssignmentButton>
        </ResponseModalContentWrapper>
      )}
    </Modal>
  );
};

export default ResponseModal;
