import React from "react";
import {
  EditAssignmentButton,
  EditAssignmentButtonsWrapper,
  StyledChangesContentWrapper,
  StyledContentTitle,
} from "./components";

const ConfirmAssignmentChangesContent = ({
  assignmentDetails,
  onClose,
  handleSubmit,
}) => {
  return (
    <StyledChangesContentWrapper className="clickAllow">
      <div>
        <StyledContentTitle>
          Are You Sure You Want To Edit Assignment -{" "}
          <strong>{assignmentDetails?.name}</strong>
        </StyledContentTitle>
      </div>
      <EditAssignmentButtonsWrapper style={{ marginTop: "20px" }}>
        <EditAssignmentButton
          background={"rgba(0, 150, 198, 0.1)"}
          color={"#0686D8"}
          onClick={handleSubmit}
        >
          Save
        </EditAssignmentButton>
        <EditAssignmentButton
          background={"#fff"}
          color={"#0686D8"}
          border={"1px solid #0686D8"}
          onClick={onClose}
        >
          Cancel
        </EditAssignmentButton>
      </EditAssignmentButtonsWrapper>
    </StyledChangesContentWrapper>
  );
};

export default ConfirmAssignmentChangesContent;
