import React, { useContext, useState } from "react";
import AgendaRow from "./AgendaRow";
import { TimeTableWrapper, WhiteWrapper } from "./components";
import { useEffect } from "react";
import { timeArray } from "../../constants/time-array";

import { useRef } from "react";
import { useSelector } from "react-redux";
import { DragDropContext } from "react-beautiful-dnd";
import { changeHoursAndMinutesInISOString } from "../../utils/dateHelper";
import {
  handleAssignmentDetailsUpdate,
  handleUpdateAssignmentFromAgenda,
  setAgendaDataBeforeDrag,
  setAssignmentEditMode,
  setDraggableAssignment,
} from "../../redux/dashboard";
import { useDispatch } from "react-redux";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";

const TimeTable = () => {
  const bottomRef = useRef();
  const dispatch = useDispatch();
  const { agendaData, sortedDataForAgenda, isLoading, draggableAssignment } =
    useSelector((state) => state.dashboard);
  const [assignmentBeforeDrag, setAssignmentBeforeDrag] = useState(null);
  const { isMobile } = useContext(ResponsiveContext);
  let date = new Date();
  const currentTime =
    date.getHours() +
    ":" +
    (date.getMinutes() < 10 ? "0" : "") +
    date.getMinutes();

  const [prevIsLoading, setPrevIsLoading] = useState(isLoading);
  useEffect(() => {
    // Check if loading changed from true to false
    if (prevIsLoading && !isLoading) {
      // give React time to update the DOM
      const timer = setTimeout(() => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 0);
      return () => clearTimeout(timer);
    }
    setPrevIsLoading(isLoading);
  }, [isLoading, prevIsLoading]);

  const handleDrop = (result) => {
    setAssignmentBeforeDrag(null);
    if (result.destination) {
      const time = result.destination.droppableId;
      if (draggableAssignment?.isDragging) {
        const hours = time.split(":")[0] - 1;
        const minutes = "00";
        const modifiedISOString = changeHoursAndMinutesInISOString(
          draggableAssignment.executionTime,
          hours,
          minutes
        );
        dispatch(
          handleUpdateAssignmentFromAgenda({
            ...draggableAssignment,
            isCreated: true,
            executionTime: modifiedISOString,
            agendaDateBeforeDrag: draggableAssignment.agendaDate,
            agendaDate: modifiedISOString,
          })
        );
        dispatch(setAssignmentEditMode(true));
        dispatch(
          handleAssignmentDetailsUpdate({
            ...draggableAssignment,
            isCreated: true,
            occurance: {
              ...draggableAssignment.occurance,
              hour: hours,
              minutes: minutes,
            },
            agendaDate: modifiedISOString,
            executionTime: modifiedISOString,
          })
        );
        dispatch(setDraggableAssignment(null));
      }
    }
  };
  const handleDrag = (el) => {
    const item = sortedDataForAgenda.find((item) => item.id === el.draggableId);
    if (!item.isDragging) {
      dispatch(setAgendaDataBeforeDrag(agendaData));
    }
    dispatch(setDraggableAssignment({ ...item, isDragging: true }));
  };
  const onDragUpdate = (el) => {
    const item = sortedDataForAgenda.find((item) => item.id === el.draggableId);
    setAssignmentBeforeDrag({ ...item, isDragging: true });
  };
  return (
    <WhiteWrapper style={{ height: "80%" }} isMobile={isMobile}>
      <DragDropContext
        onDragStart={(el) => handleDrag(el)}
        onDragEnd={(result) => handleDrop(result)}
        onDragUpdate={(el) => onDragUpdate(el)}
      >
        <TimeTableWrapper>
          {timeArray.map((item) => (
            <React.Fragment key={item.time}>
              <AgendaRow
                key={"AgendaRow" + item.time}
                time={item.time}
                assignmentBeforeDrag={assignmentBeforeDrag}
              />
              {+currentTime.split(":")[0] === +item.time.split(":")[0] && (
                <div key={"currentTime" + item.time} ref={bottomRef} />
              )}
            </React.Fragment>
          ))}
        </TimeTableWrapper>
      </DragDropContext>
    </WhiteWrapper>
  );
};

export default TimeTable;
