import { locationToAppData } from "./locationToAppData";

export const setParams = (item, locations) => {
  let paramObj = {};
  item.parameters.forEach((param) => {
    switch (param.type) {
      case "location":
        const location = locations?.find(
          (loc) =>
            JSON.stringify(loc.pixelCoordinates) ===
            JSON.stringify(param.default)
        );
        const locData = locationToAppData(location);
        paramObj[param.parameter] = locData;
        break;

      case "text":
        paramObj[param.parameter] = param.value.split(",")[0];
        break;

      case "number":
        paramObj[param.parameter] = +param.default;
        break;

      case "key_value":
        paramObj[param.parameter] = param.default;
        break;

      case "end_time":
        paramObj[param.parameter] = -1;
        break;

      default:
        paramObj[param.parameter] = "";
        break;
    }
  });

  return paramObj;
};
