import styled from "styled-components";
import ICONS from "../../constants/icons";

export const StyledSubmitButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: unset;
  color: #fff;
  background-color: rgba(0, 150, 198, 1);
  padding: 12px 40px;
  border-radius: 48px;
  width: 200px;
  cursor: pointer;
`;
export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

export const StyledSelect = styled.select`
  border-radius: 16px;
  width: 266px;
  height: 52px;
  padding: 0px 32px 0px 20px;
  appearance: none;
  background-image: url(${ICONS.ChevronDown});
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 12px;
  color: #030a1b;
  font-size: 16px;
  border: ${(props) => (props.disabled ? "unset" : "2px solid #c4c4c4;")};
  background-color: ${(props) =>
    props.disabled ? "rgba(237, 237, 237, 0.5);" : "#ffffff;"};
  text-overflow: ellipsis;
`;

export const StyledOption = styled.option`
  color: #030a1b;
  font-size: 16px;
`;
