import React from "react";
import {
  InputTitleWrapper,
  InputTitle,
  UnderLine,
  RadioWrapper,
  RadioTitle,
  RadioInput,
  StyledRadioLabel,
  LabelText,
  DateAndTimeWrapper,
  DateBox,
  ButtonWrapper,
  StyledButton,
  WeekDaysWrapper,
  WeekDay,
} from "./components";
import OutsideClickHandler from "react-outside-click-handler";
import TimePicker from "react-time-picker";
import { ErrorMessage } from "../../pages/robots/tasks-in-assignments/components";
import { ModalContentForm } from "../add-client-modal/components";
import InputText from "../input/InputText";
import { InputWrapper } from "../input/components";
import COLORS from "../../constants/colors";
import Calendar from "react-calendar";

import moment from "moment";
import STRINGS from "../../constants/strings";
import AssignmentNotifications from "../notifications-in-assignment/AssignmentNotifications";
import AssignmentEndTime from "./AssignmentEndTime";

const AssignmentModalContent = ({
  newAssignmentForm,
  setNewAssignmentForm,
  currentModalData,
  chosenAssignment,
  dashboard,
  handleChooseWeekday,
  handleNameChange,
  handleSubmit,
  handleRadioBtnChange,
  handleTimeChange,
  handleCalendarChange,
  errorText,
  showCalendar,
  setShowCalendar,
}) => {
  return (
    <ModalContentForm>
      <InputTitleWrapper style={{ marginTop: "20px" }}>
        <InputTitle>Assignment Name</InputTitle>
        <InputWrapper>
          <InputText
            height="6.4rem"
            borderColor={COLORS.secondary.water_green}
            handleChange={(e) => handleNameChange(e, "name")}
            value={newAssignmentForm?.name || ""}
            error={errorText?.name}
            backgroundColor="#fff"
          />
        </InputWrapper>
      </InputTitleWrapper>

      <UnderLine
        style={{ width: "100%", margin: "20px 0px" }}
        assignmentEditMode
      />

      <RadioWrapper>
        <RadioTitle>When?</RadioTitle>
        <div style={{ display: "flex", marginTop: "20px" }}>
          <StyledRadioLabel>
            <RadioInput
              type="radio"
              name="when"
              value={"Run Every"}
              checked={!newAssignmentForm?.disposable}
              onChange={() => handleRadioBtnChange(false)}
            />
            <LabelText>Run Every</LabelText>
          </StyledRadioLabel>
          <StyledRadioLabel style={{ marginLeft: "20px" }}>
            <RadioInput
              type="radio"
              name="when"
              checked={newAssignmentForm?.disposable}
              value={"Run On"}
              onChange={() => handleRadioBtnChange(true)}
            />
            <LabelText>Run On</LabelText>
          </StyledRadioLabel>
        </div>
      </RadioWrapper>

      {newAssignmentForm.disposable && (
        <React.Fragment>
          <RadioWrapper>
            {/* <DateTitle>Run On</DateTitle> */}
            <DateAndTimeWrapper>
              <div style={{ width: "100%" }}>
                <DateBox
                  showCalendar={showCalendar}
                  onClick={() => setShowCalendar((prev) => !prev)}
                >
                  {moment(new Date(newAssignmentForm.executionTime)).format(
                    "dddd, MMM Do"
                  )}
                </DateBox>
                {errorText?.validateDate && (
                  <ErrorMessage>*{errorText?.validateDate}</ErrorMessage>
                )}
              </div>
              <div style={{ width: "100%" }}>
                <DateBox>
                  <TimePicker
                    format={"hh:mm a"}
                    disableClock={true}
                    clearIcon={false}
                    onChange={(timeValue) => {
                      handleTimeChange(timeValue);
                    }}
                    value={newAssignmentForm.time}
                  />
                </DateBox>
                {errorText?.validateTime && (
                  <ErrorMessage>*{errorText?.validateTime}</ErrorMessage>
                )}
              </div>
            </DateAndTimeWrapper>
          </RadioWrapper>
          {showCalendar && (
            <div style={{ position: "absolute", zIndex: "9999" }}>
              <OutsideClickHandler
                onOutsideClick={() => setShowCalendar(false)}
              >
                <Calendar
                  onChange={(value) => handleCalendarChange(value)}
                  value={new Date(newAssignmentForm.executionTime)}
                />
              </OutsideClickHandler>
            </div>
          )}
        </React.Fragment>
      )}

      {!newAssignmentForm.disposable && (
        <React.Fragment>
          <RadioWrapper>
            <WeekDaysWrapper>
              {newAssignmentForm?.weekDays?.map((item, index) => (
                <WeekDay
                  key={index}
                  active={item.chosen}
                  onClick={() => handleChooseWeekday(index)}
                >
                  {item.day.split("")[0]}
                </WeekDay>
              ))}
            </WeekDaysWrapper>
            {errorText?.weekDays && (
              <ErrorMessage>*{errorText.weekDays}</ErrorMessage>
            )}
            <DateBox style={{ width: "100%", margin: "20px 0px" }}>
              <TimePicker
                format={"hh:mm a"}
                disableClock={true}
                clearIcon={false}
                onChange={(timeValue) => {
                  handleTimeChange(timeValue);
                }}
                value={newAssignmentForm.time}
              />
            </DateBox>
          </RadioWrapper>
        </React.Fragment>
      )}
      {!newAssignmentForm.disposable && (
        <AssignmentEndTime
          newAssignmentForm={newAssignmentForm}
          setNewAssignmentForm={setNewAssignmentForm}
        />
      )}
      <UnderLine style={{ margin: "0px 0px 20px 0px", width: "100%" }} />

      <AssignmentNotifications
        newAssignmentForm={newAssignmentForm}
        setNewAssignmentForm={setNewAssignmentForm}
      />

      {!dashboard && (
        <ButtonWrapper>
          <StyledButton
            onClick={() =>
              handleSubmit(
                newAssignmentForm,
                currentModalData,
                chosenAssignment
              )
            }
          >
            {currentModalData && Object.keys(currentModalData)?.length
              ? STRINGS.update_client
              : STRINGS.create}
          </StyledButton>
        </ButtonWrapper>
      )}
    </ModalContentForm>
  );
};

export default AssignmentModalContent;
