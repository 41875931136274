import React, { useMemo } from "react";
import { useContext } from "react";
import { NavigationMap } from "../../components/navigation_map";
import { RobotsDataContext } from "../../contexts/RobotsDataContext";

const MapComponent = ({
  mapWidth,
  mapHeight,
  pointWidth,
  robotWidth,
  showToolTip,
  onlyRobotId,
  showDeleteMap,
  showIcons,
  FLMap,
}) => {
  const { RobotsData } = useContext(RobotsDataContext);

  const robotsDataForMap = useMemo(() => {
    if (onlyRobotId)
      return RobotsData.filter((robot) => robot.id === onlyRobotId);
    else return RobotsData;
  }, [onlyRobotId, RobotsData]);

  return (
    <NavigationMap
      component_width={mapWidth}
      component_height={mapHeight}
      robotsPosition={robotsDataForMap}
      pointWidth={pointWidth}
      robotWidth={robotWidth}
      showToolTip={showToolTip}
      onlyRobotId={onlyRobotId}
      showDeleteMap={showDeleteMap}
      showIcons={showIcons}
      FLMap={FLMap}
    />
  );
};

export default MapComponent;
