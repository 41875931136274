import React from "react";
import { HeaderCompWraper } from "./components";

const HeaderComponent = ({ name, quantity, isChosen, onClick }) => {
  return (
    <HeaderCompWraper onClick={onClick} isChosen={isChosen}>{`${name}${
      quantity ? `(${quantity})` : ""
    }`}</HeaderCompWraper>
  );
};

export default HeaderComponent;
