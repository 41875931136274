import React, { useEffect, useState } from "react";
import { CustomModalContent, StyledModalBackground } from "./components";
import Draggable from "react-draggable";

const CustomModal = ({
  children,
  isOpen,
  width,
  height,
  showBackground = true,
  transform,
  modalStyles,
  isDraggable,
  onRequestClose = () => {},
  shouldCloseOnOverlayClick = true,
}) => {
  const content = {
    position: "absolute",
    zIndex: "111",
    width: width || "80%",
    overflow: "unset",
    height: height || "65%",
    top: modalStyles?.top || "50%",
    left: modalStyles?.left || "50%",
    right: modalStyles?.right || "auto",
    bottom: modalStyles?.bottom || "auto",
    transform: transform || "translate(-50%, -50%)",
    borderRadius: "16px",
    border: "none",
    padding: "0 ",
    outline: modalStyles?.outline,
  };
  const nodeRef = React.useRef(null);
  const [bounds, setBounds] = useState({
    top: -(window.innerHeight - nodeRef?.current?.offsetHeight),
    left: -(window.innerWidth - nodeRef?.current?.offsetWidth),
    right: 0,
    bottom: 0,
  });

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setBounds((prev) => ({
        ...prev,
        top: -(window.innerHeight - nodeRef?.current?.offsetHeight),
        left: -(window.innerWidth - nodeRef?.current?.offsetWidth),
      }));
    };
    isDraggable && nodeRef.current && handleWindowSizeChange();
    window.addEventListener("resize", () => {
      handleWindowSizeChange();
    });
    return () => {
      window.removeEventListener("resize", () => {
        handleWindowSizeChange();
      });
    };
  }, [
    nodeRef?.current?.offsetHeight,
    nodeRef?.current?.offsetWidth,
    isDraggable,
  ]);

  return (
    isOpen && (
      <React.Fragment>
        {showBackground && (
          <StyledModalBackground
            onClick={() => shouldCloseOnOverlayClick && onRequestClose()}
          />
        )}
        {isDraggable && (
          <Draggable
            nodeRef={nodeRef}
            bounds={{
              top: bounds.top,
              left: bounds.left,
              right: bounds.right,
              bottom: bounds.bottom,
            }}
            disabled={!isDraggable}
          >
            <CustomModalContent
              ref={nodeRef}
              style={{
                ...content,
                cursor: isDraggable ? "pointer" : "unset",
              }}
            >
              {children}
            </CustomModalContent>
          </Draggable>
        )}

        {!isDraggable && (
          <CustomModalContent
            style={{
              ...content,
              cursor: isDraggable ? "pointer" : "unset",
            }}
          >
            {children}
          </CustomModalContent>
        )}
      </React.Fragment>
    )
  );
};

export default CustomModal;
