import axios from "axios";

const rotateRobot = async (robotName, angle) => {
  const res = await axios.post(`/robots/${robotName}/rotate`, {
    angle,
  });
  return res.data;
};

export default rotateRobot;
