import styled from "styled-components";

export const StyledIncomingCallWrapper = styled.div`
  padding: ${({ callIsOpen }) => (callIsOpen ? "70px 24px 0 24px" : "24px")};
`;
export const IncomingCallContent = styled.div``;
export const StyledTitle = styled.h3`
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: #030a1b;
`;

export const CloseIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 27px;
  margin-top: 36px;
  flex-direction: ${({ callIsOpen }) => (callIsOpen ? "column" : "row")};
`;

export const StyledMeetingModalContent = styled.div`
  width: 100%;
  height: 90%;
  padding: 28px;
`;

export const StyledMeetingFrameWrapper = styled.div`
  width: 100%;
  height: ${({ callerLocation }) =>
    callerLocation ? "calc(100% - 50px)" : "calc(100% - 30px)"};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledRobotControlWrapper = styled.div`
  text-align: center;
  font-weight: 300;
  font-size: 18px;
  color: #636764;
  border: 1px solid #e5eae7;
  border-radius: 8px;
  padding: 7px 10px;
  width: 729px;
  margin: auto;

  gap: 7px;
  display: flex;
  flex-direction: column;
`;

export const StyledRotationWrapper = styled.div`
  font-weight: bold;
  font-size: 16px;
  display: flex;
  width: 249px;
  height: 51px;
  border-radius: 50px;
  align-items: center;
  justify-content: space-around;

  > img {
    width: 72px;
    height: 40px;
    border-radius: 48px;
    padding: 9px;
  }
`;

export const StyledWall = styled.div`
  height: 44px;
  border: 1px solid #e5eae7;
`;

export const StyledCallTitle1 = styled.h1`
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  margin: 0;
  margin-top: ${({ withMargin }) => (withMargin ? "18px" : "0")};
`;
