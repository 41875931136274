import { configureStore } from "@reduxjs/toolkit";
import clientReducer from "./clients";
import robotsReducer from "./robots";
import mapsReducer from "./maps";
import toastReducer from "./toast";
import dashboardReducer from "./dashboard";
import applicationsReducer from "./applications";
import userManagementReducer from "./userManagement";
import tooltipReducer from "./tooltip";
import analyticsReducer from "./analytics";

export const store = configureStore({
  reducer: {
    clients: clientReducer,
    robots: robotsReducer,
    maps: mapsReducer,
    toast: toastReducer,
    dashboard: dashboardReducer,
    applications: applicationsReducer,
    userManagement: userManagementReducer,
    tooltip: tooltipReducer,
    analytics: analyticsReducer,
  },
});
