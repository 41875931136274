import React, { useEffect, useRef, useState } from "react";
import {
  StyledTooltipBox,
  StyledTooltipTriangle,
  StyledTooltipWrapper,
} from "./components";
import { useSelector } from "react-redux";

const StyledTooltip = () => {
  const boxRef = useRef();
  const {
    elementCoordinates,
    text,
    tooltipStyles,
    tooltipChildWidth,
    tooltipChildHeight,
    type = "top",
    top = 0,
    left = 0,
  } = useSelector((state) => state.tooltip);

  const [boxWidth, setBoxWidth] = useState();
  const [boxHeight, setBoxHeight] = useState();

  useEffect(() => {
    setBoxWidth(boxRef?.current?.offsetWidth);
    setBoxHeight(boxRef?.current?.offsetHeight);
  }, [elementCoordinates]);

  const triangleLeft =
    (type === "top" || type === "bottom") && `${boxWidth / 2 - 5}px`;
  const triangleRight = type === "left" && "-5px";
  const triangleTop = type === "bottom" && "-5px";
  const triangleBottom =
    type === "left" && boxHeight / 2 - tooltipChildHeight / 2;
  const triangleTransform =
    type === "left" ? "rotate(-45deg)" : type === "bottom" && "rotate(225deg)";
  const triangleBackground = tooltipStyles?.background;
  return (
    elementCoordinates && (
      <StyledTooltipWrapper
        style={{
          transform:
            type === "left"
              ? "translate(-100%, -50%)"
              : type === "bottom" &&
                `translate(-50%, ${tooltipChildHeight + 6}px)`,
          top: `${elementCoordinates?.y - (type === "top" ? 12 : 0) + top}px`,
          left: `${
            elementCoordinates?.x +
            (type === "top" || type === "bottom"
              ? tooltipChildWidth / 2
              : -12) +
            left
          }px`,
        }}
      >
        <div style={{ position: "relative", width: "100%" }}>
          <StyledTooltipBox ref={boxRef} style={{ ...tooltipStyles }}>
            <div>{text}</div>
          </StyledTooltipBox>

          <StyledTooltipTriangle
            style={{
              top: triangleTop,
              left: triangleLeft,
              right: triangleRight,
              bottom: triangleBottom,
              transform: triangleTransform,
              background: triangleBackground,
            }}
            border={tooltipStyles?.border}
            // borderColor={tooltipStyles?.borderColor}
          />
        </div>
      </StyledTooltipWrapper>
    )
  );
};

export default StyledTooltip;
