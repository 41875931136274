import React, { useState, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import runImmediateTask from "../../APIs/tasks/runImmediate";
import COLORS from "../../constants/colors";
import {
  ErrorMessage,
  StyledOption,
  StyledSelect,
} from "../../pages/robots/tasks-in-assignments/components";
import { setITModal } from "../../redux/dashboard";
import CustomButton from "../button/CustomButton";
import { ModalFooter } from "../modal-outline/components";
import ModalOutline from "../modal-outline/ModalOutline";
import { ModalForm, StyledModalName } from "../send-robot-to-modal/components";
import { ROBOTTASKSTATUSENUM } from "../../enums";
import TaskParametersEditMode from "../agenda-assignment-details-modal/TaskParametersEditMode";
import useTasks from "../../hooks/useTasks";
import { setParams } from "../../utils/setParams";
import MobileFormInputFocus from "../form-mobile-focus-hoc/MobileFormInputFocus";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";

const AssignCustomTaskModal = ({
  isOpen,
  setIsOpen,
  chosenApp,
  setChosenApp,
  shortcut,
}) => {
  const { handleTaskValidation, isError, setIsError } = useTasks({});
  const { isMobile } = useContext(ResponsiveContext);
  const { locations } = useSelector((state) => state.maps);
  const { chosenClient, currentUser } = useSelector((state) => state.clients);
  const { appsData } = useSelector((state) => state.applications);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleSelectChange = (appId) => {
    setIsError(false);
    if (!appId) {
      setChosenApp({});
    } else {
      const item = appsData.find((item) => item.id === appId);
      const paramObj = setParams(item, locations);
      setChosenApp({
        ...item,
        application: item,
        parameters: paramObj,
      });
    }
  };

  const handleParametersChange = useCallback(
    (inputValue, taskId, paramName, paramId) => {
      setIsError(false);
      setChosenApp((prev) => ({
        ...prev,
        parameters: { ...prev.parameters, [paramName]: inputValue },
      }));
    },
    [setChosenApp, setIsError]
  );

  const [audio] = React.useState(
    new Audio(
      "https://fms-s3-dev.s3.eu-central-1.amazonaws.com/audio/fleet-task-audio.mov"
    )
  );

  const playAudio = useCallback(() => {
    try {
      audio.play();
    } catch (error) {
      console.log(error);
    }
  }, [audio]);

  const handleConfirm = async (e) => {
    e.preventDefault();
    let body = {
      clientId: chosenClient,
      userId: currentUser.id,
      parameters: chosenApp?.parameters,
      applicationId: chosenApp?.id,
      applicationName: chosenApp?.aliasName,
      status: "In Progress",
      type: "IMMEDIATE",
      liableRobotId: chosenApp?.chosenRobot?.id,
    };

    const isValid = handleTaskValidation({
      ...body,
      application: chosenApp?.application,
    });
    if (!isValid) {
      setIsError(true);
    } else {
      setLoading(true);
      setIsOpen(false);
      dispatch(setITModal({ isOpen: "loading", robotName: "" }));
      setTimeout(async () => {
        try {
          const task = await runImmediateTask(body);
          setLoading(false);
          if (task.status === ROBOTTASKSTATUSENUM.DEFAULT) {
            setTimeout(() => {
              playAudio();
              dispatch(
                setITModal({ isOpen: "done", robotName: task?.robot?.name })
              );
            }, 1000);
          }
          if (task.status === ROBOTTASKSTATUSENUM.DELAYED) {
            setTimeout(() => {
              dispatch(
                setITModal({ isOpen: "busy", robotName: task?.robot?.name })
              );
            }, 1000);
          }
          if (task.status === ROBOTTASKSTATUSENUM.OFFLINE) {
            setTimeout(() => {
              dispatch(setITModal({ isOpen: "error", robotName: "" }));
            }, 1000);
          }
          setChosenApp({});
        } catch (error) {
          if (error?.response?.data?.message?.startsWith("Please Release")) {
            setTimeout(() => {
              dispatch(
                setITModal({
                  isOpen: ROBOTTASKSTATUSENUM.EMERGENCY,
                  robotName: error.response.data.message,
                })
              );
            }, 1000);
          } else {
            setTimeout(() => {
              dispatch(
                setITModal({
                  isOpen: "error",
                  robotName: "",
                  error: error?.response?.data?.message,
                })
              );
            }, 1000);
          }
          setLoading(false);
        }
      }, 2000);
    }
  };

  return (
    <React.Fragment>
      <ModalOutline
        width="40%"
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false);
          setChosenApp({});
          setIsError(false);
        }}
        heading={"Assign A New Task"}
      >
        <MobileFormInputFocus>
          <ModalForm
            // onSubmit={!loading && handleConfirm}
            style={{ padding: isMobile && "25px 25px 0 25px" }}
          >
            <div
              style={{ maxHeight: "40vh", overflow: "auto", padding: "0 10px" }}
            >
              {shortcut ? (
                <StyledModalName>{chosenApp.aliasName}</StyledModalName>
              ) : (
                <StyledSelect
                  style={{ width: "100%", marginTop: "10px" }}
                  value={chosenApp?.id}
                  onChange={(e) => handleSelectChange(e.target.value)}
                >
                  <StyledOption value={""} defaultValue={false}>
                    Choose application
                  </StyledOption>
                  {appsData.map((app, ind) => (
                    <StyledOption
                      key={ind}
                      value={app.id}
                      //   selected={app.aliasName === item?.application?.aliasName}
                    >
                      {app.aliasName}
                    </StyledOption>
                  ))}
                </StyledSelect>
              )}

              {isError && (!chosenApp || !Object.keys(chosenApp).length) && (
                <ErrorMessage>*This field can not be empty</ErrorMessage>
              )}
              <TaskParametersEditMode
                item={chosenApp}
                setChosenApp={setChosenApp}
                isError={isError}
                handleParametersChange={handleParametersChange}
                dashboard
              />
            </div>
            <ModalFooter>
              <CustomButton
                padding={isMobile ? "8px 40px" : "0 7rem"}
                height={isMobile && "3.3rem"}
                fontFamily={"Ubuntu-Regular"}
                fontSize={"16px"}
                backgroundColor={COLORS.secondary.ncs}
                shadow={false}
                // type="submit"
                onClick={!loading ? handleConfirm : () => {}}
              >
                Confirm
              </CustomButton>
            </ModalFooter>
          </ModalForm>
        </MobileFormInputFocus>
      </ModalOutline>
    </React.Fragment>
  );
};

export default AssignCustomTaskModal;
