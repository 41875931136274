import React, { useContext, useEffect, useState } from "react";
import {
  ComponentHeader,
  ComponentTitle,
} from "../admin-page/environment-setup/components";
import STRINGS from "../../constants/strings";
import { HeaderStyledButton } from "../robots/assignments/components";
import UserManagementTable from "../../components/user-management/UserManagementTable";
import TeamMemberModal from "../../components/user-management/user-management-modals/TeamMemberModal";
import { useDispatch, useSelector } from "react-redux";
import {
  setEditUserData,
  setIsSuccessModal,
  setDeleteUserData,
  handleDeleteUser,
} from "../../redux/userManagement";
import MemberSuccessModal from "../../components/user-management/user-management-modals/MemberSuccessModal";
import InfoModal from "../../components/info-modal/InfoModal";
import { InfoModalTypes } from "../../constants/types";
import getClientMembers from "../../APIs/user-management/getClientMembers";
import { setUsersData } from "../../redux/userManagement";
import deleteClientUser from "../../APIs/user-management/deleteMember";
import RotateDeviceAlert from "../../components/rotate-device-alert/RotateDeviceAlert";
import { ROLESENUM } from "../../enums";
import getClientMembersAdmin from "../../APIs/user-management/getClientMembersAdmin";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";

const UserManagement = () => {
  const { isTablet, isLandscape } = useContext(ResponsiveContext);
  const [isInvitationModalOpen, setIsINvitationModalOpen] = useState(false);
  const { isSuccessModal, deleteUserData, editUserData } = useSelector(
    (state) => state.userManagement
  );
  const { client, currentRole } = useSelector((state) => state.clients);
  const dispatch = useDispatch();

  useEffect(() => {
    if (client?.id) {
      if (currentRole === ROLESENUM.ADMIN) {
        getClientMembersAdmin(client.id).then((data) => {
          dispatch(setUsersData(data));
        });
      } else {
        getClientMembers(client.id).then((data) => {
          dispatch(setUsersData(data));
        });
      }
    }
  }, [client.id, dispatch, currentRole]);

  return isTablet && !isLandscape ? (
    <RotateDeviceAlert />
  ) : (
    <div>
      <ComponentHeader>
        <ComponentTitle>{`${STRINGS.user_management}`}</ComponentTitle>
        <HeaderStyledButton
          onClick={() => setIsINvitationModalOpen(true)}
        >{`${STRINGS.invite_team_member}`}</HeaderStyledButton>
      </ComponentHeader>

      <UserManagementTable />

      <TeamMemberModal
        chosenClient={client}
        isOpen={isInvitationModalOpen || editUserData}
        onRequestClose={() => {
          setIsINvitationModalOpen(false);
          dispatch(setEditUserData(null));
        }}
      />

      <MemberSuccessModal
        isOpen={isSuccessModal}
        onRequestClose={() => dispatch(setIsSuccessModal(false))}
      />

      <InfoModal
        style={{ maxWidth: "52.6rem" }}
        isOpen={deleteUserData}
        type={InfoModalTypes.WARNING}
        title="Warning!"
        subTitle="You are sure you want to delete this user? This action cannot be undone."
        // noOuterClickClosing
        cancelAction={() => {
          dispatch(setDeleteUserData(null));
        }}
        submitAction={async () => {
          await deleteClientUser(client.id, deleteUserData.id);
          dispatch(handleDeleteUser());
          dispatch(setDeleteUserData(null));
        }}
        noOuterClickClosing
        cancelText={"Cancel"}
        submitText={"Yes Delete"}
        disabled={false}
      />
    </div>
  );
};

export default UserManagement;
