import React from "react";
import {
  CardInfoRow,
  GeneralInfoHeader,
  GeneralInfoWrapper,
  PercentageWrapper,
  RobotLogoWrapper,
  RobotRowPhoto,
  SettingIcon,
  StyledBattery,
  StyledInfoKey,
  StyledInfoValue,
  StyledRobotCardInfo,
  StyledStatus,
} from "./components";
import { ROBOTSTATUSENUM, ROBOTSTATUSENUMALIAS, ROLESENUM } from "../../enums";
import ICONS from "../../constants/icons";
import { useSelector } from "react-redux";
import { UnderLine } from "../../components/add-assignment-modal/components";
import { determineRobotStatusText } from "../../utils/robots/robotStatusText";
const RobotGeneralInfoMobile = ({ currentRobot, handleSetting }) => {
  const { currentRole } = useSelector((state) => state.clients);
  return (
    <GeneralInfoWrapper style={{ flexDirection: "column", padding: "13px" }}>
      <GeneralInfoHeader>
        <RobotLogoWrapper style={{ width: "unset" }}>
          <RobotRowPhoto status={currentRobot?.status} alt="Logo" />
          <StyledStatus
            style={{
              background: "#9094910d",
              padding: "8px 16px",
              borderRadius: "8px",
            }}
            status={currentRobot?.status || ROBOTSTATUSENUM.OFF}
          >
            {ROBOTSTATUSENUMALIAS[currentRobot?.status?.replace(/\s/g, "")] ||
              ROBOTSTATUSENUM.OFF}
          </StyledStatus>
        </RobotLogoWrapper>
        <StyledInfoValue style={{ display: "flex" }}>
          {currentRobot?.status &&
            currentRobot?.status !== ROBOTSTATUSENUM.OFF && (
              <>
                <PercentageWrapper>
                  {currentRobot?.battery !== "nan" &&
                    currentRobot?.battery !== "NaN" &&
                    `${+(currentRobot?.battery * 100).toFixed(0)}%`}
                </PercentageWrapper>
                <StyledBattery
                  style={{ width: "38px", height: "18px", margin: "unset" }}
                  battery={(+currentRobot?.battery)?.toFixed(2)}
                />
              </>
            )}
        </StyledInfoValue>
      </GeneralInfoHeader>
      <UnderLine style={{ width: "100%", marginTop: "10px" }} />
      <StyledRobotCardInfo>
        <CardInfoRow>
          <StyledInfoKey>Current task</StyledInfoKey>
          <StyledInfoValue>
            {determineRobotStatusText(currentRobot)}
          </StyledInfoValue>
        </CardInfoRow>
      </StyledRobotCardInfo>
      {[ROLESENUM.ADMIN, ROLESENUM.DEVELOPER].includes(currentRole) && (
        <SettingIcon src={ICONS.NavSettings} onClick={handleSetting} />
      )}
    </GeneralInfoWrapper>
  );
};

export default RobotGeneralInfoMobile;
