import React from "react";
import {
  StyledTypeRow,
  StyledTypeText,
  TypeSelectorWrapper,
} from "./components";
import ICONS from "../../../constants/icons";
import { useDispatch } from "react-redux";
import { setWidgetModalData } from "../../../redux/analytics";

const AnalyticTypeSelector = ({ style, onChoose = () => {} }) => {
  const dispatch = useDispatch();
  const types = [
    { icon: ICONS.Pie, text: "Pie" },
    { icon: ICONS.Chart, text: "Chart" },
    { icon: ICONS.Graph, text: "Graph" },
    { icon: ICONS.NumberOneIcon, text: "Number" },
    { icon: ICONS.TaskCompleted, text: "Task Completed" },
  ];
  const handleClick = (item) => {
    dispatch(setWidgetModalData({ type: item.text, isCreated: false }));
    onChoose();
  };
  return (
    <TypeSelectorWrapper style={{ ...style }}>
      {types.map((item) => (
        <StyledTypeRow key={item.text} onClick={() => handleClick(item)}>
          <img alt="" src={item.icon} />
          <StyledTypeText>{item.text}</StyledTypeText>
        </StyledTypeRow>
      ))}
    </TypeSelectorWrapper>
  );
};

export default AnalyticTypeSelector;
