import axios from "axios";

const getAnalyticsPreview = async (clientId, params) => {
  const res = await axios.get(
    `/analytics/preview/${clientId}?${params.toString()}`
  );
  return res.data;
};

export default getAnalyticsPreview;
