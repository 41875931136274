import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { setMapModalData } from "../../redux/maps";
import StyledFileInput from "../styled-input-type-file/StyledFileInput";
import ICONS from "../../constants/icons";
import {
  IconWrapper,
  StyledIcon,
} from "../../pages/robots/tasks-in-assignments/components";
import { InputTitle } from "../add-assignment-modal/components";
import { InputError } from "../input/components";
import { MAPTYPESENUM } from "../../enums";

const UploadMap = ({
  mapModalData,
  setErrorMessages,
  errorMessages,
  mapType = MAPTYPESENUM.DASHBOARD_MAP,
}) => {
  const dispatch = useDispatch();

  const handMapPhotoUpload = useCallback(
    async (event) => {
      const file = event.target.files[0];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

      if (file && allowedTypes.includes(file.type)) {
        dispatch(
          setMapModalData({
            ...mapModalData,
            [mapType]: event.target?.files[0],
          })
        );
        setErrorMessages((prev) => ({
          ...prev,
          [mapType]: "",
        }));
      } else {
        setErrorMessages((prev) => ({
          ...prev,
          [mapType]:
            "Sorry, the file format you are trying to upload is not supported",
        }));
      }
    },
    [dispatch, mapModalData, mapType, setErrorMessages]
  );

  const imageSrc = useMemo(() => {
    if (mapType === MAPTYPESENUM.DASHBOARD_MAP && mapModalData) {
      return (
        (mapModalData[MAPTYPESENUM.DASHBOARD_MAP] &&
          URL.createObjectURL(mapModalData[MAPTYPESENUM.DASHBOARD_MAP])) ||
        mapModalData?.mapUrl
      );
    } else {
      if (mapType === MAPTYPESENUM.FLEET_RULES_MAP && mapModalData) {
        return (
          (mapModalData[MAPTYPESENUM.FLEET_RULES_MAP] &&
            URL.createObjectURL(mapModalData[MAPTYPESENUM.FLEET_RULES_MAP])) ||
          mapModalData?.editedMapUrl
        );
      }
    }
  }, [mapModalData, mapType]);

  return (
    <>
      <InputTitle style={{ color: "#5C605D", marginTop: "20px" }}>
        {mapType === MAPTYPESENUM.FLEET_RULES_MAP
          ? "Upload fleet rules map"
          : "Upload dashboard map"}
      </InputTitle>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "30px",
          position: "relative",
        }}
      >
        {mapModalData && (mapModalData[mapType] || mapModalData?.mapUrl) ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <StyledFileInput
                id={`styledFileInput + ${mapType}`}
                handleChange={handMapPhotoUpload}
              >
                <IconWrapper background={"rgba(6, 134, 216, 0.1)"}>
                  <StyledIcon src={ICONS.PencilDraw} />
                </IconWrapper>
              </StyledFileInput>
            </div>
            <img
              src={imageSrc}
              alt=""
              style={{ cursor: "pointer", width: "316px" }}
            />
          </div>
        ) : (
          <>
            <StyledFileInput
              id={`styledFileInput + ${mapType}`}
              handleChange={handMapPhotoUpload}
            >
              <img
                src={ICONS.UploadImage}
                alt=""
                style={{ cursor: "pointer" }}
              />
            </StyledFileInput>
          </>
        )}
        {errorMessages[mapType] && (
          <InputError
            className="error"
            style={{ left: "28px", top: `calc(100% + -2.4rem)` }}
          >
            * {errorMessages[mapType]}
          </InputError>
        )}
      </div>
    </>
  );
};

export default UploadMap;
