import { ROBOTSTATUSENUM } from "../../enums";

export const determineRobotStatusText = (robot) => {
  switch (robot?.status) {
    case ROBOTSTATUSENUM.FREE:
      return "No task assigned";

    case ROBOTSTATUSENUM.BUSY:
      return (
        robot?.full_status?.current_task?.split("_")?.join(" ") ||
        robot?.currentApplication
      );

    case ROBOTSTATUSENUM.CHARGING:
      return "Robot is at the charging station";

    case ROBOTSTATUSENUM.ERROR:
      return robot?.full_status?.current_task?.split("_")?.join(" ");

    case ROBOTSTATUSENUM.OFF:
      return "Robot not connected";

    case ROBOTSTATUSENUM.PAUSED:
      return "The emergency button has been activated";

    default:
      return "Robot not connected";
  }
};
