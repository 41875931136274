import React, { useState } from "react";
import ModalOutline from "../modal-outline/ModalOutline";
import { ParameterNamesWrapper, StyledModalForm } from "./components";
import { ApplicationNameWrapper } from "../add-application-modal/components";
import InputText from "../input/InputText";
import {
  IconWrapper,
  InputTitle,
  RowUnderLine,
  StyledIcon,
  TaskTitle,
} from "../../pages/robots/tasks-in-assignments/components";
import ICONS from "../../constants/icons";
import { useEffect } from "react";
import { StyledButton } from "../styled-button/components";
import { v4 as uuidv4 } from "uuid";

const KeyValueModal = ({
  isOpen,
  onRequestClose,
  keyValueParameters,
  onSubmit,
}) => {
  const [list, setList] = useState(keyValueParameters?.list);
  const [isListError, setIsListError] = useState(false);
  useEffect(() => {
    setList(keyValueParameters?.list);
  }, [keyValueParameters]);

  const handleAddValues = () => {
    setIsListError(false);
    setList((prev) => [...prev, { id: uuidv4(), key: "", value: "" }]);
  };
  const handleInputChange = (value, inputName, id) => {
    setIsListError(false);
    setList((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, [inputName]: value } : item
      )
    );
  };
  const handleDeleteValues = (id) => {
    setList((prev) => prev.filter((item) => item.id !== id));
  };
  return (
    <ModalOutline
      width={"35%"}
      isOpen={isOpen}
      heading={"Key Value"}
      onRequestClose={onRequestClose}
    >
      <StyledModalForm>
        <ParameterNamesWrapper>
          <ApplicationNameWrapper>
            <div>
              <InputTitle style={{ color: "#000" }}>Parameter Name</InputTitle>
              <InputText
                disabled={true}
                width="100%"
                height="5.4rem"
                value={keyValueParameters?.parameter_name || ""}
                backgroundColor="#fff"
              />
            </div>
            <div>
              <InputTitle style={{ color: "#000" }}>Parameter</InputTitle>
              <InputText
                width="100%"
                height="5.4rem"
                value={keyValueParameters?.parameter || ""}
                backgroundColor="#fff"
                disabled={true}
              />
            </div>
          </ApplicationNameWrapper>
        </ParameterNamesWrapper>
        <RowUnderLine style={{ margin: "39px 0" }} />
        <div>
          <TaskTitle>Parameters</TaskTitle>
          <div
            style={{
              maxHeight: "34rem",
              overflow: "auto",
              padding: "27px 0px",
            }}
          >
            {list?.map((el) => (
              <ApplicationNameWrapper
                key={el.id}
                style={{ marginTop: "30px", alignItems: "center", gap: "50px" }}
              >
                <div>
                  <InputTitle style={{ color: "#000" }}>Key</InputTitle>
                  <InputText
                    width="100%"
                    height="5.4rem"
                    value={el?.key || ""}
                    backgroundColor="#fff"
                    handleChange={(e) => handleInputChange(e, "key", el.id)}
                    error={
                      isListError && !el?.key && "This field can not be empty"
                    }
                  />
                </div>
                <div>
                  <InputTitle style={{ color: "#000" }}>Value</InputTitle>
                  <InputText
                    width="100%"
                    height="5.4rem"
                    value={el?.value || ""}
                    backgroundColor="#fff"
                    handleChange={(e) => handleInputChange(e, "value", el.id)}
                    error={
                      isListError && !el?.value && "This field can not be empty"
                    }
                  />
                </div>
                <IconWrapper
                  style={{ marginLeft: "auto", marginTop: "15px" }}
                  background={"rgba(212, 47, 57, 0.1)"}
                  onClick={(e) => {
                    e.preventDefault();
                    handleDeleteValues(el.id);
                  }}
                >
                  <StyledIcon src={ICONS.DeleteIcon} />
                </IconWrapper>
              </ApplicationNameWrapper>
            ))}
          </div>
          <img
            src={ICONS.AddIcon}
            alt=""
            style={{ width: "42px", marginTop: "30px", cursor: "pointer" }}
            onClick={handleAddValues}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <StyledButton
            borderColor={"#fff"}
            fontSize={"16px"}
            color={"#fff"}
            fontFamily={"unset"}
            background={"#0096C6"}
            padding={"8px 45px"}
            style={{ width: "300px", marginTop: "20px" }}
            type="submit"
            onClick={() => onSubmit(keyValueParameters, list, setIsListError)}
          >
            Save
          </StyledButton>
        </div>
      </StyledModalForm>
    </ModalOutline>
  );
};
export default KeyValueModal;
