import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import deleteFloor from "../APIs/maps/deletefloor";

const initialState = {
  locations: [],
  mapsData: [],
  chosenMapUrl: "",
  mapModalData: null,
  chosenFloor: "",
  chosenBuilding: "",
  buildingOptions: [],
  floorOptions: [],
  isMapLoading: false,
  chosenMap: null,
};
export const handleMapDelete = createAsyncThunk(
  "maps/handleMapDelete",
  async ({ chosenBuilding, chosenFloor }, { rejectWithValue }) => {
    try {
      await deleteFloor(chosenBuilding, chosenFloor);
    } catch (error) {
      throw rejectWithValue(error.response.data);
    }
  }
);
export const mapsSlice = createSlice({
  name: "robots",
  initialState,
  reducers: {
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
    addLocation: (state, action) => {
      state.locations = [...state.locations, action.payload];
    },
    deleteLocation: (state, action) => {
      state.locations = [
        ...state.locations.filter((item) => action.payload !== item.id),
      ];
    },
    setMapsData: (state, action) => {
      state.mapsData = action.payload;
    },
    setChosenMapUrl: (state, action) => {
      state.chosenMapUrl = action.payload;
    },
    setMapModalData: (state, action) => {
      state.mapModalData = action.payload;
    },
    setChosenBuilding: (state, action) => {
      state.chosenBuilding = action.payload;
    },
    setChosenFloor: (state, action) => {
      state.chosenFloor = action.payload;
    },
    setIsMapLoading: (state, action) => {
      state.isMapLoading = action.payload;
    },
    setBuildingOptions: (state, action) => {
      state.buildingOptions = action.payload;
    },
    setFloorOptions: (state, action) => {
      const options = [...action.payload];
      options.sort((a, b) => {
        const isANumber = /^\d/.test(a);
        const isBNumber = /^\d/.test(b);

        if (!isANumber && isBNumber) {
          return -1; // Text comes before numbers
        }
        if (isANumber && !isBNumber) {
          return 1; // Numbers come after text
        }
        // If both are numbers (or start with numbers), sort numerically
        if (isANumber && isBNumber) {
          return (
            parseFloat(a.match(/^\d+/)[0]) - parseFloat(b.match(/^\d+/)[0])
          );
        }
        // Otherwise, sort alphabetically
        return a.localeCompare(b);
      });
      state.floorOptions = options;
    },
    setChosenMap: (state, action) => {
      state.chosenMap = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(handleMapDelete.fulfilled, (state, action) => {
      const updatedMapsData = state.mapsData.map((el) =>
        el.building === state.chosenBuilding
          ? {
              ...el,
              floors: el.floors.filter(
                (item) => item.floor !== state.chosenFloor
              ),
            }
          : el
      );
      state.mapsData = updatedMapsData;
      state.chosenFloor = state.floorOptions.filter(
        (el) => el !== state.chosenFloor
      );
    });
  },
});

export const {
  setLocations,
  addLocation,
  deleteLocation,
  setMapsData,
  setChosenMapUrl,
  setMapModalData,
  setChosenFloor,
  setChosenBuilding,
  setIsMapLoading,
  setBuildingOptions,
  setFloorOptions,
  setChosenMap,
} = mapsSlice.actions;

export default mapsSlice.reducer;
