import React, { useState } from "react";
import { FloatingMenu, MainButton, ChildButton } from "./";
import { useApplications } from "../../hooks/useApplications";
import AssignCustomTaskModal from "../assign-custom-task-modal/AssignCustomTaskModal";
import { useSelector } from "react-redux";

const FLoatButtonAgenda = () => {
  const [floatMenuOpen, setFloatMenuOpen] = useState(false);
  const {
    setChosenAppFunc,
    isAppModalOpen,
    setIsAppModalOpen,
    chosenApp,
    setChosenApp,
  } = useApplications();

  const { appsData } = useSelector((state) => state.applications);

  const [customTaskModal, setCustomTaskModal] = useState(false);

  return (
    <>
      <FloatingMenu direction="up" spacing={8} floatMenuOpen={floatMenuOpen}>
        <MainButton
          iconResting={"+"}
          iconActive={"x"}
          backgroundColor="rgba(52, 97, 158, 1)"
          onClick={() => setFloatMenuOpen((prev) => !prev)}
          size={64}
        />

        <ChildButton
          onClick={() => {
            setCustomTaskModal(true);
            setFloatMenuOpen((prev) => !prev);
          }}
          icon={"Assign custom task"}
          backgroundColor="white"
        />

        {appsData
          .filter((item) => item.shortcut)
          .map((item) => (
            <ChildButton
              icon={item.aliasName}
              backgroundColor="white"
              key={`child-float-button + ${item.aliasName}`}
              onClick={() => {
                setChosenAppFunc(item);
                setFloatMenuOpen((prev) => !prev);
              }}
            />
          ))}
      </FloatingMenu>
      <AssignCustomTaskModal
        isOpen={isAppModalOpen}
        setIsOpen={setIsAppModalOpen}
        chosenApp={chosenApp}
        setChosenApp={setChosenApp}
        shortcut={true}
      />
      {customTaskModal && (
        <AssignCustomTaskModal
          isOpen={customTaskModal}
          setIsOpen={setCustomTaskModal}
          chosenApp={chosenApp}
          setChosenApp={setChosenApp}
        />
      )}
    </>
  );
};

export default FLoatButtonAgenda;
