import { createContext, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { getRobots } from "../APIs";
import {
  setRobotsDataRedux,
  setRobotTaskWarningData,
  setAbortTask,
  setLowBatteryRobotsQueue,
  setEmergencyPressedRobots,
} from "../redux/robots";
import socket, { joinSocketRoom } from "../APIs/socket/socket";
import { ROBOTSTATUSENUM } from "../enums";

export const RobotsDataContext = createContext();

export const RobotsDataProvider = ({ children }) => {
  const { client, chosenClient } = useSelector((state) => state.clients);
  const [RobotsData, setRobotsData] = useState([]);
  const dispatch = useDispatch();

  const [dataIndicator, setDataIndicator] = useState(false);

  const { mutate: getRobotsMutation } = useMutation("get-robots", () =>
    getRobots(chosenClient)
  );

  const getAllRobots = useCallback(() => {
    getRobotsMutation(null, {
      onSuccess: (robots) => {
        setRobotsData(robots);
      },
      onError: (err) => console.log("Error", err),
    });
  }, [getRobotsMutation, setRobotsData]);

  useEffect(() => {
    chosenClient && getAllRobots();
  }, [chosenClient, getAllRobots]);

  useEffect(() => {
    if (client?.name) {
      joinSocketRoom(client.name);
      socket.on("robots_data", (data) => {
        setDataIndicator((prev) => !prev);
        const socketData = Object.values(data);
        let seenLowBatteryRobots =
          JSON.parse(sessionStorage.getItem("seen_low_battery_robots")) || [];
        let robotsQueue = [];
        socketData.forEach((el) => {
          if (
            el.full_status &&
            +el.full_status.battery_level * 10 < 3 &&
            !seenLowBatteryRobots.includes(el.robotId) &&
            el.status !== ROBOTSTATUSENUM.OFF
          ) {
            robotsQueue.push(el.robotId);
          }
        });
        dispatch(setLowBatteryRobotsQueue(robotsQueue));

        setRobotsData((prev) =>
          prev.map((robot) => {
            const robotData = socketData.find(
              (data) => data.robot_id === robot.robotId
            );
            if (robotData) return { ...robot, ...robotData };
            else return robot;
          })
        );
      });

      socket.on("robot_warning", (data) => {
        if (data.message === "close_modal") {
          dispatch(setAbortTask(data));
        } else {
          dispatch(setRobotTaskWarningData(data));
        }
      });

      socket.on("emergency_button", (data) => {
        dispatch(setEmergencyPressedRobots(data));
      });

      return () => {
        socket.emit("leaveRoom", client.name);
        socket.off("connect");
        socket.off("disconnect");
        socket.off("robot_data");
        socket.off("robot_warning");
        socket.off("emergency_button");
      };
    }
  }, [client, dispatch]);

  useEffect(() => {
    dispatch(setRobotsDataRedux(RobotsData));
  }, [RobotsData, dispatch]);

  return (
    <RobotsDataContext.Provider
      value={{
        RobotsData,
        setRobotsData,
        dataIndicator,
      }}
    >
      {children}
    </RobotsDataContext.Provider>
  );
};
