import React, { useContext, useRef, useState } from "react";
import { Title } from "../../pages/sign-in/components";
import STRINGS from "../../constants/strings";
import {
  StyledTerm,
  StyledTermsContentTitle,
  StyledTermsFooter,
  TermsWrapper,
} from "./components";
import {
  EditAssignmentButton,
  EditAssignmentButtonsWrapper,
} from "../agenda-assignment-details-modal/components";
import { termsText } from "./TermsAndConditionsText";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
const TermsAndConditions = ({ handleSubmit, handleDecline }) => {
  const termsWrapperRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const { isMobile } = useContext(ResponsiveContext);
  const handleAccept = () => {
    const termsWrapper = termsWrapperRef.current;
    if (
      isAtBottom ||
      termsWrapper.scrollHeight - termsWrapper.scrollTop <=
        termsWrapper.clientHeight + 1
    ) {
      handleSubmit();
    } else {
      termsWrapper.scrollTo({
        top: termsWrapper.scrollHeight,
      });
    }
  };
  const handleScroll = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop <=
      e.target.clientHeight + 1
    ) {
      setIsAtBottom(true);
    } else {
    }
  };
  return (
    <>
      <Title
        style={{
          fontSize: isMobile ? "2.2rem" : "3.2rem",
          color: "#0096C6",
          width: "100%",
        }}
      >
        {STRINGS.terms_and_conditions}
      </Title>

      <StyledTermsContentTitle style={{ fontSize: isMobile && "17px" }}>
        Orchestra System Terms and Conditions
      </StyledTermsContentTitle>

      <TermsWrapper
        onScroll={handleScroll}
        ref={termsWrapperRef}
        style={{ height: "40vh" }}
      >
        {termsText.map((el) => (
          <>
            <StyledTerm>{el.subtitle}</StyledTerm>
            <StyledTerm>{el.a}</StyledTerm>
            <StyledTerm>{el.b}</StyledTerm>
          </>
        ))}
      </TermsWrapper>
      <StyledTermsFooter>
        <EditAssignmentButtonsWrapper style={{ marginTop: "20px" }}>
          <EditAssignmentButton
            background={"rgba(0, 150, 198, 1)"}
            color={"#fff"}
            onClick={handleAccept}
          >
            Accept
          </EditAssignmentButton>
          <EditAssignmentButton
            background={"#fff"}
            color={"#0686D8"}
            border={"1px solid #0686D8"}
            onClick={handleDecline}
          >
            Decline
          </EditAssignmentButton>
        </EditAssignmentButtonsWrapper>
      </StyledTermsFooter>
    </>
  );
};

export default TermsAndConditions;
