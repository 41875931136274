import { useMemo } from "react";
import moment from "moment";
import { ROBOTTASKSTATUSENUM } from "../enums";

const useTimeFormat = (assignmentDetails) => {
  const timeInterval = useMemo(() => {
    let execTime;
    execTime = moment(assignmentDetails?.executionTime).format("HH:mm");
    if (execTime === "Invalid date")
      execTime = assignmentDetails?.executionTime;
    if (
      assignmentDetails.assignment ||
      [
        ROBOTTASKSTATUSENUM.SENTTOROBOT,
        ROBOTTASKSTATUSENUM.DEFAULT,
        ROBOTTASKSTATUSENUM.DELAYED,
        ROBOTTASKSTATUSENUM.INPROGRESS,
      ].includes(assignmentDetails.status)
    ) {
      if (
        assignmentDetails?.assignment &&
        assignmentDetails?.tasks?.every(
          (item) =>
            item.status === ROBOTTASKSTATUSENUM.DONE ||
            item.status === ROBOTTASKSTATUSENUM.Failed
        )
      ) {
        let time = "";
        let tasks = [...assignmentDetails.tasks];
        tasks.sort((a, b) => {
          return (
            moment(a.updatedAt)
              .format("HH:mm")
              .replace(":", "")
              .replace(" ", "") -
            moment(b.updatedAt)
              .format("HH:mm")
              .replace(":", "")
              .replace(" ", "")
          );
        });

        // for (let task of assignmentDetails.tasks) {
        //   if (task?.task?.updatedAt > time) time = task.task.updatedAt;
        //   if (
        //     [
        //       ROBOTTASKSTATUSENUM.DEFAULT,
        //       ROBOTTASKSTATUSENUM.DELAYED,
        //       ROBOTTASKSTATUSENUM.INPROGRESS,
        //       ROBOTTASKSTATUSENUM.SENTTOROBOT,
        //     ].includes(task.status)
        //   ) {
        //     time = "";
        //     break;
        //   }
        // }
        time = tasks[tasks.length - 1]?.updatedAt;

        if (!time) return execTime;
        return `${execTime} - ${moment(time).format("HH:mm")}`;
      } else return execTime;
    } else
      return `${moment(assignmentDetails.createdAt).format("HH:mm")} - ${moment(
        assignmentDetails.updatedAt
      ).format("HH:mm")}`;
  }, [assignmentDetails]);

  const weekDays = useMemo(() => {
    const daysWithNum = {
      0: "Sunday",
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
    };
    let chosenDays = [];
    if (assignmentDetails.occurance) {
      chosenDays = assignmentDetails.occurance.weekDays
        ?.split(",")
        .map((item) => {
          return daysWithNum[item];
        });
    }
    return chosenDays?.join(", ");
  }, [assignmentDetails]);

  const notFinishedAssignemnt = useMemo(() => {
    return assignmentDetails?.tasks?.some((tsk) =>
      [
        ROBOTTASKSTATUSENUM.DEFAULT,
        ROBOTTASKSTATUSENUM.DELAYED,
        ROBOTTASKSTATUSENUM.INPROGRESS,
        ROBOTTASKSTATUSENUM.SENTTOROBOT,
      ].includes(tsk.status)
    );
  }, [assignmentDetails]);

  return {
    timeInterval,
    weekDays,
    notFinishedAssignemnt,
  };
};

export default useTimeFormat;
