import { NavLink } from "react-router-dom";
import styled from "styled-components";
import COLORS from "../../constants/colors";
import FONTS from "../../constants/fonts";
import ICONS from "../../constants/icons";
export const WholeWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
`;
export const Sidebar = styled.div`
  min-height: 100%;
  width: ${({ isOpen }) => (isOpen ? "26.8rem" : "12.2rem")};
  min-width: ${({ isOpen }) => (isOpen ? "26.8rem" : "12.2rem")};
  max-width: ${({ isOpen }) => (isOpen ? "26.8rem" : "12.2rem")};
  display: flex;
  flex-direction: column;
  display: ${(props) => props.isMobile && "none"};
`;

export const SidebarHeader = styled.div`
  width: 100%;
  height: 9.6rem;
  padding-left: 4.2rem;
  display: flex;
  align-items: center;
  margin-bottom: 4.2rem;
`;

export const HeaderBurger = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  background-image: url(${ICONS.BurgerMenu});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  margin-right: 2rem;
`;

export const HeaderLogo = styled.div`
  width: 4.6rem;
  height: 4.6rem;
  border-radius: 50%;
  background-image: ${({ img }) =>
    img ? `url(${img})` : `url(${ICONS.LogoRoundBackground})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
`;

export const SidebarTab = styled.div`
  width: 100%;
  padding: 0 2.2rem;
  position: relative;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    position: absolute;
    content: "";
    top: 0;
    z-index: 7;
    right: ${({ isOpen }) => (isOpen ? "-0.8rem" : 0)};
    height: 100%;
    width: 0.8rem;
    background: ${({ theme, active }) =>
      active ? theme.color.secondary.ncs : "transparent"};
    border-radius: 0.4rem;
  }
  & > div {
    background: ${({ theme, active }) =>
      active ? `${theme.color.secondary.aero}4d` : "transparent"};
    width: ${({ isOpen }) => (isOpen ? "100%" : "fit-content")};
  }
  &:hover {
    opacity: 1;
    & span {
      display: ${({ isOpen }) => (isOpen ? "none" : "block")};
      font-family: ${({ active }) =>
        active ? FONTS.UbuntuMedium : FONTS.UbuntuRegular};
    }
  }
`;

export const LinkHoverTooltip = styled.span`
  background: ${COLORS.secondary.main_bg};
  position: absolute;
  padding: 0.6rem 1.2rem;
  color: ${COLORS.primary.oxford};
  font-size: 1.2rem;
  border-radius: 1.2rem;
  z-index: 1000;
  left: Calc(100% + 1rem);
  display: none;
  white-space: nowrap;
  text-transform: capitalize;
  box-shadow: inset 0.3rem 0.3rem 1.7rem 0 rgba(8, 24, 63, 0.08);
`;

export const SidebarNavItem = styled.div`
  width: 100%;
  border-radius: 1.6rem;
  display: flex;
  align-items: center;
  padding: 1.6rem;
`;

export const SidebarNavIcon = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  background-image: url(${({ icon }) => icon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 2rem;
`;

export const SidebarNavText = styled.div`
  margin-left: 2.2rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-transform: capitalize;
  position: relative;
`;

export const SidebarFooter = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  & > * a {
    display: inline;
    color: #08183f7a;
    line-height: 1.8rem;
    font-size: 1.2rem;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const RightContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.isMobile ? "" : "100vh")};
  height: 100%;
  overflow: auto;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  height: ${(props) => (props.isMobile ? "5.6rem" : "9.6rem")};
  background: ${(props) =>
    props.isMobile ? "rgba(0, 25, 63, 0.88)" : "#ffffff"};
  display: flex;
  align-items: center;
  padding: ${(props) => (props.isMobile ? "0 20px" : "0 4rem 0 8.8rem")};
  justify-content: space-between;
  position: sticky;
`;

export const PageTitle = styled.div`
  font-family: "AssistantSemiBold", "Ubuntu-Regular";
  font-size: 2.4rem;
  line-height: 2.8rem;
  text-transform: capitalize;
`;
export const HeaderLogout = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  background-image: url(${ICONS.LogOut});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
`;

export const MainContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  background: ${(props) =>
    props.isMobile && props.url === "/dashboard"
      ? "#ffff"
      : `${COLORS.secondary?.main_bg}`};
  box-shadow: inset 0.3rem 0.3rem 1.7rem 0 rgba(8, 24, 63, 0.08);
  border-radius: ${(props) => (props.isMobile ? "" : "4.2rem 0 0 0")};
  padding: 5rem 5rem 4rem;

  @media screen and (max-width: 1100px) {
    padding: 3rem 3rem 4rem;
  }
  @media screen and (max-width: 760px) {
    padding: 1rem 2rem;
  }
  height: 100%;
  overflow: auto;
`;

export const ArrowDownUp = styled.img`
  left: 90px;
  top: 4px;
  position: absolute;
`;

export const SubLinksWrapper = styled.div`
  max-height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const StyledNavLink = styled(NavLink)`
  padding: 10px 3.8rem;
`;

export const HeaderSelect = styled.select`
  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 16px;
  width: ${(props) => (props.isMobile ? "220px" : "266px")};
  height: ${(props) => (props.isMobile ? "35px" : "40px")};
  padding: 0px 24px 0px 24px;
  appearance: none;
  background-image: url(${ICONS.ChevronDown});
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 6px;
`;
export const SelectOption = styled.option``;

export const StyledWelcomeText = styled.div`
  color: #fff;
  font-weight: 700;
  font-size: 16px;
`;
