import styled from "styled-components";

export const AssignmentsPageWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  height: 100%;
`;
export const HeaderStyledButton = styled.div`
  width: 204px;
  height: 44px;
  background: #0096c6;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  :hover {
    box-shadow: 5px 4px 40px rgba(6, 134, 216, 0.72);
    border: 3px solid #0096c6;
  }
  :active {
    box-shadow: unset;
  }
`;

export const ComponentTitle = styled.div`
  color: #030a1b;
  font-size: 32px;
`;

export const AssignmentCardWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 24px;
  max-width: 360px;
  opacity: ${(props) => (props.opacity ? "100%" : "40%")};
  border: 2px solid #ffffff;
  cursor: pointer;
  :hover {
    border-color: #0686d8;
  }
  :active {
    border-color: #0686d8;
    box-shadow: ${(props) =>
      props.showEdit ? "unset" : "inset 0px 10px 30px rgba(0, 0, 0, 0.15)"};
  }
`;

export const CardHeader = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  padding: 8px 16px 8px 16px;
  justify-content: space-between;
  align-items: center;
`;
export const CardHeaderText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #909491;
`;

export const HeaderStatusWrapper = styled.div`
  display: flex;
  padding: 8px 16px 8px 16px;
`;
export const HeaderStatusText = styled.div`
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #030a1b;
`;
export const StatusToggleIcon = styled.img`
  margin-left: 10px;
  /* cursor: pointer; */
`;

export const StyledUnderline = styled.div`
  width: 312px;
  height: 0px;
  border-bottom: 1px solid #909491;
  margin: 12px 0px;
  opacity: 20%;
`;

export const StyledCardNameWrapper = styled.div`
  position: relative;
  background: #0686d8;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;
export const StyledCardName = styled.div`
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  overflow: hidden;
  margin: 10px 16px 10px 16px;
  height: 56px;
  max-height: 56px;
`;

export const CardRow = styled.div`
  display: flex;
  /* padding: 20px 0px; */
  justify-content: space-between;
  align-items: center;
`;

export const CardRowText = styled.div`
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  color: #030a1b;
`;
export const CardRowContent = styled.div`
  border-radius: 8px;
  color: #0686d8;
  background: rgba(173, 238, 226, 0.1);
  padding: 10px 16px 10px 16px;
`;

export const AddAssignmentIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 350px;
`;
export const StyledAddAssignmentText = styled.p`
  color: #08183f;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  opacity: 0.75;
  margin-top: 10px;
`;

export const AssignmentsPage = styled.div`
  height: 90vh;
  overflow: auto;
`;

export const StyledExpiredText = styled.p`
  font-size: 18px;
  color: #5c605d;
`;
