import axios from "axios";

const acceptInvite = async (vars) => {
  const res = await axios.put(
    `/auth/accept-invite?invitationToken=${vars?.token}`,
    {
      password: vars.password,
    }
  );
  return res;
};

export default acceptInvite;
