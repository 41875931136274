import React from "react";
import { StyledOverflowDiv } from "./components";
import {
  StyledLabel,
  StyledOptionType,
  StyledOptionsBox,
} from "../styled-select-multiply-choice/components";
import { StyledSelectType } from "../styled-select/components";
const MultiUserSelect = ({
  handleClick,
  text,
  usersData,
  showOptions,
  newAssignmentForm,
  handleCheckboxChange,
  notificationType,
}) => {
  return (
    <div style={{ position: "relative" }}>
      <StyledSelectType
        style={{
          height: "35px",
          width: "180px",
          backgroundPositionY: "4px",
        }}
        onClick={handleClick}
        // showOptions={showOptions}
        // color={color}
      >
        <StyledOverflowDiv>{text}</StyledOverflowDiv>
      </StyledSelectType>
      {showOptions && (
        <StyledOptionsBox
          style={{
            transform: "translateY(-100%)",
            top: "0",
            width: "180px",
            maxHeight: "230px",
            overflow: "auto",
          }}
        >
          {[
            { firstName: "All", lastName: "Users", id: "All" },
            ...usersData,
          ].map((user) => (
            <StyledOptionType key={user.id}>
              <StyledLabel htmlFor={user.id}>
                <input
                  style={{ width: "18px" }}
                  type="checkbox"
                  id={user.id}
                  name={user.name}
                  checked={
                    !!newAssignmentForm[notificationType]?.find(
                      (item) => item.id === user.id
                    ) ||
                    (user.id === "All" &&
                      newAssignmentForm[notificationType]?.length ===
                        usersData?.length)
                  }
                  onChange={(e) => {
                    handleCheckboxChange(e, user, notificationType);
                  }}
                />
                <div style={{ color: "#909491" }}>
                  {user.firstName + " " + user.lastName}
                </div>
              </StyledLabel>
            </StyledOptionType>
          ))}
        </StyledOptionsBox>
      )}
    </div>
  );
};

export default MultiUserSelect;
