import React from "react";
import {
  AgendaAssignmentTooltip,
  AgendaTooltipTriangle,
  ToolTipWrapper,
} from "./components";

const AgendaTooltip = ({ widthRef, item }) => {
  return (
    <ToolTipWrapper
      style={{
        left: `calc(0% -  ${widthRef?.current?.clientWidth / 2}px  + 25px)`,
      }}
    >
      <AgendaAssignmentTooltip ref={widthRef}>
        {item.name}
        <AgendaTooltipTriangle />
      </AgendaAssignmentTooltip>
    </ToolTipWrapper>
  );
};

export default AgendaTooltip;
