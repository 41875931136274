import axios from "axios";

const deleteFloor = async (buidlingName, floorName) => {
  const res = await axios.delete(
    `/maps/building/${buidlingName}/${floorName}/delete`
  );
  return res.data;
};

export default deleteFloor;
