import styled from "styled-components";
export const ComponentWrapper = styled.div``;

export const ClientsTable = styled.div`
  width: 100%;
  margin-top: 20px;
`;
export const TableHeader = styled.div`
  width: 100%;
  background: #0096c6;
  border-radius: 8px;
  display: flex;
  align-items: center;
`;
export const TableHeaderItems = styled.div`
  width: 100%;
  padding: 12px 0px;
  color: #fff;
  &:first-child {
    text-align: center;
  }
`;
export const TableRow = styled.div`
  margin-top: 10px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #fff;

  :hover {
    border-color: #0096c6;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  }
`;

export const TableRowItem = styled.div`
  width: 100%;
  border: unset;
  height: 100%;
  border-radius: 8px;
  background-color: #fff;
  padding: 12px 8px 12px 8px;
  font-weight: 400;
`;
export const VertMenuWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

export const StyledEditBox = styled.div`
  position: absolute;
  right: 10px;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  z-index: 11;
`;
export const EditItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 20px;
  padding: 15px 14px 10px 0px;
  cursor: pointer;
`;

export const StyledEditIcon = styled.img`
  margin-right: 10px;
`;

export const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
`;

export const ParametersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 200%;
`;
export const MultiplyWrapper = styled.div`
  display: flex;
`;

export const StyledLabel = styled.label`
  width: 20%;
  display: flex;
  justify-content: center;
`;

export const StyledSelect = styled.select`
  width: 42.4rem !important;
  height: 6.4rem;
  border-radius: 1.6rem;
  border: 2px solid rgb(203, 213, 207);
  background: rgb(255, 255, 255);
  padding: 0px 2.4rem;
  margin: 0px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

export const DateAndTimeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
  width: 100%;
  gap: 20px;
`;
export const DateBox = styled.div`
  width: 100%;
  height: 64px;
  color: #030a1b;
  font-weight: 400;
  font-size: 16px;
  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: ${(props) => props.showCalendar && "2px solid #0686D8"};
`;
