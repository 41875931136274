import React, { useContext } from "react";
import HeaderComponent from "../../../components/user-management/HeaderComponent";
import { AdminTableContext } from "../../../contexts/AdminTableContext";
import ManagementTableComponent from "../../../components/user-management/ManagementTableComponent";

const UserAdministrationTable = ({ chosenClient, setChosenClient }) => {
  const { clients } = useContext(AdminTableContext);
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          marginTop: "50px",
          width: "100%",
          overflowX: "auto",
        }}
      >
        {clients.map((item) => (
          <HeaderComponent
            key={item.id}
            name={item.name}
            // quantity={clients.filter((client) => client.name === item).length}
            isChosen={chosenClient?.id === item.id}
            onClick={() => setChosenClient(item)}
          />
        ))}
      </div>

      <ManagementTableComponent
        chosenClient={chosenClient}
        userAdministrationPage
      />
    </React.Fragment>
  );
};

export default UserAdministrationTable;
