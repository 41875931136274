import React from "react";
import {
  DateBox,
  WeekDay,
  WeekDaysWrapper,
} from "../../../components/add-assignment-modal/components";
import { StyledSingleLineContainer } from "../../../components/commonStyles";
import TimePicker from "react-time-picker/dist/TimePicker";
import ICONS from "../../../constants/icons";
import { v4 as uuidv4 } from "uuid";

const PredefinedTimes = ({ batterySettingForm, setBatterySettingForm }) => {
  let date = new Date();
  const currentTime =
    date.getHours() +
    ":" +
    (date.getMinutes() < 10 ? "0" : "") +
    date.getMinutes();
  const handleChooseWeekday = (id, dayIndex) => {
    const copiedArray = { ...batterySettingForm };
    copiedArray.predefined_times = copiedArray.predefined_times.map((el) =>
      el.id === id
        ? {
            ...el,
            week_days: el.week_days.map((item) => {
              if (dayIndex === item.index) {
                let obj = { ...item };
                obj.chosen = !obj.chosen;
                return obj;
              } else return item;
            }),
          }
        : el
    );

    setBatterySettingForm(copiedArray);
  };
  const handleAddWeekDays = () => {
    const newDays = {
      id: uuidv4(),
      week_days: [
        {
          index: 0,
          day: "Sun",
          chosen: false,
        },
        {
          index: 1,
          day: "Mon",
          chosen: false,
        },
        {
          index: 2,
          day: "Tue",
          chosen: false,
        },
        {
          index: 3,
          day: "Wed",
          chosen: false,
        },
        {
          index: 4,
          day: "Thu",
          chosen: false,
        },
        {
          index: 5,
          day: "Fri",
          chosen: false,
        },
        {
          index: 6,
          day: "Sat",
          chosen: false,
        },
      ],
      time: {
        from: currentTime,
        to: currentTime,
      },
    };
    setBatterySettingForm((prev) => ({
      ...prev,
      predefined_times: [...prev.predefined_times, newDays],
    }));
  };

  const handleTimeChange = (id, timeValue, type) => {
    const copiedArray = { ...batterySettingForm };
    copiedArray.predefined_times = copiedArray.predefined_times.map((el) =>
      el.id === id
        ? {
            ...el,
            time: { ...el.time, [type]: timeValue },
          }
        : el
    );
    setBatterySettingForm(copiedArray);
  };

  return (
    <>
      <StyledSingleLineContainer
        style={{
          marginTop: "25px",
          gap: "20px",
          overflow: "auto",
          maxHeight: "200px",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {batterySettingForm.predefined_times.map((el) => (
          <div style={{ display: "flex", width: "100%" }}>
            <WeekDaysWrapper
              style={{
                justifyContent: "flex-start",
                gap: "15px",
                marginTop: "unset",
              }}
            >
              {el.week_days.map((item) => (
                <WeekDay
                  style={{ width: "30px", height: "30px", fontSize: "20px" }}
                  key={item.index}
                  active={item.chosen}
                  onClick={() => handleChooseWeekday(el.id, item.index)}
                >
                  {item.day.split("")[0]}
                </WeekDay>
              ))}
            </WeekDaysWrapper>
            <StyledSingleLineContainer>
              <StyledSingleLineContainer
                style={{ gap: "10px", justifyContent: "flex-end" }}
              >
                From:
                <DateBox style={{ width: "120px", height: "40px" }}>
                  <TimePicker
                    format={"hh:mm a"}
                    disableClock={true}
                    clearIcon={false}
                    onChange={(timeValue) => {
                      handleTimeChange(el.id, timeValue, "from");
                    }}
                    value={el.time.from}
                  />
                </DateBox>
              </StyledSingleLineContainer>
              <StyledSingleLineContainer
                style={{ gap: "10px", justifyContent: "flex-end" }}
              >
                To:
                <DateBox style={{ width: "120px", height: "40px" }}>
                  <TimePicker
                    format={"hh:mm a"}
                    disableClock={true}
                    clearIcon={false}
                    onChange={(timeValue) => {
                      handleTimeChange(el.id, timeValue, "to");
                    }}
                    value={el.time.to}
                  />
                </DateBox>
              </StyledSingleLineContainer>
            </StyledSingleLineContainer>
          </div>
        ))}
      </StyledSingleLineContainer>
      <img
        src={ICONS.AddIcon}
        alt=""
        style={{ width: "25px", marginTop: "20px", cursor: "pointer" }}
        onClick={handleAddWeekDays}
      />
    </>
  );
};

export default PredefinedTimes;
