import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSignError } from "../../redux/clients";
import COLORS from "../../constants/colors";
import { SubTitle, Title, VerifyEmailImg } from "./components";
import OutlineNonAuthorised from "../../components/outline-non-authorised/OutlineNonAuthorised";
import CustomButton from "../../components/button/CustomButton";
import STRINGS from "../../constants/strings";
import resendVerification from "../../APIs/auth/resendVerification";

const ResendVerification = () => {
  const [responseMessage, setResponseMessage] = useState("");
  const emailToVerify = localStorage.getItem("emailToVerify");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSignError(null));
  }, [dispatch]);
  const handleResendVerification = async () => {
    try {
      const data = await resendVerification(emailToVerify);
      setResponseMessage(data.data);
    } catch (error) {
      setResponseMessage(error.response.data.message);
    }
  };
  return (
    <OutlineNonAuthorised doubleBackground>
      <VerifyEmailImg />
      {responseMessage ? (
        <Title style={{ margin: "1.6rem 0" }}>{responseMessage}</Title>
      ) : (
        <>
          <Title style={{ margin: "1.6rem 0" }}>
            {STRINGS.user_is_not_verified}
          </Title>

          <>
            <SubTitle style={{ width: "32.2rem", marginBottom: "6rem" }}>
              {emailToVerify}
            </SubTitle>
            <CustomButton
              backgroundColor={COLORS.secondary.ncs}
              onClick={handleResendVerification}
            >
              Resend verification
            </CustomButton>
          </>
        </>
      )}
    </OutlineNonAuthorised>
  );
};

export default ResendVerification;
