import React from "react";
import { StyledCalendarRow } from "./components";
import { TimeAmount, TimeDiv } from "../dashboard/components";
import { useEffect } from "react";
import { useState } from "react";
import ICONS from "../../constants/icons";

const CalendarRow = ({ time, children }) => {
  const [showCurrentTime, setShowCurrentTime] = useState({});
  useEffect(() => {
    let date = new Date();
    const currentTime =
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes();
    +currentTime.split(":")[0] === +time.split(":")[0] - 1 &&
      setShowCurrentTime({
        show: true,
        top: (date.getMinutes() * 100) / 60,
      });
  }, [time]);
  return (
    <StyledCalendarRow className="clickRevert">
      <TimeDiv style={{ minHeight: "70px" }}>
        {showCurrentTime.show && (
          <img
            src={ICONS.CurrentTimeLine}
            alt="currentTime"
            style={{ position: "absolute", top: `${showCurrentTime.top}%` }}
          />
        )}
        <TimeAmount style={{ width: "70px" }}>
          {time === "24:00" ? "00:00" : time}
        </TimeAmount>
      </TimeDiv>
      {children}
    </StyledCalendarRow>
  );
};

export default CalendarRow;
