import { ServerConnection } from "./protocol";

/** @type {string} */
let group;

/** @type {ServerConnection} */
let serverConnection;

/** @type {Object} */
let groupStatus = {};

/** @type {Function} */
let videoReady = () => {};

/** @type {string} */
let token = null;

/** @type {boolean} */
let connectingAgain = false;

/**
 * @typedef {Object} settings
 * @property {boolean} [localMute]
 * @property {string} [video]
 * @property {string} [audio]
 * @property {string} [simulcast]
 * @property {string} [send]
 * @property {string} [request]
 * @property {boolean} [activityDetection]
 * @property {boolean} [displayAll]
 * @property {Array.<number>} [resolution]
 * @property {boolean} [mirrorView]
 * @property {boolean} [blackboardMode]
 * @property {string} [filter]
 * @property {boolean} [preprocessing]
 * @property {boolean} [hqaudio]
 * @property {boolean} [forceRelay]
 */

/** @type{settings} */
let fallbackSettings = null;

/**
 * @param {settings} settings
 */
function storeSettings(settings) {
  try {
    window.sessionStorage.setItem("settings", JSON.stringify(settings));
    fallbackSettings = null;
  } catch (e) {
    console.warn("Couldn't store settings:", e);
    fallbackSettings = settings;
  }
}

/**
 * This always returns a dictionary.
 *
 * @returns {settings}
 */
function getSettings() {
  /** @type {settings} */
  let settings;
  try {
    let json = window.sessionStorage.getItem("settings");
    settings = JSON.parse(json);
  } catch (e) {
    console.warn("Couldn't retrieve settings:", e);
    settings = fallbackSettings;
  }
  return settings || {};
}

/**
 * @param {settings} settings
 */
function updateSettings(settings) {
  let s = getSettings();
  for (let key in settings) s[key] = settings[key];
  storeSettings(s);
}

/**
 * @param {string} key
 * @param {any} value
 */
function updateSetting(key, value) {
  let s = {};
  s[key] = value;
  updateSettings(s);
}

/**
 * @param {string} key
 */
function delSetting(key) {
  let s = getSettings();
  if (!(key in s)) return;
  delete s[key];
  storeSettings(s);
}

function isMobileLayout() {
  if (window.matchMedia("only screen and (max-width: 1024px)").matches)
    return true;
  return false;
}

/**
 * @param {boolean} [force]
 */
function hideVideo(force) {
  let mediadiv = document.getElementById("peers");
  if (mediadiv?.childElementCount > 0 && !force) return;
  setVisibility("video-container", false);
  scheduleReconsiderDownRate();
}

function showVideo() {
  let hasmedia = document.getElementById("peers")?.childElementCount > 0;
  if (isMobileLayout()) {
    setVisibility("show-video", false);
    setVisibility("collapse-video", hasmedia);
  }
  setVisibility("video-container", hasmedia);
  scheduleReconsiderDownRate();
}

/**
 * @param{boolean} connected
 */
function setConnected(connected) {
  if (connected) {
    window.onresize = function (e) {
      scheduleReconsiderDownRate();
    };
  } else {
    if (!connectingAgain) displayError("Disconnected", "error");
    hideVideo();
    window.onresize = null;
  }
}

/**
 * @this {ServerConnection}
 */
async function gotConnected() {
  setConnected(true);
  let again = connectingAgain;
  connectingAgain = false;
  await join(again);
}

/**
 * @param {boolean} again
 */
async function join(again) {
  let username = "fleet";
  let credentials;
  if (token) {
    credentials = {
      type: "token",
      token: token,
    };
    if (!again)
      // the first time around, we need to join with no username in
      // order to give the server a chance to reply with 'need-username'.
      username = null;
  } else {
    let pw = "password";
    if (!groupStatus.authServer) credentials = pw;
    else
      credentials = {
        type: "authServer",
        authServer: groupStatus.authServer,
        location: window.location.href,
        password: pw,
      };
  }

  try {
    await serverConnection.join(group, username, credentials);
  } catch (e) {
    console.error(e);
    displayError(e);
    serverConnection.close();
  }
}

/**
 * @this {ServerConnection}
 */
function onPeerConnection() {
  if (!getSettings().forceRelay) return null;
  let old = this.rtcConfiguration;
  /** @type {RTCConfiguration} */
  let conf = {};
  for (let key in old) conf[key] = old[key];
  conf.iceTransportPolicy = "relay";
  return conf;
}

/**
 * @this {ServerConnection}
 * @param {number} code
 * @param {string} reason
 */
function gotClose(code, reason) {
  closeUpMedia();
  setConnected(false);
  if (code !== 1000) {
    console.warn("Socket close", code, reason);
  }
}

/**
 * @this {ServerConnection}
 * @param {Stream} c
 */
function gotDownStream(c) {
  c.onclose = function (replace) {
    if (!replace) delMedia(c.localId + c.username);
  };
  c.onerror = function (e) {
    console.error(e);
    displayError(e);
  };
  c.ondowntrack = function (track, transceiver, label, stream) {
    setMedia(c);
  };
  c.onstatus = function (status) {
    setMediaStatus(c);
  };
  if (getSettings().activityDetection)
    c.setStatsInterval(activityDetectionInterval);

  setMedia(c);
}

/**
 * @param {string} id
 * @param {boolean} visible
 */
function setVisibility(id, visible) {
  try {
    let elt = document.getElementById(id);
    if (visible) elt?.classList?.remove("invisible");
    else elt.classList.add("invisible");
  } catch (error) {}
}

/** @returns {number} */
function getMaxVideoThroughput() {
  let v = getSettings().send;
  switch (v) {
    case "lowest":
      return 150000;
    case "low":
      return 300000;
    case "normal":
      return 700000;
    case "unlimited":
      return null;
    default:
      console.error("Unknown video quality", v);
      return 700000;
  }
}

/**
 * @param {string} what
 * @returns {Object<string,Array<string>>}
 */

function mapRequest(what) {
  what = "everything";
  switch (what) {
    case "":
      return {};
    case "audio":
      return { "": ["audio"] };
    case "screenshare":
      return { screenshare: ["audio", "video"], "": ["audio"] };
    case "everything-low":
      return { "": ["audio", "video-low"] };
    case "everything":
      return { "": ["audio", "video"] };
    default:
      throw new Error(`Unknown value ${what} in request`);
  }
}

/**
 * @param {string} what
 * @param {string} label
 * @returns {Array<string>}
 */

function mapRequestLabel(what, label) {
  let r = mapRequest(what);
  if (label in r) return r[label];
  else return r[""];
}

const activityDetectionInterval = 200;

/**
 * @this {Stream}
 * @param {Object<string,any>} stats
 */
function gotUpStats(stats) {
  let values = [];

  for (let id in stats) {
    if (stats[id] && stats[id]["outbound-rtp"]) {
      let rate = stats[id]["outbound-rtp"].rate;
      if (typeof rate === "number") {
        values.push(rate);
      }
    }
  }

  if (values.length === 0) {
  } else {
    values.sort((x, y) => x - y);
  }
}

/**
 * @param {string} [localId]
 */
function newUpStream(localId) {
  let c = serverConnection.newUpStream(localId);
  c.onstatus = function (status) {
    setMediaStatus(c);
  };
  c.onerror = function (e) {
    console.error(e);
    displayError(e);
  };
  return c;
}

/**
 * Sets an up stream's video throughput and simulcast parameters.
 *
 * @param {Stream} c
 * @param {number} bps
 * @param {boolean} simulcast
 */
async function setSendParameters(c, bps, simulcast) {
  if (!c.up) throw new Error("Setting throughput of down stream");
  let senders = c.pc.getSenders();
  for (let i = 0; i < senders.length; i++) {
    let s = senders[i];
    if (!s.track || s.track.kind !== "video") continue;
    let p = s.getParameters();
    if (
      !p.encodings ||
      (!simulcast && p.encodings.length !== 1) ||
      (simulcast && p.encodings.length !== 2)
    ) {
      await replaceUpStream(c);
      return;
    }
    p.encodings.forEach((e) => {
      if (!e.rid || e.rid === "h") e.maxBitrate = bps || unlimitedRate;
    });
    await s.setParameters(p);
  }
}

let reconsiderParametersTimer = null;

/**
 * Sets the send parameters for all up streams.
 */
async function reconsiderSendParameters() {
  cancelReconsiderParameters();
  let t = getMaxVideoThroughput();
  let s = doSimulcast();
  let promises = [];
  for (let id in serverConnection.up) {
    let c = serverConnection.up[id];
    promises.push(setSendParameters(c, t, s));
  }
  await Promise.all(promises);
}

/**
 * Schedules a call to reconsiderSendParameters after a delay.
 * The delay avoids excessive flapping.
 */
function scheduleReconsiderParameters() {
  cancelReconsiderParameters();
  reconsiderParametersTimer = setTimeout(
    reconsiderSendParameters,
    10000 + Math.random() * 10000
  );
}

function cancelReconsiderParameters() {
  if (reconsiderParametersTimer) {
    clearTimeout(reconsiderParametersTimer);
    reconsiderParametersTimer = null;
  }
}

/**
 * @typedef {Object} filterDefinition
 * @property {string} [description]
 * @property {string} [contextType]
 * @property {Object} [contextAttributes]
 * @property {(this: Filter, ctx: RenderingContext) => void} [init]
 * @property {(this: Filter) => void} [cleanup]
 * @property {(this: Filter, src: CanvasImageSource, width: number, height: number, ctx: RenderingContext) => boolean} f
 */

/**
 * @param {MediaStream} stream
 * @param {filterDefinition} definition
 * @constructor
 */
function Filter(stream, definition) {
  /** @ts-ignore */
  if (!HTMLCanvasElement.prototype.captureStream) {
    throw new Error("Filters are not supported on this platform");
  }

  /** @type {MediaStream} */
  this.inputStream = stream;
  /** @type {filterDefinition} */
  this.definition = definition;
  /** @type {number} */
  this.frameRate = 30;
  /** @type {HTMLVideoElement} */
  this.video = document.createElement("video");
  /** @type {HTMLCanvasElement} */
  this.canvas = document.createElement("canvas");
  /** @type {any} */
  this.context = this.canvas.getContext(
    definition.contextType || "2d",
    definition.contextAttributes || null
  );
  /** @type {MediaStream} */
  this.captureStream = null;
  /** @type {MediaStream} */
  this.outputStream = null;
  /** @type {number} */
  this.timer = null;
  /** @type {number} */
  this.count = 0;
  /** @type {boolean} */
  this.fixedFramerate = false;
  /** @type {Object} */
  this.userdata = {};
  /** @type {MediaStream} */
  this.captureStream = this.canvas.captureStream(0);

  /** @ts-ignore */
  if (!this.captureStream.getTracks()[0].requestFrame) {
    console.warn("captureFrame not supported, using fixed framerate");
    /** @ts-ignore */
    this.captureStream = this.canvas.captureStream(this.frameRate);
    this.fixedFramerate = true;
  }

  this.outputStream = new MediaStream();
  this.outputStream.addTrack(this.captureStream.getTracks()[0]);
  this.inputStream.getTracks().forEach((t) => {
    t.onended = (e) => this.stop();
    if (t.kind !== "video") this.outputStream.addTrack(t);
  });
  this.video.srcObject = stream;
  this.video.addEventListener("play", videoReady);
  this.video.muted = true;
  this.video.play();
  if (this.definition.init) this.definition.init.call(this, this.context);
  this.timer = setInterval(() => this.draw(), 1000 / this.frameRate);
}

Filter.prototype.draw = function () {
  // check framerate every 30 frames
  if (this.count % 30 === 0) {
    let frameRate = 0;
    this.inputStream.getTracks().forEach((t) => {
      if (t.kind === "video") {
        let r = t.getSettings().frameRate;
        if (r) frameRate = r;
      }
    });
    if (frameRate && frameRate !== this.frameRate) {
      clearInterval(this.timer);
      this.timer = setInterval(() => this.draw(), 1000 / this.frameRate);
    }
  }

  let ok = false;
  try {
    ok = this.definition.f.call(
      this,
      this.video,
      this.video.videoWidth,
      this.video.videoHeight,
      this.context
    );
  } catch (e) {
    console.error(e);
  }
  if (ok && !this.fixedFramerate) {
    /** @ts-ignore */
    this.captureStream.getTracks()[0].requestFrame();
  }

  this.count++;
};

Filter.prototype.stop = function () {
  if (!this.timer) return;
  this.captureStream.getTracks()[0].stop();
  clearInterval(this.timer);
  this.timer = null;
  if (this.definition.cleanup) this.definition.cleanup.call(this);
};

/**
 * Removes any filter set on c.
 *
 * @param {Stream} c
 */
function removeFilter(c) {
  let old = c.userdata.filter;
  if (!old) return;

  if (!(old instanceof Filter))
    throw new Error("userdata.filter is not a filter");

  c.setStream(old.inputStream);
  old.stop();
  c.userdata.filter = null;
}

/**
 * Sets the filter described by c.userdata.filterDefinition on c.
 *
 * @param {Stream} c
 */
function setFilter(c) {
  removeFilter(c);

  if (!c.userdata.filterDefinition) return;

  let filter = new Filter(c.stream, c.userdata.filterDefinition);
  c.setStream(filter.outputStream);
  c.userdata.filter = filter;
}

function isSafari() {
  let ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") >= 0 && ua.indexOf("chrome") < 0;
}

const unlimitedRate = 1000000000;
const simulcastRate = 100000;
const hqAudioRate = 128000;

/**
 * Decide whether we want to send simulcast.
 *
 * @returns {boolean}
 */
function doSimulcast() {
  switch (getSettings().simulcast) {
    case "on":
      return true;
    case "off":
      return false;
    default:
      let count = 0;
      for (let n in serverConnection.users) {
        if (!serverConnection.users[n].permissions["system"]) {
          count++;
          if (count > 2) break;
        }
      }
      if (count <= 2) return false;
      let bps = getMaxVideoThroughput();
      return bps <= 0 || bps >= 2 * simulcastRate;
  }
}

/**
 * Sets up c to send the given stream.  Some extra parameters are stored
 * in c.userdata.
 *
 * @param {Stream} c
 * @param {MediaStream} stream
 */

function setUpStream(c, stream) {
  if (c.stream !== null) throw new Error("Setting nonempty stream");

  c.setStream(stream);

  try {
    setFilter(c);
  } catch (e) {
    displayWarning("Couldn't set filter: " + e);
  }

  c.onclose = (replace) => {
    removeFilter(c);
    if (!replace) {
      stopStream(c.stream);
      if (c.userdata.onclose) c.userdata.onclose.call(c);
      delMedia(c.localId);
    }
  };

  /**
   * @param {MediaStreamTrack} t
   */
  function addUpTrack(t) {
    let settings = getSettings();
    if (c.label === "camera") {
      if (t.kind === "audio") {
        if (settings.localMute) t.enabled = false;
      } else if (t.kind === "video") {
        if (settings.blackboardMode) {
          t.contentHint = "detail";
        }
      }
    }
    t.onended = (e) => {
      stream.onaddtrack = null;
      stream.onremovetrack = null;
      c.close();
    };

    let encodings = [];
    let simulcast = doSimulcast();
    if (t.kind === "video") {
      let bps = getMaxVideoThroughput();
      // Firefox doesn't like us setting the RID if we're not
      // simulcasting.
      if (simulcast && c.label !== "screenshare") {
        encodings.push({
          rid: "h",
          maxBitrate: bps || unlimitedRate,
        });
        encodings.push({
          rid: "l",
          scaleResolutionDownBy: 2,
          maxBitrate: simulcastRate,
        });
      } else {
        encodings.push({
          maxBitrate: bps || unlimitedRate,
        });
      }
    } else {
      if (settings.hqaudio) {
        encodings.push({
          maxBitrate: hqAudioRate,
        });
      }
    }
    let tr = c.pc.addTransceiver(t, {
      direction: "sendonly",
      streams: [stream],
      sendEncodings: encodings,
    });

    // Firefox before 110 does not implement sendEncodings, and
    // requires this hack, which throws an exception on Chromium.
    try {
      let p = tr.sender.getParameters();
      if (!p.encodings) {
        p.encodings = encodings;
        tr.sender.setParameters(p);
      }
    } catch (e) {}
  }

  // c.stream might be different from stream if there's a filter
  c.stream.getTracks().forEach(addUpTrack);

  stream.onaddtrack = function (e) {
    addUpTrack(e.track);
  };

  stream.onremovetrack = function (e) {
    let t = e.track;

    /** @type {RTCRtpSender} */
    let sender;
    c.pc.getSenders().forEach((s) => {
      if (s.track === t) sender = s;
    });
    if (sender) {
      c.pc.removeTrack(sender);
    } else {
      console.warn("Removing unknown track");
    }

    let found = false;
    c.pc.getSenders().forEach((s) => {
      if (s.track) found = true;
    });
    if (!found) {
      stream.onaddtrack = null;
      stream.onremovetrack = null;
      c.close();
    }
  };

  c.onstats = gotUpStats;
  c.setStatsInterval(2000);
}

/**
 * Replaces c with a freshly created stream, duplicating any relevant
 * parameters in c.userdata.
 *
 * @param {Stream} c
 * @returns {Promise<Stream>}
 */
async function replaceUpStream(c) {
  removeFilter(c);
  let cn = newUpStream(c.localId);
  cn.label = c.label;
  if (c.userdata.filterDefinition)
    cn.userdata.filterDefinition = c.userdata.filterDefinition;
  if (c.userdata.onclose) cn.userdata.onclose = c.userdata.onclose;
  let media =
    /** @type{HTMLVideoElement} */
    (document.getElementById("media-" + c.localId));
  setUpStream(cn, c.stream);
  await setMedia(
    cn,
    cn.label === "camera" && getSettings().mirrorView,
    cn.label === "video" && media
  );
  return cn;
}

/**
 * Replaces all up streams with the given label.  If label is null,
 * replaces all up stream.
 *
 * @param {string} label
 */
async function replaceUpStreams(label) {
  let promises = [];
  for (let id in serverConnection.up) {
    let c = serverConnection.up[id];
    if (label && c.label !== label) continue;
    promises.push(replaceUpStream(c));
  }
  await Promise.all(promises);
}

/**
 * @param {MediaStream} s
 */
function stopStream(s) {
  s.getTracks().forEach((t) => {
    try {
      t.stop();
    } catch (e) {
      console.warn(e);
    }
  });
}

/**
 * closeUpMedia closes all up connections with the given label.  If label
 * is null, it closes all up connections.
 *
 * @param {string} [label]
 */
function closeUpMedia(label) {
  for (let id in serverConnection.up) {
    let c = serverConnection.up[id];
    if (label && c.label !== label) continue;
    c.close();
  }
}

/**
 * @param {string} label
 * @returns {Stream}
 */
function findUpMedia(label) {
  for (let id in serverConnection.up) {
    let c = serverConnection.up[id];
    if (c.label === label) return c;
  }
  return null;
}

/**
 * @param {string} id
 * @param {boolean} force
 * @param {boolean} [value]
 */
function forceDownRate(id, force, value) {
  let c = serverConnection.down[id];
  if (!c) throw new Error("Unknown down stream");
  if ("requested" in c.userdata) {
    if (force) c.userdata.requested.force = !!value;
    else delete c.userdata.requested.force;
  } else {
    if (force) c.userdata.requested = { force: value };
  }
  reconsiderDownRate(id);
}

/**
 * Maps 'video' to 'video-low'.  Returns null if nothing changed.
 *
 * @param {string[]} requested
 * @returns {string[]}
 */
function mapVideoToLow(requested) {
  let result = [];
  let found = false;
  for (let i = 0; i < requested.length; i++) {
    let r = requested[i];
    if (r === "video") {
      r = "video-low";
      found = true;
    }
    result.push(r);
  }
  if (!found) return null;
  return result;
}

/**
 * Reconsider the video track requested for a given down stream.
 *
 * @param {string} [id] - the id of the track to reconsider, all if null.
 */
function reconsiderDownRate(id) {
  if (!serverConnection) return;
  if (!id) {
    for (let id in serverConnection.down) {
      reconsiderDownRate(id);
    }
    return;
  }
  let c = serverConnection.down[id];
  if (!c) throw new Error("Unknown down stream");
  let normalrequest = mapRequestLabel(getSettings().request, c.label);

  let requestlow = mapVideoToLow(normalrequest);
  if (requestlow === null) return;

  let old = c.userdata.requested;
  let low = false;
  if (old && "force" in old) {
    low = old.force;
  } else {
    let media =
      /** @type {HTMLVideoElement} */
      (document.getElementById("media-" + c.localId));
    if (media) {
      let w = media.scrollWidth;
      let h = media.scrollHeight;
      if (w && h && w * h <= 320 * 240) {
        low = true;
      }
    } else console.log("No media for stream");
  }

  if (low !== !!(old && old.low)) {
    if ("requested" in c.userdata) c.userdata.requested.low = low;
    else c.userdata.requested = { low: low };
    c.request(low ? requestlow : null);
  }
}

let reconsiderDownRateTimer = null;

/**
 * Schedules reconsiderDownRate() to be run later.  The delay avoids too
 * much recomputations when resizing the window.
 */
function scheduleReconsiderDownRate() {
  if (reconsiderDownRateTimer) return;
  reconsiderDownRateTimer = setTimeout(() => {
    reconsiderDownRateTimer = null;
    reconsiderDownRate();
  }, 200);
}

/**
 * setMedia adds a new media element corresponding to stream c.
 *
 * @param {Stream} c
 * @param {boolean} [mirror]
 *     - whether to mirror the video
 * @param {HTMLVideoElement} [video]
 *     - the video element to add.  If null, a new element with custom
 *       controls will be created.
 */
async function setMedia(c, mirror, video) {
  const hideVideo = localStorage.getItem("chosenCamera") !== c.username;
  if (hideVideo) return;
  let div = document.getElementById("peer-" + c.localId + c.username);
  if (!div) {
    div = document.createElement("div");
    div.id = "peer-" + c.localId + c.username;

    div.classList.add("peer");
    let peersdiv = document.getElementById("peers");
    peersdiv.appendChild(div);
  }

  showHideMedia(c, div);

  let media =
    /** @type {HTMLVideoElement} */
    (document.getElementById("media-" + c.localId));
  if (!media) {
    if (video) {
      media = video;
    } else {
      media = document.createElement("video");
      if (c.up) media.muted = true;
    }

    media.classList.add("media");
    media.classList.add("popupMedia");
    media.autoplay = true;
    media.playsInline = true;
    media.id = "media-" + c.localId;
    div.appendChild(media);
  }

  if (mirror) media.classList.add("mirror");
  else media.classList.remove("mirror");

  if (!video && media.srcObject !== c.stream) media.srcObject = c.stream;
  media.addEventListener("play", videoReady);

  if (!c.up) {
    media.onfullscreenchange = function (e) {
      forceDownRate(c.id, document.fullscreenElement === media, false);
    };
  }

  setMediaStatus(c);

  showVideo();

  if (!c.up && isSafari() && !findUpMedia("camera")) {
    // Safari doesn't allow autoplay unless the user has granted media access
    try {
      let stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((t) => t.stop());
    } catch (e) {}
  }
}

export const hideCamera = (cameraName) => {
  const div = document.querySelector(`[id$="${cameraName}"]`);
  if (div) div.remove();
};

/**
 * @param {Stream} c
 * @param {HTMLElement} elt
 */
function showHideMedia(c, elt) {
  let display = c.up || getSettings().displayAll;
  if (!display && c.stream) {
    let tracks = c.stream.getTracks();
    for (let i = 0; i < tracks.length; i++) {
      let t = tracks[i];
      if (t.kind === "video") {
        display = true;
        break;
      }
    }
  }
  if (display) elt.classList.remove("peer-hidden");
  else elt.classList.add("peer-hidden");
}

/**
 * @param {string} localId
 */
function delMedia(localId) {
  let mediadiv = document.getElementById("peers");
  let peer = document.getElementById("peer-" + localId);
  if (!peer) return;

  let media =
    /** @type{HTMLVideoElement} */
    (document.getElementById("media-" + localId));

  if (!media) {
    peer.remove();
    return;
  }
  try {
    media.srcObject = null;
    mediadiv.removeChild(peer);
  } catch (error) {
    console.log(error);
  }

  hideVideo();
}

/**
 * @param {Stream} c
 */
function setMediaStatus(c) {
  let state = c && c.pc && c.pc.iceConnectionState;
  let good = state === "connected" || state === "completed";

  let media = document.getElementById("media-" + c.localId);
  if (!media) {
    console.warn("Setting status of unknown media.");
    return;
  }
  if (good) {
    media.classList.remove("media-failed");
    if (c.userdata.play) {
      if (media instanceof HTMLMediaElement)
        media.play().catch((e) => {
          console.error(e);
          displayError(e);
        });
      delete c.userdata.play;
    }
  } else {
    media.classList.add("media-failed");
  }
}

/**
 * @param {string} id
 * @param {string} kind
 */
function gotUser(id, kind) {
  switch (kind) {
    case "add":
      if (Object.keys(serverConnection.users).length === 3)
        reconsiderSendParameters();
      break;
    case "delete":
      if (Object.keys(serverConnection.users).length < 3)
        scheduleReconsiderParameters();
      break;
    default:
      console.warn("Unknown user kind", kind);
      break;
  }
}

/**
 * @this {ServerConnection}
 * @param {string} group
 * @param {Array<string>} perms
 * @param {Object<string,any>} status
 * @param {Object<string,any>} data
 * @param {string} error
 * @param {string} message
 */
async function gotJoined(kind, group, perms, status, data, error, message) {
  switch (kind) {
    case "fail":
      if (error === "need-username" || error === "duplicate-username") {
        setVisibility("passwordform", false);
        connectingAgain = true;
      } else {
        token = null;
      }
      if (error !== "need-username")
        displayError("The server said: " + message);
      this.close();

      return;
    case "redirect":
      this.close();
      token = null;
      document.location.href = message;
      return;
    case "leave":
      this.close();
      token = null;

      return;
    case "join":
    case "change":
      token = null;
      // don't discard endPoint and friends
      for (let key in status) groupStatus[key] = status[key];

      if (kind === "change") return;
      break;
    default:
      token = null;
      displayError("Unknown join message");
      this.close();
      return;
  }

  token = null;

  if (status.locked) displayWarning("This group is locked");

  if (typeof RTCPeerConnection === "undefined")
    displayWarning("This browser doesn't support WebRTC");
  else this.request(mapRequest(getSettings().request));
}

/**
 * A command known to the command-line parser.
 *
 * @typedef {Object} command
 * @property {string} [parameters]
 *     - A user-readable list of parameters.
 * @property {string} [description]
 *     - A user-readable description, null if undocumented.
 * @property {() => string} [predicate]
 *     - Returns null if the command is available.
 * @property {(c: string, r: string) => void} f
 */

/**
 * The set of commands known to the command-line parser.
 *
 * @type {Object.<string,command>}
 */
let commands = {};

function operatorPredicate() {
  if (
    serverConnection &&
    serverConnection.permissions &&
    serverConnection.permissions.indexOf("op") >= 0
  )
    return null;
  return "You are not an operator";
}

commands.me = {
  f: (c, r) => {
    // handled as a special case
    throw new Error("this shouldn't happen");
  },
};

commands.set = {
  f: (c, r) => {
    if (!r) {
      let settings = getSettings();
      let s = "";
      for (let key in settings)
        s = s + `${key}: ${JSON.stringify(settings[key])}\n`;
      return;
    }
    let p = parseCommand(r);
    let value;
    if (p[1]) {
      value = JSON.parse(p[1]);
    } else {
      value = true;
    }
    updateSetting(p[0], value);
  },
};

commands.unset = {
  f: (c, r) => {
    delSetting(r.trim());
    return;
  },
};

commands.leave = {
  description: "leave group",
  f: (c, r) => {
    if (!serverConnection) throw new Error("Not connected");
    serverConnection.close();
  },
};

commands.lock = {
  predicate: operatorPredicate,
  description: "lock this group",
  parameters: "[message]",
  f: (c, r) => {
    serverConnection.groupAction("lock", r);
  },
};

commands.unlock = {
  predicate: operatorPredicate,
  description: "unlock this group, revert the effect of /lock",
  f: (c, r) => {
    serverConnection.groupAction("unlock");
  },
};

commands.subgroups = {
  predicate: operatorPredicate,
  description: "list subgroups",
  f: (c, r) => {
    serverConnection.groupAction("subgroups");
  },
};

function renegotiateStreams() {
  for (let id in serverConnection.up) serverConnection.up[id].restartIce();
  for (let id in serverConnection.down) serverConnection.down[id].restartIce();
}

commands.renegotiate = {
  description: "renegotiate media streams",
  f: (c, r) => {
    renegotiateStreams();
  },
};

commands.replace = {
  f: (c, r) => {
    replaceUpStreams(null);
  },
};

/**
 * parseCommand splits a string into two space-separated parts.  The first
 * part may be quoted and may include backslash escapes.
 *
 * @param {string} line
 * @returns {string[]}
 */
function parseCommand(line) {
  let i = 0;
  while (i < line.length && line[i] === " ") i++;
  let start = " ";
  if ((i < line.length && line[i] === '"') || line[i] === "'") {
    start = line[i];
    i++;
  }
  let first = "";
  while (i < line.length) {
    if (line[i] === start) {
      if (start !== " ") i++;
      break;
    }
    if (line[i] === "\\" && i < line.length - 1) i++;
    first = first + line[i];
    i++;
  }

  while (i < line.length && line[i] === " ") i++;
  return [first, line.slice(i)];
}

let errorFunction = (message) => {
  console.log("galene: ", message);
};

/**
 * @param {unknown} message
 * @param {string} [level]
 */
function displayError(message, level) {
  errorFunction(message);
  console.log("galene: ", message);
}

/**
 * @param {unknown} message
 */
function displayWarning(message) {
  return displayError(message, "warning");
}

let connecting = false;

const hideAndShow = (chosenCamera, cameraList, videoLoaded) => {
  for (let camera of cameraList) {
    if (chosenCamera === camera) {
      const div = document.querySelector(`[id$="${camera}"]`);
      if (!div) continue;
      videoLoaded();
      div.style.width = "100%";
    } else {
      const div = document.querySelector(`[id$="${camera}"]`);
      if (!div) continue;
      div.style.width = 0;
    }
  }
};

async function serverConnect(
  chosenCamera,
  cameraList,
  videoLoaded,
  currentRobotId
) {
  if (
    serverConnection &&
    serverConnection.socket &&
    currentRobotId === groupStatus.name
  ) {
    renegotiateStreams();
    hideAndShow(chosenCamera, cameraList, videoLoaded);
    return;
  }

  if (
    serverConnection &&
    serverConnection.socket &&
    currentRobotId !== groupStatus.name
  )
    serverConnection.close();
  serverConnection = new ServerConnection();
  serverConnection.onconnected = gotConnected;
  serverConnection.onpeerconnection = onPeerConnection;
  serverConnection.onclose = gotClose;
  serverConnection.ondownstream = gotDownStream;
  serverConnection.onuser = gotUser;
  serverConnection.onjoined = gotJoined;

  try {
    await serverConnection.connect("wss://video.robotics.webiz.com/ws");
  } catch (e) {
    console.error(e);
    displayError(e.message ? e.message : "Couldn't connect to camera socket");
  }
}

export const startConnect = async function (
  chosenCamera,
  cameraList,
  videoLoaded,
  currentRobotId
) {
  if (!group) return;
  if (connecting) return;

  // Connect to the server, gotConnected will join.
  connecting = true;
  try {
    await serverConnect(chosenCamera, cameraList, videoLoaded, currentRobotId);
  } finally {
    connecting = false;
  }
};

export const start = async (groupName, setGaleneError, videoLoaded) => {
  groupStatus.name = groupName;
  group = groupStatus.name;
  errorFunction = setGaleneError;
  videoReady = videoLoaded;
};
