import styled from "styled-components";

export const StyledCalendarHeader = styled.div`
  padding: 10px 10px 0 10px;
`;
export const CalendarWithRangeWrapper = styled.div`
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
`;

export const StyledRangeRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  gap: 20px;
`;
export const StyledRangeP = styled.span`
  font-size: 16px;
  color: #030a1b;
  white-space: nowrap;
`;
export const StyledDateInput = styled.div`
  border: 2px solid #d4d4d4;
  padding: 10px 20px;
  border-radius: 12px;
  color: #e5eae7;
  width: 150px;
`;

export const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
