import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import ICONS from "../../constants/icons";
import { AssignmentsContext } from "../../contexts/AssignmentsContext";
import useAssignmentsModal from "../../hooks/useAssignmentsModal";
import { BackArrow } from "../../pages/robots/components";
import { AssignmentDetailHeader } from "../../pages/robots/tasks-in-assignments/components";
import {
  handleAssignmentDetailsUpdate,
  setAssignmentEditMode,
} from "../../redux/dashboard";
import { parseAssignment } from "../../utils/parseAssignments";
import AssignmentModalContent from "../add-assignment-modal/AssignmentModalContent";
import { UnderLine } from "../add-assignment-modal/components";
import {
  BackWrapper,
  EditAssignmentButton,
  EditAssignmentButtonsWrapper,
  StyledBackBtnText,
  StyledEditAssignmentText,
} from "./components";

const AssignmentDetailsEditMode = ({
  assignmentDetails,
  newAssignmentForm,
  setNewAssignmentForm,
  setIsModalOpen,
  currentModalData,
  setCurrentModalData,
  setIsOpen,
  handleBackBtnClick,
  setIsEditAssignmentModal,
  errorText,
  isValidAssignment,
  handleAssignmentSave,
}) => {
  const { assignmentsData, setAssignmentsData, assignmentTemplate } =
    useContext(AssignmentsContext);
  const dispatch = useDispatch();
  const {
    handleChooseWeekday,
    handleNameChange,
    handleRadioBtnChange,
    handleTimeChange,
    handleCalendarChange,
    showCalendar,
    setShowCalendar,
  } = useAssignmentsModal({
    newAssignmentForm,
    setNewAssignmentForm,
    setIsOpen,
    assignmentsData,
    setAssignmentsData,
  });
  useEffect(() => {
    setCurrentModalData(assignmentDetails);
  }, [assignmentDetails, setCurrentModalData, dispatch]);

  useEffect(() => {
    if (currentModalData && assignmentDetails.isCreated) {
      const parsedAssignment = parseAssignment(currentModalData);

      Object.keys(currentModalData).length &&
        setNewAssignmentForm({ ...assignmentTemplate, ...parsedAssignment });
    } else {
      const parsedAssignment = assignmentDetails
        ? parseAssignment(assignmentDetails)
        : null;
      setNewAssignmentForm({ ...assignmentTemplate, ...parsedAssignment });
    }
  }, [
    currentModalData,
    setNewAssignmentForm,
    assignmentTemplate,
    assignmentDetails,
  ]);
  const handleSubmit = () => {
    // let first = parseAssignment(currentModalData);
    // first = { ...first, ...currentModalData };
    let updated = parseAssignment(newAssignmentForm);
    updated = { ...updated, ...newAssignmentForm };

    if (isValidAssignment(newAssignmentForm, setIsModalOpen)) {
      if (updated.disposable) {
        setIsModalOpen && setIsModalOpen(true);
      }
      //  else if (
      //   !updated.disposable &&
      //   first.weekDays &&
      //   JSON.stringify(first.weekDays) !== JSON.stringify(updated.weekDays)
      // )
      // {
      //   setIsModalOpen && setIsModalOpen(true);
      // }
      else {
        setIsEditAssignmentModal(true);
      }
    }
  };

  const handleCancel = () => {
    assignmentDetails.isCreated
      ? dispatch(setAssignmentEditMode(false))
      : dispatch(handleAssignmentDetailsUpdate(null));
  };

  return (
    <React.Fragment>
      <AssignmentDetailHeader style={{ padding: "20px 35px 0px 35px" }}>
        {assignmentDetails.isCreated && (
          <BackWrapper onClick={handleBackBtnClick}>
            <BackArrow src={ICONS.BackArrowBlue} />
            <StyledBackBtnText className="clickRevert">Back</StyledBackBtnText>
          </BackWrapper>
        )}
        <StyledEditAssignmentText>
          {newAssignmentForm.isCreated
            ? "Edit Assignment"
            : "Add New Assignment"}
        </StyledEditAssignmentText>
        <UnderLine style={{ width: "100%", marginTop: "20px" }} />
      </AssignmentDetailHeader>
      <AssignmentModalContent
        newAssignmentForm={newAssignmentForm}
        setNewAssignmentForm={setNewAssignmentForm}
        assignmentsData={assignmentsData}
        setAssignmentsData={setAssignmentsData}
        currentModalData={currentModalData}
        handleChooseWeekday={handleChooseWeekday}
        handleNameChange={handleNameChange}
        handleRadioBtnChange={handleRadioBtnChange}
        handleTimeChange={handleTimeChange}
        handleCalendarChange={handleCalendarChange}
        errorText={errorText}
        showCalendar={showCalendar}
        setShowCalendar={setShowCalendar}
        dashboard
      />
      <EditAssignmentButtonsWrapper>
        <EditAssignmentButton
          assignmentEditMode
          background={"#34619E"}
          color={"#fff"}
          onClick={
            newAssignmentForm.isCreated
              ? handleSubmit
              : () =>
                  handleAssignmentSave(
                    newAssignmentForm,
                    newAssignmentForm.isCreated ? currentModalData : {},
                    null,
                    setIsModalOpen
                  )
          }
        >
          {newAssignmentForm.isCreated ? "Save Changes" : "Save"}{" "}
        </EditAssignmentButton>
        <EditAssignmentButton
          assignmentEditMode
          background={"#fff"}
          color={"#0686D8"}
          border={"1px solid #0686D8"}
          onClick={() => handleCancel()}
          className="clickRevert"
        >
          Cancel
        </EditAssignmentButton>
      </EditAssignmentButtonsWrapper>
    </React.Fragment>
  );
};

export default AssignmentDetailsEditMode;
