import React, { useEffect, useRef, useState } from "react";
import {
  ErrorMessage,
  InputWrapper,
  ParametersWrapper,
  StyledParInput,
} from "../../pages/robots/tasks-in-assignments/components";

import { InputTitle } from "../add-assignment-modal/components";
import ParamFields from "./ParamFields";
import { useChooseRobot } from "../../hooks/useChooseRobot";
import { StyledSelect } from "./components";
import { ROLESENUM } from "../../enums";
import { useSelector } from "react-redux";

const TaskParametersEditMode = ({
  item,
  isError,
  assignmentDetails,
  setAssignmentsDetails,
  handleParametersChange,
  dashboard,
  setChosenApp,
}) => {
  const { SelectRobotComp } = useChooseRobot();
  let errorDivRef = useRef();
  const { currentRole } = useSelector((state) => state.clients);
  const [isCustom, setIsCustom] = useState(false);

  useEffect(() => {
    if (
      item?.parameters &&
      ("Custom" === item?.parameters["end_time"] ||
        ![-1, 30, 60, 120, 180].includes(item?.parameters["end_time"]))
    ) {
      setIsCustom(true);
    } else {
      setIsCustom(false);
    }
  }, [item]);

  useEffect(() => {
    if (isError && errorDivRef?.current) {
      errorDivRef?.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    } else {
      errorDivRef.current = null;
    }
  }, [isError]);

  return (
    <ParametersWrapper
      style={{ marginTop: dashboard && "25px", flexDirection: "column" }}
    >
      {item?.application?.parameters?.map((param) => (
        <React.Fragment key={param.id + "task-parameters"}>
          <InputWrapper key={param.id} style={{ width: dashboard && "100%" }}>
            <InputTitle>{param.parameter_name}</InputTitle>
            {param.type === "number" ? (
              <>
                <StyledParInput
                  onClick={(e) => e.stopPropagation()}
                  type="number"
                  min={param.min}
                  max={param.max}
                  style={{ width: "100%" }}
                  value={item.parameters[param.parameter]}
                  onChange={(e) => {
                    handleParametersChange(
                      e.target.value,
                      item.id,
                      param.parameter,
                      param.id
                    );
                  }}
                  disabled={item.disabled}
                />

                {isError &&
                  (+param.min > +item.parameters[param.parameter] ||
                    +param.max < +item.parameters[param.parameter] ||
                    item.parameters[param.parameter]?.length === 0) && (
                    <ErrorMessage
                      ref={(el) => {
                        if (!errorDivRef?.current) errorDivRef.current = el;
                      }}
                    >
                      * Invalid input
                    </ErrorMessage>
                  )}
              </>
            ) : (
              <ParamFields
                param={param}
                item={item}
                handleParametersChange={handleParametersChange}
                isError={isError}
                errorDivRef={errorDivRef}
                isCustom={isCustom}
              />
            )}
          </InputWrapper>
          <InputWrapper>
            {param.type === "endtime" && isCustom && (
              <>
                <InputTitle>{"Minutes"}</InputTitle>
                <StyledParInput
                  onClick={(e) => e.stopPropagation()}
                  type="number"
                  style={{ width: "100px" }}
                  value={item.parameters["end_time"]}
                  onChange={(e) => {
                    handleParametersChange(
                      +e.target.value,
                      item.id,
                      "end_time",
                      param.id
                    );
                  }}
                  disabled={item.disabled}
                />
                {isError &&
                  (!item?.parameters["end_time"] ||
                    item?.parameters["end_time"] === "Custom") && (
                    <ErrorMessage
                      ref={(el) => {
                        if (!errorDivRef?.current) errorDivRef.current = el;
                      }}
                    >
                      * This field can not be empty
                    </ErrorMessage>
                  )}
              </>
            )}
          </InputWrapper>
        </React.Fragment>
      ))}
      {[ROLESENUM.ADMIN, ROLESENUM.DEVELOPER].includes(currentRole) && (
        <InputWrapper>
          <InputTitle>
            Robots' list{" "}
            <span>
              (*used to assign task to specific robot, leave empty otherwise)
            </span>
          </InputTitle>
          <SelectRobotComp
            component={StyledSelect}
            isError={isError}
            errorDivRef={errorDivRef}
            item={item}
            onChange={(robot) => {
              assignmentDetails
                ? setAssignmentsDetails((prev) => ({
                    ...prev,
                    tasks: prev.tasks.map((el) =>
                      el.id === item.id ? { ...el, chosenRobot: robot } : el
                    ),
                  }))
                : setChosenApp((prev) => ({ ...prev, chosenRobot: robot }));
            }}
          />
        </InputWrapper>
      )}
    </ParametersWrapper>
  );
};

export default TaskParametersEditMode;
