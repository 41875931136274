import { useContext, useEffect } from "react";
import { RobotsDataContext } from "../../../contexts/RobotsDataContext";
import Robot from "../handlers/robot";

const useShowRobot = ({ ctx, loadDraw, initialData, showRobots }) => {
  const { RobotsData } = useContext(RobotsDataContext);

  useEffect(() => {
    if (ctx && showRobots) {
      Robot.ctx = ctx;
      loadDraw(initialData);
      RobotsData.forEach((el) => {
        if (el.position) {
          Robot.drawRobot(el);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx, RobotsData, showRobots, initialData]);

  useEffect(() => {
    if (!showRobots && showRobots !== null) loadDraw(initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx, showRobots, initialData]);

  return {};
};

export default useShowRobot;
