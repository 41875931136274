import styled from "styled-components";

export const HeaderCompWraper = styled.div`
  /* width: 160px; */
  cursor: pointer;
  padding: 10px 55px;
  border-bottom: 4px solid #0686d8;
  font-size: 16px;
  font-weight: 600;
  border-bottom: ${(props) =>
    props.isChosen ? "4px solid #0686d8" : "4px solid #E5EAE7"};
  color: ${(props) => (props.isChosen ? "#000000" : "#909491")};
`;

export const StyledTableBox = styled.div`
  padding: 18px 24px;
  color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const RowWrapper = styled.div`
  background: #0096c6;
  border-radius: 8px;
  display: flex;
  align-items: "center";
  width: 100%;
  margin-top: 24px;
  padding: 2px 20px;
`;

export const StyledP = styled.p`
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledModalFormWrapper = styled.div`
  padding: 36px;
  background: #fff;
  border-radius: 0px 0px 16px 16px;
`;

export const StyledModalText = styled.div`
  color: #030a1b;
  font-weight: 400;
  font-size: 16px;
  line-height: 18.8px;
  margin: 2.8rem 0;
`;

export const StyledSuccessModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SuccessModalTitle = styled.div`
  color: #030a1b;
  font-weight: 500;
  font-size: 24px;
  line-height: 118%;
  margin-top: 24px;
  text-align: center;
`;

export const StyledFooterText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 118%;
  color: #909491;
  text-align: center;
  margin-top: 36px;
`;

export const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
  > input {
    width: 25px;
    height: 25px;
  }
`;
