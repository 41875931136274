import styled from "styled-components";

export const StreamingImg = styled.div`
  margin-top: 15px;
  height: 100%;
  width: 100%;
  background: rgba(1, 1, 1, 0.2);
  mix-blend-mode: normal;
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderStyledButton = styled.button`
  width: 204px;
  height: 44px;
  background: #0096c6;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border: none;
  :hover {
    box-shadow: 5px 4px 40px rgba(6, 134, 216, 0.72);
    border: 3px solid #0096c6;
  }
  :active {
    box-shadow: unset;
  }
`;
