export const mapsDataManipulation = (maps) => {
  let data = [];
  maps?.forEach((el) => {
    const found = data.find((item) => item.building === el.building);
    if (!found) {
      data.push({
        building: el.building,
        floors: [el],
      });
    } else {
      data = data.map((item) =>
        item.building === el.building
          ? {
              ...item,
              floors: [...item.floors, el],
            }
          : item
      );
    }
  });
  return data;
};
