import styled from "styled-components";

export const CloseIconWrapper = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
  z-index: 1;
`;

export const ModalActionTitle = styled.div`
  color: #030a1b;
  font-size: 36px;
  line-height: 160%;
`;

export const ModalActionSubtitle = styled.div`
  color: #030a1b;
  font-size: 28px;
  line-height: 160%;
  letter-spacing: 0.28px;
`;

export const StyledActionContentWrapper = styled.div`
  position: relative;
  height: ${({ fullscreen, subtitle }) =>
    fullscreen
      ? "98%"
      : subtitle
      ? "calc(100% - 230px)"
      : "calc(100% - 180px)"};
  width: 98%;
  margin: auto;
`;

export const Button1 = styled.button`
  border-radius: 48px;
  border: 1px solid #0686d8;
  background: #fff;
  display: flex;
  width: 204px;
  padding: 8px 40px;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  color: #0686d8;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;

  cursor: pointer;

  :disabled {
    background: rgba(212, 212, 212, 1);
    cursor: not-allowed;
    border: none;
    color: #fff;
  }
`;

export const Button2 = styled.button`
  border-radius: 48px;
  background: #0686d8;
  border: none;
  display: flex;
  width: 204px;
  padding: 8px 40px;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;

  cursor: pointer;

  :disabled {
    background: rgba(212, 212, 212, 1);
    cursor: not-allowed;
  }
`;

export const StyledButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 50px;
  padding: 33px 0;
`;

export const StyledTopControllsWrapper = styled.div`
  height: 57px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(30, 30, 30, 0.65);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledRotationWrapper = styled.div`
  font-weight: bold;
  font-size: 16px;
  display: flex;
  width: 249px;
  height: 51px;
  border-radius: 50px;
  align-items: center;
  justify-content: space-around;
  color: #fff;

  > img {
    width: 72px;
    height: 40px;
    border-radius: 48px;
    padding: 9px;
  }
`;

export const StyledCloseWrapper = styled.div`
  padding: 19px 24px 24px 24px;

  > h1 {
    color: #030a1b;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 118%; /* 18.88px */
    letter-spacing: 0.16px;
    margin-bottom: 5px;
  }

  > h2 {
    color: #030a1b;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 118%; /* 18.88px */
    letter-spacing: 0.16px;
    opacity: 0.6;
  }

  > div {
    margin-top: 12px;
    display: flex;

    > button {
      cursor: pointer;
      width: 153px;
      height: 44px;
    }

    > button:nth-child(1) {
      border-radius: 48px;
      background: rgba(212, 47, 57, 0.1);
      color: rgba(212, 47, 57, 1);
      border: none;
      margin-right: 32px;
    }
    > button:nth-child(2) {
      border-radius: 48px;
      border: 1px solid #0686d8;
      background: rgba(6, 134, 216, 0);
      color: rgba(6, 134, 216, 1);
    }
  }
`;
