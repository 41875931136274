import React, { useContext } from "react";
import AgendaHeader from "./AgendaHeader";
import { WhiteWrapper } from "./components";
import TimeTable from "./TimeTable";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";

const AgendaTable = ({ setIsOpen }) => {
  const { isMobile } = useContext(ResponsiveContext);
  return (
    <WhiteWrapper
      isMobile={isMobile}
      style={{
        width: "100%",
        height: isMobile ? "100%" : "62vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AgendaHeader setIsOpen={setIsOpen} />
      <TimeTable />
    </WhiteWrapper>
  );
};

export default AgendaTable;
