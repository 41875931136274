import styled from "styled-components";
import COLORS from "../../constants/colors";
import FONTS from "../../constants/fonts";
import ICONS from "../../constants/icons";

export const IconDragNDropWrapper = styled.div`
  width: 17rem;
  height: 17rem;
  position: relative;
  margin-right: 4.4rem;
`;

export const InputFileWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const FileInputInput = styled.input`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
`;

export const FileInputLabel = styled.label`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  border-radius: 1.5rem;
`;

export const InputFileContent = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) =>
    props.selectedImage
      ? `url(${props.selectedImage})`
      : `url(${ICONS.DragAndDrop})`};
  background-repeat: no-repeat;
  background-size: ${(props) => (props.selectedImage ? "cover" : "100% 100%")};
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:hover {
    & > * {
      transform: scale(1.05);
    }
  }
`;

export const InputFileIcon = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  background-image: url(${ICONS.UploadFileCloud});
  background-repeat: no-repeat;
  background-size: contain;
`;

export const InputFileText = styled.div`
  width: 10.8rem;
  text-align: center;
  color: ${COLORS.secondary.ncs};
  font-family: ${FONTS.UbuntuMedium};
  line-height: 1.6rem;
  font-size: 1.2rem;
  padding: 0.4rem;
`;
