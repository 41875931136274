import React, { useRef, useState } from "react";
import { StyledWeekDays } from "../../pages/dashboard/components";
import { UnderLine } from "../add-assignment-modal/components";
import {
  EditBoxWrapper,
  ModalForm,
  RightDiv,
  StyledAssignmentDate,
  StyledCreatedBy,
  StyledIconButton,
  StyledTasksTitle,
  StyledTasksTitleWrapper,
} from "./components";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  AssignmentDetailsHeader,
  AssignmentDetailsTitle,
  LeftDiv,
  StyledAssignmentStatus,
} from "./components";
import {
  AGENDAASSIGNMENTSSTATUSENUM,
  ROBOTTASKSTATUSENUM,
  ROLESENUM,
} from "../../enums";
import TaskParameters from "./TaskParameters";
import useTimeFormat from "../../hooks/useTimeFormat";
import moment from "moment";
import EditBox from "../edit-box/EditBox";
import NoTasksComponent from "./NoTasksComponent";
import ICONS from "../../constants/icons";
import { v4 as uuidv4 } from "uuid";
import TaskEditMode from "./TaskEditMode";
import {
  setAssignmentDetailsBeforeChange,
  setAssignmentEditMode,
  setStopAssignmentData,
  setStopTaskData,
} from "../../redux/dashboard";
import { useDispatch, useSelector } from "react-redux";
import GetElementCoordinates from "../get-element-coordinates/GetElementCoordinates";
const AssignmentDetails = ({
  assignmentDetails,
  setIsDeleteModal,
  setAssignmentsDetails,
  setIsDeleteAssignmentRunEvery,
  calendar,
}) => {
  const dispatch = useDispatch();
  const { timeInterval, weekDays } = useTimeFormat(assignmentDetails);
  const { calendarDate } = useSelector((state) => state.dashboard);
  const { currentRole } = useSelector((state) => state.clients);
  const [tasksBeforeChanges, setTasksBeforeChanges] = useState([]);

  const drawerHeaderRef = useRef();
  const showStopIcon =
    (assignmentDetails.assignment &&
      assignmentDetails?.agendaAssignmentStatus ===
        AGENDAASSIGNMENTSSTATUSENUM.INPROGRESS) ||
    (!assignmentDetails.assignment &&
      assignmentDetails.status !== ROBOTTASKSTATUSENUM.DONE &&
      assignmentDetails.status !== ROBOTTASKSTATUSENUM.Failed &&
      assignmentDetails.status !== ROBOTTASKSTATUSENUM.STOPPED &&
      assignmentDetails.status !== ROBOTTASKSTATUSENUM.DEFAULT);

  const handleAddNewTask = () => {
    const newTask = {
      id: uuidv4(),
      isEditMode: true,
      isCreated: false,
    };
    setAssignmentsDetails((prev) => ({
      ...prev,
      tasks: [...prev.tasks, newTask],
    }));
  };

  return (
    <React.Fragment>
      <AssignmentDetailsHeader ref={drawerHeaderRef}>
        <LeftDiv>
          <div style={{ display: "flex", gap: "10px" }}>
            <StyledAssignmentDate>
              {`${moment(
                assignmentDetails.disposable
                  ? assignmentDetails?.executionTime
                  : calendarDate
              ).format("dddd , MMM D")} -
              ${timeInterval}`}
            </StyledAssignmentDate>
            <div style={{ display: "flex", gap: "5px" }}>
              {assignmentDetails?.phoneNots?.length > 0 && (
                <GetElementCoordinates
                  text={
                    assignmentDetails?.assignment
                      ? assignmentDetails?.phoneNots
                          ?.map((item) => item.firstName + " " + item.lastName)
                          ?.join(",")
                      : assignmentDetails.phoneNots[0]
                  }
                  left={"0"}
                  type={"bottom"}
                  styles={{
                    border: "2px solid #000",
                    background: "#FFFFFF",
                    color: "#08183F",
                    borderRadius: "10px",
                    // whiteSpace: "unset",
                    boxShadow: "0px 4px 10px rgba(0, 147, 198, 0.18)",
                  }}
                >
                  <img src={ICONS.Whatsapp} alt="" />
                </GetElementCoordinates>
              )}
              {assignmentDetails?.emailNots?.length > 0 && (
                <GetElementCoordinates
                  text={
                    assignmentDetails?.assignment
                      ? assignmentDetails?.emailNots
                          ?.map((item) => item.firstName + " " + item.lastName)
                          ?.join(",")
                      : assignmentDetails.emailNots[0]
                  }
                  left={"0"}
                  type={"bottom"}
                  styles={{
                    border: "2px solid #000",
                    background: "#FFFFFF",
                    color: "#08183F",
                    // whiteSpace: "unset",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 10px rgba(0, 147, 198, 0.18)",
                  }}
                >
                  <img src={ICONS.EmailIcon} alt="" />
                </GetElementCoordinates>
              )}
            </div>
          </div>
          <AssignmentDetailsTitle calendar={calendar}>
            {assignmentDetails.assignment
              ? assignmentDetails?.name
              : assignmentDetails.name}
          </AssignmentDetailsTitle>
          <div
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <StyledAssignmentStatus
              status={
                assignmentDetails?.agendaAssignmentStatus ||
                assignmentDetails?.status
              }
            >
              {assignmentDetails?.agendaAssignmentStatus ||
                assignmentDetails?.status}
            </StyledAssignmentStatus>
            {showStopIcon && (
              <img
                style={{ cursor: "pointer" }}
                src={ICONS.AbortIcon}
                alt="stop"
                onClick={() => {
                  assignmentDetails.assignment &&
                    dispatch(setStopAssignmentData(assignmentDetails));
                  !assignmentDetails.assignment &&
                    dispatch(setStopTaskData(assignmentDetails));
                }}
              />
            )}
          </div>
          {assignmentDetails?.createdBy?.username && (
            <StyledCreatedBy>
              Created by {assignmentDetails?.createdBy?.username}{" "}
            </StyledCreatedBy>
          )}
          {!assignmentDetails.assignment &&
            assignmentDetails?.task?.createdBy?.username && (
              <StyledCreatedBy>
                Created by {assignmentDetails?.task.createdBy?.username}{" "}
              </StyledCreatedBy>
            )}
          {assignmentDetails.assignment && !assignmentDetails.disposable && (
            <StyledWeekDays margin={assignmentDetails?.createdBy?.username}>
              Weekly On {weekDays}
            </StyledWeekDays>
          )}
          {!assignmentDetails.assignment && (
            <StyledWeekDays margin={assignmentDetails?.createdBy?.username}>
              {" "}
              Run immediately - quick action.
            </StyledWeekDays>
          )}
        </LeftDiv>
        <RightDiv>
          {assignmentDetails?.assignment &&
            assignmentDetails?.agendaAssignmentStatus !==
              AGENDAASSIGNMENTSSTATUSENUM.DEFAULT && (
              <CircularProgressbar
                value={assignmentDetails.progressPercentage || 0}
                text={
                  assignmentDetails.progressPercentage
                    ? ` ${assignmentDetails.progressPercentage}%`
                    : "0%"
                }
                styles={buildStyles({
                  strokeLinecap: "butt",
                  textSize: "24px",
                  pathTransitionDuration: 0.5,
                  pathColor: `rgba(47, 212, 83,1)`,
                  textColor: "#030A1B",
                  trailColor: "#D5D5D5",
                  backgroundColor: "#3e98c7",
                })}
              />
            )}
          {assignmentDetails.agendaAssignmentStatus ===
            AGENDAASSIGNMENTSSTATUSENUM.DEFAULT && (
            <EditBoxWrapper>
              <EditBox
                handleEdit={() => {
                  dispatch(setAssignmentDetailsBeforeChange(assignmentDetails));
                  dispatch(setAssignmentEditMode(true));
                }}
                handleDelete={() => {
                  assignmentDetails.disposable
                    ? setIsDeleteModal(true)
                    : setIsDeleteAssignmentRunEvery(true);
                }}
              />
            </EditBoxWrapper>
          )}
        </RightDiv>
      </AssignmentDetailsHeader>

      <UnderLine style={{ width: "100%" }} />

      <div
        style={{
          // height: `calc(100vh - ${tasksDivHeight}px)`,
          maxHeight: calendar ? "350px" : "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <StyledTasksTitleWrapper>
          <StyledTasksTitle>Tasks</StyledTasksTitle>
          <StyledIconButton
            disabled={
              currentRole === ROLESENUM.VIEWER ||
              assignmentDetails.agendaAssignmentStatus !==
                AGENDAASSIGNMENTSSTATUSENUM.DEFAULT
            }
            onClick={handleAddNewTask}
          >
            <img
              src={
                currentRole !== ROLESENUM.VIEWER &&
                assignmentDetails.agendaAssignmentStatus ===
                  AGENDAASSIGNMENTSSTATUSENUM.DEFAULT
                  ? ICONS.AddIcon
                  : ICONS.GreyCross
              }
              alt="Add"
            />
          </StyledIconButton>
        </StyledTasksTitleWrapper>

        <ModalForm style={{ height: "100%", background: "#fff" }}>
          {assignmentDetails.assignment &&
            assignmentDetails?.tasks?.length > 0 &&
            assignmentDetails?.tasks?.map((item, index) =>
              item?.isEditMode ? (
                <TaskEditMode
                  key={item.id}
                  item={item}
                  assignmentDetails={assignmentDetails}
                  setAssignmentsDetails={setAssignmentsDetails}
                  tasksBeforeChanges={tasksBeforeChanges}
                  setTasksBeforeChanges={setTasksBeforeChanges}
                />
              ) : (
                item?.isCreated && (
                  <TaskParameters
                    taskIndex={index}
                    item={item}
                    key={item.id}
                    setAssignmentsDetails={setAssignmentsDetails}
                    setTasksBeforeChanges={setTasksBeforeChanges}
                  />
                )
              )
            )}

          {assignmentDetails.assignment &&
            assignmentDetails?.tasks?.length < 1 && (
              <NoTasksComponent handleAddNewTask={handleAddNewTask} />
            )}

          {!assignmentDetails.assignment && (
            <TaskParameters item={assignmentDetails} />
          )}
        </ModalForm>
      </div>
    </React.Fragment>
  );
};

export default AssignmentDetails;
