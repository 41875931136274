import React, { useState } from "react";
import { useDispatch } from "react-redux";
import COLORS from "../../constants/colors";
import InputText from "../input/InputText";
import { InputTitle, UnderLine } from "../add-assignment-modal/components";
import { BuildinModalContentWrapper } from "./components";
import { StyledSelect } from "../../pages/robots/tasks-in-assignments/components";
import { useSelector } from "react-redux";
import { StyledButton } from "../button/components";
import addFloor from "../../APIs/maps/addFloor";
import {
  setChosenBuilding,
  setChosenFloor,
  setChosenMapUrl,
  setMapModalData,
  setMapsData,
} from "../../redux/maps";
import updateFloor from "../../APIs/maps/updateFloor";
import { InputError } from "../input/components";
import UploadMap from "./UploadMap";
import { MAPTYPESENUM } from "../../enums";
import Loader from "../loader/Loader";

const MapModalContent = () => {
  const { mapsData, mapModalData } = useSelector((state) => state.maps);
  const [errorMessages, setErrorMessages] = useState({
    building: "",
    file: "",
    floorName: "",
  });
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);

  const handleFloorsResponse = (response, type) => {
    dispatch(setChosenMapUrl(response.editedMapUrl));
    dispatch(setChosenBuilding(response.building));
    dispatch(setChosenFloor(response.floor));
    let data = [];
    if (type === "add") {
      data = mapsData.map((el) =>
        el.building === response.building
          ? {
              ...el,
              floors: [
                ...el.floors,
                {
                  floor: response.floor,
                  mapUrl: response.mapUrl,
                  editedMapUrl: response.editedMapUrl,
                  id: response.id,
                },
              ],
            }
          : el
      );
    } else {
      data = mapsData.map((el) =>
        el.building === response.building
          ? {
              ...el,
              floors: el.floors.map((item) =>
                item.id === response.id
                  ? {
                      floor: response.floor,
                      mapUrl: response.mapUrl,
                      editedMapUrl: response.editedMapUrl,
                      id: response.id,
                    }
                  : item
              ),
            }
          : el
      );
    }
    dispatch(setMapsData(data));
    dispatch(setMapModalData(null));
  };

  const handleAddBuilding = async () => {
    let locFormData = new FormData();
    locFormData.append(
      `${MAPTYPESENUM.DASHBOARD_MAP}`,
      mapModalData[MAPTYPESENUM.DASHBOARD_MAP]
    );
    locFormData.append(
      `${MAPTYPESENUM.FLEET_RULES_MAP}`,
      mapModalData[MAPTYPESENUM.FLEET_RULES_MAP]
    );
    locFormData.append("buildingName", mapModalData.building);
    locFormData.append("floorName", mapModalData.floorName);

    const response = await addFloor(locFormData);
    const building = mapsData.find((el) => el.building === response.building);
    if (building.floors.find((el) => el.id === response.id)) {
      handleFloorsResponse(response, "update");
    } else handleFloorsResponse(response, "add");
  };

  const handleUpdateBuilding = async () => {
    let locFormData = new FormData();
    locFormData.append(
      `${MAPTYPESENUM.DASHBOARD_MAP}`,
      mapModalData[MAPTYPESENUM.DASHBOARD_MAP]
    );
    locFormData.append(
      `${MAPTYPESENUM.FLEET_RULES_MAP}`,
      mapModalData[MAPTYPESENUM.FLEET_RULES_MAP]
    );
    locFormData.append("buildingName", mapModalData.building);
    locFormData.append("floorName", mapModalData.floorName);
    locFormData.append("id", mapModalData.id);
    const response = await updateFloor(locFormData);
    handleFloorsResponse(response, "update");
  };

  const handleValidations = (data) => {
    let errors = {};
    if (!data.building) {
      errors = { ...errors, building: "Please enter a name for the building" };
    }
    if (!data[MAPTYPESENUM.FLEET_RULES_MAP] && !data.mapUrl) {
      errors = {
        ...errors,
        [MAPTYPESENUM.FLEET_RULES_MAP]: "Please upload a map before saving",
      };
    }
    if (!data[MAPTYPESENUM.DASHBOARD_MAP] && !data.mapUrl) {
      errors = {
        ...errors,
        [MAPTYPESENUM.DASHBOARD_MAP]: "Please upload a map before saving",
      };
    }
    if (!data.floorName) {
      errors = { ...errors, floorName: "Please enter a name for the map." };
    }
    setErrorMessages(errors);
    if (
      errors.building ||
      errors[MAPTYPESENUM.DASHBOARD_MAP] ||
      errors[MAPTYPESENUM.FLEET_RULES_MAP] ||
      errors.floorName
    ) {
      return false;
    } else return true;
  };
  const handleSave = async () => {
    setUploading(true);
    const isValid = handleValidations(mapModalData);
    if (isValid) {
      mapModalData?.isCreated
        ? await handleUpdateBuilding()
        : await handleAddBuilding();
    }
    setUploading(false);
  };
  return (
    <BuildinModalContentWrapper>
      <InputTitle style={{ color: "#5C605D" }}>Building</InputTitle>
      <div style={{ position: "relative" }}>
        <StyledSelect
          margin="0 0 1.8rem 0"
          style={{ width: "100%" }}
          borderColor={COLORS.secondary.water_gray}
          onChange={(e) =>
            dispatch(
              setMapModalData({ ...mapModalData, building: e.target.value })
            )
          }
          value={mapModalData?.building}
          // error={touched[field.name] && errors[field.name]}
        >
          {mapsData?.map((el) => (
            <option value={el.building}>{el.building}</option>
          ))}
        </StyledSelect>
        {errorMessages.building && (
          <InputError className="error" style={{ left: "28px" }}>
            * {errorMessages.building}
          </InputError>
        )}
      </div>
      <InputTitle style={{ color: "#5C605D", marginTop: "20px" }}>
        Map Name
      </InputTitle>
      <>
        <InputText
          margin="0 0 1.8rem 0"
          borderColor={COLORS.secondary.water_gray}
          handleChange={(val) => {
            setErrorMessages((prev) => ({
              ...prev,
              floorName: "",
            }));
            dispatch(setMapModalData({ ...mapModalData, floorName: val }));
          }}
          value={mapModalData?.floorName}
          error={errorMessages.floorName}
        />
      </>
      <UnderLine />

      <div
        style={{
          display: "flex",
        }}
      >
        <UploadMap
          mapModalData={mapModalData}
          errorMessages={errorMessages}
          setErrorMessages={setErrorMessages}
          mapType={MAPTYPESENUM.DASHBOARD_MAP}
        />

        <UploadMap
          mapModalData={mapModalData}
          errorMessages={errorMessages}
          setErrorMessages={setErrorMessages}
          mapType={MAPTYPESENUM.FLEET_RULES_MAP}
        />
      </div>

      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <StyledButton
          color="#fff"
          backgroundColor="#0686D8"
          padding="12px 30px"
          fontSize="14px"
          onClick={handleSave}
          disabled={uploading}
        >
          {uploading ? (
            <Loader height={50} noText noMargin padding={0} />
          ) : (
            "Save"
          )}
        </StyledButton>
      </div>
    </BuildinModalContentWrapper>
  );
};

export default MapModalContent;
