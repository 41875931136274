import React, { useContext, useState, useEffect } from "react";
import CalendarDays from "./CalendarDays";
import momentTimezone from "moment-timezone";
import getAgendaWeek from "../../APIs/calendar/getAgendaWeek";
import { useSelector } from "react-redux";
import { useAgendaData } from "../map/hooks/useAgendaData";
import {
  handleAgendaRevert,
  setAssignmentsRedux,
  setIsLoading,
} from "../../redux/dashboard";
import { useDispatch } from "react-redux";
import CalendarHeader from "./CalendarHeader";
import AssignmentDetailsWrapper from "../../components/agenda-assignment-details-modal/AssignmentDetailsWrapper";
import useRevert from "../../hooks/useRevert";
import CalendarTable from "./CalendarTable";
import { CalendarGifWrapper } from "./components";
import RotateDeviceAlert from "../../components/rotate-device-alert/RotateDeviceAlert";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";

const CalendarPage = () => {
  const [chosenDate, setChosenDate] = useState(new Date());
  const [drawerRef, setDrawerRef] = useState(null);
  const { isTablet, isLandscape } = useContext(ResponsiveContext);
  const { chosenClient } = useSelector((state) => state.clients);
  const { isAssignmentEditMode, refreshAgendaState, chosenWeekDays } =
    useSelector((state) => state.dashboard);
  // eslint-disable-next-line no-unused-vars
  const _ = useRevert("clickRevert", () => {
    dispatch(handleAgendaRevert());
  });
  const [isOpen, setIsOpen] = useState(false);

  const { handleAgendaDataBinding } = useAgendaData();
  const dispatch = useDispatch();
  useEffect(() => {
    if (chosenWeekDays.length) {
      dispatch(setIsLoading(true));
      getAgendaWeek(chosenClient, chosenWeekDays[0], momentTimezone.tz.guess())
        .then(({ oldTasks: tasks, futureAssignments }) => {
          const bindedData = handleAgendaDataBinding(
            tasks,
            futureAssignments,
            chosenWeekDays[0]
          );
          dispatch(setAssignmentsRedux(bindedData));
          dispatch(setIsLoading(false));
        })
        .catch(() => {
          dispatch(setIsLoading(false));
        });
    }
  }, [
    chosenWeekDays,
    chosenClient,
    handleAgendaDataBinding,
    dispatch,
    refreshAgendaState,
  ]);

  return (
    <React.Fragment>
      {isTablet && !isLandscape ? (
        <CalendarGifWrapper>
          <RotateDeviceAlert />
        </CalendarGifWrapper>
      ) : (
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CalendarHeader
            chosenDate={chosenDate}
            setChosenDate={setChosenDate}
            chosenWeekDays={chosenWeekDays}
          />

          <div
            style={{
              width: "100%",
              marginTop: "20px",
              background: "#fff",
              display: "flex",
              flexDirection: "column",
              height: "95%",
            }}
          >
            <CalendarDays chosenDate={chosenDate} />
            <CalendarTable
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              drawerRef={drawerRef}
              setDrawerRef={setDrawerRef}
            />
          </div>
        </div>
      )}

      {isAssignmentEditMode && (
        <AssignmentDetailsWrapper
          calendar
          setDrawerRef={setDrawerRef}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
    </React.Fragment>
  );
};

export default CalendarPage;
