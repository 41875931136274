import styled from "styled-components";
import COLORS from "../../constants/colors";
import FONTS from "../../constants/fonts";
import { StyledSearchableSelect } from "../searchable-select/components";

export const InputTextWrapper = styled.div`
  width: ${({ width }) => (width ? width : "100%")};
  height: ${({ height }) => (height ? height : "5.6rem")};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "1.6rem"};
  border: ${({ borderColor, disabled, noBorder, borderFocus, border }) =>
    border
      ? border
      : borderFocus
      ? `2px solid ${COLORS.secondary.ncs}`
      : disabled
      ? ""
      : borderColor
      ? `2px solid ${borderColor}`
      : noBorder
      ? `none`
      : "2px solid #E5EAE7"};
  background: ${({ backgroundColor, disabled }) =>
    disabled
      ? "rgba(237, 237, 237, 0.5)"
      : backgroundColor
      ? backgroundColor
      : "transparent"};
  font-family: Ubuntu-Bold;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1.6rem")};
  color: ${({ textColor, borderColor }) =>
    textColor ? textColor : borderColor ? borderColor : "#fff"};
  padding: ${({ padding }) => (padding ? padding : "0 2.4rem")};
  margin: ${({ margin }) => (margin ? margin : "0")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  & *.when-focused {
    display: ${({ isFocused }) => (isFocused ? "block" : "none")};
  }
  & *.when-focused_hidden {
    opacity: ${({ isFocused }) => (isFocused ? "1" : "0")};
  }
  position: relative;
`;

export const InputError = styled.div`
  font-family: "AssistantBold", ${FONTS.UbuntuBold};
  font-size: 1.1rem;
  line-height: 1.4rem;
  letter-spacing: 0.01em;
  color: ${COLORS.secondary.rosso};
  position: absolute;
  top: Calc(100% + 0.6rem);
`;

export const InputIcon = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  background-image: url(${({ icon }) => icon});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 1.6rem;
`;

export const InputContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InputName = styled.div`
  line-height: 2.2rem;
  font-size: 1.2rem;
  font-family: "AssistantSemiBold", "Ubuntu-Medium";
  text-transform: capitalize;
  color: ${({ theme }) => theme.color.secondary.morning};
`;

export const InputInput = styled.input`
  height: 3.2rem;
  font-size: 1.4rem;
  font-family: "AssistantRegular", "Ubuntu-Regular";
  color: ${({ theme }) => theme.color.secondary.grayscale};
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.6rem 0;
  justify-content: center;

  ${StyledSearchableSelect} {
    width: 42.4rem;
    height: 6.4rem;
    margin: auto;
    border: solid rgb(203, 213, 207);
    background-image: none;
  }
`;

export const InputNote = styled.div`
  width: 30.6rem;
  font-family: "AssistantSemiBold", "Ubuntu-Medium";
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.01rem;
  color: ${COLORS.secondary.morning};
  margin-left: 3.2rem;
`;
