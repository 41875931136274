import React, { useState } from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import {
  EditAssignmentButton,
  EditAssignmentButtonsWrapper,
  StyledChangesContentWrapper,
  StyledContentTitle,
  StyledModalTitleWrapper,
} from "../components";
import {
  StyledRadioLabel,
  LabelText,
  RadioInput,
} from "../../add-assignment-modal/components";

const EditAssignmentModal = ({
  setIsEditAssignmentModal,
  handleSubmit,
  newAssignmentForm,
  currentModalData,
  hadnleSubmitOTE,
  createDuplicatedRunOn,
}) => {
  const { assignmentDetails, assignmentDetailsBeforeChange } = useSelector(
    (state) => state.dashboard
  );

  const [onAll, setOnAll] = useState(false);

  const handleSaveClick = useCallback(async () => {
    if (onAll) {
      handleSubmit(
        newAssignmentForm,
        newAssignmentForm.isCreated ? currentModalData : {},
        null,
        setIsEditAssignmentModal
      );
    } else {
      const closeModal = () => setIsEditAssignmentModal(false);
      if (
        JSON.stringify(assignmentDetailsBeforeChange?.weekDays) ===
        JSON.stringify(newAssignmentForm.occurance?.weekDays)
      ) {
        await hadnleSubmitOTE(assignmentDetails?.agendaDate, closeModal);
      } else {
        createDuplicatedRunOn(closeModal);
      }
    }
  }, [
    onAll,
    newAssignmentForm,
    currentModalData,
    handleSubmit,
    hadnleSubmitOTE,
    setIsEditAssignmentModal,
    assignmentDetails,
    assignmentDetailsBeforeChange,
    createDuplicatedRunOn,
  ]);

  return (
    <StyledChangesContentWrapper className="clickAllow">
      <StyledModalTitleWrapper style={{ alignItems: "flex-start" }}>
        <StyledContentTitle>
          Edit Recurring Assignment - <strong>{assignmentDetails?.name}</strong>
        </StyledContentTitle>
      </StyledModalTitleWrapper>
      <div style={{ marginTop: "36px" }}>
        <StyledRadioLabel style={{ marginTop: "15px" }}>
          <RadioInput
            type="radio"
            name="when"
            value={"Only this event"}
            onChange={(e) => setOnAll(!e.target.checked)}
            checked={!onAll}
          />
          <LabelText style={{ fontSize: "16px" }}>Only this event</LabelText>
        </StyledRadioLabel>
        <StyledRadioLabel style={{ marginTop: "15px" }}>
          <RadioInput
            type="radio"
            name="when"
            value={"This and following events"}
            checked={onAll}
            onChange={(e) => setOnAll(e.target.checked)}
          />
          <LabelText style={{ fontSize: "16px" }}>
            This and following events
          </LabelText>
        </StyledRadioLabel>
      </div>
      <EditAssignmentButtonsWrapper
        style={{ padding: "unset", marginTop: "36px" }}
      >
        <EditAssignmentButton
          background={"rgba(0,150,198,0.1)"}
          color={"#0686D8"}
          onClick={handleSaveClick}
        >
          Save
        </EditAssignmentButton>
        <EditAssignmentButton
          background={"#fff"}
          color={"#0686D8"}
          border={"1px solid #0686D8"}
          onClick={() => {
            setIsEditAssignmentModal(false);
          }}
        >
          Cancel
        </EditAssignmentButton>
      </EditAssignmentButtonsWrapper>
    </StyledChangesContentWrapper>
  );
};

export default EditAssignmentModal;
