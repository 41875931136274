import React from "react";
import OutlineAuthorised from "../../components/outline-authorised/OutlineAuthorised";
import RobotWarningModalComponent from "../../components/robot-task-warning-modal/RobotWarningModalComponent";
import URLS from "../../constants/urls";
import { AdminTableProvider } from "../../contexts/AdminTableContext";
import { AssignmentsProvider } from "../../contexts/AssignmentsContext";
import { RobotsDataProvider } from "../../contexts/RobotsDataContext";
import useRouting from "../../hooks/useRouting";
import Dashboard from "../dashboard/Dashboard";
import Assignments from "../robots/assignments/Assignments";
import RobotRoutes from "../robots/RobotRoutes";
import AssignmentTasks from "../robots/tasks-in-assignments/AssignmentTasks";
import TeleoperationComponent from "../../components/teleoperation/TeleoperationComponent";
import UserManagement from "../user-management/UserManagement";
import { ROLESENUM } from "../../enums";
import StyledTooltip from "../../components/styled-tooltip/StyledTooltip";
import Simulator from "../simulator/Simulator";
import CalendarPage from "../calendar/CalendarPage";
import RobotActions from "../robot-actions/RobotActions";
import LocationTable from "../admin-page/location/LocationTable";
import ApplicationDetails from "../admin-page/application/ApplicationDetails";
import ApplicationTable from "../admin-page/application/ApplicationTable";
import Map from "../map/Map";
import UserAdministration from "../admin-page/user-administration/UserAdministration";
import EnvironmentSetup from "../admin-page/environment-setup/EnvironmentSetup";
import MapsManagement from "../../components/maps-management/MapsManagement";
import LowBatteryModal from "../../components/low-battery-modal/LowBatteryModal";
import AnalyticsAdmin from "../admin-page/analytics/AnalyticsAdmin";
import AnalyticsLog from "../admin-page/analytics-log/AnalyticsLog";
import EmergencyButtonModal from "../../components/emergency-button-modals/EmergencyButtonModal";

const AuthorisedComponents = () => {
  const { PrivateRoute } = useRouting();
  return (
    <AdminTableProvider>
      <RobotsDataProvider>
        <OutlineAuthorised>
          <AssignmentsProvider>
            <PrivateRoute exact path={URLS.dashboard} component={Dashboard} />
            <PrivateRoute
              exact
              path={URLS.assignments}
              component={Assignments}
            />
            <PrivateRoute
              exact
              path={URLS.assignments_tasks}
              component={AssignmentTasks}
            />
            <PrivateRoute exact path={URLS.calendar} component={CalendarPage} />
          </AssignmentsProvider>
          <PrivateRoute path={URLS.robots} component={RobotRoutes} />
          <PrivateRoute
            path={URLS.user_management}
            allowedRoles={[ROLESENUM.ADMIN, ROLESENUM.MANAGER]}
            component={UserManagement}
          />

          <PrivateRoute
            exact
            path={URLS.fleet_rules}
            allowedRoles={[ROLESENUM.ADMIN, ROLESENUM.DEVELOPER]}
            component={Map}
          />

          <PrivateRoute
            exact
            path={URLS.location}
            allowedRoles={[ROLESENUM.ADMIN, ROLESENUM.DEVELOPER]}
            component={LocationTable}
          />
          <PrivateRoute
            exact
            path={URLS.application}
            allowedRoles={[ROLESENUM.ADMIN, ROLESENUM.DEVELOPER]}
            component={ApplicationTable}
          />
          <PrivateRoute
            exact
            path={URLS.application_details}
            allowedRoles={[ROLESENUM.ADMIN, ROLESENUM.DEVELOPER]}
            component={ApplicationDetails}
          />
          <PrivateRoute
            exact
            path={[URLS.admin, URLS.environment_setup]}
            component={EnvironmentSetup}
          />

          <PrivateRoute
            exact
            allowedRoles={[ROLESENUM.ADMIN]}
            path={URLS.user_administration}
            component={UserAdministration}
          />
          <PrivateRoute
            exact
            allowedRoles={[ROLESENUM.ADMIN]}
            path={URLS.analytics_log}
            component={AnalyticsLog}
          />
          <PrivateRoute
            exact
            allowedRoles={[ROLESENUM.ADMIN, ROLESENUM.DEVELOPER]}
            path={URLS.maps}
            component={MapsManagement}
          />
          <PrivateRoute exact path={URLS.simulator} component={Simulator} />

          <PrivateRoute
            exact
            path={URLS.analytics}
            component={AnalyticsAdmin}
          />

          <RobotWarningModalComponent />

          <TeleoperationComponent />
          <StyledTooltip />
          <RobotActions />
          <LowBatteryModal />
          <EmergencyButtonModal />
        </OutlineAuthorised>
      </RobotsDataProvider>
    </AdminTableProvider>
  );
};

export default AuthorisedComponents;
