import { Directions } from "./FloatingMenu";
import { StyledChildButtonWrapper } from "./components";

const ChildButton = ({
  direction = Directions.Up,
  index = 1,
  size = 40,
  spacing = 0,
  isOpen = false,
  onClick = null,
  icon,
  ...rest
}) => {
  const offsetX =
    direction === "right"
      ? (size + spacing) * index
      : direction === "left"
      ? (size + spacing) * index * -1
      : 0;
  const offsetY =
    direction === "down"
      ? (size + spacing) * index
      : direction === "up"
      ? (size + spacing) * index * -1
      : 0;

  return (
    <StyledChildButtonWrapper
      isOpen={isOpen}
      size={size}
      spacing={spacing}
      direction={direction}
      {...rest}
      onClick={isOpen ? onClick : null}
      style={{
        transform: `translate(${isOpen ? 0 : -offsetX}px, ${
          isOpen ? 0 : -offsetY
        }px)`,
      }}
    >
      {icon}
    </StyledChildButtonWrapper>
  );
};

export default ChildButton;
