import React from "react";
import { database, ref, set } from "../../services/firebase";
import {
  ButtonsWrapper,
  CloseIconWrapper,
  StyledIncomingCallWrapper,
  StyledTitle,
} from "./components";
import ICONS from "../../constants/icons";
import StyledButtonComp from "../styled-button/StyledButton";
import { useSelector } from "react-redux";
import { TASKLOGSTATUSENUM } from "../../enums";
import updateTaskMessage from "../../APIs/tasks-archive/updateTaskMessage";

const Patients = ({
  setOpen,
  setRoomData,
  patientList,
  setIsIncomingCallModal,
  setInCallWith,
  callIsOpen,
  setFilteredPatientList,
}) => {
  const { client } = useSelector((state) => state.clients);

  const handleDeny = (id) => {
    set(ref(database, `requests/${client.name}/` + id), null);
    updateTaskMessage(
      client.name,
      id,
      TASKLOGSTATUSENUM.INFO,
      "Call was denied"
    )
      .then()
      .catch();
  };

  const handleApprove = async (id) => {
    const roomId = "unlimited-robotics-" + Math.floor(Math.random() * 100000);
    const password = Math.floor(Math.random() * 100000);
    setRoomData({
      participantId: id,
      roomId,
      password,
    });
    setOpen(true);

    set(ref(database, `requests/${client.name}/` + id), {
      status: "approved",
      roomId: roomId,
      password: password,
    });
    setInCallWith(id);
    updateTaskMessage(client.name, id, TASKLOGSTATUSENUM.INFO, "Call started")
      .then()
      .catch();
  };

  const hadnleHold = (id) => {
    setFilteredPatientList((prev) => {
      const newList = { ...prev };
      delete newList[id];
      return newList;
    });
  };

  return (
    <StyledIncomingCallWrapper callIsOpen={callIsOpen}>
      <CloseIconWrapper
        onClick={() => {
          setIsIncomingCallModal(false);
        }}
      >
        <img src={ICONS.BlackCross} alt="Close" />
      </CloseIconWrapper>
      <StyledTitle>Incoming Call From</StyledTitle>
      {Object.keys(patientList).map((pat) => (
        <div key={pat}>
          <StyledTitle style={{ fontWeight: "600", marginTop: "20px" }}>
            {pat}
          </StyledTitle>
          <ButtonsWrapper callIsOpen={callIsOpen}>
            {callIsOpen ? (
              <StyledButtonComp
                background={"rgba(6, 134, 216, 0.1)"}
                color={"#0686D8"}
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  marginTop: "10px",
                }}
                onClick={() => hadnleHold(pat)}
              >
                <img src={ICONS.HoldCall} alt="" />
                Hold
              </StyledButtonComp>
            ) : (
              <StyledButtonComp
                background={"rgba(47, 213, 83, 0.1)"}
                color={"#2FD553"}
                style={{ fontSize: "16px", fontWeight: "500", gap: "10px" }}
                onClick={() => handleApprove(pat)}
              >
                <img src={ICONS.AnswerCall} alt="" />
                Answer
              </StyledButtonComp>
            )}
            <StyledButtonComp
              background={"rgba(213, 47, 57, 0.1)"}
              color={"#D52F39"}
              style={{ fontSize: "16px", fontWeight: "500", gap: "10px" }}
              onClick={() => handleDeny(pat)}
            >
              <img src={ICONS.DeclineCall} alt="" />
              Decline
            </StyledButtonComp>
          </ButtonsWrapper>
        </div>
      ))}
    </StyledIncomingCallWrapper>
  );
};

export default Patients;
