import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import {
  EditAssignmentButton,
  EditAssignmentButtonsWrapper,
  StyledChangesContentWrapper,
  StyledContentTitle,
  StyledModalTitleWrapper,
} from "../components";
import {
  LabelText,
  RadioInput,
  StyledRadioLabel,
} from "../../add-assignment-modal/components";
const DeleteTaskRunEveryModal = ({ handleCancel, handleDelete }) => {
  const { deleteTaskData, calendarDate } = useSelector(
    (state) => state.dashboard
  );
  const [onAll, setOnAll] = useState(false);

  const handleSaveTasks = useCallback(
    (e) => {
      if (onAll) handleDelete(deleteTaskData, false, calendarDate);
      else handleDelete(deleteTaskData, true, calendarDate);
    },
    [onAll, deleteTaskData, handleDelete, calendarDate]
  );

  return (
    <StyledChangesContentWrapper className="clickAllow">
      <StyledModalTitleWrapper style={{ alignItems: "flex-start" }}>
        <StyledContentTitle>
          Are You Sure You Want To Delete -{" "}
          <strong>{deleteTaskData?.application?.aliasName}?</strong>
        </StyledContentTitle>
      </StyledModalTitleWrapper>
      <div style={{ marginTop: "36px" }}>
        <StyledRadioLabel style={{ marginTop: "15px" }}>
          <RadioInput
            type="radio"
            name="when"
            value={"Only this event"}
            onChange={(e) => setOnAll(!e.target.checked)}
            checked={!onAll}
          />
          <LabelText style={{ fontSize: "16px" }}>Only this event</LabelText>
        </StyledRadioLabel>
        <StyledRadioLabel style={{ marginTop: "15px" }}>
          <RadioInput
            type="radio"
            name="when"
            value={"This and following events"}
            onChange={(e) => setOnAll(e.target.checked)}
            checked={onAll}
          />
          <LabelText style={{ fontSize: "16px" }}>
            This and following events
          </LabelText>
        </StyledRadioLabel>
      </div>
      <EditAssignmentButtonsWrapper
        style={{ padding: "unset", marginTop: "36px" }}
      >
        <EditAssignmentButton
          background={"rgba(213, 47, 57, 0.1)"}
          color={"#D42F39"}
          onClick={handleSaveTasks}
        >
          Delete
        </EditAssignmentButton>
        <EditAssignmentButton
          background={"#fff"}
          color={"#0686D8"}
          border={"1px solid #0686D8"}
          onClick={handleCancel}
        >
          Cancel
        </EditAssignmentButton>
      </EditAssignmentButtonsWrapper>
    </StyledChangesContentWrapper>
  );
};

export default DeleteTaskRunEveryModal;
