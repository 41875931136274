const sendNotification = (message, icon) => {
  if (document.hidden) {
    const notification = new Notification("Incoming call", {
      icon: icon,
      body: message,
    });
    notification.onclick = (event) => {
      event.preventDefault(); // prevent the browser from focusing the Notification's tab
      window.focus();
    };
  }
};

export const checkPageStatus = (message, icon) => {
  try {
    if (!("Notification" in window)) {
      console.log("This browser does not support system notifications!");
    } else if (Notification.permission === "granted") {
      sendNotification(message, icon);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission((permission) => {
        if (permission === "granted") {
          sendNotification(message, icon);
        }
      });
    }
  } catch (error) {
    console.log("notifications error: ", error);
  }
};
