import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import COLORS from "../../constants/colors";
import STRINGS from "../../constants/strings";
import { ModalContentForm } from "../add-client-modal/components";
import CustomButton from "../button/CustomButton";
import { ModalFooter } from "../modal-outline/components";
import ModalOutline from "../modal-outline/ModalOutline";
import { InputWrapper } from "../input/components";
import InputText from "../input/InputText";
import { ApplicationNameWrapper, TitleWrapper } from "./components";
import { v4 as uuidv4 } from "uuid";
import createApplication from "../../APIs/application/createApplication";
import updateApplication from "../../APIs/application/updateApplication";
import { useState } from "react";
import { setAppsData } from "../../redux/applications";

const AddApplicationModal = ({
  isOpen,
  setIsOpen,
  setNewApplicationForm,
  currentModalData,
  newApplicationForm,
  setApplicationDetails,
}) => {
  const { appsData } = useSelector((state) => state.applications);
  const dispatch = useDispatch();
  const { chosenClient } = useSelector((state) => state.clients);
  const [errorValidations, setErrorValidations] = useState({
    aliasName: "",
    technicalName: "",
    type: "",
  });

  useEffect(() => {
    Object.keys(currentModalData).length &&
      setNewApplicationForm(currentModalData);
  }, [currentModalData, setNewApplicationForm]);

  const handleChange = (inputValue, fieldName) => {
    let obj = { ...newApplicationForm };
    obj[fieldName] = inputValue;
    setNewApplicationForm(obj);
  };

  const handleAddApplication = async (newApplicationForm) => {
    let obj = { ...newApplicationForm, id: uuidv4(), clientId: chosenClient };
    try {
      const response = await createApplication(obj);
      obj.id = response.id;
      dispatch(setAppsData([...appsData, obj]));
      setIsOpen(false);
      setNewApplicationForm({});
      setErrorValidations({});
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleUpdate = async (newApplicationForm) => {
    let obj = { ...newApplicationForm };
    obj.clientId = chosenClient;
    await updateApplication(obj);
    setApplicationDetails && setApplicationDetails(obj);
    dispatch(
      setAppsData(
        appsData.map((item) => (item.id === obj.id ? newApplicationForm : item))
      )
    );
    setIsOpen(false);
    setErrorValidations({});
  };

  const handleSubmit = (currentModalData) => {
    let errors = { aliasName: "", technicalName: "" };
    Object.keys(errors).forEach((key) => {
      // if (key === "type") {
      //   newApplicationForm.parameters.find((item) => !item.type)
      //     ? (errors = {
      //         ...errors,
      //         type: "This field can not be empty",
      //       })
      //     : (errors = {
      //         ...errors,
      //         type: "",
      //       });
      // } else
      if (!newApplicationForm[key]) {
        errors = {
          ...errors,
          [key]: "This field can not be empty",
        };
      } else {
        errors = {
          ...errors,
          [key]: "",
        };
      }
    });
    setErrorValidations(errors);
    //sort parameters by endtime at the last position
    const endTimePar = newApplicationForm.parameters.find(
      (param) => param.type === "endtime"
    );
    const sortedApplication = {
      ...newApplicationForm,
      parameters: endTimePar
        ? [
            ...newApplicationForm.parameters.filter(
              (param) => param.type !== "endtime"
            ),
            endTimePar,
          ]
        : newApplicationForm.parameters,
    };
    //////////////////////////////////////////////////
    if (!Object.values(errors).find((item) => item)) {
      Object.keys(currentModalData).length
        ? handleUpdate(sortedApplication)
        : handleAddApplication(sortedApplication);
    }
  };
  const handleRunTimeChange = (value) => {
    setNewApplicationForm((prev) => ({ ...prev, exp: value }));
  };

  return (
    <ModalOutline
      isOpen={isOpen}
      heading={
        Object.keys(currentModalData).length
          ? STRINGS.edit_application
          : STRINGS.create_new_application
      }
      onRequestClose={() => {
        setErrorValidations({
          aliasName: "",
          technicalName: "",
        });
        setIsOpen(false);
      }}
      buttonText={STRINGS.create}
      newApplicationForm={newApplicationForm}
      // newClientForm={newClientForm}
      //   onSubmit={submitForm}
    >
      <ModalContentForm style={{ gap: "13px", minWidth: "915px" }}>
        <ApplicationNameWrapper>
          <InputWrapper>
            <InputText
              width="100%"
              height="6.4rem"
              borderColor={COLORS.secondary.water_green}
              placeholder={STRINGS.alias_name}
              handleChange={(e) => handleChange(e, "aliasName")}
              name={STRINGS.alias_name}
              value={newApplicationForm.aliasName || ""}
              error={errorValidations.aliasName}
              backgroundColor="#fff"
            />
          </InputWrapper>
          <InputWrapper>
            <InputText
              width="100%"
              height="6.4rem"
              borderColor={COLORS.secondary.water_green}
              placeholder={STRINGS.technical_name}
              handleChange={(e) => handleChange(e, "technicalName")}
              name={STRINGS.technical_name}
              value={newApplicationForm.technicalName || ""}
              error={errorValidations.technicalName}
              backgroundColor="#fff"
            />
          </InputWrapper>
        </ApplicationNameWrapper>
        <TitleWrapper style={{ gap: "20px" }}>
          <InputText
            width="15rem"
            height="6.4rem"
            type="number"
            padding="0 2.0rem"
            borderColor={COLORS.secondary.water_green}
            placeholder={"Max Run-Time"}
            handleChange={(e) => handleRunTimeChange(e)}
            name={"Max Run-Time"}
            value={newApplicationForm.exp || ""}
            //   error={touched[field.name] && errors[field.name]}
            backgroundColor="#fff"
          />
        </TitleWrapper>
      </ModalContentForm>
      <ModalFooter>
        <CustomButton
          backgroundColor={COLORS.secondary.ncs}
          type="submit"
          capitalize
          onClick={() => handleSubmit(currentModalData)}
        >
          {Object.keys(currentModalData).length
            ? STRINGS.update_client
            : STRINGS.create}
        </CustomButton>
      </ModalFooter>
    </ModalOutline>
  );
};

export default AddApplicationModal;
