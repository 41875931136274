import React from "react";
import HeaderComponent from "./HeaderComponent";
import ManagementTableComponent from "./ManagementTableComponent";
import { useDispatch, useSelector } from "react-redux";
import { setRoleFilter } from "../../redux/userManagement";

const UserManagementTable = () => {
  const dispatch = useDispatch();
  const { usersData, roleFilter } = useSelector(
    (state) => state.userManagement
  );
  const { client } = useSelector((state) => state.clients);
  const headerComponents = ["All", "Manager", "User", "Viewer"];

  return (
    <React.Fragment>
      <div style={{ display: "flex", marginTop: "50px" }}>
        {headerComponents.map((item) => (
          <HeaderComponent
            key={item}
            name={item}
            quantity={
              usersData.filter((user) => item === "All" || user.role === item)
                .length
            }
            isChosen={roleFilter === item}
            onClick={() => dispatch(setRoleFilter(item))}
          />
        ))}
      </div>

      <ManagementTableComponent chosenClient={client} />
    </React.Fragment>
  );
};

export default UserManagementTable;
