import React, { useEffect } from "react";
import Modal from "react-modal";
import sendWarningResponse from "../../APIs/tasks-archive/warningResponse";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";
import MapComponent from "../../pages/robots/MapComponent";
import {
  EditAssignmentButtonsWrapper,
  EditAssignmentButton,
} from "../agenda-assignment-details-modal/components";
import {
  CloseIconWrapper,
  ModalContentWrapper,
  ModalWarningHeader,
  ModalWarningText,
  ModalWarningTitle,
} from "./components";
import useMaps from "../../hooks/useMaps";

const customStyles = {
  overlay: {
    backgroundColor: `${COLORS.primary.oxford}80`,
    zIndex: "111",
  },
  content: {
    zIndex: "111",
    width: "40%",
    height: "fit-content",
    top: "50%",
    left: "50%",
    right: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    border: "3px solid #d4b02f",
    padding: "0 ",
  },
};

const RobotTaskWarningModal = ({
  isOpen,
  task,
  setResponseModal,
  setIsWarningModal,
}) => {
  // eslint-disable-next-line no-unused-vars
  const _ = useMaps({ onlyRobotId: task?.robot?.id });

  useEffect(() => {
    let timeout;
    if (isOpen) {
      timeout = setTimeout(() => {
        //api call await
        setIsWarningModal(false);
        setResponseModal({
          isOpen: true,
          type: "aborted",
        });
      }, 45000);
    }
    return () => {
      if (isOpen) {
        clearTimeout(timeout);
      }
    };
  }, [isOpen, task, setResponseModal, setIsWarningModal]);

  const handleConfirm = async () => {
    sendWarningResponse(task.id, {
      message: "Ok, coming",
      app_id: task?.app_id,
      command: task?.command,
    });
    setResponseModal({ isOpen: true, type: "success" });
  };

  const handleAbortTask = async () => {
    sendWarningResponse(task.id, {
      message: "Abort task",
      app_id: task?.app_id,
      command: task?.command,
    });
    setResponseModal({ isOpen: true, type: "aborted" });
  };
  return (
    <Modal
      onRequestClose={() => {}}
      isOpen={!!isOpen}
      style={customStyles}
      ariaHideApp={false}
    >
      <ModalContentWrapper>
        <ModalWarningHeader>
          <CloseIconWrapper onClick={() => handleAbortTask()}>
            <img src={ICONS.BlackCross} alt="X" />
          </CloseIconWrapper>
          <img
            src={ICONS.WarningHexagonIcon}
            alt="Warning"
            style={{ marginTop: "12px" }}
          />
          <ModalWarningTitle>{task?.title}</ModalWarningTitle>
        </ModalWarningHeader>

        <ModalWarningText>{task?.message}</ModalWarningText>
        <div style={{ margin: "36px 0px" }}>
          <MapComponent
            mapWidth={516}
            mapHeight={386}
            pointWidth={0}
            robotWidth={20}
            showToolTip={false}
            onlyRobotId={task?.robot?.id}
          />
        </div>

        <EditAssignmentButtonsWrapper
          style={{ padding: "unset", marginTop: "32px", width: "100%" }}
        >
          <EditAssignmentButton
            background={"rgba(6, 134, 216, 0.1)"}
            color={"#0686D8"}
            style={{ fontSize: "16px", fontWeight: "500" }}
            onClick={handleConfirm}
          >
            Ok, coming
          </EditAssignmentButton>
          <EditAssignmentButton
            background={"#fff"}
            color={"#0686D8"}
            border={"1px solid #0686D8"}
            onClick={handleAbortTask}
          >
            Abort task
          </EditAssignmentButton>
        </EditAssignmentButtonsWrapper>
      </ModalContentWrapper>
    </Modal>
  );
};

export default RobotTaskWarningModal;
