import styled from "styled-components";

export const StyledNumberWidgetWrapper = styled.div`
  display: flex;
  padding: 10px 50px;
  justify-content: center;
  align-items: center;
  background-color: #0686d81a;
  font-size: ${({ isMobile }) => (isMobile ? "80px" : "120px")};
  color: #0686d8;
  border-radius: 16px;
  font-weight: 700;
`;

export const CustomLegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: ${({ isMobile }) => (isMobile ? "100px" : "170px")};
  gap: 0 20px;
`;
