import styled from "styled-components";

export const StyledIconWrapper = styled.div`
  display: flex;
  text-decoration: none;
  -webkit-transition: -webkit-transform 300ms;
  transition: transform 300ms;
  -webkit-transform: ${({ isOpen }) => `rotate(${isOpen ? 180 : 0}deg)`};
  transform: ${({ isOpen }) => `rotate(${isOpen ? 180 : 0}deg)`};
  font-weight: 100;
  font-size: 30px;
  color: #fff;
  justify-content: center;
  align-items: center;

  margin-top: ${({ isOpen }) => (isOpen ? "5px" : 0)};
  margin-bottom: ${({ isOpen }) => (isOpen ? 0 : "5px")};
`;

export const StyledChildButtonWrapper = styled.li`
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  border: none;
  cursor: pointer;
  outline: none;
  -webkit-user-drag: none;
  font-weight: bold;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: ${({ slideSpeed }) => `all ${slideSpeed}ms`};
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  margin-bottom: 15px;
  pointer-events: ${({ isOpen }) => (isOpen ? "auto" : "none")};

  padding: 8px;
  border-radius: 24px;
  border: 1px solid #0096c6;
  background: #fff;
  margin-left: auto;
`;

export const DIRECTIONS = {
  up: "column-reverse",
  down: "column",
  left: "row-reverse",
  right: "row",
};

export const StyledFloatingMenuUL = styled.ul`
  display: flex;
  width: fit-content;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction: ${({ direction }) => DIRECTIONS[direction]};
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 25px;
  right: 25px;
  z-index: 2;

  > li {
    display: ${({ hideList }) => (hideList ? "none" : "")};
  }
`;
