export const pnpoly = (nvert, vertx, verty, testx, testy) => {
  let i,
    j,
    c = false;
  for (i = 0, j = nvert - 1; i < nvert; j = i++) {
    let l = verty[i] > testy;
    let r = verty[j] > testy;
    if (
      l !== r &&
      testx <
        ((vertx[j] - vertx[i]) * (testy - verty[i])) / (verty[j] - verty[i]) +
          vertx[i]
    ) {
      c = !c;
    }
  }
  return c;
};

export const pointInCircle = (x, y, cx, cy, radius) => {
  let distancesquared = (x - cx) * (x - cx) + (y - cy) * (y - cy);
  return distancesquared <= radius * radius;
};
