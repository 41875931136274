import React, { useContext, useEffect, useState } from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { ResponsiveContext } from "../../../../contexts/ResponsiveContext";
const ChartWidget = ({ analytics }) => {
  const { isMobile } = useContext(ResponsiveContext);

  const [pieData, setPieData] = useState([]);

  useEffect(() => {
    const data = Object.entries(analytics.pieData).map(([name, value]) => {
      return {
        name,
        value: value * 1,
      };
    });
    setPieData(data);
  }, [analytics]);

  return (
    <ResponsiveContainer width={400} height={isMobile ? 160 : 200}>
      <BarChart width={500} height={300} data={pieData}>
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="value" stackId="a" fill="#0686D8" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ChartWidget;
