import styled from "styled-components";
import ICONS from "../../constants/icons";

export const StyledSearchableSelect = styled.div`
  appearance: none;
  background-image: ${(props) =>
    props.showOptions ? `url(${ICONS.ArrowUp})` : `url(${ICONS.ArrowDown})`};
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: ${(props) => (props.isMobile ? "14px" : "18px")};
  color: ${(props) => props.color || "#030a1b"};
  font-size: 16px;
  border: ${(props) =>
    props.opened ? "2px solid #0096C6" : "2px solid #c4c4c4"};
  width: 100%;
  height: ${(props) => (props.isMobile ? "35px" : "50px")};
  border-radius: 16px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SearchableSelectText = styled.div`
  width: 100%;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
`;

export const SearchableOptionsWrapper = styled.div`
  position: absolute;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 16px 0px;
  margin-top: 1px;
  z-index: 999;
  border-radius: 16px;
  border: 2px solid rgba(196, 196, 196, 1);
`;

export const SearchOptionsInput = styled.input`
  border: unset;
`;

export const StyledSearchableOption = styled.div`
  color: #030a1b;
  background: unset;
  padding: 8px;
  font-size: 15px;
  display: flex;
  cursor: pointer;
  :hover {
    color: #0686d8;
    background: rgba(6, 134, 216, 0.1);
  }
`;
