export const populateSharedZones = (data, name = "sharedZones") => {
  let zones = data[name].map((item) => item.pixelCoordinates);
  const zoneIds = data[name].map((area) => area.id);
  const zoneNames = data[name].map((area) => area.name);
  zones = zones.map((item) => item.map((nest) => [nest[0], nest[1]]));

  let zonesObject = {};

  zones.forEach((element, index) => {
    zonesObject[`Polygon_${name}_${zoneNames[index]}_${zoneIds[index]}`] =
      element;
  });
  return zonesObject;
};

export const populateWorkingAreas = (data, name = "workingAreas") => {
  let zones = data[name].map((item) => item.pixelCoordinates);
  let robotNames = data[name].map((area) => area.robot.id);
  const zoneIds = data[name].map((area) => area.id);
  const zoneNames = data[name].map((area) => area.name);
  zones = zones.map((item) => item.map((nest) => [nest[0], nest[1]]));

  let zonesObject = {};

  zones.forEach((element, index) => {
    zonesObject[
      `Polygon_${name}_${zoneNames[index]}_${robotNames[index]}_${zoneIds[index]}`
    ] = element;
  });
  return zonesObject;
};

export const populateSharedZone = (data, name = "sharedZones") => {
  const { pixelCoordinates, id, name: zoneName } = data;

  const zone = pixelCoordinates.map((nest) => [nest[0], nest[1]]);

  let zoneObject = {};

  zoneObject[`Polygon_${name}_${zoneName}_${id}`] = zone;
  return zoneObject;
};

export const populateWorkingArea = (data, robotId, name = "workingAreas") => {
  const { pixelCoordinates, id, name: zoneName } = data;

  const zone = pixelCoordinates.map((nest) => [nest[0], nest[1]]);

  let zoneObject = {};

  zoneObject[`Polygon_${name}_${zoneName}_${robotId}_${id}`] = zone;
  return zoneObject;
};
