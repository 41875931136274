import styled from "styled-components";

export const StyledTermsFooter = styled.div``;

export const StyledTermsContentTitle = styled.h3`
  font-size: 24px;
  line-height: 28px;
  width: 100%;
`;

export const StyledTermsContentWrapper = styled.div``;

export const TermsWrapper = styled.ul`
  overflow: auto;
  scroll-behavior: smooth; /* Enable smooth scrolling */
`;

export const StyledTerm = styled.p``;
