import React, { createContext, useMemo, useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";

const BEFORE_DESKTOP_WIDTH = 1024;

export const ResponsiveContext = createContext(null);

export const ResponsiveProvider = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isIos, setIsIos] = useState(false);

  const [isBeforeDesktop, setisBeforeDesktop] = useState(
    window.innerWidth <= BEFORE_DESKTOP_WIDTH
  );
  const [respSidebarOpen, setRespSidebarOpen] = useState(false);

  const toggleRespSidebar = useCallback(
    () => setRespSidebarOpen((curr) => !curr),
    [setRespSidebarOpen]
  );

  useEffect(() => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // return "iOS";
      setIsIos(true);
    }

    const resize = (e) => {
      setWidth(window.innerWidth);
      setisBeforeDesktop(e.target.innerWidth <= BEFORE_DESKTOP_WIDTH);
    };
    window.addEventListener("resize", resize);

    return () => window.removeEventListener("resize", resize);
  }, []);
  const isMobile = width <= 760;
  const isTablet = width <= 1300 && width > 760;
  const isDesktop = width > 1300;
  const isLandscape = window.matchMedia("(orientation: landscape)").matches;

  const contextValue = useMemo(
    () => ({
      isMobile,
      isTablet,
      isDesktop,
      isLandscape,
      isBeforeDesktop,
      respSidebarOpen,
      isIos,
      toggleRespSidebar,
    }),
    [
      isMobile,
      isTablet,
      isDesktop,
      isLandscape,
      isBeforeDesktop,
      respSidebarOpen,
      isIos,
      toggleRespSidebar,
    ]
  );

  return (
    <ResponsiveContext.Provider value={contextValue}>
      {children}
    </ResponsiveContext.Provider>
  );
};
