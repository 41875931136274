import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  analyticsConfigurationData: [],
  widgetModalData: null,
  analyticsData: [],
  triggerAnalyticsRefetch: false,
  eventNames: [],
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setAnalyticsConfigurationData: (state, action) => {
      state.analyticsConfigurationData = action.payload;
    },
    setWidgetModalData: (state, action) => {
      state.widgetModalData = action.payload;
    },
    setAnalyticsData: (state, action) => {
      state.analyticsData = action.payload;
    },
    setEventNames: (state, action) => {
      state.eventNames = action.payload;
    },
    setTriggerAnalyticsRefetch: (state) => {
      state.triggerAnalyticsRefetch = !state.triggerAnalyticsRefetch;
    },
  },
});

export const {
  setAnalyticsConfigurationData,
  setWidgetModalData,
  setAnalyticsData,
  setTriggerAnalyticsRefetch,
  setEventNames,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;
