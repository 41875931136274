import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddApplicationModal from "../../../components/add-application-modal/AddApplicationModal";
import ICONS from "../../../constants/icons";
import STRINGS from "../../../constants/strings";
import {
  ClientsTable,
  ComponentHeader,
  ComponentTitle,
  ComponentWrapper,
  EditItem,
  HeaderStyledButton,
  StyledEditBox,
  StyledEditIcon,
  TableHeader,
  TableHeaderItems,
  TableRow,
  TableRowItem,
  VertMenuWrapper,
  ParametersWrapper,
  MultiplyWrapper,
  StyledLabel,
} from "../environment-setup/components";
import { v4 as uuidv4 } from "uuid";
import { InfoModalTypes } from "../../../constants/types";
import InfoModal from "../../../components/info-modal/InfoModal";
import { useMutation } from "react-query";
import getApplications from "../../../APIs/application/getApplications";
import deleteApplication from "../../../APIs/application/deleteApplication";
import updateApplication from "../../../APIs/application/updateApplication";
import { setToastMessages } from "../../../redux/toast";
import { Link } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { setAppsData } from "../../../redux/applications";

const ApplicationTable = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [newApplicationForm, setNewApplicationForm] = useState({});
  const [itemToDelete, setItemToDelete] = useState();
  const { chosenClient } = useSelector((state) => state.clients);
  const { appsData } = useSelector((state) => state.applications);
  const headerArr = [
    STRINGS.alias_name,
    STRINGS.technical_name,
    STRINGS.parameter_name,
    STRINGS.parameter,
  ];
  const handleEdit = (item) => {
    setCurrentModalData(item);
    setIsOpen(true);
  };

  const handleDelete = async (id) => {
    const filteredArr = appsData.filter((item) => {
      return item.id !== id;
    });

    try {
      await deleteApplication(id);
      dispatch(setAppsData(filteredArr));
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleAddNewApplication = () => {
    setIsOpen(true);
    setNewApplicationForm({
      aliasName: "",
      technicalName: "",
      exp: 60,
      parameters: [
        {
          id: uuidv4(),
          parameter_name: "location",
          parameter: "target_goal",
          type: "location",
        },
      ],
    });
    setCurrentModalData({});
  };

  const handleChooseApp = async (data) => {
    let count = 0;
    appsData.forEach((item) => {
      item.shortcut && count++;
    });
    if ((count === 3 && data.shortcut) || count < 3) {
      await updateApplication({ ...data, shortcut: !data.shortcut });
      dispatch(
        setAppsData(
          appsData.map((item) =>
            item.id === data.id ? { ...item, shortcut: !item.shortcut } : item
          )
        )
      );
    } else {
      dispatch(
        setToastMessages({
          text: "You can't choose more than 3 application",
          type: "error",
        })
      );
    }
  };

  const { mutate: getApplicationsMutation } = useMutation(
    "get-applications",
    () => getApplications(chosenClient)
  );
  const getApplicationsFunc = useCallback(() => {
    getApplicationsMutation(null, {
      onSuccess: (applications) => {
        dispatch(setAppsData(applications));
      },
      onError: (err) => console.log("Error", err),
    });
  }, [getApplicationsMutation, dispatch]);

  useEffect(() => {
    chosenClient && getApplicationsFunc();
  }, [chosenClient, getApplicationsFunc]);

  return (
    <React.Fragment>
      <ComponentWrapper>
        <ComponentHeader>
          <ComponentTitle>{`${STRINGS.application}`}</ComponentTitle>
          <HeaderStyledButton
            onClick={handleAddNewApplication}
            style={{ width: "230px" }}
          >{`${STRINGS.add_new_application}`}</HeaderStyledButton>
        </ComponentHeader>
        <AddApplicationModal
          newApplicationForm={newApplicationForm}
          setNewApplicationForm={setNewApplicationForm}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          currentModalData={currentModalData}
        />
        <ClientsTable>
          <TableHeader>
            {headerArr?.map((item, index) => (
              <TableHeaderItems key={index}>{item}</TableHeaderItems>
            ))}
          </TableHeader>
          {appsData?.map((item, index) => (
            <Link to={`/admin/applications/${item.id}`}>
              <TableRow key={index}>
                <TableRowItem
                  style={{ justifyContent: "space-between", display: "flex" }}
                >
                  <StyledLabel>
                    <input
                      style={{ width: "20px" }}
                      type="checkbox"
                      onChange={() => handleChooseApp(item)}
                      checked={item.shortcut}
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </StyledLabel>
                  <div style={{ width: "66%" }}>{item.aliasName}</div>
                </TableRowItem>
                <TableRowItem>{item.technicalName}</TableRowItem>
                <ParametersWrapper>
                  {item.parameters?.map((element) => (
                    <MultiplyWrapper key={element.id}>
                      <TableRowItem>{element.parameter_name}</TableRowItem>
                      <TableRowItem>{element.parameter}</TableRowItem>
                    </MultiplyWrapper>
                  ))}
                </ParametersWrapper>

                <VertMenuWrapper>
                  <img
                    src={ICONS.MenuVertical}
                    alt="edit"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowEdit((prev) => !prev && item.id);
                    }}
                  />
                  {showEdit === item.id && (
                    <React.Fragment>
                      <OutsideClickHandler
                        onOutsideClick={() => setShowEdit(false)}
                      >
                        <StyledEditBox>
                          <EditItem
                            onClick={(e) => {
                              e.preventDefault();
                              setShowEdit(false);
                              handleEdit(item);
                            }}
                          >
                            <StyledEditIcon
                              src={ICONS.PencilDraw}
                              alt="Pencil"
                            />
                            Edit
                          </EditItem>
                          <EditItem
                            onClick={(e) => {
                              e.preventDefault();
                              setIsDeleteModal(true);
                              setItemToDelete(item);
                            }}
                          >
                            <StyledEditIcon
                              src={ICONS.DeleteIcon}
                              alt="DeleteIcon"
                            />
                            Delete
                          </EditItem>
                        </StyledEditBox>
                      </OutsideClickHandler>
                    </React.Fragment>
                  )}
                </VertMenuWrapper>
              </TableRow>
            </Link>
          ))}
        </ClientsTable>
      </ComponentWrapper>
      <InfoModal
        isOpen={isDeleteModal}
        setIsDeleteModal={setIsDeleteModal}
        type={InfoModalTypes.ATTENTION}
        itemId={itemToDelete?.id}
        title="Are you sure to delete ?"
        // content="We are processing your information. Please be patient, it might take a while."
        noOuterClickClosing
        cancelAction={() => {
          setIsDeleteModal(false);
          setShowEdit(false);
        }}
        submitAction={() => {
          handleDelete(itemToDelete?.id);
          setIsDeleteModal(false);
          setShowEdit(false);
        }}
        cancelText={"No"}
        submitText={"Yes"}
        disabled={false}
      />
    </React.Fragment>
  );
};

export default ApplicationTable;
