import React from "react";
import {
  EditAssignmentButton,
  EditAssignmentButtonsWrapper,
  StyledChangesContentWrapper,
  StyledContentTitle,
  StyledModalTitleWrapper,
} from "../components";
import { useSelector } from "react-redux";
import stopAssignment from "../../../APIs/tasks-archive/stopAssignmnet";

const StopAssignmentModal = ({ handleCancel }) => {
  const { stopAssignmentData } = useSelector((state) => state.dashboard);
  const handleStopAssignment = async () => {
    const firstTask = stopAssignmentData.tasks[0];

    // returns array of updated assignment tasks
    await stopAssignment(firstTask?.launchId);
    handleCancel();
  };

  return (
    <StyledChangesContentWrapper className="clickAllow">
      <StyledModalTitleWrapper>
        <StyledContentTitle>
          Are You Sure You Want To Stop -{" "}
          <strong>{stopAssignmentData?.name}?</strong>
        </StyledContentTitle>
      </StyledModalTitleWrapper>
      <EditAssignmentButtonsWrapper style={{ marginTop: "20px" }}>
        <EditAssignmentButton
          background={"rgba(213, 47, 57, 0.1)"}
          color={"#D42F39"}
          onClick={handleStopAssignment}
        >
          Stop
        </EditAssignmentButton>
        <EditAssignmentButton
          background={"#fff"}
          color={"#0686D8"}
          border={"1px solid #0686D8"}
          onClick={handleCancel}
        >
          Cancel
        </EditAssignmentButton>
      </EditAssignmentButtonsWrapper>
    </StyledChangesContentWrapper>
  );
};

export default StopAssignmentModal;
