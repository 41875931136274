import React, { useState } from "react";
import ManagementTableHeader from "./ManagementTableHeader";
import { RowWrapper, StyledP, StyledTableBox } from "./components";
import { EditBoxWrapper } from "../agenda-assignment-details-modal/components";
import EditBox from "../edit-box/EditBox";
import { useDispatch, useSelector } from "react-redux";
import { setDeleteUserData, setEditUserData } from "../../redux/userManagement";
import moment from "moment/moment";
import { useEffect } from "react";

const ManagementTableComponent = ({ chosenClient, userAdministrationPage }) => {
  const { usersData, roleFilter } = useSelector(
    (state) => state.userManagement
  );
  const [usersList, setUsersList] = useState([]);
  const { client } = useSelector((state) => state.clients);
  const dispatch = useDispatch();

  useEffect(() => {
    const filteredData = usersData.filter(
      (item) =>
        ((roleFilter === "All" || item.role === roleFilter) &&
          Object.keys(item.roles).includes(chosenClient?.id)) ||
        item.roles[chosenClient?.id] === roleFilter
    );

    const data = userAdministrationPage
      ? filteredData
      : filteredData.filter((el) => el.roles[chosenClient.id] !== "Developer");
    setUsersList(data);
  }, [usersData, chosenClient, roleFilter, userAdministrationPage]);

  return (
    <div style={{ width: "100%" }}>
      <ManagementTableHeader />
      {usersList.map((item) => (
        <RowWrapper style={{ background: "#fff" }} key={item.id}>
          <StyledTableBox
            style={{
              color: "#030A1B",
              width: "18%",
            }}
          >
            <StyledP>{item.firstName + " " + item.lastName}</StyledP>
          </StyledTableBox>
          <StyledTableBox
            style={{
              color: "#030A1B",
              width: "24%",
            }}
          >
            <StyledP>{item.email}</StyledP>
          </StyledTableBox>
          <StyledTableBox
            style={{
              color: "#030A1B",
              width: "18%",
            }}
          >
            <StyledP>{item.phone}</StyledP>
          </StyledTableBox>
          <StyledTableBox
            style={{
              color: "#030A1B",
              width: "12%",
            }}
          >
            <StyledP
              style={{
                border: "1px solid #E5EAE7",
                borderRadius: "8px",
                padding: "10px 24px",
                justifyContent: "center",
              }}
            >
              {chosenClient?.id
                ? item?.roles[chosenClient.id]
                : item?.roles[client.id]}
            </StyledP>
          </StyledTableBox>
          <StyledTableBox
            style={{
              color: "#030A1B",
              width: "18%",
            }}
          >
            <StyledP>
              {item.verified && moment(item.activationDate).fromNow()}
            </StyledP>
          </StyledTableBox>
          <EditBoxWrapper
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "unset",
              width: "10%",
            }}
          >
            <EditBox
              handleEdit={() =>
                dispatch(
                  setEditUserData({
                    ...item,
                    chosenClient: chosenClient || client,
                  })
                )
              }
              handleDelete={() => dispatch(setDeleteUserData(item))}
            />
          </EditBoxWrapper>
        </RowWrapper>
      ))}
    </div>
  );
};

export default ManagementTableComponent;
