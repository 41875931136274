export const locationToAppData = (location) => {
  let data = {
    x: 0,
    y: 0,
    angle: 0,
    map_name: "default",
    user_id: null,
    name: location?.name,
  };

  if (location?.pixelCoordinates) {
    const [x, y, angle] = location.pixelCoordinates;
    data.x = x;
    data.y = y;
    data.angle = angle;
  }
  if (location?.owners?.length) {
    data.user_id = location.owners[0].id;
  }
  if (location?.map) {
    const { floor, building } = location.map;
    data.map_name = building + "__" + floor;
  }
  return data;
};
