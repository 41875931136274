import React from "react";
import {
  LabelText,
  RadioInput,
  RadioTitle,
  RadioWrapper,
  StyledAfterDate,
  StyledEndTimeDate,
  StyledRadioLabel,
} from "./components";
import OutsideClickHandler from "react-outside-click-handler";
import Calendar from "react-calendar";
import { useState } from "react";
import moment from "moment";

const AssignmentEndTime = ({ newAssignmentForm, setNewAssignmentForm }) => {
  const [afterValue, setAfterValue] = useState("7 days");
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarValue, setCalendarValue] = useState();
  const handleEndTimeType = (type) => {
    if (type === "Never") {
      setNewAssignmentForm((prev) => ({ ...prev, endTime: null }));
    }
    if (type === "After") {
      setCalendarValue(
        moment(new Date())
          .add(afterValue.split(" ")[0], afterValue.split(" ")[1])
          .format()
      );
      setNewAssignmentForm((prev) => ({
        ...prev,
        endTime: moment(new Date())
          .add(afterValue.split(" ")[0], afterValue.split(" ")[1])
          .format(),
      }));
    }
    if (type === "On") {
      handleCalendarChange(calendarValue);
    }
    setNewAssignmentForm((prev) => ({ ...prev, endTimeType: type }));
  };
  const handleCalendarChange = (value) => {
    setCalendarValue(value);
    setNewAssignmentForm((prev) => ({ ...prev, endTime: value || new Date() }));
    setShowCalendar(false);
  };
  const handleAfterSelect = (e) => {
    setAfterValue(e.target.value);
    const value = e.target.value.split(" ");
    setCalendarValue(moment(new Date()).add(value[0], value[1]).format());
    setNewAssignmentForm((prev) => ({
      ...prev,
      endTime: moment(new Date()).add(value[0], value[1]).format(),
    }));
  };
  return (
    <RadioWrapper style={{ margin: "0 0 15px 0" }}>
      <RadioTitle style={{ fontSize: "17px" }}>Ends:</RadioTitle>
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          justifyContent: "space-between",
        }}
      >
        <StyledRadioLabel>
          <RadioInput
            style={{ width: "14px", height: "14px" }}
            type="radio"
            name="endTime"
            value={"Never"}
            checked={
              newAssignmentForm.endTimeType === "Never" ||
              (!newAssignmentForm.endTime && !newAssignmentForm.endTimeType)
            }
            onChange={() => handleEndTimeType("Never")}
          />
          <LabelText style={{ fontSize: "14px" }}>Never</LabelText>
        </StyledRadioLabel>
        <div style={{ display: "flex", gap: "7px", position: "relative" }}>
          <StyledRadioLabel style={{ marginLeft: "20px" }}>
            <RadioInput
              style={{ width: "14px", height: "14px" }}
              type="radio"
              name="endTime"
              checked={
                newAssignmentForm?.endTimeType === "On" ||
                (newAssignmentForm?.endTime &&
                  newAssignmentForm?.endTimeType !== "After")
              }
              value={"On"}
              onChange={() => handleEndTimeType("On")}
            />
            <LabelText style={{ fontSize: "14px" }}>On</LabelText>
          </StyledRadioLabel>
          <StyledEndTimeDate
            disabled={
              !newAssignmentForm?.endTime ||
              newAssignmentForm?.endTimeType === "After"
            }
            onClick={() =>
              newAssignmentForm?.endTimeType === "On" && setShowCalendar(true)
            }
          >
            {moment(newAssignmentForm?.endTime || new Date()).format(
              "MMM D, YYYY"
            )}
          </StyledEndTimeDate>
          {showCalendar && (
            <div
              style={{
                position: "absolute",
                zIndex: "9999",
                transform: "translate(0% , -100%)",
              }}
            >
              <OutsideClickHandler
                onOutsideClick={() => setShowCalendar(false)}
              >
                <Calendar
                  onChange={(value) => handleCalendarChange(value)}
                  value={new Date()}
                />
              </OutsideClickHandler>
            </div>
          )}
        </div>
        <div style={{ display: "flex", gap: "7px" }}>
          <StyledRadioLabel style={{ marginLeft: "20px" }}>
            <RadioInput
              style={{ width: "14px", height: "14px" }}
              type="radio"
              name="endTime"
              checked={newAssignmentForm?.endTimeType === "After"}
              value={"After"}
              onChange={() => handleEndTimeType("After")}
            />
            <LabelText style={{ fontSize: "14px" }}>After</LabelText>
          </StyledRadioLabel>
          <StyledAfterDate
            disabled={newAssignmentForm?.endTimeType !== "After"}
            value={afterValue}
            onChange={(e) => handleAfterSelect(e)}
          >
            <option value={"7 days"}>One Week</option>
            <option value={"14 days"}>Two Weeks</option>
            <option value={"1 months"}>One Month</option>
            <option value={"3 months"}>One Quarter</option>
          </StyledAfterDate>
        </div>
      </div>
    </RadioWrapper>
  );
};

export default AssignmentEndTime;
