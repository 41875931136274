import { useState } from "react";
import Canvas from "./DrawCanvasFunctional";
import {
  BottomButtons,
  ButtonSZ,
  ButtonCH,
  ButtonWA,
  ButtonLO,
  DrawcanvasWrapper,
  StyledCanvasWrapper,
  StyledEditZoneWrapper,
  StyledDeleteIcon,
  ButtonRO,
  StyledCanvasComponentWrapper,
  StyledRoutesWrapper,
  StyledrouteComponentWrapper,
} from "./components";
import { MAPLOCATIONENUM, ZONEIDENUM } from "../../enums";
import UploadMapImage from "./UploadMapImage";
import ICONS from "../../constants/icons";
import InfoModal from "../../components/info-modal/InfoModal";
import { InfoModalTypes } from "../../constants/types";
import { useDrawCanvas } from "./useDrawCanvas";
import AddLocationModal from "../../components/add-location-modal/AddLocationModal";
import InputModal from "../../components/Input-modal/InputModal";
import { useSelector } from "react-redux";

const DrawCanvas = ({
  setCanvasAreas,
  noImage,
  handleFetch,
  visibleCanvasItems,
  highlightRoute,
  determineRobotNameWithWorkignArea,
  showRobots,
  chosenRobot,
  robots,
}) => {
  const { chosenMap } = useSelector((state) => state.maps);
  const [currentModalData, setCurrentModalData] = useState({
    name: "",
    direction: "",
    x: "",
    y: "",
  });
  const [newLocationForm, setNewLocationForm] = useState({
    name: "",
    x: 0,
    y: 0,
    location: [0, 0],
    direction: 0,
  });

  const [inputModalData, setInputModalData] = useState({
    isOpen: false,
    callback: () => {},
    title: "",
    robots: null,
    name: "",
    chosenRobotId: null,
  });

  const {
    locationType,
    canvasDimensions,
    chosenZone,
    addCheckpointText,
    deleteModal,
    tool,
    routeData,
    setDeleteModal,
    handleAddEntry,
    handleFinish,
    handleZoneClick,
    handleZoneUpdate,
    handleZoneDelete,
    handleMapDelete,
    finishRouting,
    isLocationModal,
    setIsLocationModal,
    handleAddLocation,
    setChosenZone,
    visibleCanvasRoutes,
  } = useDrawCanvas({
    setCanvasAreas,
    chosenMap,
    handleFetch,
    setCurrentModalData,
    newLocationForm,
    setInputModalData,
    robots,
    visibleCanvasItems,
  });

  const handleRobotHover = (route) => {
    highlightRoute(route);
  };
  const handleMouseLeave = () => {
    highlightRoute(null);
  };

  return (
    <DrawcanvasWrapper>
      <StyledCanvasComponentWrapper>
        {visibleCanvasRoutes?.length ? (
          <StyledRoutesWrapper
            maxHeight={canvasDimensions && canvasDimensions[1]}
          >
            {visibleCanvasRoutes.map((route, index) => (
              <StyledrouteComponentWrapper
                onMouseEnter={() => handleRobotHover(route)}
                onMouseLeave={() => handleMouseLeave()}
                onClick={() => setChosenZone(route)}
                key={"StyledrouteComponentWrapper" + index}
              >
                {route.startZone.type === "workingAreas" && (
                  <h2>
                    Working area -{" "}
                    {determineRobotNameWithWorkignArea(route.startZone.id)}
                  </h2>
                )}
                <ol>
                  <li>
                    Start: {route.startZone.type} - {route.startZone.name}
                  </li>
                  {route.road.slice(0, -1).map((road, index) => (
                    <li key={"pointList" + index}>
                      {road.type
                        ? road.type + " - " + road.name
                        : "No point Type"}
                    </li>
                  ))}
                  <li>
                    End: {route.endZone.type} - {route.endZone.name}
                  </li>
                </ol>
              </StyledrouteComponentWrapper>
            ))}
          </StyledRoutesWrapper>
        ) : null}
        <div
          style={{ position: "relative", background: "#fff", padding: "30px" }}
        >
          {canvasDimensions ? (
            <StyledCanvasWrapper>
              <Canvas
                imgSrc={chosenMap?.editedMapUrl}
                width={canvasDimensions[0]}
                height={canvasDimensions[1]}
                tool={tool}
                // onDataUpdate={handleDataChange}
                onFinishDraw={handleFinish}
                initialData={visibleCanvasItems}
                brushSize={3}
                locationType={locationType}
                onZoneClick={handleZoneClick}
                showRobots={showRobots}
              />
              <StyledDeleteIcon
                onClick={() => setDeleteModal(true)}
                src={ICONS.DeleteIcon}
                alt="DeleteIcon"
              />
              <InfoModal
                style={{ maxWidth: "52.6rem" }}
                isOpen={deleteModal}
                setIsDeleteModal={setDeleteModal}
                type={InfoModalTypes.WARNING}
                itemId={chosenMap?.id}
                title="Are you sure you want to delete ?"
                content=""
                noOuterClickClosing
                cancelAction={() => setDeleteModal(false)}
                submitAction={handleMapDelete}
                cancelText={"Cancel"}
                submitText={"Yes Delete"}
                disabled={false}
              />
            </StyledCanvasWrapper>
          ) : (
            noImage &&
            !canvasDimensions && <UploadMapImage handleFetch={handleFetch} />
          )}
        </div>
      </StyledCanvasComponentWrapper>
      {chosenZone && (
        <StyledEditZoneWrapper zone={chosenZone.type}>
          Modify {chosenZone.type} ?
          {[ZONEIDENUM.SHAREDZONE, ZONEIDENUM.WORKINGAREA].includes(
            chosenZone.type
          ) && (
            <button onClick={() => handleZoneUpdate(chosenZone)}>update</button>
          )}
          <button onClick={() => handleZoneDelete(chosenZone)}>delete</button>
          <button onClick={() => setChosenZone(null)}>X</button>
        </StyledEditZoneWrapper>
      )}
      {addCheckpointText && (
        <StyledEditZoneWrapper zone={chosenZone?.type}>
          {addCheckpointText}
        </StyledEditZoneWrapper>
      )}

      <BottomButtons>
        <ButtonSZ
          disabled={!chosenMap?.mapUrl}
          onClick={() => handleAddEntry(MAPLOCATIONENUM.SHAREDZONE)}
        >
          Shared Zone
        </ButtonSZ>
        <ButtonCH
          disabled={!chosenMap?.mapUrl}
          onClick={() => handleAddEntry(MAPLOCATIONENUM.CHECKPOINT)}
        >
          Checkpoint
        </ButtonCH>
        <ButtonWA
          disabled={!chosenMap?.mapUrl}
          onClick={() => handleAddEntry(MAPLOCATIONENUM.WORKINGAREA)}
        >
          Working area
        </ButtonWA>
        <ButtonLO
          disabled={!chosenMap?.mapUrl}
          onClick={() => handleAddEntry(MAPLOCATIONENUM.LOCATION)}
        >
          location
        </ButtonLO>
        <ButtonRO
          disabled={!chosenMap?.mapUrl}
          onClick={() =>
            routeData ? finishRouting() : handleAddEntry(MAPLOCATIONENUM.ROUTE)
          }
        >
          {routeData ? "Finish route" : "Route"}
        </ButtonRO>
      </BottomButtons>

      <AddLocationModal
        isOpen={isLocationModal}
        newLocationForm={newLocationForm}
        setNewLocationForm={setNewLocationForm}
        setIsOpen={setIsLocationModal}
        setCurrentModalData={setCurrentModalData}
        currentModalData={currentModalData}
        handleAddLocationFromFleet={handleAddLocation}
        fleetRules={true}
      />
      <InputModal
        isOpen={inputModalData.isOpen}
        title={inputModalData.title}
        chosenRobot={chosenRobot}
        onRequestClose={() =>
          setInputModalData({
            isOpen: false,
            callback: () => {},
            robots: null,
          })
        }
        handleSubmit={inputModalData.callback}
        robots={inputModalData.robots}
        angle={inputModalData.angle}
        defaultState={{
          name: inputModalData?.name,
          chosenRobotId: inputModalData?.chosenRobotId,
        }}
      />
    </DrawcanvasWrapper>
  );
};

export default DrawCanvas;
