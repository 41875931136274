import { useCallback, useRef } from "react";

export const useDebounce = () => {
  const timer = useRef(null);

  const debounce = useCallback((func, delay) => {
    return function (...args) {
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }, []);

  return { debounce };
};
