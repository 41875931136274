import React from "react";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "./ToastMessage.css";
const ToastMessageComponent = ({ type, text, id }) => {
  useEffect(() => {
    toast[type](text, {});
  }, [text, type, id]);

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default ToastMessageComponent;
