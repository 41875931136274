import { useEffect, useCallback } from "react";

export default function useRevert(className, fn) {
  const handleClickForRevert = useCallback(
    (event) => {
      const allowedComponents = [...document.getElementsByClassName(className)];
      if (
        allowedComponents.some(
          (item) => item.contains && item.contains(event.target)
        )
      ) {
        fn();
      }
    },
    [className, fn]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickForRevert, true);
    return () => {
      document.removeEventListener("click", handleClickForRevert, true);
    };
  }, [handleClickForRevert]);

  return null;
}
