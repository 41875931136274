import styled from "styled-components";

export const BatteryModalWrapper = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 8px;
  border: 2px solid #d42f39;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 24px;
  > img {
    width: 70px;
    margin-top: 30px;
  }
  > h1 {
    color: #d42f39;
    font-size: 32px;
  }
  > button {
    width: ${(props) => (props.isMobile ? "120px" : "250px")};
    height: 44px;
    cursor: pointer;

    background: rgba(6, 134, 216, 0.1);
    border-radius: 48px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;
    gap: 10px;
    border: none;

    font-family: "Ubuntu-Medium";
    color: #0686d8;
    font-weight: 500;
    font-size: 16px;
  }
`;
