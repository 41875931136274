import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import Loader from "../components/loader/Loader";
import URLS from "../constants/urls";
import Auth from "../components/auth-component/Auth";
import { setClientLoader } from "../redux/clients";
import { useDispatch } from "react-redux";

const useRouting = () => {
  const {
    isLoggedIn: enabled,
    isClientLoading,
    currentUser,
    isCompanyMember,
  } = useSelector((state) => state.clients);
  const dispatch = useDispatch();
  useEffect(() => {
    let timeout;
    if (isClientLoading) {
      timeout = setTimeout(() => {
        dispatch(setClientLoader(false));
        localStorage.removeItem("token");
      }, 15000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isClientLoading, dispatch]);

  const PrivateRoute = useCallback(
    ({ component: Component, path, exact, allowedRoles, ...rest }) => {
      return (
        <Route path={path} exact={exact}>
          <Auth allowedRoles={allowedRoles} component={Component} />
        </Route>
      );
    },
    []
  );

  const PublicRoute = useCallback(
    ({ component: Component, ...rest }) => {
      const authed = enabled && currentUser;
      return (
        <Route
          {...rest}
          render={(props) =>
            isClientLoading ? (
              <Loader height="100vh" />
            ) : !authed ? (
              <Component {...props} />
            ) : (
              <Redirect to={URLS.dashboard} />
            )
          }
        />
      );
    },
    [currentUser, isClientLoading, enabled]
  );

  const CompanyRoute = useCallback(
    ({ component: Component, ...rest }) => {
      const authed = enabled || isCompanyMember;
      return (
        <Route
          {...rest}
          render={(props) =>
            isClientLoading ? (
              <Loader height="100vh" />
            ) : !authed ? (
              <Redirect to={{ pathname: URLS.sign_in }} />
            ) : (
              <Component {...props} />
            )
          }
        />
      );
    },
    [isCompanyMember, isClientLoading, enabled]
  );

  return { PrivateRoute, PublicRoute, CompanyRoute };
};

export default useRouting;
