import Lottie from "lottie-react";
import React from "react";
import RotateTabletGif from "../../assets/RotateTabletGif.json";
import { RotateDeviceText, StyledRotateDeviceWrapper } from "./components";
const RotateDeviceAlert = () => {
  return (
    <StyledRotateDeviceWrapper>
      <Lottie animationData={RotateTabletGif} />
      <RotateDeviceText>Please rotate your device</RotateDeviceText>
    </StyledRotateDeviceWrapper>
  );
};

export default RotateDeviceAlert;
