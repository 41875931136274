import React, { useCallback, useContext, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AddAssignmentModal from "../../../components/add-assignment-modal/AddAssignmentModal";
import STRINGS from "../../../constants/strings";
import { ComponentHeader } from "../../admin-page/environment-setup/components";

import {
  AssignmentCardWrapper,
  AssignmentsPageWrapper,
  ComponentTitle,
  HeaderStyledButton,
  StyledAddAssignmentText,
} from "./components";
import { AssignmentsContext } from "../../../contexts/AssignmentsContext";
import useAssignments from "../../../hooks/useAssignments";
import getAssignments from "../../../APIs/assignments/getClientAssignments";
import AssignmentCard from "./AssignmentCard";
import { parseAssignment } from "../../../utils/parseAssignments";
import ICONS from "../../../constants/icons";
import { ASSIGNMENTSTATUSENUM, ROLESENUM } from "../../../enums";
import StyledSelectMultiplyChoice from "../../../components/styled-select-multiply-choice/StyledSelectMultiplyChoice";
import Loader from "../../../components/loader/Loader";

const DEFAULT_TAKE = 10;

const Assignments = () => {
  const bottomRef = useRef(null);
  const { assignmentsData, setAssignmentsData, assignmentTemplate } =
    useContext(AssignmentsContext);

  const { chosenClient, currentRole } = useSelector((state) => state.clients);
  const DEFAULT_OPTIONS = [
    { id: "1", name: ASSIGNMENTSTATUSENUM.Active, checked: true },
    { id: "2", name: ASSIGNMENTSTATUSENUM.Off, checked: false },
    { id: "3", name: ASSIGNMENTSTATUSENUM.EXPIRED, checked: false },
    { id: "0", name: "All", checked: false },
  ];
  const [options, setOptions] = useState(DEFAULT_OPTIONS);
  const [isLoading, setIsLoading] = useState();
  const [take, setTake] = useState(DEFAULT_TAKE);
  const [isMoreData, setIsMoreData] = useState(true);
  const {
    isOpen,
    setIsOpen,
    handleModalOpen,
    newAssignmentForm,
    setNewAssignmentForm,
    currentModalData,
    handleDelete,
    handleEdit,
    handleToggle,
    handleDuplicate,
  } = useAssignments({
    setAssignmentsData,
    assignmentsData,
    options,
  });

  const handleFetch = useCallback(() => {
    const checkedOptions = options.filter((i) => i.checked).map((i) => i.name);
    if (
      chosenClient &&
      (!assignmentsData.length || assignmentsData.length >= DEFAULT_TAKE) &&
      isMoreData
    ) {
      setIsLoading(true);
      getAssignments(
        chosenClient,
        DEFAULT_TAKE,
        checkedOptions,
        take - DEFAULT_TAKE
      )
        .then((data) => {
          const assignments = data
            ? data.map((assignment) => ({
                ...assignmentTemplate,
                ...parseAssignment(assignment),
              }))
            : [];
          if (data && assignments.length < DEFAULT_TAKE) setIsMoreData(false);
          setAssignmentsData((prev) => [...prev, ...assignments]);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  }, [
    chosenClient,
    assignmentTemplate,
    setAssignmentsData,
    take,
    isMoreData,
    assignmentsData.length,
    options,
  ]);

  React.useEffect(() => {
    handleFetch();
  }, [handleFetch]);
  React.useEffect(() => {
    return () => {
      chosenClient && getAssignments(chosenClient, DEFAULT_TAKE, null, 0);
    };
  }, [chosenClient]);

  const handleCheckboxChange = React.useCallback(
    (id) => {
      setAssignmentsData([]);
      setTake(DEFAULT_TAKE);
      setIsMoreData(true);
      setOptions((prev) => {
        let copiedPrev = [...prev];

        if (id === "0") {
          if (prev.find((item) => item.id === id).checked) {
            copiedPrev = prev.map((item) => ({ ...item, checked: false }));
          } else {
            copiedPrev = prev.map((item) => ({ ...item, checked: true }));
          }
        } else {
          copiedPrev = prev.map((item) =>
            item.id === id ? { ...item, checked: !item.checked } : item
          );
        }
        const isAllChecked = (opt) =>
          opt.checked || (!opt.checked && opt.id === "0");
        copiedPrev.every(isAllChecked)
          ? (copiedPrev[3].checked = true)
          : (copiedPrev[3].checked = false);
        return copiedPrev;
      });
    },
    [setAssignmentsData]
  );
  const handleScroll = (e) => {
    const bottom =
      Math.floor(e.target.scrollHeight) - Math.floor(e.target.scrollTop) ===
      e.target.clientHeight;
    if (bottom && isMoreData && assignmentsData.length >= DEFAULT_TAKE) {
      setTake((prev) => prev + DEFAULT_TAKE);
    }
  };
  return (
    <div
      style={{ height: "100%", overflow: "auto" }}
      onScroll={(e) => handleScroll(e)}
    >
      <ComponentHeader>
        <div
          style={{
            display: "flex",
            gap: "24px",
          }}
        >
          <ComponentTitle>{STRINGS.assignments}</ComponentTitle>
          <div style={{ width: "150px" }}>
            <StyledSelectMultiplyChoice
              options={options}
              handleCheckboxChange={handleCheckboxChange}
            />
          </div>
          {isLoading && <Loader height={40} noText noMargin padding={0} />}
        </div>
        {currentRole !== ROLESENUM.VIEWER && (
          <HeaderStyledButton onClick={handleModalOpen}>
            {STRINGS.add_assignment}
          </HeaderStyledButton>
        )}
      </ComponentHeader>
      <AddAssignmentModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        currentModalData={currentModalData}
        newAssignmentForm={newAssignmentForm}
        setNewAssignmentForm={setNewAssignmentForm}
        setAssignmentsData={setAssignmentsData}
        assignmentsData={assignmentsData}
        bottomRef={bottomRef}
      />

      <AssignmentsPageWrapper>
        {assignmentsData.map((item, index) => (
          <AssignmentCard
            key={item.id}
            index={index}
            item={item}
            handleToggle={handleToggle}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleDuplicate={handleDuplicate}
          />
        ))}
        <AssignmentCardWrapper
          opacity={1}
          style={{
            width: "360px",
            height: "417px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={handleModalOpen}
        >
          <img
            src={ICONS.AssignmentIcon}
            alt="add"
            style={{ width: "200px" }}
          />
          <StyledAddAssignmentText>
            + Add new assignment
          </StyledAddAssignmentText>
        </AssignmentCardWrapper>
      </AssignmentsPageWrapper>
      <div ref={bottomRef} />
    </div>
  );
};

export default Assignments;
