import styled from "styled-components";
import ICONS from "../../constants/icons";

export const ITMWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 40px 23px;
  justify-content: space-between;
  position: relative;
  border: 1px solid #0686d8;
  border-radius: 16px;
`;

export const LeftLoading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  > h1 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  > button {
    width: ${(props) => (props.isMobile ? "120px" : "384px")};
    height: 44px;
    cursor: pointer;

    background: rgba(6, 134, 216, 0.1);
    border-radius: 48px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;
    gap: 10px;
    border: none;

    font-family: "Ubuntu-Medium";
    color: #0686d8;
    font-weight: 500;
    font-size: 16px;
  }

  > p {
    max-width: ${(props) => props.isMobile && "227px"};
    text-align: center;
    margin-bottom: 10px;
  }
`;

export const LeftError = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: inherit;
  > h1 {
    font-size: 24px;
    font-weight: 400;
    width: ${(props) => (props.isMobile ? "230px" : "315px")};
    text-align: center;
  }
  > button {
    width: ${(props) => (props.isMobile ? "120px" : "384px")};
    height: 44px;
    cursor: pointer;

    background: rgba(6, 134, 216, 0.1);
    border-radius: 48px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;
    gap: 10px;
    border: none;

    font-family: "Ubuntu-Medium";
    color: #0686d8;
    font-weight: 500;
    font-size: 16px;
  }

  > p {
    text-align: center;
    margin-bottom: 10px;
  }
`;

export const LeftDone = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: inherit;
  > h1 {
    font-size: 24px;
    font-weight: 400;
    width: 315px;
    text-align: center;
  }
  > button {
    width: ${(props) => (props.isMobile ? "120px" : "384px")};
    height: 44px;
    cursor: pointer;

    background: rgba(6, 134, 216, 0.1);
    border-radius: 48px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;
    gap: 10px;
    border: none;

    font-family: "Ubuntu-Medium";
    color: #0686d8;
    font-weight: 500;
    font-size: 16px;
  }

  > p {
    text-align: center;
    width: 256px;
  }
`;

export const ModalCloseX = styled.div`
  width: 6.2rem;
  height: 3.2rem;
  background-image: url(${ICONS.CloseGray});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;

  position: absolute;
  right: 17px;
  top: 20px;
  width: 16px;
  height: 16px;
`;

export const StyledRobotImg = styled.img`
  width: ${(props) => props.isMobile && "130px"};
  position: ${(props) => props.isMobile && "absolute"};
  right: ${(props) => props.isMobile && "-18px"};
  top: ${(props) => props.isMobile && "123px"};
`;
