import React, { useContext, useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  LineChart,
} from "recharts";
import { ResponsiveContext } from "../../../../contexts/ResponsiveContext";

const GraphWidget = ({ analytics }) => {
  const { isMobile } = useContext(ResponsiveContext);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    const data = Object.entries(analytics.graphData).map(([name, value]) => {
      return {
        name,
        value: analytics.graphData[name].length,
      };
    });
    setGraphData(data);
  }, [analytics]);

  return (
    <ResponsiveContainer
      width={isMobile ? 400 : 400}
      height={isMobile ? 150 : 200}
    >
      <LineChart width={500} height={300} data={graphData}>
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="" dataKey="value" stroke="#0686D8" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default GraphWidget;
