import Tool from "./tool";
export const Location = "Location";

const location = { ...Tool };

location.name = "Location";

location.onMouseDown = function onMouseDown(start, options, callback) {
  if (!this.state) {
    this.state = {
      ...this.state,
      initialCircle: this.createStartPoint(start, 5),
      startPoint: start,
    };
  }
  if (!this.state.pathData) {
    this.state.pathData = [];
  }
  this.setInitSettings({ start, options });
  callback(this.state);
  this.resetState();
};

location.draw = function drawPoint(start, position, isInitSet, data) {
  if (isInitSet === false) {
    this.setInitSettings({ start, options: data.options || {} }, true);
  }
  this.ctx.beginPath();
  this.ctx.strokeStyle = "transparent";
  this.ctx.moveTo(start.x, start.y);
  this.ctx.arc(start.x, start.y, 5, 0, Math.PI * 2, true);
  this.ctx.lineTo(position.x, position.y);
  this.ctx.stroke();
};

location.onMouseMove = function onMouseMove() {};

location.onMouseUp = function onMouseUp(position, callback) {
  if (!this.state) return;
  // NOTE: This state data is just to avoid draw in
  // the first mouse up
  this.state.data.push([position.x, position.y]);
  if (this.state.data.length > 1) {
    const data = [
      [this.state.firstMouseDown.x, this.state.firstMouseDown.y],
      [position.x, position.y],
    ];
    const start = this.state.start;
    const options = this.state.options;
    this.drawCrossDirection(this.state.data, 10);
    this.resetState();
    callback();
    return {
      data: data,
      canvas: {
        start,
        end: position,
        options,
      },
    };
  }
};

location.drawCrossDirection = function (points, pixelDistance) {
  const x1 = points[0][0];
  const x2 = points[1][0];
  const y1 = points[0][1];
  const y2 = points[1][1];

  const xCoord = (x1 + x2) / 2 + pixelDistance * Math.sign(y2 - y1);
  const yCoord = (y1 + y2) / 2 - pixelDistance * Math.sign(x2 - x1);

  const path = new Path2D();
  path.arc(xCoord, yCoord, 5, 0, Math.PI * 26, true);

  this.ctx.fillStyle = "#f0ae71";
  this.ctx.fill(path);
  this.ctx.strokeStyle = "#f0ae71";
  this.ctx.stroke(path);
  this.ctx.strokeStyle = "#555";
};

export default location;
