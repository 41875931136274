import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  toastMessage: null,
};

export const toastMessage = createSlice({
  name: "toasts",
  initialState,
  reducers: {
    setToastMessages: (state, action) => {
      state.toastMessage = { ...action.payload, id: uuidv4() };
    },
  },
});

export const { setToastMessages, clearToastMessage } = toastMessage.actions;

export default toastMessage.reducer;
