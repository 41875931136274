import React from "react";
import { RowWrapper, StyledTableBox } from "./components";

const ManagementTableHeader = () => {
  const header = [
    { title: "Full Name", width: "18%" },
    { title: "Email", width: "24%" },
    { title: "Phone", width: "18%" },
    { title: "Role", width: "12%" },
    { title: "Activate", width: "18%" },
    { title: "", width: "10%" },
  ];
  return (
    <RowWrapper style={{ background: "#0096c6" }}>
      {header.map((item) => (
        <StyledTableBox style={{ width: item.width }} key={item.title}>
          {item.title}
        </StyledTableBox>
      ))}
    </RowWrapper>
  );
};

export default ManagementTableHeader;
