import React from "react";
import Modal from "react-modal";
import COLORS from "../../constants/colors";
import { StyledCloseWrapper } from "./components";

const customStyles = {
  overlay: {
    backgroundColor: `${COLORS.primary.oxford}80`,
    zIndex: "111",
  },
  content: {
    zIndex: "111",
    width: "385px",
    height: "147px",
    top: "50%",
    left: "50%",
    right: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    padding: "0 ",
  },
};

const ConfirmCloseModal = ({ isOpen, setCloseModal, requestCloseMain }) => {
  const requestClose = () => setCloseModal(false);

  return (
    <Modal
      onRequestClose={requestClose}
      isOpen={!!isOpen}
      style={customStyles}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    >
      <StyledCloseWrapper>
        <h1>Are you sure you want to exit?</h1>
        <h2>
          Please note that this action does not send a command to the robot
        </h2>

        <div>
          <button onClick={requestCloseMain}>Exit</button>
          <button onClick={requestClose}>Cancel</button>
        </div>
      </StyledCloseWrapper>
    </Modal>
  );
};

export default ConfirmCloseModal;
