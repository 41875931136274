import React, { useCallback, useState } from "react";
import {
  IconWrapper,
  StyledIcon,
  TableWrapper,
  TaskButton,
  TaskTitle,
  TasksHeader,
} from "../../robots/tasks-in-assignments/components";
import ICONS from "../../../constants/icons";
import ApplicationParametersRow from "./ApplicationParametersRow";
import { v4 as uuidv4 } from "uuid";
import updateApplication from "../../../APIs/application/updateApplication";
import KeyValueModal from "../../../components/key-value-modal/KeyValueModal";
import { setToastMessages } from "../../../redux/toast";
import { useDispatch } from "react-redux";

const ApplicationDetailsParameters = ({
  applicationDetails,
  setApplicationDetails,
  setParametersBeforeChanges,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isError, setIsError] = useState(false);
  const [keyValueParameters, setKeyValueParameters] = useState(null);
  const dispatch = useDispatch();

  const handleAddParameters = () => {
    setIsEditMode(true);
    const applicationForm = { ...applicationDetails };
    applicationForm.parameters = [
      ...applicationForm.parameters,
      {
        id: uuidv4(),
        parameter_name: "",
        parameter: "",
      },
    ];
    setApplicationDetails(applicationForm);
  };

  const handleChange = (inputValue, fieldName) => {
    let obj = { ...applicationDetails };
    obj[fieldName] = inputValue;
    setApplicationDetails(obj);
  };

  const handleParametersChange = useCallback(
    (inputValue, parId, fieldName) => {
      setIsError(false);
      let obj = {
        ...applicationDetails,
        parameters: applicationDetails.parameters.map((paramObj) => {
          const parObj = { ...paramObj };
          if (parObj.id === parId) {
            parObj[fieldName] = inputValue;
            return parObj;
          } else return parObj;
        }),
      };
      setApplicationDetails(obj);
    },
    [applicationDetails, setApplicationDetails]
  );

  const handleSubmit = async () => {
    !isEditMode && setParametersBeforeChanges(applicationDetails.parameters);
    !isEditMode && setIsEditMode((prev) => !prev);
    let obj = { ...applicationDetails };
    if (isEditMode) {
      const isPar = ([key, element]) => key !== "default" && !element;
      const booleanArray = obj.parameters.map((item) => {
        if (item.type === "endtime") return false;
        if (
          (item.type === "key_value" && (!item.list || !item?.list?.length)) ||
          (item.type === "text" && !item.value) ||
          (item.type === "number" && (!item.min || !item.max))
        ) {
          return true;
        } else return Object.entries(item).some(isPar);
      });

      if (booleanArray.includes(true)) {
        setIsError(true);
      } else {
        setIsError(false);
        await updateApplication(obj);
        setIsEditMode((prev) => !prev);
      }
    }
    setApplicationDetails && setApplicationDetails(obj);
  };

  const handleParameterDelete = async (parId) => {
    // check if there is only one location param
    const found = applicationDetails.parameters.find(
      (item) => item.id === parId
    );
    if (found.type === "location") {
      const locationParameters = applicationDetails.parameters.filter(
        (item) => item.type === "location"
      );
      if (locationParameters.length === 1) {
        dispatch(
          setToastMessages({
            text: "Application must have at least one location",
            type: "error",
          })
        );
        return;
      }
    }
    //
    const updatedApp = {
      ...applicationDetails,
      parameters: applicationDetails.parameters.filter(
        (item) => item.id !== parId
      ),
    };
    await updateApplication(updatedApp);
    setApplicationDetails(updatedApp);
  };

  return (
    <TableWrapper>
      <TasksHeader>
        <TaskTitle>Parameters</TaskTitle>
        <div style={{ display: "flex", gap: "10px" }}>
          <IconWrapper
            background={
              isEditMode ? "rgba(255, 193, 35, 0.1)" : "rgba(6, 134, 216, 0.1)"
            }
            onClick={handleSubmit}
          >
            <StyledIcon
              src={isEditMode ? ICONS.SaveDisk : ICONS.PencilDraw}
              alt="Pencil"
            />
          </IconWrapper>
          <TaskButton onClick={handleAddParameters}>Add parameter</TaskButton>
        </div>
      </TasksHeader>
      {applicationDetails?.parameters?.map(
        (par, index) =>
          par.type !== "endtime" && (
            <ApplicationParametersRow
              key={par.id}
              par={par}
              index={index}
              isEditMode={isEditMode}
              handleChange={handleChange}
              handleParametersChange={handleParametersChange}
              handleParameterDelete={handleParameterDelete}
              setKeyValueParameters={setKeyValueParameters}
              isError={isError}
            />
          )
      )}
      <KeyValueModal
        isOpen={keyValueParameters}
        keyValueParameters={keyValueParameters}
        setApplicationDetails={setApplicationDetails}
        onRequestClose={() => {
          setKeyValueParameters(null);
        }}
        onSubmit={(keyValueParameters, list, setIsListError) => {
          if (list.find((item) => !item.key || !item.value)) {
            setIsListError(true);
          } else {
            setApplicationDetails((prev) => ({
              ...prev,
              parameters: prev.parameters.map((item) =>
                item.type === "key_value" &&
                item.parameter_name === keyValueParameters.parameter_name
                  ? { ...item, list: list }
                  : item
              ),
            }));
            setKeyValueParameters(null);
          }
        }}
      />
    </TableWrapper>
  );
};

export default ApplicationDetailsParameters;
