import React from "react";
import { StyledSingleLineContainer } from "../../../components/commonStyles";
import ICONS from "../../../constants/icons";
import InputText from "../../../components/input/InputText";
import COLORS from "../../../constants/colors";
import { InputWithBackgroundWrapper, PercentSign } from "./components";
import STRINGS from "../../../constants/strings";

const ChargingAvailability = ({
  batterySettingForm,
  setBatterySettingForm,
}) => {
  const handleParametersChange = (value) => {
    setBatterySettingForm((prev) => ({
      ...prev,
      charging_availability: { inactivity_time: value },
    }));
  };

  return (
    <StyledSingleLineContainer style={{ gap: "15px", marginTop: "30px" }}>
      <StyledSingleLineContainer style={{ gap: "5px", width: "auto" }}>
        <span>{STRINGS.inactivity_time}</span>
        <img src={ICONS.Info} alt="" />
      </StyledSingleLineContainer>
      <InputWithBackgroundWrapper>
        <InputText
          disabled={false}
          width="170px"
          height="5.4rem"
          type="number"
          padding="0 2.0rem"
          borderColor={COLORS.secondary.water_green}
          handleChange={(e) => handleParametersChange(e)}
          value={batterySettingForm.charging_availability.inactivity_time || ""}
          backgroundColor="#fff"
          margin="12px 0"
        />
        <PercentSign style={{ right: "50px" }}>Minutes</PercentSign>
      </InputWithBackgroundWrapper>
    </StyledSingleLineContainer>
  );
};

export default ChargingAvailability;
