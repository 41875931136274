import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  ColumnNumber,
  IconWrapper,
  InputTitle,
  InputWrapper,
  StyledIcon,
  StyledOption,
  StyledSelect,
  TaskRow,
} from "../../robots/tasks-in-assignments/components";
import COLORS from "../../../constants/colors";
import InputText from "../../../components/input/InputText";
import { NumberInputsWrapper } from "../../../components/add-application-modal/components";
import ICONS from "../../../constants/icons";
// import { InputError } from "../../../components/input/components";
import { StyledListParameterButton } from "../../../components/list-parameter-button/components";
import { v4 as uuidv4 } from "uuid";
import { InputError } from "../../../components/input/components";
import GetElementCoordinates from "../../../components/get-element-coordinates/GetElementCoordinates";
import STRINGS from "../../../constants/strings";
import StyledSelectMultiplyChoice from "../../../components/styled-select-multiply-choice/StyledSelectMultiplyChoice";

const ALLLOCOBJ = { id: "allLocID", name: "All locations", checked: true };

const ApplicationParametersRow = ({
  par,
  index,
  isEditMode,
  handleParametersChange,
  handleParameterDelete,
  setKeyValueParameters,
  isError,
}) => {
  const { locations } = useSelector((state) => state.maps);
  const allCustomLocs = [
    ALLLOCOBJ,
    ...locations.map((item) => ({ ...item, checked: false })),
  ];

  const [customLocations, setCustomLocations] = useState(
    par?.customLocs
      ? allCustomLocs.map((loc) => {
          if (par.customLocs.find((item) => item.id === loc.id))
            return { id: loc.id, name: loc.name, checked: true };
          return { id: loc.id, name: loc.name, checked: false };
        })
      : allCustomLocs
  );

  const handleListModalOpen = useCallback(() => {
    if (par?.list?.length) {
      setKeyValueParameters(par);
    } else {
      setKeyValueParameters({
        ...par,
        list: [
          {
            id: uuidv4(),
            key: "",
            value: "",
          },
        ],
      });
    }
  }, [par, setKeyValueParameters]);

  const extraInputs = useMemo(() => {
    switch (par.type) {
      case "text":
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <div>
              <InputTitle>Value</InputTitle>
              <InputText
                disabled={!isEditMode}
                width="100%"
                height="5.4rem"
                borderColor={COLORS.secondary.water_green}
                handleChange={(e) => handleParametersChange(e, par.id, "value")}
                value={par.value || ""}
                error={isError && !par.value && "This field can not be empty"}
                backgroundColor="#fff"
              />
            </div>
            <GetElementCoordinates
              text={STRINGS.first_value_info}
              top={-20}
              type={"bottom"}
              styles={{
                border: "1px solid #050505",
                background: "#FFFFFF",
                color: "#08183F",
                boxShadow: "0px 4px 10px rgba(0, 147, 198, 0.18)",
              }}
            >
              <img
                src={ICONS.InfoIcon}
                style={{
                  width: "16px",
                  margin: "42px 0px 0px 5px",
                  cursor: "pointer",
                  opacity: "0.3",
                }}
                alt=""
              />
            </GetElementCoordinates>
          </div>
        );

      case "number":
        return (
          <>
            <NumberInputsWrapper>
              <div>
                <InputTitle>Min</InputTitle>
                <InputText
                  disabled={!isEditMode}
                  width="100%"
                  height="5.4rem"
                  type="number"
                  padding="0 2.0rem"
                  borderColor={COLORS.secondary.water_green}
                  handleChange={(e) => handleParametersChange(e, par.id, "min")}
                  value={par.min || ""}
                  error={isError && !par.min && "This field can not be empty"}
                  backgroundColor="#fff"
                />
              </div>
              <div>
                <InputTitle>Max</InputTitle>
                <InputText
                  disabled={!isEditMode}
                  width="100%"
                  height="5.4rem"
                  padding="0 2.0rem"
                  type="number"
                  borderColor={COLORS.secondary.water_green}
                  handleChange={(e) => handleParametersChange(e, par.id, "max")}
                  value={par.max || ""}
                  error={isError && !par.max && "This field can not be empty"}
                  backgroundColor="#fff"
                />
              </div>
            </NumberInputsWrapper>
            <div>
              <InputTitle>Default</InputTitle>
              <InputText
                disabled={!isEditMode}
                width="100%"
                height="5.4rem"
                padding="0 2.0rem"
                type="number"
                borderColor={COLORS.secondary.water_green}
                handleChange={(e) =>
                  handleParametersChange(e, par.id, "default")
                }
                value={par.default || ""}
                error={
                  +par.default < +par.min || +par.default > +par.max
                    ? "This field can not be empty"
                    : ""
                }
                backgroundColor="#fff"
              />
            </div>
          </>
        );

      case "key_value":
        return (
          <>
            <div style={{ position: "relative" }}>
              <InputTitle>List</InputTitle>
              <StyledListParameterButton
                onClick={(e) => {
                  e.preventDefault();
                  handleListModalOpen();
                }}
                disabled={!isEditMode}
                height="5.4rem"
                borderColor={COLORS.secondary.water_green}
                // handleChange={(e) => handleParametersChange(e, par.id, "value")}
                // value={par.value || ""}
              >
                <img src={ICONS.ListIcon} alt="" /> {par?.list?.length || 0}{" "}
                Values
              </StyledListParameterButton>
              {isError && !par?.list?.length && (
                <InputError>*List can not be empty</InputError>
              )}
            </div>
            <div style={{ position: "relative" }}>
              <InputTitle>Default</InputTitle>
              <StyledSelect
                onClick={(e) => e.stopPropagation()}
                style={{ width: "100%" }}
                value={par.default}
                disabled={!isEditMode}
                onChange={(e) => {
                  handleParametersChange(e.target.value, par.id, "default");
                }}
              >
                <>
                  <StyledOption value={""}>No Default</StyledOption>
                  {par?.list?.map((item) => (
                    <StyledOption key={item.id} value={item.value}>
                      {item.key}
                    </StyledOption>
                  ))}
                </>
              </StyledSelect>
            </div>
          </>
        );

      case "location":
        return (
          <>
            <div style={{ position: "relative" }}>
              <InputTitle>Customization</InputTitle>
              <StyledSelectMultiplyChoice
                options={customLocations}
                handleCheckboxChange={(id, item) => {
                  let chosenLocs = [];
                  setCustomLocations((prev) => {
                    chosenLocs = prev.map((loc) => {
                      if (loc.id === item.id)
                        return {
                          id: loc.id,
                          name: loc.name,
                          checked: !loc.checked,
                        };
                      return loc;
                    });
                    return chosenLocs;
                  });

                  if (
                    chosenLocs.find(
                      (item) => item.id === "allLocID" && item.checked
                    )
                  ) {
                    if (item.id === "allLocID") {
                      setCustomLocations([
                        ALLLOCOBJ,
                        ...locations.map((item) => ({
                          id: item.id,
                          name: item.name,
                          checked: true,
                        })),
                      ]);
                      handleParametersChange([ALLLOCOBJ], par.id, "customLocs");
                    } else {
                      setCustomLocations((prev) => {
                        chosenLocs = prev.map((loc) => {
                          if (loc.id === item.id || loc.id === "allLocID")
                            return {
                              id: loc.id,
                              name: loc.name,
                              checked: false,
                            };
                          return loc;
                        });
                        return chosenLocs;
                      });
                    }
                  } else {
                    if (item.id === "allLocID")
                      setCustomLocations([
                        {
                          ...ALLLOCOBJ,
                          checked: false,
                        },
                        ...locations.map((item) => ({
                          id: item.id,
                          name: item.name,
                          checked: false,
                        })),
                      ]);

                    handleParametersChange(
                      chosenLocs.filter((item) => item.checked),
                      par.id,
                      "customLocs"
                    );
                  }
                }}
                disabled={!isEditMode}
              />
            </div>
            <div style={{ position: "relative" }}>
              <InputTitle>Default</InputTitle>
              <StyledSelect
                onClick={(e) => e.stopPropagation()}
                style={{ width: "100%" }}
                value={par.default}
                disabled={!isEditMode}
                onChange={(e) => {
                  handleParametersChange(
                    e.target.value.split(",").map((item) => +item),
                    par.id,
                    "default"
                  );
                }}
              >
                <>
                  <StyledOption value={""}>No Default</StyledOption>
                  {locations.map((loc) => (
                    <StyledOption key={loc.id} value={loc.pixelCoordinates}>
                      {loc.name}
                    </StyledOption>
                  ))}
                </>
              </StyledSelect>
            </div>
          </>
        );

      default:
        return null;
    }
  }, [
    par,
    handleListModalOpen,
    handleParametersChange,
    isEditMode,
    isError,
    locations,
    customLocations,
  ]);

  return (
    <TaskRow style={{ marginTop: "20px" }}>
      <ColumnNumber>{index + 1}.</ColumnNumber>
      <InputWrapper>
        <InputTitle>Parameter Name</InputTitle>
        <InputText
          disabled={!isEditMode}
          width="100%"
          height="5.4rem"
          borderColor={COLORS.secondary.water_green}
          handleChange={(e) =>
            handleParametersChange(e, par.id, "parameter_name")
          }
          value={par.parameter_name || ""}
          error={
            isError && !par.parameter_name && "This field can not be empty"
          }
          backgroundColor="#fff"
        />
      </InputWrapper>
      <InputWrapper>
        <InputTitle>Parameter</InputTitle>
        <InputText
          disabled={!isEditMode}
          width="100%"
          height="5.4rem"
          borderColor={COLORS.secondary.water_green}
          handleChange={(e) => handleParametersChange(e, par.id, "parameter")}
          value={par.parameter || ""}
          error={isError && !par.parameter && "This field can not be empty"}
          backgroundColor="#fff"
        />
      </InputWrapper>
      <div style={{ position: "relative" }}>
        <InputTitle>Type</InputTitle>
        <StyledSelect
          disabled={!isEditMode}
          style={{ width: "100%" }}
          onChange={(e) =>
            handleParametersChange(e.target.value, par.id, "type")
          }
          defaultValue={par.type}
        >
          <StyledOption value={""} defaultValue={false}>
            Type
          </StyledOption>
          <StyledOption value={"location"}>Location</StyledOption>
          <StyledOption value={"text"}>Text</StyledOption>
          <StyledOption value={"number"}>Number</StyledOption>
          <StyledOption value={"freeText"}>Free text</StyledOption>
          <StyledOption value={"key_value"}>Key/Value</StyledOption>
        </StyledSelect>
        {isError && !par.type && (
          <InputError>*Please choose type of parameter</InputError>
        )}
      </div>

      {extraInputs}

      <IconWrapper
        style={{ marginLeft: "auto" }}
        background={"rgba(212, 47, 57, 0.1)"}
        onClick={(e) => {
          e.preventDefault();
          handleParameterDelete(par.id);
        }}
      >
        <StyledIcon src={ICONS.DeleteIcon} />
      </IconWrapper>
    </TaskRow>
  );
};

export default ApplicationParametersRow;
