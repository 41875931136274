import React from "react";
import {
  EditAssignmentButton,
  EditAssignmentButtonsWrapper,
  StyledChangesContentWrapper,
  StyledContentTitle,
  StyledModalTitleWrapper,
} from "./components";

const DeleteAssignmentModalContent = ({
  setIsOpen,
  handleDelete,
  assignmentDetails,
}) => {
  return (
    <StyledChangesContentWrapper className="clickAllow">
      <StyledModalTitleWrapper>
        <StyledContentTitle>
          Are You Sure You Want To Delete -{" "}
          <strong>{assignmentDetails?.name}?</strong>
        </StyledContentTitle>
      </StyledModalTitleWrapper>
      <EditAssignmentButtonsWrapper style={{ marginTop: "20px" }}>
        <EditAssignmentButton
          background={"rgba(213, 47, 57, 0.1)"}
          color={"#D42F39"}
          onClick={handleDelete}
        >
          Delete
        </EditAssignmentButton>
        <EditAssignmentButton
          background={"#fff"}
          color={"#0686D8"}
          border={"1px solid #0686D8"}
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </EditAssignmentButton>
      </EditAssignmentButtonsWrapper>
    </StyledChangesContentWrapper>
  );
};

export default DeleteAssignmentModalContent;
