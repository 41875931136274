import styled from "styled-components";

export const ModalFormWrapper = styled.div`
  padding: 24px 34px;
`;

export const StyledModalSubtitle = styled.h4`
  font-size: 24px;
  font-weight: 500;
`;
export const StyledBatteryTypesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StyledBatteryTypeContainer = styled.div`
  margin-top: 20px;
`;
export const StyledTypeTitle = styled.div`
  font-size: 16px;
`;

export const StyledInputWithBackground = styled.input``;

export const InputWithBackgroundWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const InputPercent = styled.input`
  padding-right: 20px;
  width: 60px;
  text-align: right;
  font-size: 16px;
`;

export const PercentSign = styled.span`
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #666;
  pointer-events: none;
`;
