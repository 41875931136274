import React, { useState } from "react";
import { useContext } from "react";
import deleteRobot from "../../APIs/robots/deleteRobot";
import InfoModal from "../../components/info-modal/InfoModal";
import STRINGS from "../../constants/strings";
import RobotRow from "./RobotRow";
import { InfoModalTypes } from "../../constants/types";
import { RobotsDataContext } from "../../contexts/RobotsDataContext";
import { TableHeader } from "../admin-page/environment-setup/components";

import { RobotsTable, RobotsTableHeaderItems } from "./components";
import MobileRobotCard from "./MobileRobotCard";
import { useSelector } from "react-redux";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";

const RobotsList = ({ isCompanyMember, setIsOpen, setEditRobot }) => {
  const { isMobile, isTablet, isLandscape } = useContext(ResponsiveContext);
  const { RobotsData, setRobotsData } = useContext(RobotsDataContext);
  const [showEdit, setShowEdit] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [chosenItem, setChosenItem] = useState({});
  const { currentRole } = useSelector((state) => state.clients);
  const headerArr = [
    "",
    STRINGS.robot_name,
    STRINGS.current_task,
    STRINGS.battery,
    STRINGS.details,
  ];

  const handleDelete = async (id) => {
    const { data } = await deleteRobot(id);
    if (data === "Robot_deleted_succesfully") {
      const filteredArr = RobotsData.filter((item) => {
        return item.id !== id;
      });
      setRobotsData(filteredArr);
    }
  };

  const handleEdit = (item) => {
    setEditRobot(item);
    setIsOpen(true);
  };
  return (
    <RobotsTable isMobile={isMobile} isTablet={isTablet}>
      {!isMobile && isLandscape && (
        <TableHeader style={{ background: "#0096C6", height: "60px" }}>
          {headerArr?.map((item, index) => (
            <RobotsTableHeaderItems key={index}>{item}</RobotsTableHeaderItems>
          ))}
        </TableHeader>
      )}
      {RobotsData?.map((item) =>
        isMobile || (isTablet && !isLandscape) ? (
          <MobileRobotCard
            key={item.id}
            item={item}
            setShowEdit={setShowEdit}
            showEdit={showEdit}
            handleEdit={handleEdit}
            setIsDeleteModal={setIsDeleteModal}
            setChosenItem={setChosenItem}
            isCompanyMember={isCompanyMember}
          />
        ) : (
          <RobotRow
            key={item.id}
            item={item}
            setShowEdit={setShowEdit}
            showEdit={showEdit}
            handleEdit={handleEdit}
            setIsDeleteModal={setIsDeleteModal}
            setChosenItem={setChosenItem}
            currentRole={currentRole}
          />
        )
      )}
      <InfoModal
        style={{ maxWidth: "52.6rem" }}
        isOpen={isDeleteModal}
        setIsDeleteModal={setIsDeleteModal}
        type={InfoModalTypes.WARNING}
        itemId={chosenItem.id}
        title="Are you sure you want to delete the robot from the list?"
        content="This will delete the robot from the system."
        noOuterClickClosing
        cancelAction={() => setIsDeleteModal(false)}
        submitAction={() => {
          handleDelete(chosenItem.id);
          setIsDeleteModal(false);
          setShowEdit(false);
        }}
        cancelText={"Cancel"}
        submitText={"Yes Delete"}
        disabled={false}
      />
    </RobotsTable>
  );
};

export default RobotsList;
