import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";
import STRINGS from "../../constants/strings";
import URLS from "../../constants/urls";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import CustomButton from "../button/CustomButton";
import {
  AdditionalBackground,
  HeaderBurger,
  HeaderLogo,
  HeaderTail,
  HeaderWrapper,
  MainContainer,
  WholeWrapper,
} from "./components";

const OutlineNonAuthorised = ({ children, doubleBackground }) => {
  const location = useLocation();
  const { isBeforeDesktop, toggleRespSidebar } = useContext(ResponsiveContext);
  return (
    <WholeWrapper doubleBackground={doubleBackground}>
      <AdditionalBackground doubleBackground={doubleBackground}>
        <HeaderWrapper>
          {isBeforeDesktop && (
            <HeaderBurger
              style={{ marginRight: "1rem" }}
              onClick={toggleRespSidebar}
            />
          )}
          <a target="_blank" href={URLS.robotics_page} rel="noreferrer">
            <HeaderLogo
              src={
                isBeforeDesktop
                  ? ICONS.LogoRoundBackground
                  : ICONS.LogoHorizontal
              }
              style={isBeforeDesktop ? {} : { marginRight: "6.4rem" }}
            />
          </a>

          <HeaderTail>
            {location?.pathname === URLS.sign_in ||
            location?.pathname === "/" ? (
              <Link to={URLS.sign_up}>
                <CustomButton
                  borderColor={COLORS.secondary.ncs}
                  capitalize
                  height={isBeforeDesktop ? "3.6rem" : ""}
                  margin={isBeforeDesktop ? "0" : "0 1rem"}
                  padding={isBeforeDesktop ? "0 2.4rem" : ""}
                >
                  {STRINGS.sign_up}
                </CustomButton>
              </Link>
            ) : (
              <Link to={URLS.sign_in}>
                <CustomButton
                  borderColor={COLORS.secondary.ncs}
                  capitalize
                  height={isBeforeDesktop ? "3.6rem" : ""}
                  margin={isBeforeDesktop ? "0" : "0 1rem"}
                  padding={isBeforeDesktop ? "0 2.4rem" : ""}
                >
                  {STRINGS.log_in}
                </CustomButton>
              </Link>
            )}
          </HeaderTail>
        </HeaderWrapper>
        <MainContainer>{children}</MainContainer>
      </AdditionalBackground>
    </WholeWrapper>
  );
};

export default OutlineNonAuthorised;
