import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import type from "prop-types";
import Line from "./handlers/line";
import Polygon from "./handlers/polygon";
import Point from "./handlers/point";
import Route from "./handlers/route";
import Location from "./handlers/location";
import canvasHandler from "./handlers/canvasHandler";
import History from "./handlers/history";
import { MAPLOCATIONENUM, POLYGONENUMS, ZONEIDENUM } from "../../enums";
import { pnpoly, pointInCircle } from "../../utils/pointInShape";
import { StyledTooltipCanvas } from "./components";
import useShowRobot from "./hooks/useShowRobot";

const tools = {
  Point: Point,
  Line: Line,
  Polygon: Polygon,
  route: Route,
  Location: Location,
};

const INITIAL_STATE = {
  Line: [],
};

const DrawCanvas = ({
  initialData,
  imgSrc,
  tool,
  brushSize,
  color,
  onFinishDraw,
  onDataUpdate,
  width,
  height,
  locationType,
  onZoneClick,
  showRobots,
}) => {
  const [state, setState] = useState({
    undoData: [],
    redoData: [],
    data: INITIAL_STATE,
    canvasData: [],
    polygonId: canvasHandler.uuid(),
    rectangleId: canvasHandler.uuid(),
    currentKey: null,
  });

  const [localTool, setLocalTool] = useState(null);
  const [localCtx, setLocalCtx] = useState(null);
  const [pointerCursor, setPointerCursor] = useState(false);
  const { locations } = useSelector((state) => state.maps);
  const canvas = useRef();
  const tipCanvas = useRef();

  useEffect(() => {
    if (localCtx) {
      initialData && imgSrc && loadDraw(initialData);
    } else {
      setLocalCtx(canvas.current.getContext("2d"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localCtx, imgSrc, initialData]);

  useEffect(() => {
    if (tool) {
      const temp = tools[tool];
      temp.ctx = localCtx;
      temp.resetState();
      setLocalTool(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tool]);

  const onMouseDown = (e) => {
    if (localTool) {
      const { polygonId, rectangleId } = state;
      if (tool !== "Line") {
        createNewToolInitialData(tool);
      }
      const key =
        tool === "Line"
          ? "Line"
          : tool === "Polygon"
          ? `Polygon_${polygonId}`
          : `Rectangle_${rectangleId}`;

      setState((prev) => ({
        ...prev,
        currentKey: key,
      }));
      localTool.onMouseDown(
        getCursorPosition(e),
        {
          brushSize,
          color,
          tool,
        },
        (data) => {
          onFinishDraw([data.start.x, data.start.y]);
          setLocalTool(null);
        }
      );
    }
  };

  const createNewToolInitialData = (tool) => {
    const toolId = tool.startsWith("Poly") && "polygonId";
    const keyId = `${tool}_${state[toolId]}`;
    if (!state.data[keyId]) {
      setState((prev) => ({
        ...prev,
        data: { ...prev, [keyId]: [] },
      }));
    }
  };

  const onMouseMove = (e) => {
    if (localTool && localTool.name !== tools.route.name) {
      localTool.onMouseMove(
        getCursorPosition(e),
        locationType === MAPLOCATIONENUM.SHAREDZONE
          ? "rgba(233,35,35,0.42)"
          : "rgba(198, 197, 248, 0.42)",
        () => {
          setState((prev) => ({
            ...prev,
            polygonId: canvasHandler.uuid(),
            currentKey: null,
          }));
          setLocalTool(null);
          onFinishDraw && onFinishDraw(state);
        }
      );
    } else {
      localTool && localTool.onMouseMove(getCursorPosition(e));
      e.preventDefault();
      e.stopPropagation();

      const { x, y } = getCursorPosition(e);

      let isPointInPath = null;
      const { Arrangement, Location, route, ...rest } = initialData;

      Arrangement &&
        Arrangement.forEach(([checkpoint, _, id, name]) => {
          if (pointInCircle(x, y, checkpoint[0], checkpoint[1], 7))
            isPointInPath = {
              type: MAPLOCATIONENUM.CHECKPOINT,
              id,
              name,
            };
        });

      let hitLoc = false;
      Location &&
        Location.forEach(([checkpoint, _, id]) => {
          if (pointInCircle(x, y, checkpoint[0], checkpoint[1], 7)) {
            hitLoc = true;
            isPointInPath = {
              type: MAPLOCATIONENUM.LOCATION,
              id,
            };
            tipCanvas.current.style.display = "unset";
            tipCanvas.current.style.left = x + "px";
            tipCanvas.current.style.top = y + "px";
            tipCanvas.current.innerText = locations.find(
              (item) => item.id === id
            ).name;
          }
        });
      if (!hitLoc) tipCanvas.current.style.display = "none";

      !isPointInPath &&
        Object.values(rest).forEach((arr, index) => {
          const xCoords = arr?.map((xy) => xy[0]);
          const yCoords = arr?.map((xy) => xy[1]);
          if (pnpoly(arr?.length, xCoords, yCoords, x, y)) {
            const id = Object.keys(rest)[index].split("_");
            isPointInPath = {
              type: id[1],
              id: id.last(),
              name: id[2],
              robotId: id[1] === ZONEIDENUM.WORKINGAREA ? id[3] : null,
            };
            return;
          }
        });

      setPointerCursor({ ...isPointInPath, coord: [x, y] });
    }
  };

  const onMouseUp = (e) => {
    if (localTool && localTool.name !== tools.route.name) {
      const newData = localTool.onMouseUp(getCursorPosition(e), () => {
        setState((prev) => ({
          ...prev,
          rectangleId: canvasHandler.uuid(),
          currentKey: null,
        }));
        setLocalTool(null);
        onFinishDraw && onFinishDraw();
      });
      updateData(newData);
    } else {
      onZoneClick(pointerCursor);
    }
  };

  const updateData = (dataFromTool) => {
    const { polygonId, rectangleId } = state;
    const key =
      tool === "Line"
        ? "Line"
        : tool === "Polygon"
        ? `Polygon_${polygonId}`
        : `Rectangle_${rectangleId}`;
    // TODO: Refactor, this code to a DRY version
    if (dataFromTool) {
      const dataToUpdate =
        key.startsWith("Poly") || key.startsWith("Line")
          ? [...state.data[key], dataFromTool.data]
          : [...state.data[key], ...dataFromTool.data];

      const newData = {
        ...state,
        [key]: dataToUpdate,
      };
      setState((prev) => ({
        ...prev,
        undoData: [...prev.undoData, prev.data],
        data: newData,
        canvasData: [...prev.canvasData, dataFromTool.canvas],
      }));

      onDataUpdate && onDataUpdate(newData);
    }
  };

  const getCursorPosition = (e) => {
    // top and left of canvas
    const { top, left } = canvas.current.getBoundingClientRect();
    // clientY and clientX coordinate inside the element that the event occur.
    return {
      x: Math.round(e.clientX - left),
      y: Math.round(e.clientY - top),
    };
  };

  const isCtrlPressed = (event) => event.ctrlKey || event.metaKey;
  const isShiftPressed = (event) => event.shiftKey;

  const onKeyDown = (event) => {
    const isCtrl = isCtrlPressed(event);
    const isShift = isShiftPressed(event);
    const Z = 90;
    // TODO: treat the case where polygon is less than 3 points
    // we must not render it, but neither redo should have its last state
    if (isCtrl && event.which === Z) {
      const modifiedUndo = state.undoData;
      const oneStepBack = History.filterPolygon(modifiedUndo.pop());
      loadDraw(oneStepBack, true);
      setState((prev) => ({
        ...prev,
        data: oneStepBack,
        undoData: modifiedUndo,
        redoData: [...prev.redoData, prev.data],
      }));
    }
    if (isCtrl && isShift && event.which === Z) {
      const modifiedRedo = state.redoData;
      const oneStepForward = modifiedRedo.pop();
      loadDraw(oneStepForward, true);
      setState((prev) => ({ ...prev, data: oneStepForward }));
    }
  };

  const onKeyUp = (event) => {
    if (event.key === "Escape") {
      // undo current drawing
      onZoneClick(null);
      if (state.currentKey) {
        let newData = History.cancel(state.currentKey, state.data);
        setState((prev) => ({
          ...prev,
          data: newData,
          currentKey: null,
        }));
        loadDraw(newData, true);
        onDataUpdate && onDataUpdate(newData);
        setLocalTool(null);
      }
    }
  };

  // TODO: refactor this function to canvas handle
  const loadDraw = (data, byPassReset) => {
    const X = 0,
      Y = 1;
    const START = 0,
      END = 1;
    // clean the canvas
    localCtx.clearRect(0, 0, canvas.current.width, canvas.current.height);
    // loop through the data
    data &&
      Object.keys(data).forEach((el) => {
        let elPoints = data[el];

        // highlightRoute when hovering on route
        if (el === "highlightRoute") {
          if (!elPoints) return;
          let shape = canvasHandler.getTool(tools.route.name);
          const temp = tools[shape];
          temp.ctx = localCtx;

          const wholeRoad = [elPoints.startZone, ...elPoints.road];
          wholeRoad.forEach((point, index, array) => {
            const { coord } = array[index + 1] || {};
            if (point.type === MAPLOCATIONENUM.CHECKPOINT) {
              temp.drawCrossDirection(
                [
                  [point.coord[0], point.coord[1]],
                  [point.coord[0], point.coord[1]],
                ],
                20
              );
            }
            if (coord) {
              temp.draw(
                { x: point.coord[0], y: point.coord[1] },
                { x: coord[0], y: coord[1] },
                false,
                {
                  options: {
                    brushSize: brushSize,
                    strokeStyle: "#003395",
                  },
                }
              );
            }
          });
          temp.drawStartPoint(
            [
              [elPoints.startZone.coord[0], elPoints.startZone.coord[1]],
              [elPoints.startZone.coord[0], elPoints.startZone.coord[1]],
            ],
            20
          );
          temp.drawEndPoint(
            [
              [elPoints.endZone.coord[0], elPoints.endZone.coord[1]],
              [elPoints.endZone.coord[0], elPoints.endZone.coord[1]],
            ],
            20
          );
          return;
        }
        // to show route with dash line
        if (el === "dashedRoute") {
          if (!elPoints) return;
          let shape = canvasHandler.getTool(tools.route.name);
          const temp = tools[shape];
          temp.ctx = localCtx;
          elPoints?.forEach((element) => {
            const wholeRoad = [element.startZone, ...element.road];
            wholeRoad.forEach((point, index, array) => {
              if (point.type === MAPLOCATIONENUM.CHECKPOINT) {
                temp.drawCrossDirection(
                  [
                    [point.coord[0], point.coord[1]],
                    [point.coord[0], point.coord[1]],
                  ],
                  20
                );
              }
              const { coord } = array[index + 1] || {};
              if (coord) {
                temp.drawDashed(
                  { x: point.coord[0], y: point.coord[1] },
                  { x: coord[0], y: coord[1] },
                  false,
                  {
                    options: {
                      brushSize: brushSize,
                      strokeStyle: "#000",
                    },
                  }
                );
              }
            });
          });
          return;
        }
        let shape = canvasHandler.getTool(el);
        const temp = tools[shape];
        temp.ctx = localCtx;
        setLocalTool(temp);
        // avoid mutate initial data;

        if (el.startsWith("Loc")) {
          elPoints.forEach((point) => {
            temp.drawCrossDirection(
              [
                [point[START][X], point[START][Y]],
                [point[END][X], point[END][Y]],
              ],
              20
            );
          });
        } else if (el.startsWith("Line") || el.startsWith("Arrang")) {
          elPoints.forEach((point) => {
            temp.draw(
              { x: point[START][X], y: point[START][Y] },
              { x: point[END][X], y: point[END][Y] },
              false,
              {
                options: { brushSize: brushSize },
              }
            );
            temp.drawCrossDirection(
              [
                [point[START][X], point[START][Y]],
                [point[END][X], point[END][Y]],
              ],
              20
            );
          });
        } else {
          elPoints.forEach((point, index, array) => {
            const nextPoint = array[index + 1] || array[0];
            if (nextPoint) {
              temp.draw(
                { x: point[X], y: point[Y] },
                { x: nextPoint[X], y: nextPoint[Y] },
                false,
                {
                  options: {
                    brushSize: brushSize,
                  },
                }
              );
            }
          });
          if (el.startsWith("Poly")) {
            let zoneColor = "rgba(233,35,35,0.42)";
            if (el.startsWith(POLYGONENUMS.WORKINGAREA))
              zoneColor = "rgba(198, 197, 248, 0.42)";
            temp.fillGeometry(elPoints, zoneColor);
          }
        }
      });
    localTool && localTool.resetState();
    setLocalTool(null);
    onFinishDraw && onFinishDraw();
    if (!byPassReset) {
      setState((prev) => ({
        ...prev,
        data: { ...prev, ...data },
      }));
      onDataUpdate && onDataUpdate({ ...state, ...data });
    }
  };

  useShowRobot({
    ctx: localCtx,
    loadDraw,
    initialData,
    showRobots,
  });

  const determineCursor = useMemo(() => {
    if (pointerCursor?.id) return "pointer";
    switch (locationType) {
      case MAPLOCATIONENUM.CHECKPOINT:
      case MAPLOCATIONENUM.LOCATION:
        return "cell";

      case MAPLOCATIONENUM.SHAREDZONE:
      case MAPLOCATIONENUM.WORKINGAREA:
        return "copy";

      default:
        return "default";
    }
  }, [locationType, pointerCursor]);

  return (
    <React.Fragment>
      <canvas
        tabIndex="1"
        ref={canvas}
        width={width}
        height={height}
        style={{
          backgroundImage: `url(${imgSrc})`,
          backgroundSize: "cover",
          cursor: determineCursor,
        }}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
      />
      <StyledTooltipCanvas ref={tipCanvas} id="tip"></StyledTooltipCanvas>
    </React.Fragment>
  );
};

DrawCanvas.propTypes = {
  /**
   * The width of canvas
   */
  width: type.number,
  /**
   * the height of the canvas
   */
  height: type.number,
  /**
   * Background image to canvas;
   */
  imgSrc: type.string,
  /**
   * BrushSize to draw
   */
  brushSize: type.number,
  /**
   * Color of what we want draw
   */
  color: type.string,
  /**
   * CanUndo
   */
  canUndo: type.bool,
  /**
   * Shapes that you can select to draw
   */
  tool: type.oneOf([
    tools.Line.name,
    tools.Polygon.name,
    tools.Point.name,
    tools.route.name,
    tools.Location.name,
  ]),
  /**
   * Is the data to be be draw when load the component
   */
  initialData: type.object,
  /**
   * This is a callback function that we be called
   * everytime the data updates
   */
  onDataUpdate: type.func,
  /**
   * This is a callback function what we be triggered
   * when the shape is drawn
   */
  onFinishDraw: type.func,
};

DrawCanvas.defaultProps = {
  width: 300,
  height: 300,
  brushSize: 2,
  color: "#2192FF",
  canUndo: false,
};

export default DrawCanvas;
