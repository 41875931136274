import React, { useEffect } from "react";
import STRINGS from "../../constants/strings";
import ModalOutline from "../modal-outline/ModalOutline";
import "./Calendar.css";
import "./TimePicker.css";
import "./Clock.css";
import useAssignmentsModal from "../../hooks/useAssignmentsModal";
import "react-toastify/dist/ReactToastify.css";
import AssignmentModalContent from "./AssignmentModalContent";

const AddAssignmentModal = ({
  isOpen,
  setIsOpen,
  currentModalData,
  newAssignmentForm,
  setNewAssignmentForm,
  setAssignmentsData,
  assignmentsData,
  bottomRef,
  chosenAssignment,
  setChosenAssignment,
}) => {
  const {
    handleModalClose,
    setErrorText,
    handleChooseWeekday,
    handleNameChange,
    handleSubmit,
    handleRadioBtnChange,
    handleTimeChange,
    handleCalendarChange,
    errorText,
    showCalendar,
    setShowCalendar,
  } = useAssignmentsModal({
    newAssignmentForm,
    setNewAssignmentForm,
    setIsOpen,
    assignmentsData,
    setAssignmentsData,
    setChosenAssignment,
    bottomRef,
  });

  useEffect(() => {
    Object.keys(currentModalData).length &&
      setNewAssignmentForm(currentModalData);
  }, [currentModalData, setNewAssignmentForm]);

  return (
    <React.Fragment>
      <ModalOutline
        isOpen={isOpen}
        heading={
          Object.keys(currentModalData).length
            ? STRINGS.edit_assignment
            : STRINGS.create_new_assignment
        }
        onRequestClose={() => {
          handleModalClose();
          setErrorText({});
        }}
      >
        <AssignmentModalContent
          bottomRef={bottomRef}
          newAssignmentForm={newAssignmentForm}
          setNewAssignmentForm={setNewAssignmentForm}
          setIsOpen={setIsOpen}
          assignmentsData={assignmentsData}
          setAssignmentsData={setAssignmentsData}
          currentModalData={currentModalData}
          chosenAssignment={chosenAssignment}
          setChosenAssignment={setChosenAssignment}
          handleChooseWeekday={handleChooseWeekday}
          handleNameChange={handleNameChange}
          handleSubmit={handleSubmit}
          handleRadioBtnChange={handleRadioBtnChange}
          handleTimeChange={handleTimeChange}
          handleCalendarChange={handleCalendarChange}
          errorText={errorText}
          showCalendar={showCalendar}
          setShowCalendar={setShowCalendar}
        />
      </ModalOutline>
    </React.Fragment>
  );
};

export default AddAssignmentModal;
