import styled from "styled-components";
import { motion } from "framer-motion";

export const ModalContainer = styled(motion.div)`
  width: 50%;
  height: 50%;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
`;

export const ModalContentWrapper = styled.div`
  background: #fff;
  position: relative;
  @keyframes unfoldIn {
    0% {
      transform: scaleY(0.005) scaleX(0);
    }
    50% {
      transform: scaleY(0.005) scaleX(1);
    }
    100% {
      transform: scaleY(1) scaleX(1);
    }
  }

  @keyframes unfoldOut {
    0% {
      transform: scaleY(1) scaleX(1);
    }
    50% {
      transform: scaleY(0.005) scaleX(1);
    }
    100% {
      transform: scaleY(0.005) scaleX(0);
    }
  }
`;

export const CloseButton = styled.svg`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 18px;
  top: 18px;
  cursor: pointer;
`;

export const StyledModalTitle = styled.h1`
  color: #030a1b;
  font-weight: 400;
  font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
`;

export const StyledHeaderLogo = styled.img`
  height: 56px;
  width: 100%;
  margin-top: 20px;
`;

export const StatusAndNameWrapper = styled.div`
  > span {
    display: none;
    color: #909491;
    text-align: left;
  }
`;
