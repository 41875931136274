import axios from "axios";

const getTasksFromArchive = async (clientId, day, timezone) => {
  const res = await axios.get(
    `/clients/${clientId}/agenda/${day}/${encodeURIComponent(timezone)}`
  );
  return res.data;
};

export default getTasksFromArchive;
