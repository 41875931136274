import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addLocation as addLocationRedux,
  deleteLocation as deleteLocationRedux,
} from "../../redux/maps";
import {
  addCheckpoint,
  addSharedZone,
  addWorkingArea,
  addLocation,
  deleteCheckpoint,
  deleteSharedZone,
  deleteWorkingArea,
  deleteMap,
} from "../../APIs";
import {
  CHECKPOINTDIRECTIONENUM,
  MAPLOCATIONENUM,
  ZONEIDENUM,
} from "../../enums";
import {
  populateSharedZone,
  populateWorkingArea,
} from "../../utils/populateZones";
import deleteLocation from "../../APIs/locations/deleteLocation";

export const useDrawCanvas = ({
  setCanvasAreas,
  chosenMap,
  handleFetch,
  setCurrentModalData,
  newLocationForm,
  setInputModalData,
  robots,
  visibleCanvasItems,
}) => {
  //canvas
  const [canvasDimensions, setCanvasDimensions] = useState(null);
  const [tool, setTool] = useState(null);

  //canvas drawings
  const [locationType, setLocationType] = useState("");
  const [chosenZone, setChosenZone] = useState(null);
  const [checkpointData, setCheckpointData] = useState(null);
  const [routeData, setRouteData] = useState(null);
  const [dashedRoutes, setDashedRoutes] = useState([]);
  const [visibleCanvasRoutes, setVisibleCanvasRoutes] = useState(null);

  //modals
  const [addCheckpointText, setAddCheckpointText] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLocationModal, setIsLocationModal] = useState(false);
  // redux
  const { chosenClient } = useSelector((state) => state.clients);
  const dispatch = useDispatch();

  // update states
  const [prevZoneData, setPrevZoneData] = useState(null);

  // cycle hooks

  useEffect(() => {
    setCanvasDimensions(null);
    const img = new Image();
    img.src = chosenMap?.mapUrl;
    img.onload = function () {
      setCanvasDimensions([this.width, this.height]);
    };
  }, [chosenMap?.mapUrl]);

  useEffect(() => {
    if (
      (checkpointData?.workingAreaId || checkpointData?.endSharedZoneId) &&
      (checkpointData?.sharedZoneId || checkpointData?.endWorkingAreaId)
    ) {
      const callback = async (name, _, angle) => {
        const checkpoint = await addCheckpoint({
          ...checkpointData,
          location: [...checkpointData.location, angle],
          name,
          mapId: chosenMap?.id,
        });
        setCanvasAreas((prev) => ({
          ...prev,
          Arrangement: [
            ...prev.Arrangement,
            [
              checkpoint.pixelCoordinates,
              checkpoint.pixelCoordinates,
              checkpoint.id,
              checkpoint.name,
            ],
          ],
        }));
        setCheckpointData(null);
        setAddCheckpointText("");
      };

      setInputModalData({
        isOpen: true,
        callback,
        title: "Create checkpoint",
        angle: true,
      });
    } else if (
      checkpointData &&
      !checkpointData.workingAreaId &&
      !checkpointData.sharedZoneId
    )
      setAddCheckpointText("Please choose the start zone");
    else if (
      checkpointData &&
      (!checkpointData.workingAreaId || !checkpointData.endSharedZoneId)
    )
      setAddCheckpointText("Please choose the ending zone");
  }, [checkpointData, setCanvasAreas, setInputModalData, chosenMap?.id]);

  // functions

  const handleAddEntry = useCallback((type) => {
    setLocationType(type);
    if (MAPLOCATIONENUM.LOCATION === type) {
      setAddCheckpointText(
        "Press on a point inside the map to create location"
      );
      setTool("Point");
    } else if (MAPLOCATIONENUM.CHECKPOINT === type) {
      setTool("Point");
    } else if (
      [MAPLOCATIONENUM.SHAREDZONE, MAPLOCATIONENUM.WORKINGAREA].includes(type)
    )
      setTool("Polygon");
    else if (MAPLOCATIONENUM.ROUTE === type) {
      setAddCheckpointText("Please choose the START zone");
      setTool("route");
    }
  }, []);

  const handleAddLocation = useCallback(async () => {
    const newLocation = await addLocation({
      clientId: chosenClient,
      location: [
        +newLocationForm.x,
        +newLocationForm.y,
        +newLocationForm.direction,
      ],
      mapId: chosenMap?.id,
      name: newLocationForm.name,
      direction: +newLocationForm.direction,
    });
    dispatch(addLocationRedux(newLocation));
    setIsLocationModal(false);
    setAddCheckpointText("");
  }, [newLocationForm, dispatch, chosenClient, chosenMap?.id]);

  const handleZoneClick = useCallback(
    (data) => {
      if (data.type === MAPLOCATIONENUM.CHECKPOINT)
        data.coord = visibleCanvasItems.Arrangement.find((item) =>
          item.includes(data.id)
        )[0];
      if (!tool && !checkpointData) {
        setDashedRoutes((prev) => {
          if (!data?.id) return prev;
          if (prev.includes(data?.id))
            return prev.filter((item) => item !== data.id);
          else return [...prev, data?.id];
        });
      }

      if (locationType === MAPLOCATIONENUM.ROUTE) {
        setRouteData((prev) => {
          let route = { ...prev };
          if (!route.startZone) {
            route.startZone = data;
            setAddCheckpointText("Choose the points of the route");
          } else {
            route.road = route.road
              ? [...route.road, { ...data, order: route.road.length + 1 }]
              : [{ ...data, order: 1 }];
            setAddCheckpointText(
              `Points: ${route.road.length} - click "Enter" or "Finish route" button when done`
            );
          }

          return route;
        });
      } else if (checkpointData) {
        if (data.type === ZONEIDENUM.SHAREDZONE) {
          if (!checkpointData.sharedZoneId) {
            setCheckpointData((prev) => ({
              ...prev,
              sharedZoneId: data.id,
              direction: CHECKPOINTDIRECTIONENUM.WTS,
            }));
          } else {
            setCheckpointData((prev) => ({
              ...prev,
              endSharedZoneId: data.id,
              direction: CHECKPOINTDIRECTIONENUM.STS,
            }));
          }
        } else if (data.type === ZONEIDENUM.WORKINGAREA) {
          setCheckpointData((prev) => {
            if (prev.workingAreaId)
              return {
                ...prev,
                endWorkingAreaId: data.id,
                direction: CHECKPOINTDIRECTIONENUM.WTW,
              };
            else
              return {
                ...prev,
                workingAreaId: data.id,
                direction: CHECKPOINTDIRECTIONENUM.STW,
              };
          });
        }
      } else if (locationType !== MAPLOCATIONENUM.LOCATION) {
        data?.type && setChosenZone(data);
      }
    },
    [checkpointData, locationType, tool, visibleCanvasItems]
  );

  const handleFinish = useCallback(
    async (data) => {
      let key, newData;
      setLocationType(null);
      switch (locationType) {
        case MAPLOCATIONENUM.CHECKPOINT:
          setCheckpointData({
            clientId: chosenClient,
            location: data,
          });
          break;

        case MAPLOCATIONENUM.LOCATION:
          setCurrentModalData({
            name: "room" + Math.round(Math.random() * 1000),
            direction: Math.round(Math.random() * 360),
            location: data,
            x: data[0] || "",
            y: data[1] || "",
            clientId: chosenClient,
          });
          setIsLocationModal(true);
          break;

        case MAPLOCATIONENUM.SHAREDZONE:
          key = "Polygon_" + data.polygonId;
          newData = data.data[key].map((item) => [item[0], item[1]]);

          const callback = async (name) => {
            const sharedZone = await addSharedZone({
              clientId: chosenClient,
              polygon: [...newData, newData[0]],
              name,
              updateId: prevZoneData?.id,
              mapId: chosenMap?.id,
            });
            setCanvasAreas((prev) => {
              let newState = { ...prev };
              if (prevZoneData?.id) {
                const prevKey = Object.keys(prev).find((item) =>
                  item.includes(prevZoneData?.id)
                );
                if (prevKey) Reflect.deleteProperty(newState, prevKey);
              }

              return {
                ...newState,
                ...populateSharedZone(sharedZone),
              };
            });
          };

          setInputModalData({
            isOpen: true,
            callback,
            title: "Create shared zone",
            name: prevZoneData?.name,
          });

          break;

        case MAPLOCATIONENUM.WORKINGAREA:
          if (data) {
            const callback = async (name, robotId) => {
              key = "Polygon_" + data.polygonId;
              newData = data.data[key].map((item) => [item[0], item[1]]);

              const payload = {
                clientId: chosenClient,
                robotId,
                name,
                polygon: [...newData, newData[0]],
                updateId: prevZoneData?.id,
                mapId: chosenMap?.id,
              };

              const workingArea = await addWorkingArea(payload);
              setCanvasAreas((prev) => {
                let newState = { ...prev };
                if (prevZoneData?.id) {
                  const prevKey = Object.keys(prev).find((item) =>
                    item.includes(prevZoneData?.id)
                  );
                  if (prevKey) Reflect.deleteProperty(newState, prevKey);
                }

                return {
                  ...newState,
                  ...populateWorkingArea(workingArea, robotId),
                };
              });
              setAddCheckpointText("");
            };

            setInputModalData({
              isOpen: true,
              callback,
              title: "Create working area",
              robots: robots,
              name: prevZoneData?.name,
              chosenRobotId: prevZoneData?.robotId,
            });
          }
          break;
        default:
          break;
      }
      setTool(null);
      setPrevZoneData(null);
    },
    [
      locationType,
      chosenClient,
      setCanvasAreas,
      setCurrentModalData,
      robots,
      setInputModalData,
      prevZoneData,
      chosenMap?.id,
    ]
  );

  const finishRouting = useCallback(async () => {
    if (!routeData) return;
    routeData.endZone = routeData.road.last();

    let workingAreaId,
      sharedZoneId,
      endSharedZoneId,
      endWorkingAreaId,
      direction;

    if (routeData.startZone.type === ZONEIDENUM.WORKINGAREA) {
      if (routeData.endZone.type === ZONEIDENUM.SHAREDZONE) {
        workingAreaId = routeData.startZone.id;
        sharedZoneId = routeData.endZone.id;
        direction = CHECKPOINTDIRECTIONENUM.WTS;
      } else {
        workingAreaId = routeData.startZone.id;
        endWorkingAreaId = routeData.endZone.id;
        direction = CHECKPOINTDIRECTIONENUM.WTW;
      }
    }

    if (routeData.startZone.type === ZONEIDENUM.SHAREDZONE) {
      if (routeData.endZone.type === ZONEIDENUM.SHAREDZONE) {
        sharedZoneId = routeData.startZone.id;
        endSharedZoneId = routeData.endZone.id;
        direction = CHECKPOINTDIRECTIONENUM.STS;
      } else {
        sharedZoneId = routeData.startZone.id;
        workingAreaId = routeData.endZone.id;
        direction = CHECKPOINTDIRECTIONENUM.STW;
      }
    }

    const roadCheckpointData = {
      clientId: chosenClient,
      direction,
      sharedZoneId,
      workingAreaId,
      endSharedZoneId,
      endWorkingAreaId,
      location: routeData.endZone.coord,
      route: JSON.stringify(routeData),
      mapId: chosenMap?.id,
    };
    const checkpoint = await addCheckpoint(roadCheckpointData);
    setCanvasAreas((prev) => ({
      ...prev,
      route: [
        ...prev.route,
        {
          ...JSON.parse(checkpoint.route),
          id: checkpoint.id,
          type: MAPLOCATIONENUM.ROUTE,
        },
      ],
    }));
    setCheckpointData(null);
    setAddCheckpointText("");
    setRouteData(null);
  }, [routeData, chosenClient, setCanvasAreas, chosenMap?.id]);

  const handleZoneUpdate = useCallback(async (zone) => {
    if (ZONEIDENUM.SHAREDZONE === zone.type) {
      setLocationType(MAPLOCATIONENUM.SHAREDZONE);
      setTool("Polygon");
    } else if (ZONEIDENUM.WORKINGAREA === zone.type) {
      setLocationType(MAPLOCATIONENUM.WORKINGAREA);
      setTool("Polygon");
    }
    setPrevZoneData(zone);
  }, []);

  const handleZoneDelete = useCallback(
    async (zone) => {
      switch (zone.type) {
        case ZONEIDENUM.CHHECKPOINT:
          await deleteCheckpoint(zone.id);
          setCanvasAreas((prev) => ({
            ...prev,
            Arrangement: prev.Arrangement.filter(
              ([x, y, id]) => zone.id !== id
            ),
          }));
          break;

        case ZONEIDENUM.LOCATION:
          await deleteLocation(zone.id);
          setCanvasAreas((prev) => ({
            ...prev,
            Location: prev.Location.filter(([x, y, id]) => zone.id !== id),
          }));
          dispatch(deleteLocationRedux(zone.id));
          break;

        case ZONEIDENUM.ROUTE:
          await deleteCheckpoint(zone.id);
          setCanvasAreas((prev) => ({
            ...prev,
            route: prev.route.filter(({ id }) => zone.id !== id),
          }));
          setDashedRoutes((prev) => prev.filter((item) => item !== zone.id));
          break;

        case ZONEIDENUM.SHAREDZONE:
          await deleteSharedZone(zone.id);
          setCanvasAreas((prev) => ({
            ...Object.fromEntries(
              Object.entries(prev).filter(([key]) => !key.endsWith(zone.id))
            ),
          }));
          break;

        case ZONEIDENUM.WORKINGAREA:
          await deleteWorkingArea(zone.id);
          setCanvasAreas((prev) => ({
            ...Object.fromEntries(
              Object.entries(prev).filter(([key]) => !key.endsWith(zone.id))
            ),
          }));
          break;

        default:
          break;
      }
      setChosenZone(null);
    },
    [setCanvasAreas, dispatch]
  );

  const handleMapDelete = useCallback(async () => {
    await deleteMap(chosenMap.id, "mapUrl");
    await handleFetch(chosenClient);
    setDeleteModal(false);
    setCanvasDimensions(null);
  }, [chosenMap, chosenClient, handleFetch]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      switch (event.key) {
        case "Escape":
          setCheckpointData(null);
          setAddCheckpointText("");
          setRouteData(null);
          setLocationType(null);
          setChosenZone(null);
          break;

        case "Enter":
          event.preventDefault();
          finishRouting();
          break;

        default:
          break;
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [finishRouting]);

  useEffect(() => {
    setCanvasAreas((prev) => {
      const newData = prev.route?.map((road) => {
        if (dashedRoutes.includes(road.startZone.id)) return road;
        else return null;
      });
      setVisibleCanvasRoutes(newData?.filter((item) => item));
      return { ...prev, dashedRoute: newData?.filter((item) => item) };
    });
  }, [dashedRoutes, setCanvasAreas]);

  return {
    locationType,
    canvasDimensions,
    chosenZone,
    addCheckpointText,
    deleteModal,
    setDeleteModal,
    tool,
    handleAddEntry,
    handleFinish,
    handleZoneClick,
    handleZoneDelete,
    handleZoneUpdate,
    handleMapDelete,
    routeData,
    finishRouting,
    isLocationModal,
    setIsLocationModal,
    handleAddLocation,
    setChosenZone,
    visibleCanvasRoutes,
  };
};
