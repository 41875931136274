import { createSlice } from "@reduxjs/toolkit";
import { ROBOTTASKSTATUSENUM } from "../enums";

const initialState = {
  robotsData: [],
  hoveredRobot: null,
  robotTaskWarningData: [],
  streamPopup: false,
  inProgressTasks: [],
  lowBatteryRobotsQueue: [],
  batterfyConfigurationData: null,
  emergencyPressedRobots: [],
};

export const robotsSlice = createSlice({
  name: "robots",
  initialState,
  reducers: {
    setRobotsDataRedux: (state, action) => {
      state.robotsData = action.payload;
    },
    setHoveredRobot: (state, action) => {
      state.hoveredRobot = action.payload;
    },
    setStreamPopup: (state, action) => {
      state.streamPopup = action.payload;
    },
    setRobotTaskWarningData: (state, action) => {
      state.robotTaskWarningData = [
        ...state.robotTaskWarningData,
        action.payload,
      ];
    },
    setInprogressTasks: (state, action) => {
      let obj = { ...action.payload };
      const isExisted = [...state.inProgressTasks].findIndex(
        (el) => el.id === obj.id
      );
      if (isExisted > -1) {
        state.inProgressTasks = state.inProgressTasks.map((el) =>
          el.id === obj.id ? obj : el
        );
      } else {
        state.inProgressTasks = [...state.inProgressTasks, obj];
      }
    },
    clearFinishedTasks: (state) => {
      state.inProgressTasks = state.inProgressTasks.filter(
        (el) => el.status === ROBOTTASKSTATUSENUM.INPROGRESS
      );
    },
    setAbortTask: (state, action) => {
      const data = { ...action.payload };
      state.robotTaskWarningData = [...state.robotTaskWarningData].filter(
        (el) => el.id !== data.id
      );
    },
    setLowBatteryRobotsQueue: (state, action) => {
      state.lowBatteryRobotsQueue = action.payload;
    },
    deleteFirstRobotFromQueue: (state, action) => {
      state.lowBatteryRobotsQueue = state.lowBatteryRobotsQueue.filter(
        (el, index) => index !== 0
      );
    },
    setEmergencyPressedRobots: (state, action) => {
      state.emergencyPressedRobots = [
        ...state.emergencyPressedRobots,
        action.payload,
      ];
    },
    deleteFirstEmergencyPressedRobot: (state, action) => {
      state.emergencyPressedRobots = state.emergencyPressedRobots.filter(
        (el, index) => index !== 0
      );
    },

    setBatterfyConfigurationData: (state, action) => {
      state.batterfyConfigurationData = action.payload;
    },
  },
});

export const {
  setRobotsDataRedux,
  setHoveredRobot,
  setRobotTaskWarningData,
  setAbortTask,
  setStreamPopup,
  setInprogressTasks,
  clearFinishedTasks,
  setLowBatteryRobotsQueue,
  deleteFirstRobotFromQueue,
  setBatterfyConfigurationData,
  setEmergencyPressedRobots,
  deleteFirstEmergencyPressedRobot,
} = robotsSlice.actions;

export default robotsSlice.reducer;
