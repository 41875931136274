import React, { useEffect, useState } from "react";
import {
  ComponentHeader,
  ComponentTitle,
} from "../environment-setup/components";
import STRINGS from "../../../constants/strings";
import { HeaderStyledButton } from "../../robots/assignments/components";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import {
  AnalyticsHeader,
  AnalyticsHeaderBtn,
  AnalytisHeaderContainer,
} from "../analytics/components";
import AnalyticsLogTable from "./AnalyticsLogTable";

const PERIODENUM = {
  FIRST: "1 hour",
  SECOND: "3 hours",
  THIRD: "Today",
  FOURTH: "Last week",
  FITH: "Last month",
};

const periodTypes = [
  PERIODENUM.FIRST,
  PERIODENUM.SECOND,
  PERIODENUM.THIRD,
  PERIODENUM.FOURTH,
  PERIODENUM.FITH,
];

const timeformat = "YYYY-MM-DD[T]HH:mm:ss";

const AnalyticsLog = () => {
  const [chosenPeriod, setChosenPeriod] = useState(PERIODENUM.FIRST);

  const [addEventModalOpen, setAddEventModalOpen] = useState(false);
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    if (chosenPeriod) {
      let params = new URLSearchParams();

      switch (chosenPeriod) {
        case PERIODENUM.FIRST:
          params.append(
            "from",
            moment().subtract(1, "hour").format(timeformat).toString()
          );
          params.append("to", moment().format(timeformat).toString());
          break;

        case PERIODENUM.SECOND:
          params.append(
            "from",
            moment().subtract(3, "hour").format(timeformat).toString()
          );
          params.append("to", moment().format(timeformat).toString());
          break;

        case PERIODENUM.THIRD:
          params.append(
            "from",
            moment(new Date()).startOf("day").format(timeformat).toString()
          );
          params.append("to", moment(new Date()).format(timeformat).toString());
          break;

        case PERIODENUM.FOURTH:
          params.append(
            "from",
            moment().subtract(7, "day").format(timeformat).toString()
          );
          params.append("to", moment().format(timeformat).toString());
          break;

        case PERIODENUM.FITH:
          params.append(
            "from",
            moment().subtract(31, "day").format(timeformat).toString()
          );
          params.append("to", moment().format(timeformat).toString());
          break;

        default:
          params = search;
        // setChosenPeriod("Custom");
      }

      history.push({
        pathname: "/admin/analytics-log",
        search: params.toString(),
      });
    }
  }, [chosenPeriod, history, search]);

  return (
    <React.Fragment>
      <ComponentHeader>
        <ComponentTitle>{`${STRINGS.analytics_log}`}</ComponentTitle>
        <div style={{ position: "relative" }}>
          <HeaderStyledButton onClick={() => setAddEventModalOpen(true)}>
            {STRINGS.add_event}
          </HeaderStyledButton>
        </div>
      </ComponentHeader>
      <AnalyticsHeader>
        <AnalytisHeaderContainer>
          {periodTypes.map((el) => (
            <AnalyticsHeaderBtn
              key={el}
              chosen={chosenPeriod === el}
              onClick={() => setChosenPeriod(el)}
            >
              {el}
            </AnalyticsHeaderBtn>
          ))}
        </AnalytisHeaderContainer>
      </AnalyticsHeader>

      <AnalyticsLogTable
        addEventModalOpen={addEventModalOpen}
        setAddEventModalOpen={setAddEventModalOpen}
      />
    </React.Fragment>
  );
};

export default AnalyticsLog;
