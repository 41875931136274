import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import {
  LabelText,
  RadioInput,
  StyledRadioLabel,
} from "../add-assignment-modal/components";
import {
  EditAssignmentButton,
  EditAssignmentButtonsWrapper,
  StyledChangesContentWrapper,
  StyledContentTitle,
  StyledModalTitleWrapper,
} from "./components";

const DeleteAssignmentRunEvery = ({
  setIsDeleteAssignmentRunEvery,
  handleDeleteAssignment,
  hadnleSubmitOTE,
}) => {
  const { assignmentDetails } = useSelector((state) => state.dashboard);
  const [onAll, setOnAll] = useState(false);

  const handleDeleteClick = useCallback(async () => {
    if (onAll) {
      await handleDeleteAssignment();
      setIsDeleteAssignmentRunEvery(false);
    } else {
      const closeModal = () => setIsDeleteAssignmentRunEvery(false);
      await hadnleSubmitOTE(
        assignmentDetails?.agendaDate,
        closeModal,
        assignmentDetails
      );
    }
  }, [
    onAll,
    assignmentDetails,
    hadnleSubmitOTE,
    handleDeleteAssignment,
    setIsDeleteAssignmentRunEvery,
  ]);

  return (
    <StyledChangesContentWrapper className="clickAllow">
      <StyledModalTitleWrapper style={{ alignItems: "flex-start" }}>
        <StyledContentTitle>
          Edit Recurring Assignment - <strong>{assignmentDetails?.name}</strong>
        </StyledContentTitle>
      </StyledModalTitleWrapper>
      <div style={{ marginTop: "36px" }}>
        <StyledRadioLabel style={{ marginTop: "15px" }}>
          <RadioInput
            type="radio"
            name="when"
            value={"Only this event"}
            onChange={(e) => setOnAll(!e.target.checked)}
            checked={!onAll}
          />
          <LabelText style={{ fontSize: "16px" }}>Only this event</LabelText>
        </StyledRadioLabel>
        <StyledRadioLabel style={{ marginTop: "15px" }}>
          <RadioInput
            type="radio"
            name="when"
            value={"This and following events"}
            onChange={(e) => setOnAll(e.target.checked)}
            checked={onAll}
          />
          <LabelText style={{ fontSize: "16px" }}>
            This and following events
          </LabelText>
        </StyledRadioLabel>
      </div>
      <EditAssignmentButtonsWrapper
        style={{ padding: "unset", marginTop: "36px" }}
      >
        <EditAssignmentButton
          background={"rgba(213, 47, 57, 0.1)"}
          color={"#D42F39"}
          onClick={handleDeleteClick}
        >
          Delete
        </EditAssignmentButton>
        <EditAssignmentButton
          background={"#fff"}
          color={"#0686D8"}
          border={"1px solid #0686D8"}
          onClick={() => {
            setIsDeleteAssignmentRunEvery(false);
          }}
        >
          Cancel
        </EditAssignmentButton>
      </EditAssignmentButtonsWrapper>
    </StyledChangesContentWrapper>
  );
};

export default DeleteAssignmentRunEvery;
