import styled from "styled-components";

export const StyledAdjustMapWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;

  > div {
    width: 500px;
    height: 500px;
  }

  > label {
    width: 100%;
    text-align: center;
  }
`;

export const StyledMap = styled.div`
  position: relative;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
  border-radius: "20px";
`;

export const StyledDot = styled.div`
  position: absolute;
  left: ${({ localMousePos }) => localMousePos.x + "px"};
  top: ${({ localMousePos }) => localMousePos.y + "px"};
  width: 5px;
  height: 5px;
  background: red;
  border-radius: 5px px;
`;

export const StyledAdjustMapOuterWrapper = styled.div`
  padding: 20px;

  > button {
    width: 200px;
    margin-top: 20px;
    margin-left: calc(50% - 100px);
    border-radius: 2.8rem;
    border: none;
    background: rgb(6, 134, 216);
    box-shadow: none;
    font-family: Ubuntu-Bold;
    font-size: 1.6rem;
    color: rgb(255, 255, 255);
    padding: 8px 24px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    opacity: 1;
    text-transform: none;
  }
`;
