import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  elementCoordinates: null,
  text: "",
  tooltipStyles: null,
  tooltipChildWidth: null,
  tooltipChildHeight: null,
  type: "top",
  top: 0,
  left: 0,
};

export const tooltipSlice = createSlice({
  name: "robots",
  initialState,
  reducers: {
    setElementCoordinates: (state, action) => {
      state.elementCoordinates = action.payload;
    },
    setTooltipText: (state, action) => {
      state.text = action.payload;
    },
    setTooltipStyles: (state, action) => {
      state.tooltipStyles = action.payload;
    },
    setTooltipChildWidth: (state, action) => {
      state.tooltipChildWidth = action.payload;
    },
    setTooltipChildHeight: (state, action) => {
      state.tooltipChildHeight = action.payload;
    },
    setTooltipType: (state, action) => {
      if (action.payload) {
        state.type = action.payload;
      }
    },
    setTop: (state, action) => {
      state.top = action.payload;
    },
    setLeft: (state, action) => {
      state.left = action.payload;
    },
  },
});

export const {
  setElementCoordinates,
  setTooltipText,
  setTooltipStyles,
  setTooltipChildWidth,
  setTooltipType,
  setTooltipChildHeight,
  setTop,
  setLeft,
} = tooltipSlice.actions;

export default tooltipSlice.reducer;
