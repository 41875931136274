import React from "react";
import URLS from "../../constants/urls";
import useRouting from "../../hooks/useRouting";
import RobotDetails from "./RobotDetails";
import Robots from "./Robots";

const RobotRoutes = () => {
  const { PrivateRoute } = useRouting();

  return (
    <React.Fragment>
      <PrivateRoute exact path={URLS.robots} component={Robots} />
      <PrivateRoute exact path={URLS.robots_id} component={RobotDetails} />
    </React.Fragment>
  );
};

export default RobotRoutes;
