import { useState, useEffect } from "react";
import moment from "moment";
import { StyledClockWrapper } from "./components";

const Clock = ({ sidebarOpen }) => {
  const [date, setDate] = useState(new Date());

  const refreshClock = () => {
    setDate(new Date());
  };
  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);
  return (
    <StyledClockWrapper sidebarOpen={sidebarOpen}>
      {moment(date).format("HH:mm")}
    </StyledClockWrapper>
  );
};
export default Clock;
