import styled from "styled-components";
import FONTS from "../../constants/fonts";
import ICONS from "../../constants/icons";
import { ROBOTSTATUSENUM } from "../../enums";

export const StyledHeaderBtn = styled.button`
  background: #0096c6;
  border-radius: 48px;
  color: #ffffff;
  font-family: ${FONTS.UbuntuRegular};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  width: 118px;
  height: 32px;
  border: unset;
  cursor: pointer;
`;

export const RobotDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const RobotDetailsButtonWrapper = styled.div`
  width: 118px;
`;
export const HeaderRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  justify-content: center;
`;
export const SwitchToMapWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  border-radius: 16px;
  background: #fff;
`;
export const MapWrapperTitle = styled.div`
  font-size: 20px;
  line-height: 23px;
  width: 179px;
  height: 23px;
  margin-left: 37px;
  margin-right: 20px;
`;
export const SwitchIcon = styled.img`
  width: 43px;
`;

export const RobotsTable = styled.div`
  width: 100%;
  margin-top: ${(props) => (!props.isMobile || props.isTablet) && "20px"};
  display: ${(props) => (props.isMobile || props.isTablet) && "flex"};
  flex-direction: ${(props) => (props.isMobile || props.isTablet) && "column"};
`;
export const RobotsTableHeaderItems = styled.div`
  width: 100%;
  padding: 12px 0px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  &:first-child {
    text-align: center;
  }
`;

export const RobotsTableRow = styled.div`
  margin-top: 10px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #fff;
  :hover {
    border-color: #0096c6;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
    .view-details-wrapper {
      > button {
        border-color: #0096c6;
      }
    }
  }
`;

export const RobotsRowItem = styled.div`
  width: 100%;
  border: unset;
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  background-color: #fff;
  padding: 12px 8px 12px 8px;
  display: flex;
  align-items: center;
  /* text-transform: lowercase;  use it with inline styling if needed*/

  ::first-letter {
    text-transform: capitalize;
  }
`;

const RobotIcon = ({ status }) => {
  switch (status) {
    case ROBOTSTATUSENUM.FREE:
      return ICONS.FreeRobot;
    case ROBOTSTATUSENUM.BUSY:
      return ICONS.ChargingRobot;
    case ROBOTSTATUSENUM.CHARGING:
      return ICONS.ChargingRobot;
    case ROBOTSTATUSENUM.PAUSED:
      return ICONS.OutOfServiceRobot;
    case ROBOTSTATUSENUM.ERROR:
      return ICONS.ErrorRobot;
    case ROBOTSTATUSENUM.OFF:
      return ICONS.OffRobot;
    default:
      return ICONS.OffRobot;
  }
};

const BatteryIcon = ({ battery }) => {
  switch (true) {
    case 0 <= +battery && +battery <= 0.2:
      return ICONS.Twenty;
    case 0.2 < +battery && +battery <= 0.4:
      return ICONS.Forty;
    case 0.4 < +battery && +battery <= 0.6:
      return ICONS.Sixty;
    case 0.6 < +battery && +battery <= 0.8:
      return ICONS.Eighty;
    case 0.8 < +battery && +battery <= 1.0:
      return ICONS.Hundred;
    default:
      return "";
  }
};

export const RobotRowPhoto = styled.div`
  margin: 5px;
  width: 50px;
  height: 50px;
  background-image: url(${RobotIcon});
  background-position: center;
  background-size: cover;
`;

export const RobotLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const StatusColor = ({ status }) => {
  switch (status) {
    case ROBOTSTATUSENUM.BUSY:
      return "#34619E";
    case ROBOTSTATUSENUM.FREE:
      return "#2FD553";
    case ROBOTSTATUSENUM.CHARGING:
      return "#34619E";
    case ROBOTSTATUSENUM.ERROR:
      return "#D52F39";
    case ROBOTSTATUSENUM.PAUSED:
      return "#D5B02F";
    case ROBOTSTATUSENUM.OFF:
      return "#909491";
    default:
      return "Off";
  }
};
export const StyledStatus = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: ${StatusColor};
`;

export const StyledBattery = styled.div`
  margin: 5px;
  width: 53px;
  height: 26px;
  background-image: url(${BatteryIcon});
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
`;
export const PercentageWrapper = styled.div`
  width: 40px;
  font-size: 14px;
`;

export const RobotDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
`;

export const BackArrow = styled.img``;

export const BackToListText = styled.div`
  color: #08183f;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  /* margin-left: 10px; */
`;

export const GeneralInfoWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.04);
  border-radius: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  /* gap: 10%; */
  padding: 36px;
  position: relative;
`;
export const RobotStatusWrapper = styled.div`
  display: flex;
  gap: 40px;
`;
export const NameAndIdWrapper = styled.div``;

export const RobotName = styled.div`
  color: #030a1b;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
`;
export const RobotId = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  color: #030a1b;
  margin-top: 30px;
`;

export const LineBorder = styled.div`
  width: 60px;
  border-bottom: 1px solid #909491;
  transform: rotate(90deg);
`;

export const BatteryAndSignalWrapper = styled.div``;
export const BatteryWrapper = styled.div`
  display: flex;
`;
export const SignalWrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;
export const BatteryContent = styled.div``;
export const SignalContent = styled.div``;
export const DataInfo = styled.div`
  color: #030a1b;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
`;

export const SettingsAndTaskWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.04);
  border-radius: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 30px;
  padding: 36px;
`;

export const StyledSettingsButton = styled.button`
  width: 200px;
  height: 58px;
  background: #ffffff;
  border: 2px solid #0096c6;
  border-radius: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
`;

export const AssignTaskButton = styled.button`
  width: 200px;
  height: 58px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)),
    #0096c6;
  border-radius: 48px;
  color: #fff;
  border: unset;
  cursor: pointer;
  :hover {
    box-shadow: 0px 4px 32px rgba(0, 150, 198, 0.5);
  }
  :active {
    box-shadow: unset;
  }
`;
export const SettingIcon = styled.img`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
`;

export const CurrentTaskWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.04);
  border-radius: 16px;
  height: 116px;
  background-image: url(${ICONS.RobotBackground});
  background-position: 90%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  justify-content: center;
  height: 100%;
`;

export const CurrentTaskText = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #030a1b;
`;

export const CurrentTaskContent = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 41px;
  color: #030a1b;
`;

export const MapTaskStreamingWrapper = styled.div`
  margin-top: ${(props) => (props.isMobile ? "10px" : "50px")};
  display: flex;
  height: 100%;
  justify-content: center;
  gap: 20px;
`;
export const MapWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.04);
  border-radius: 16px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const StyledTitle = styled.div`
  font-size: 24px;
  line-height: 28px;
  color: #030a1b;
`;
export const ComplitedTasksWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.04);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100px;
  margin-top: 50px;
`;
export const StreamingWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.04);
  border-radius: 16px;
  padding: ${(props) => (props.isMobile ? "13px" : "43px")};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MapImage = styled.img`
  margin-top: 20px;
  width: 100%;
`;

export const RoomName = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #030a1b;
  margin-top: 15px;
`;

export const MapAndTasksWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const AddRobotContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
`;
export const LabelText = styled.div`
  margin-top: 40px;
  margin-left: ${(props) => (props.isMobile ? "50px" : "80px")};
  color: #030a1b;
`;

export const RobotModalContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RobotsRowButton = styled.button`
  background: #fff;
  border-radius: 48px;
  color: #030a1b;
  font-family: ${FONTS.UbuntuRegular};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  width: 118px;
  height: 32px;
  border: 2px solid #fff;
  cursor: pointer;
  :hover {
    color: ${(props) => !props.isMobile && "#fff"};
    background: ${(props) => !props.isMobile && "#0096c6"};
  }
`;

export const StyledSelectStreaming = styled.div`
  appearance: none;
  background-image: ${(props) =>
    props.showOptions ? `url(${ICONS.ArrowUp})` : `url(${ICONS.ArrowDown})`};
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: ${(props) => (props.isMobile ? "14px" : "18px")};
  color: ${(props) => props.color || "#030a1b"};
  font-size: 16px;
  border: ${(props) =>
    props.opened ? "2px solid #0096C6" : "2px solid #c4c4c4"};
  width: 100%;
  height: ${(props) => (props.isMobile ? "35px" : "50px")};
  border-radius: 16px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledOptionBoxStreaming = styled.div`
  position: absolute;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 16px 0px;
  margin-top: 1px;
  z-index: 999;

  border: 1px solid #0096c6;
  border-radius: 8px;
`;
export const StyledCameraOptions = styled.div`
  color: #0686d8;
  padding: 10px 16px;
  color: #909491;
  font-weight: 700;

  cursor: pointer;
  :hover {
    color: #34619e;
    background: rgba(0, 150, 198, 0.1);
  }
`;

export const StreamingHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
`;

export const StyledErrorPageWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
`;

export const StyledErrorPageTitle = styled.h2`
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #030a1b;
`;

export const StyledImgWrapper = styled.div`
  margin-top: 42px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledErrorText = styled.p`
  font-size: 20px;
  text-align: center;
`;

export const StyledTryAgainBtn = styled.button`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)),
    #0096c6;
  border-radius: 48px;
  border: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 40px;
  color: #fff;
  font-size: 16px;
  margin-top: 24px;
  cursor: pointer;
`;

export const StyledCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledRobotCardInfo = styled.div`
  width: 100%;
`;

export const CardInfoRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;
export const StyledInfoKey = styled.p`
  font-size: 14px;
  color: #909491;
  font-weight: 500;
`;
export const StyledInfoValue = styled.p`
  color: #030a1b;
  font-size: 14px;
  text-align: end;
  font-weight: 400;
`;

export const StyledSwitchIconsWrapper = styled.div`
  display: flex;
  padding: 10px;
  gap: 19px;
  background: ${(props) => props.background};
  border-radius: 8px;
`;

export const IconBackgroundWrapper = styled.div`
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.toggle ? "rgba(202, 240, 248, 0.30)" : "rgba(241, 241, 241, 1)"};
  border: ${(props) => props.toggle && "1px solid #0686D8"};
`;

export const RobotModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 40vh;
  overflow: auto;
`;

export const GeneralInfoHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
