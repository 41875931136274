import React from "react";
import { PercentageWrapper, StyledPercentage } from "./components";

const ProgressPercentage = ({ progressPercentage }) => {
  return (
    <PercentageWrapper>
      <StyledPercentage>{progressPercentage}%</StyledPercentage>
    </PercentageWrapper>
  );
};

export default ProgressPercentage;
