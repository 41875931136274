import axios from "axios";
let prevTake, prevSkip, prevClientId, prevFilter;
const getAssignments = async (clientId, take, filter, skip) => {
  if (
    prevTake === take &&
    prevSkip === skip &&
    prevFilter?.toString() === filter?.toString() &&
    prevClientId === clientId
  )
    return;
  prevTake = take;
  prevSkip = skip;
  prevFilter = filter;
  prevClientId = clientId;
  const res = await axios.get(
    `/clients/${clientId}/assignments?take=${take}&filter=${filter}&skip=${skip}`
  );
  return res.data;
};

export default getAssignments;
