import React, { useContext, useEffect, useState } from "react";
import {
  ComponentHeader,
  ComponentTitle,
} from "../environment-setup/components";
import STRINGS from "../../../constants/strings";
import {
  AnalyticsDatesRange,
  AnalyticsHeader,
  AnalyticsHeaderBtn,
  AnalytisHeaderContainer,
} from "./components";
import AnalyticTypeSelector from "./AnalyticTypeSelector";
import OutsideClickHandler from "react-outside-click-handler";
import AnalyticsTable from "./AnalyticsTable";
import { HeaderStyledButton } from "../../robots/assignments/components";
import CalendarWithRange from "../../../components/calendar-with-range/CalendarWithRange";
import AnalyticsConfigModal from "./AnalyticsConfigModal";
import { useDispatch } from "react-redux";
import { setWidgetModalData } from "../../../redux/analytics";
import { useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { StyledUnderLine } from "../../map/components";
import ICONS from "../../../constants/icons";
import { ROLESENUM } from "../../../enums";
import { ResponsiveContext } from "../../../contexts/ResponsiveContext";
import HeadeWithArrows from "../../../components/header-with-arrows/HeadeWithArrows";

const AnalyticsAdmin = () => {
  const periodTypes = ["Today", "This Week", "This Month", "Custom"];
  const [chosenPeriod, setChosenPeriod] = useState("Today");
  const [showTypeSelector, setShowTypeSelector] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const { widgetModalData } = useSelector((state) => state.analytics);
  const { currentRole } = useSelector((state) => state.clients);
  const { isMobile } = useContext(ResponsiveContext);
  const { search } = useLocation();

  const dateParams = Object.fromEntries(new URLSearchParams(search));

  const history = useHistory();

  const dispatch = useDispatch();
  const handlePeriodType = (el) => {
    if (el === periodTypes[3]) {
      setShowCalendar(true);
    }
    setChosenPeriod(el);
  };

  const handleCalendarApply = (values) => {
    setShowCalendar(false);
    let params = new URLSearchParams();
    params.append("from", moment(values[0]).format("YYYY-MM-DD").toString());
    params.append("to", moment(values[1]).format("YYYY-MM-DD").toString());
    history.push({
      pathname: "/analytics",
      search: params.toString(),
    });
  };

  useEffect(() => {
    if (chosenPeriod) {
      let params = new URLSearchParams();

      switch (chosenPeriod) {
        case "Today":
          params.append(
            "from",
            moment(new Date()).format("YYYY-MM-DD").toString()
          );
          params.append(
            "to",
            moment(new Date()).format("YYYY-MM-DD").toString()
          );
          break;
        case "This Week":
          const startOfWeek = moment().startOf("isoWeek").format("YYYY-MM-DD");
          params.append("from", startOfWeek);
          params.append(
            "to",
            moment(new Date()).format("YYYY-MM-DD").toString()
          );
          break;

        case "This Month":
          const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
          params.append("from", startOfMonth);
          params.append(
            "to",
            moment(new Date()).format("YYYY-MM-DD").toString()
          );
          break;

        default:
          params = search;
        // setChosenPeriod("Custom");
      }
      history.push({
        pathname: "/analytics",
        search: params.toString(),
      });
    }
  }, [chosenPeriod, history, dateRange, search]);

  return (
    <React.Fragment>
      <ComponentHeader>
        <ComponentTitle>{`${STRINGS.analytics}`}</ComponentTitle>
        {currentRole === ROLESENUM.ADMIN && !isMobile && (
          <div style={{ position: "relative" }}>
            <HeaderStyledButton onClick={() => setShowTypeSelector(true)}>
              {STRINGS.add_content}
            </HeaderStyledButton>
            <OutsideClickHandler
              onOutsideClick={() => setShowTypeSelector(false)}
            >
              {showTypeSelector && (
                <AnalyticTypeSelector
                  onChoose={() => setShowTypeSelector(false)}
                  style={{ position: "absolute", left: "-5px", top: "50px" }}
                />
              )}
            </OutsideClickHandler>
          </div>
        )}
      </ComponentHeader>
      <AnalyticsHeader>
        {isMobile ? (
          <HeadeWithArrows
            options={periodTypes}
            handleOptionClick={(value) => handlePeriodType(value)}
          />
        ) : (
          <AnalytisHeaderContainer>
            {periodTypes.map((el, index) => (
              <>
                {index !== 0 && (
                  <StyledUnderLine style={{ marginTop: "12px" }} />
                )}
                <AnalyticsHeaderBtn
                  key={el}
                  chosen={chosenPeriod === el}
                  onClick={() => handlePeriodType(el)}
                >
                  {el} {el === "Custom" && <img src={ICONS.GreyArrow} alt="" />}
                </AnalyticsHeaderBtn>
              </>
            ))}
          </AnalytisHeaderContainer>
        )}

        <div style={{ position: "relative" }}>
          <OutsideClickHandler onOutsideClick={() => setShowCalendar(false)}>
            <div
              style={{
                position: "absolute",
                top: "55px",
                right: isMobile ? "0" : "-100px",
                zIndex: "111",
              }}
            >
              {showCalendar && (
                <CalendarWithRange
                  value={dateRange}
                  setValue={setDateRange}
                  handleApply={(values) => {
                    handleCalendarApply(values);
                  }}
                />
              )}
            </div>
          </OutsideClickHandler>
        </div>
      </AnalyticsHeader>
      {isMobile && (
        <AnalyticsDatesRange>
          From {moment(dateParams.from).format("DD MMM")} - Until{" "}
          {moment(dateParams.to).format("DD MMM")}
        </AnalyticsDatesRange>
      )}

      <AnalyticsTable />

      {widgetModalData && (
        <AnalyticsConfigModal
          onRequestClose={() => dispatch(setWidgetModalData(null))}
        />
      )}
    </React.Fragment>
  );
};

export default AnalyticsAdmin;
