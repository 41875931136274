export function hasCollision(predefinedTimes) {
  // Convert time to minutes since start of the day for easier comparison
  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  // Iterate over the array to check for collisions
  for (let i = 0; i < predefinedTimes.length; i++) {
    const { week_days: weekDays1, time: time1 } = predefinedTimes[i];

    for (let j = i + 1; j < predefinedTimes.length; j++) {
      const { week_days: weekDays2, time: time2 } = predefinedTimes[j];

      // Iterate over each day of the week
      for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
        const day1 = weekDays1[dayIndex];
        const day2 = weekDays2[dayIndex];

        // Check if the same day is chosen in both structures
        if (day1.chosen && day2.chosen) {
          // Convert time ranges to minutes for comparison
          const start1 = timeToMinutes(time1.from);
          const end1 = timeToMinutes(time1.to);
          const start2 = timeToMinutes(time2.from);
          const end2 = timeToMinutes(time2.to);

          // Check for collision
          if (
            start1 <= end2 &&
            start2 <= end1 // Overlapping condition
          ) {
            return true; // Collision detected
          }
        }
      }
    }
  }

  return false; // No collision detected
}
