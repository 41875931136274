import React from "react";
import { TASKLOGSTATUSENUM } from "../../enums";
import { RobotIconWrapper, StyledLocator } from "./components";
import GetElementCoordinates from "../get-element-coordinates/GetElementCoordinates";

const IconOnMap = ({
  loc,
  scaleX,
  scaleY,
  pointWidth,
  mapRotateAngle,
  robotWidth,
  showToolTip,
}) => {
  const borderColor =
    loc.status === TASKLOGSTATUSENUM.ERROR
      ? "#F02121"
      : loc.status === TASKLOGSTATUSENUM.SUCCESS
      ? "#2FD453"
      : "";
  return (
    // showToolTip && (
    loc.position &&
    loc.message && (
      <RobotIconWrapper
        style={{
          left: scaleX * loc.position?.x - robotWidth / 2,
          top: scaleY * loc.position?.y - robotWidth / 2,
          position: "absolute",
        }}
      >
        <GetElementCoordinates
          text={loc.message}
          // left={`${robotWidth / 2}px`}
          // transform={`rotate(${-mapRotateAngle}deg)`}
          styles={{
            background: "#FFFFFF",
            color: "#08183F",
            boxShadow: "0px 4px 10px rgba(0, 147, 198, 0.18)",
            border: `1px solid ${borderColor}`,
          }}
        >
          <StyledLocator
            key={`icon-pos-${loc.id}`}
            onClick={(e) => e.stopPropagation()}
            robotWidth={robotWidth}
            pointWidth={pointWidth}
            style={{
              transform: `rotate(${+loc.position?.angle - 90}deg)`,
              background: loc.icon,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          />
        </GetElementCoordinates>
      </RobotIconWrapper>
    )
  );
  // );
};
export default IconOnMap;
