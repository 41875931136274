import React, { useContext, useEffect, useState } from "react";
import {
  CheckboxWrapper,
  StyledModalFormWrapper,
  StyledModalText,
} from "../components";
import { Field, Formik } from "formik";
import InputText from "../../input/InputText";
import COLORS from "../../../constants/colors";
import { StyledButton } from "../../button/components";
import STRINGS from "../../../constants/strings";
import { InputTitle } from "../../../pages/robots/tasks-in-assignments/components";
import StyledSelectForStreaming from "../../../pages/robots/StyledSelectForStreaming";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsSuccessModal,
  updateUsersData,
} from "../../../redux/userManagement";
import { v4 as uuidv4 } from "uuid";
import inviteMember from "../../../APIs/user-management/inviteMember";
import updateUser from "../../../APIs/user-management/updateUser";
import { setInviteMemberValues } from "../../../redux/clients";
import { ROLESENUM } from "../../../enums";
import StyledSelectMultiplyChoice from "../../styled-select-multiply-choice/StyledSelectMultiplyChoice";
import { AdminTableContext } from "../../../contexts/AdminTableContext";
import updateUserAdmin from "../../../APIs/user-management/updateUserAdmin";
import { useLocation } from "react-router-dom";

const TeamMemberModalContent = ({ onRequestClose, chosenClient }) => {
  const location = useLocation();
  const roles =
    location.pathname === "/admin/user-administration"
      ? ["Developer"]
      : ["Manager", "User", "Viewer"];
  const { editUserData } = useSelector((state) => state.userManagement);
  const { client, currentRole } = useSelector((state) => state.clients);
  const { clients } = useContext(AdminTableContext);
  const dispatch = useDispatch();
  const [availableClients, setAvailableClients] = useState(clients);
  const [memberModalForm, setMemberModalForm] = useState({
    id: uuidv4(),
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    roles: null,
    role: "",
    sendEmail: true,
  });

  useEffect(() => {
    editUserData &&
      setMemberModalForm({
        ...editUserData,
        role: editUserData.roles[chosenClient.id],
      });

    editUserData &&
      setAvailableClients(
        clients.map((el) =>
          Object.keys(editUserData.roles).includes(el.id)
            ? { ...el, checked: true }
            : el
        )
      );
  }, [editUserData, clients, chosenClient]);

  const handleValidation = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "Required";
    }
    if (!values.lastName) {
      errors.lastName = "Required";
    }
    if (!values.email) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    if (!values.phone) {
      errors.phone = "Required";
    }
    if (!values.role || values.role === "Choose Role") {
      errors.role = "Required";
    }
    return errors;
  };
  const handleSubmit = async (values) => {
    let data = {};
    let rolesObj = {};
    if (values.roles) {
      Object.keys(values.roles).forEach((el) => {
        if (el !== "all") rolesObj[el] = values.role;
      });
    } else {
      rolesObj[chosenClient.id] = values.role;
    }
    if (!editUserData) {
      data = await inviteMember({
        ...values,
        roles: rolesObj,
      });
      dispatch(updateUsersData({ ...data, role: values.role }));
    } else {
      if (currentRole === ROLESENUM.ADMIN) {
        data = await updateUserAdmin({
          ...values,
          roles: rolesObj,
          clientId: client.id,
          userId: editUserData.id,
        });
      } else {
        data = await updateUser({
          ...values,
          roles: rolesObj,
          clientId: client.id,
          userId: editUserData.id,
        });
      }
      dispatch(updateUsersData({ ...data, roles: values.roles }));
    }
    onRequestClose();
    !editUserData && dispatch(setIsSuccessModal(true));
  };
  const handleSelectChange = (id, item, values, field, setFieldValue) => {
    setAvailableClients((prev) =>
      prev.map((el) => (el.id === id ? { ...el, checked: !el.checked } : el))
    );

    if (!item.checked) {
      setFieldValue("roles", {
        ...values.roles,
        [item.id]: "",
      });
    } else {
      let copyObj = { ...values.roles };
      delete copyObj[item.id];
      setFieldValue("roles", copyObj);
    }
  };
  return (
    <StyledModalFormWrapper>
      <Formik
        // autoComplete="off"
        enableReinitialize
        initialValues={{ ...memberModalForm }}
        validate={(values) => {
          return handleValidation(values);
        }}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(setInviteMemberValues(values));
          handleSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <InputTitle style={{ color: "#030A1B" }}>Name</InputTitle>
            <div style={{ display: "flex", gap: "5%" }}>
              <Field id="firstName" name="firstName">
                {({ field }) => (
                  <InputText
                    margin="0 0 1.8rem 0"
                    borderColor={COLORS.secondary.water_gray}
                    placeholder={"First Name"}
                    handleChange={(v) => setFieldValue(field.name, v)}
                    value={field.value}
                    error={touched[field.name] && errors[field.name]}
                  />
                )}
              </Field>
              <Field id="lastName" name="lastName">
                {({ field }) => (
                  <InputText
                    margin=" 0 0 1.8rem 0"
                    type="lastName"
                    borderColor={COLORS.secondary.water_gray}
                    placeholder={"Last Name"}
                    handleChange={(v) => setFieldValue(field.name, v)}
                    value={field.value}
                    error={touched[field.name] && errors[field.name]}
                  />
                )}
              </Field>
            </div>
            <Field id="email" name="email">
              {({ field }) => (
                <>
                  <InputTitle style={{ color: "#030A1B" }}>Email</InputTitle>
                  <InputText
                    margin=" 0 0 1.8rem 0"
                    borderColor={COLORS.secondary.water_gray}
                    disabled={editUserData}
                    placeholder={"Email"}
                    handleChange={(v) => setFieldValue(field.name, v)}
                    value={field.value}
                    error={touched[field.name] && errors[field.name]}
                  />
                </>
              )}
            </Field>
            <Field id="phone" name="phone">
              {({ field }) => (
                <>
                  <InputTitle style={{ color: "#030A1B" }}>
                    Phone Number
                  </InputTitle>
                  <InputText
                    margin="1.8rem 0"
                    type="text"
                    borderColor={COLORS.secondary.water_gray}
                    placeholder={"Phone Number"}
                    handleChange={(v) => setFieldValue(field.name, v)}
                    value={field.value}
                    error={touched[field.name] && errors[field.name]}
                  />
                </>
              )}
            </Field>
            {currentRole === ROLESENUM.ADMIN &&
              location.pathname === "/admin/user-administration" && (
                <Field id="clients" name="clients">
                  {({ field }) => (
                    <>
                      <InputTitle style={{ color: "#030A1B" }}>
                        Client
                      </InputTitle>
                      <div style={{ margin: "0 0 1.8rem 0" }}>
                        <StyledSelectMultiplyChoice
                          options={availableClients}
                          LAYER={"Choose clients"}
                          handleCheckboxChange={(id, item) => {
                            handleSelectChange(
                              id,
                              item,
                              values,
                              field,
                              setFieldValue
                            );
                          }}
                        />
                      </div>
                    </>
                  )}
                </Field>
              )}
            <Field id="role" name="role">
              {({ field }) => (
                <>
                  <InputTitle style={{ color: "#030A1B" }}>Role</InputTitle>
                  <StyledSelectForStreaming
                    color={"#909491"}
                    width={"100%"}
                    height="5.6rem"
                    options={roles}
                    name="role"
                    chosenValue={field.value || "Choose role"}
                    setChosenValue={(val) => {
                      setFieldValue(field.name, val);
                    }}
                    error={touched[field.name] && errors[field.name]}
                  />
                </>
              )}
            </Field>
            <Field id="sendEmail" name="sendEmail">
              {({ field }) => (
                <CheckboxWrapper htmlFor={"sendEmail"}>
                  <InputTitle style={{ color: "#030A1B" }}>
                    Send Email
                  </InputTitle>
                  <input
                    type="checkbox"
                    checked={field.value}
                    value={field.value}
                    id={"sendEmail"}
                    onChange={(e) =>
                      setFieldValue(field.name, e.target.checked)
                    }
                  />
                </CheckboxWrapper>
              )}
            </Field>
            <StyledModalText>{STRINGS.modal_text}</StyledModalText>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <StyledButton
                borderColor={"#fff"}
                fontSize={"16px"}
                fontFamily={"unset"}
                backgroundColor={"#0096C6"}
                padding={"8px 45px"}
                type="submit"
                disabled={isSubmitting}
              >
                {editUserData ? "Confirm" : STRINGS.send_invitation}
              </StyledButton>
            </div>
          </form>
        )}
      </Formik>
    </StyledModalFormWrapper>
  );
};

export default TeamMemberModalContent;
