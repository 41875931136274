import React from "react";
import Modal from "react-modal";
import COLORS from "../../../constants/colors";
import "./confirmModalChange.css";

const ConfirmChangeModal = ({
  children,
  isOpen,
  onRequestClose,
  animation,
  callIsOpen,
}) => {
  const customStyles = callIsOpen
    ? {
        overlay: {
          backgroundColor: `unset`,
          zIndex: "111",
        },

        content: {
          position: "absolute",
          inset: "100%",
          transform: "translate(-100%, -100%)",
          width: "236px",
          height: "368px",
          overflow: "unset",
          borderRadius: "16px",
          border: "none",
          padding: "0",
        },
      }
    : {
        overlay: {
          backgroundColor: `${COLORS.primary.oxford}80`,
          zIndex: "111",
        },

        content: {
          zIndex: "111",
          // minWidth: "600px",
          maxWidth: "Calc(100% - 6.4rem)",
          overflow: "unset",
          height: "fit-content",
          top: "50%",
          left: "50%",
          right: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "16px",
          border: "none",
          padding: "0 ",
          animation: animation ? "swing 0.9s 5 ease-in-out" : "",
        },
      };

  return (
    <Modal
      style={customStyles}
      portalClassName="clickAllow"
      isOpen={!!isOpen}
      ariaHideApp={false}
      onRequestClose={onRequestClose}
    >
      {children}
    </Modal>
  );
};

export default ConfirmChangeModal;
