import axios from "axios";

const sendMessageToApp = async (robotId, message, app_id, command) => {
  const res = await axios.post(`/robots/${robotId}/send-message`, {
    message,
    app_id,
    command,
  });
  return res.data;
};

export default sendMessageToApp;
