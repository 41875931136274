import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = localStorage.getItem("token");
      const clientId = localStorage.getItem("selected_client_id");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        // custom header for aws has to be without underscore
        config.headers.clientid = clientId;
      }
    }

    return config;
  },
  (error) => Promise.reject(error.response)
);
