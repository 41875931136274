import React from "react";

const StyledFileInput = ({ children, handleChange, id }) => {
  return (
    <>
      <input
        type="file"
        style={{ clip: "rect(0, 0, 0, 0)", height: "1px", width: "1px" }}
        id={id || "upload-input"}
        onChange={handleChange}
      />
      <label htmlFor={id || "upload-input"}>{children}</label>
    </>
  );
};

export default StyledFileInput;
