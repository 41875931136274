import React from "react";
import ModalOutline from "../../modal-outline/ModalOutline";
import STRINGS from "../../../constants/strings";
import TeamMemberModalContent from "./TeamMemberModalContent";

const TeamMemberModal = ({ isOpen, onRequestClose, chosenClient }) => {
  return (
    <ModalOutline
      width={"40%"}
      isOpen={isOpen}
      heading={STRINGS.add_new_team_member}
      onRequestClose={onRequestClose}
      buttonText={STRINGS.send_invitation}
    >
      <TeamMemberModalContent
        onRequestClose={onRequestClose}
        chosenClient={chosenClient}
      />
    </ModalOutline>
  );
};

export default TeamMemberModal;
