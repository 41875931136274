import styled from "styled-components";

export const StyledButton = styled.button`
  width: 100%;
  padding: 14px 40px;
  border-radius: 48px;
  cursor: pointer;
  font-size: 16px;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  border: ${(props) => (props.border ? props.border : "unset")};
  display: flex;
  justify-content: center;
  align-items: center;
  :disabled {
    cursor: not-allowed;
  }
`;
