import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROBOTTASKSTATUSENUM } from "../../enums";
import useTasks from "../../hooks/useTasks";
import { ErrorMessage } from "../../pages/robots/tasks-in-assignments/components";
import { setEditTaskData } from "../../redux/dashboard";
import {
  EditAssignmentButton,
  EditAssignmentButtonsWrapper,
  StyledOption,
  StyledSelect,
  TaskEditModeWrapper,
} from "./components";
import TaskParametersEditMode from "./TaskParametersEditMode";
import { setParams } from "../../utils/setParams";

const TaskEditMode = ({
  item,
  setAssignmentsDetails,
  assignmentDetails,
  tasksBeforeChanges,
  setTasksBeforeChanges,
}) => {
  const { locations } = useSelector((state) => state.maps);

  const { appsData } = useSelector((state) => state.applications);
  const dispatch = useDispatch();
  const { handleSave, handleTaskValidation, isError, setIsError } = useTasks({
    assignmentDetails,
    setAssignmentsDetails,
  });

  const handleSelectChange = (e, id) => {
    setIsError(false);
    const found = {
      ...appsData.find((item) => item.id === e.target.value),
    };

    setAssignmentsDetails((prev) => ({
      ...prev,
      tasks: prev.tasks.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            application: found,
            parameters: setParams(found, locations),
          };
        } else return item;
      }),
    }));
  };

  const handleParametersChange = useCallback(
    (inputValue, taskId, paramName) => {
      setIsError(false);
      const obj = {
        ...assignmentDetails,
        tasks: assignmentDetails.tasks.map((item) => {
          if (item.id === taskId) {
            let params = { ...item.parameters };
            params[paramName] = inputValue;
            return { ...item, parameters: params };
          } else return item;
        }),
      };
      setAssignmentsDetails(obj);
    },
    [assignmentDetails, setAssignmentsDetails, setIsError]
  );

  const handleCancel = () => {
    const found = tasksBeforeChanges.find((el) => el.id === item.id);
    if (found && item.isCreated) {
      setAssignmentsDetails((prev) => ({
        ...prev,
        tasks: prev.tasks.map((el) =>
          el.id === item.id
            ? { ...found, status: ROBOTTASKSTATUSENUM.DEFAULT }
            : el
        ),
      }));
    } else {
      setAssignmentsDetails((prev) => ({
        ...prev,
        tasks: prev.tasks.filter((el) => el.id !== item.id),
      }));
    }
    setTasksBeforeChanges((prev) => prev.filter((el) => el.id !== item.id));
  };

  const handleSubmit = (e) => {
    const isValid = handleTaskValidation(item);
    if (
      isValid &&
      !assignmentDetails?.disposable &&
      !assignmentDetails?.justCreated
    ) {
      dispatch(setEditTaskData(item));
    }
    if (
      isValid &&
      (assignmentDetails?.disposable || assignmentDetails?.justCreated)
    ) {
      handleSave(e, item);
    }
    setTasksBeforeChanges((prev) => prev.filter((el) => el.id !== item.id));
  };
  return (
    <TaskEditModeWrapper>
      <div style={{ color: "#5C605D", marginLeft: "20px" }}>Task</div>
      <StyledSelect
        style={{ width: "100%", marginTop: "10px" }}
        onChange={(e) => handleSelectChange(e, item.id)}
      >
        <StyledOption value={""} defaultValue={false}>
          Choose Task
        </StyledOption>
        {appsData.map((app, ind) => (
          <StyledOption
            key={ind}
            value={app.id}
            selected={app.aliasName === item?.application?.aliasName}
          >
            {app.aliasName}
          </StyledOption>
        ))}
      </StyledSelect>
      {isError &&
        (!item?.application || !Object.keys(item?.application).length) && (
          <ErrorMessage>*This field can not be empty</ErrorMessage>
        )}
      <TaskParametersEditMode
        item={item}
        isError={isError}
        setIsError={setIsError}
        assignmentDetails={assignmentDetails}
        setAssignmentsDetails={setAssignmentsDetails}
        handleParametersChange={handleParametersChange}
        dashboard
      />

      <EditAssignmentButtonsWrapper
        style={{ padding: "unset", marginTop: "32px" }}
      >
        <EditAssignmentButton
          background={"#0686D8"}
          color={"#fff"}
          onClick={handleSubmit}
        >
          Save
        </EditAssignmentButton>
        <EditAssignmentButton
          background={"#fff"}
          color={"#0686D8"}
          border={"1px solid #0686D8"}
          onClick={handleCancel}
        >
          Cancel
        </EditAssignmentButton>
      </EditAssignmentButtonsWrapper>
    </TaskEditModeWrapper>
  );
};

export default TaskEditMode;
