import styled from "styled-components";
import { ZONEIDENUM } from "../../enums";

export const DrawcanvasWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const BottomButtons = styled.div`
  display: flex;
  flex-direction: initial !important;
  justify-content: space-around;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 147, 198, 0.18);
  border-radius: 20px;
  padding: 15px 5px;
  margin-top: 20px;
  :disabled {
    cursor: not-allowed;
  }
`;

export const ButtonCH = styled.button`
  width: 130px;
  height: 40px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #a8d6f3;
  border: 1px solid #000000;
  border-radius: 6px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: -0.015em;
  padding: 0 20px;
  color: #000000;
  cursor: pointer;
  :disabled {
    cursor: not-allowed;
  }
`;

export const ButtonSZ = styled.button`
  background: rgba(233, 35, 35, 0.42);
  border: 1px solid #000000;
  color: #000000;
  font-weight: 500;
  font-size: 15px;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0 20px;
  :disabled {
    cursor: not-allowed;
  }
`;

export const ButtonWA = styled.button`
  background: #c6c5f8;
  border: 1px solid #000000;
  border-radius: 6px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 17px;
  :disabled {
    cursor: not-allowed;
  }
`;
export const ButtonLO = styled.button`
  background: #f0ae71;
  border: 1px solid #000000;
  border-radius: 6px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0 15px;
  font-size: 15px;
  cursor: pointer;
  :disabled {
    cursor: not-allowed;
  }
`;
export const ButtonRO = styled.button`
  background: rgba(59, 129, 26, 0.44);
  border: 1px solid #000000;
  border-radius: 6px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0 15px;
  font-size: 15px;
  cursor: pointer;
  :disabled {
    cursor: not-allowed;
  }
`;

export const FleetRulesList = styled.button`
  background-color: ${(props) =>
    props.chosen ? "rgba(202, 240, 248, 0.3)" : " #fff"};
  font-size: 16px;
  font-weight: 500;
  color: #08183f;
  border: unset !important;

  cursor: pointer;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 5px 0px;
  border-left: 1px solid #000;
`;

export const RobotsListWrapper = styled.div`
  display: flex;
  background: #fff;
  padding: 0px 7px;
  margin-right: 10px;
  border-radius: 20px 20px 0px 0px;
`;

export const StyledDrawCanvasWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
`;

export const RobotDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledCanvasWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  padding: 25px;
`;

export const StyleFLHeaderWraper = styled.div`
  display: flex;
  justify-content: space-between;

  > select {
    width: 175px;
    height: 40px;
    background: #ffffff;
    border: 2px solid #c4c4c4;
    border-radius: 16px;
    padding: 0 24px;
  }
`;

export const StyledEditZoneWrapper = styled.div`
  border: 1px solid #000000;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  color: #000000;
  font-weight: 600;
  font-size: 15px;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  background: ${(props) =>
    props.zone === ZONEIDENUM.SHAREDZONE
      ? "rgba(233,35,35,0.42)"
      : ZONEIDENUM.LOCATION
      ? "rgba(0, 147, 198, 0.08)"
      : props.zone === ZONEIDENUM.CHHECKPOINT
      ? "#a8d6f3"
      : "#c6c5f8"};

  > button {
    height: 23px;
    background: rgba(255, 255, 255, 0.63);
    mix-blend-mode: normal;
    border: 1px solid #000000;
    border-radius: 3px;
    padding: 0px 5px;
    cursor: pointer;
  }
`;

export const StyleUploadMapImageWrapper = styled.div`
  background: #d9d9d9;
  width: 100%;
  height: 731px;
  display: flex;
  justify-content: center;
  align-items: center;

  > input {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
  }

  > label {
    width: 204px;
    height: 44px;
    background: #0096c6;
    border-radius: 48px;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }
`;

export const StyledDeleteIcon = styled.img`
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
`;

export const StyledUnderLine = styled.div`
  width: 0px;
  height: 25px;
  border-left: 1px solid #cbd5cf;
  transform: rotate(1deg);
`;

export const StyledArrowLeft = styled.img`
  transform: rotate(90deg);
`;
export const StyledArrowRight = styled.img`
  transform: rotate(270deg);
`;

export const StyledCanvasComponentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const StyledRoutesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 280px;
  height: 100%;
  padding: 30px 15px;
  margin: 0 10px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 147, 198, 0.18);
  border-radius: 6px;
  max-height: ${(props) => props.maxHeight + "px"};
  overflow: scroll;
`;

export const StyledrouteComponentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  border: 2px solid #5c605d;
  padding: 20px;
  cursor: pointer;

  > h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  :hover {
    background: rgba(255, 255, 255, 0.26);
    border: 2px solid #0686d8;
    box-shadow: 0px 4px 4px rgba(0, 147, 198, 0.18),
      0px 4px 4px rgba(0, 147, 198, 0.18);
    opacity: 0.7;
  }
`;

export const StyledTooltipCanvas = styled.div`
  background-color: white;
  border-radius: 8px;
  position: absolute;
  padding: 10px;
  display: none;
`;

export const MapWrapperTitle = styled.div`
  font-size: 20px;
  line-height: 23px;
  height: 23px;
  margin-left: 37px;
  margin-right: 20px;
`;

export const SwitchIcon = styled.img`
  width: 43px;
`;

export const SwitchToMapWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  text-align: right;
`;
