import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import {
  StyledCameraOptions,
  StyledOptionBoxStreaming,
  StyledSelectStreaming,
} from "./components";
import { InputError } from "../../components/input/components";
import { CAMERAALIASES } from "../../enums";

const StyledSelectForStreaming = ({
  options,
  chosenValue,
  setChosenValue,
  color,
  width,
  error,
  fromPopup,
  isMobile,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  return (
    <div
      style={{
        position: fromPopup ? "absolute" : "relative",
        width: width,
        zIndex: 2,
        margin: fromPopup ? "4px 0 0 20px" : "0",
      }}
    >
      <OutsideClickHandler onOutsideClick={() => setShowOptions(false)}>
        <div
          style={{
            background: fromPopup ? "#fff" : "transparent",
            borderRadius: "16px",
          }}
        >
          <StyledSelectStreaming
            isMobile={isMobile}
            showOptions={showOptions}
            opened={showOptions}
            onClick={() => setShowOptions((prev) => !prev)}
            color={color}
          >
            {CAMERAALIASES[chosenValue] || chosenValue}
          </StyledSelectStreaming>
          {showOptions && (
            <StyledOptionBoxStreaming>
              {options.map((item) => (
                <StyledCameraOptions
                  key={CAMERAALIASES[item] || item}
                  // chosen={chosenValue === item}
                  onClick={() => {
                    setChosenValue(item);
                    setShowOptions(false);
                  }}
                >
                  {CAMERAALIASES[item] || item}
                </StyledCameraOptions>
              ))}
            </StyledOptionBoxStreaming>
          )}
          {error && (
            <InputError className="error" style={{ left: "25px" }}>
              * {error}
            </InputError>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default StyledSelectForStreaming;
