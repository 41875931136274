import styled from "styled-components";
import COLORS from "../../constants/colors";
import FONTS from "../../constants/fonts";
import ICONS from "../../constants/icons";
import { InfoModalTypes } from "../../constants/types";

export const WholeWrapper = styled.div`
  min-width: 48.6rem;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 2.8rem 4.7rem 3.6rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const HeaderIcon = ({ type }) => {
  switch (type) {
    case InfoModalTypes.SUCCESS:
      return ICONS.SuccessModalIcon;
    case InfoModalTypes.ATTENTION:
      return ICONS.AttentionModalIcon;
    case InfoModalTypes.WARNING:
      return ICONS.WarningModalIcon;
    default:
      return "";
  }
};
const HeaderColor = ({ type }) => {
  switch (type) {
    case InfoModalTypes.SUCCESS:
      return COLORS.secondary.emerald;
    case InfoModalTypes.ATTENTION:
      return COLORS.secondary.ncs;
    case InfoModalTypes.WARNING:
      return COLORS.secondary.rosso;
    default:
      return "";
  }
};

export const ModalHeader = styled.div`
  width: 100%;
  height: 16.8rem;
  position: relative;
  background: ${HeaderColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalHeaderIcon = styled.div`
  width: 10rem;
  height: 10rem;
  position: absolute;
  background-image: url(${HeaderIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const ModalClose = styled.div`
  width: 6rem;
  height: 6rem;
  position: absolute;
  background-image: url(${ICONS.CloseWhite});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  top: 1rem;
  right: 1.4rem;
  cursor: pointer;
  &:hover {
    transform: scale(1.08);
  }
`;

export const ModalTitle = styled.div`
  width: 100%;
  font-family: "AssistantBold", ${FONTS.UbuntuBold};
  font-size: 2.8rem;
  line-height: 3.4rem;
  letter-spacing: 0.75px;
  text-align: center;
  // text-transform: capitalize;
`;

export const ModalSubTitle = styled.div`
  width: 40rem;
  font-family: ${FONTS.UbuntuRegular};
  font-size: 2.2rem;
  line-height: 3.3rem;
  letter-spacing: 0.75px;
  text-align: center;
`;

export const ModalContent = styled.div`
  font-family: ${FONTS.UbuntuRegular};
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.75px;
  text-align: center;
  width: 29.3rem;
  color: ${COLORS.secondary.morning};
  margin: 1rem 0;
  width: 100%;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.4rem;
`;

export const OkayLink = styled.div`
  text-decoration: underline;
  color: ${COLORS.secondary.ncs};
  font-family: ${FONTS.UbuntuRegular};
  font-size: 1.6rem;
  line-height: 2.6rem;
  cursor: pointer;
  text-transform: capitalize;
`;
