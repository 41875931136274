import styled from "styled-components";

export const StyledConfirmButton = styled.button`
  width: ${(props) => (props.isMobile ? "120px" : "384px")};
  height: 44px;
  cursor: pointer;

  background: rgba(6, 134, 216, 0.1);
  border-radius: 48px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  gap: 10px;
  border: none;

  font-family: "Ubuntu-Medium";
  color: #0686d8;
  font-weight: 500;
  font-size: 16px;
`;
