import React, { useEffect } from "react";
import AnalyticsWidget from "./AnalyticsWidget";
import { useDispatch } from "react-redux";
import { setAnalyticsData, setEventNames } from "../../../redux/analytics";
import getAllAnalytics from "../../../APIs/analytics/getAllAnalytics";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import getEventNames from "../../../APIs/analytics/getEventNames";

const AnalyticsTable = () => {
  const location = useLocation();

  const dispatch = useDispatch();
  const { analyticsData, triggerAnalyticsRefetch } = useSelector(
    (state) => state.analytics
  );
  const { chosenClient } = useSelector((state) => state.clients);

  useEffect(() => {
    if (chosenClient) {
      const params = new URLSearchParams(location.search);
      getAllAnalytics(chosenClient, params).then((data) => {
        dispatch(setAnalyticsData(data));
      });
    }
  }, [dispatch, chosenClient, triggerAnalyticsRefetch, location.search]);

  useEffect(() => {
    if (chosenClient)
      getEventNames(chosenClient).then((data) => {
        dispatch(setEventNames(data));
      });
  }, [dispatch, chosenClient]);

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
      {analyticsData.map((el) => (
        <AnalyticsWidget key={el.id} item={el} />
      ))}
    </div>
  );
};

export default AnalyticsTable;
