import React, { useState } from "react";
import COLORS from "../../constants/colors";
import InputText from "../input/InputText";
import { InputTitle } from "../../pages/robots/tasks-in-assignments/components";
import { BuildinModalContentWrapper } from "./components";
import { StyledButton } from "../button/components";
import addBuilding from "../../APIs/maps/addBuilding";
import updateBuilding from "../../APIs/maps/updateBuilding";
import { mapsDataManipulation } from "./multiMapsDataManipulation";
import { useDispatch } from "react-redux";
import { setChosenBuilding, setMapsData } from "../../redux/maps";
import { useSelector } from "react-redux";

const BuildingModalContent = ({ buildingModalData, onSubmit = () => {} }) => {
  const { mapsData, chosenBuilding } = useSelector((state) => state.maps);
  const [errorMessage, setErrorMessage] = useState("");
  const [buildingName, setBuildingName] = useState(
    buildingModalData?.buildingName
  );
  const dispatch = useDispatch();

  const handleSaveBuilding = async () => {
    if (!buildingName) {
      setErrorMessage("Please enter a name for the building.");
      return;
    }
    if (!buildingModalData?.isCreated) {
      const data = await addBuilding({ buildingName });
      const response = mapsDataManipulation([data]);
      dispatch(setMapsData([...mapsData, ...response]));
      dispatch(setChosenBuilding(response[0]?.building));
      onSubmit();
    } else {
      const data = await updateBuilding({
        newBuildingName: buildingName,
        oldBuildingName: buildingModalData.buildingName,
      });
      const response = mapsDataManipulation([data[0]]);
      dispatch(
        setMapsData(
          mapsData.map((el) =>
            el.building === chosenBuilding ? response[0] : el
          )
        )
      );
      dispatch(setChosenBuilding(response[0]?.building));
      onSubmit();
    }
  };

  return (
    <BuildinModalContentWrapper>
      <InputTitle style={{ color: "#5C605D" }}>Building Name</InputTitle>
      <InputText
        margin="0 0 1.8rem 0"
        borderColor={COLORS.secondary.water_gray}
        handleChange={(val) => {
          setErrorMessage("");
          setBuildingName(val);
        }}
        value={buildingName}
        error={errorMessage}
      />
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <StyledButton
          color="#fff"
          backgroundColor="#0686D8"
          padding="12px 30px"
          fontSize="14px"
          onClick={handleSaveBuilding}
        >
          Save
        </StyledButton>
      </div>
    </BuildinModalContentWrapper>
  );
};

export default BuildingModalContent;
