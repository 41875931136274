import React, { useCallback } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LabelText,
  RadioInput,
  StyledRadioLabel,
} from "../../add-assignment-modal/components";
import {
  EditAssignmentButton,
  EditAssignmentButtonsWrapper,
  StyledChangesContentWrapper,
  StyledContentTitle,
  StyledModalTitleWrapper,
} from "../components";
import { setEditTaskData } from "../../../redux/dashboard";

const EditTaskModalRunEvery = ({ handleSave, handleSaveException }) => {
  const { editTaskData, calendarDate } = useSelector(
    (state) => state.dashboard
  );
  const [onAll, setOnAll] = useState(false);
  const dispatch = useDispatch();

  const handleSaveTasks = useCallback(
    (e) => {
      if (onAll) handleSave(e, editTaskData);
      else handleSaveException(e, editTaskData, calendarDate);
    },
    [onAll, editTaskData, handleSave, handleSaveException, calendarDate]
  );

  return (
    <StyledChangesContentWrapper className="clickAllow">
      <StyledModalTitleWrapper style={{ alignItems: "flex-start" }}>
        <StyledContentTitle>
          Edit Recurring Task -{" "}
          <strong>{editTaskData?.application?.aliasName}</strong>
        </StyledContentTitle>
      </StyledModalTitleWrapper>
      <div style={{ marginTop: "36px" }}>
        <StyledRadioLabel style={{ marginTop: "15px" }}>
          <RadioInput
            type="radio"
            name="when"
            value={"Only this event"}
            // checked={!newAssignmentForm?.disposable}
            onChange={(e) => setOnAll(!e.target.checked)}
            checked={!onAll}
          />
          <LabelText style={{ fontSize: "16px" }}>Only this event</LabelText>
        </StyledRadioLabel>
        <StyledRadioLabel style={{ marginTop: "15px" }}>
          <RadioInput
            type="radio"
            name="when"
            // checked={newAssignmentForm?.disposable}
            value={"This and following events"}
            onChange={(e) => setOnAll(e.target.checked)}
            checked={onAll}
          />
          <LabelText style={{ fontSize: "16px" }}>
            This and following events
          </LabelText>
        </StyledRadioLabel>
      </div>
      <EditAssignmentButtonsWrapper
        style={{ padding: "unset", marginTop: "36px" }}
      >
        <EditAssignmentButton
          background={"rgba(0,150,198,0.1)"}
          color={"#0686D8"}
          onClick={handleSaveTasks}
        >
          Save
        </EditAssignmentButton>
        <EditAssignmentButton
          background={"#fff"}
          color={"#0686D8"}
          border={"1px solid #0686D8"}
          onClick={() => dispatch(setEditTaskData(null))}
        >
          Cancel
        </EditAssignmentButton>
      </EditAssignmentButtonsWrapper>
    </StyledChangesContentWrapper>
  );
};

export default EditTaskModalRunEvery;
