import React from "react";
import {
  ProgressBarWrapper,
  ProgressBarValue,
  ProgressBarSec,
} from "./components";

const ProgressBar = () => {
  return (
    <ProgressBarWrapper>
      <ProgressBarSec>
        <ProgressBarValue></ProgressBarValue>
      </ProgressBarSec>
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
