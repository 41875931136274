import React, { useEffect, useState } from "react";
import { StyledFloatingMenuUL } from "./components";

export const DIRECTIONS = {
  up: "column-reverse",
  down: "column",
  left: "row-reverse",
  right: "row",
};

export const Directions = {
  Up: "up",
  Down: "down",
  Left: "left",
  Right: "right",
};

const FloatingMenu = ({
  slideSpeed = 500,
  direction = Directions.Down,
  floatMenuOpen = false,
  spacing = 8,
  children,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hideList, setHideList] = useState(true);

  useEffect(() => {
    let timeOut;
    if (floatMenuOpen) {
      setHideList(false);
      timeOut = setTimeout(() => {
        setIsOpen(true);
      }, 50);
    } else {
      timeOut = setTimeout(() => {
        setHideList(true);
      }, 300);
      setIsOpen(false);
    }

    return () => {
      clearTimeout(timeOut);
    };
  }, [floatMenuOpen]);

  const childrenWithProps = React.Children.map(children, (child, index) =>
    React.cloneElement(child, {
      isOpen,
      slideSpeed,
      direction,
      index,
      spacing,
    })
  );

  return (
    <StyledFloatingMenuUL direction={direction} hideList={hideList} {...rest}>
      {childrenWithProps}
    </StyledFloatingMenuUL>
  );
};

export default FloatingMenu;
