import React, { useContext, useEffect, useState } from "react";
import {
  SearchableOptionsWrapper,
  SearchableSelectText,
  StyledSearchableOption,
  StyledSearchableSelect,
} from "./components";
import ICONS from "../../constants/icons";
import InputText from "../input/InputText";
import OutsideClickHandler from "react-outside-click-handler";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import { InputError } from "../input/components";

const SearchableSelect = ({
  options,
  value,
  handleChange = () => {},
  width,
  error,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectOptions, setSelectOptions] = useState(options);
  const [selectValue, setSelectValue] = useState(value);
  const [inputValue, setInputValue] = useState("");

  const { isMobile } = useContext(ResponsiveContext);

  const handleInputChange = (value) => {
    setInputValue(value);
  };
  const handleSelectChange = (value) => {
    setSelectValue(value);
    setShowOptions(false);
    handleChange(value);
  };
  useEffect(() => {
    setSelectValue(value);
  }, [value]);
  useEffect(() => {
    const filtered = options?.filter((item) =>
      item?.toLowerCase().includes(inputValue.toLowerCase())
    );
    setSelectOptions(filtered);
  }, [inputValue, options, showOptions]);

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <OutsideClickHandler onOutsideClick={() => setShowOptions(false)}>
        <StyledSearchableSelect
          style={{ width: width }}
          isMobile={isMobile}
          showOptions={showOptions}
          onClick={() => {
            setInputValue("");
            setShowOptions((prev) => !prev);
          }}
        >
          <SearchableSelectText>
            {selectValue || "Choose option"}
          </SearchableSelectText>
          {error && <InputError className="error">* {error}</InputError>}
        </StyledSearchableSelect>
        {showOptions && (
          <SearchableOptionsWrapper>
            <InputText
              handleChange={handleInputChange}
              placeholder={"Search"}
              icon={ICONS.SearchGrey}
              padding={"8px"}
              height={"2.5rem"}
              noBorder
            />
            <div style={{ padding: "5px 0" }}>
              {selectOptions?.map((item, index) => (
                <StyledSearchableOption
                  key={index}
                  onClick={() => handleSelectChange(item)}
                >
                  {item}
                </StyledSearchableOption>
              ))}
            </div>
          </SearchableOptionsWrapper>
        )}
      </OutsideClickHandler>
    </div>
  );
};

export default SearchableSelect;
