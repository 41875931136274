import { createContext, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import getClients from "../APIs/clients/getClients";
import { setChosenClient, setClient, setUserRole } from "../redux/clients";
import getLocations from "../APIs/locations/getLocations";
import { setLocations } from "../redux/maps";

export const AdminTableContext = createContext(null);

export const AdminTableProvider = ({ children }) => {
  const { chosenClient } = useSelector((state) => state.clients);
  const [clients, setClients] = useState([]);
  const dispatch = useDispatch();

  const { mutate: getClientsMutation } = useMutation("get-clients", getClients);

  const getAllClients = useCallback(() => {
    getClientsMutation(null, {
      onSuccess: (clients) => {
        setClients(clients);
        if (!localStorage.getItem("selected_client_id") && clients.length) {
          dispatch(setChosenClient(clients[0]?.id));
          dispatch(setClient(clients[0]));
        } else {
          dispatch(
            setClient(
              clients.find(
                (item) => item.id === localStorage.getItem("selected_client_id")
              )
            )
          );
        }
        dispatch(setUserRole());
      },
      onError: (err) => console.log("Error", err),
    });
  }, [getClientsMutation, dispatch]);

  useEffect(() => {
    getAllClients();
  }, [getAllClients]);

  useEffect(() => {
    chosenClient &&
      getLocations(chosenClient).then((data) => dispatch(setLocations(data)));
  }, [chosenClient, dispatch]);

  return (
    <AdminTableContext.Provider
      value={{
        clients,
        setClients,
      }}
    >
      {children}
    </AdminTableContext.Provider>
  );
};
