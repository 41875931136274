import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import addMap from "../../APIs/maps/addMap";
import Loader from "../../components/loader/Loader";
import { StyleUploadMapImageWrapper } from "./components";

const UploadMapImage = ({ handleFetch }) => {
  const { chosenClient } = useSelector((state) => state.clients);
  const { chosenMap } = useSelector((state) => state.maps);

  const [loading, setLoading] = useState(false);

  const handMapPhotoUpload = async (event) => {
    if (event.target?.files[0]) {
      let formData = new FormData();
      formData.append("file", event.target?.files[0]);
      formData.append("mapId", chosenMap.id);
      setLoading(true);
      await addMap(formData, "mapUrl");
      await handleFetch(chosenClient);
      setLoading(false);
    }
  };

  return (
    <StyleUploadMapImageWrapper>
      {loading ? (
        <Loader />
      ) : (
        <>
          <input
            type="file"
            id="upload-map-image"
            onChange={handMapPhotoUpload}
          />
          <label htmlFor="upload-map-image">Upload client map</label>
        </>
      )}
    </StyleUploadMapImageWrapper>
  );
};

export default UploadMapImage;
