import { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { getRobots } from "../../APIs";
import {
  populateSharedZones,
  populateWorkingAreas,
} from "../../utils/populateZones";

import { MAPLOCATIONENUM, POLYGONENUMS } from "../../enums";
import useMaps from "../../hooks/useMaps";

const LAYER = "All Layers";

const LAYERS = [
  { id: "0", name: LAYER, checked: true },
  { id: "1", name: MAPLOCATIONENUM.CHECKPOINT, checked: false },
  { id: "2", name: MAPLOCATIONENUM.LOCATION, checked: false },
  { id: "3", name: MAPLOCATIONENUM.SHAREDZONE, checked: false },
  { id: "4", name: MAPLOCATIONENUM.WORKINGAREA, checked: false },
];

export const useFleetRules = () => {
  // eslint-disable-next-line no-unused-vars
  const _ = useMaps({});

  //client
  const { chosenClient } = useSelector((state) => state.clients);
  const { locations, mapsData, chosenMap } = useSelector((state) => state.maps);

  //map
  const [noImage, setNoImage] = useState(null);

  //robots
  const [robots, setRobots] = useState([]);
  const [chosenRobot, setChosenRobot] = useState({ id: null, name: null });

  //canvas
  const [canvasAreas, setCanvasAreas] = useState({});
  const [visibleCanvasItems, setVisibleCanvasItems] = useState({
    prev: null,
    curr: null,
  });

  const [chosenLayer, setChosenLayer] = useState(LAYERS);

  const [chosenrouteInd, setChosenRouteInd] = useState(null);
  const [showRobotsPage, setShowRobotsPage] = useState(1);
  const handleFetch = useCallback(
    async (id, data) => {
      if (chosenMap && data.length > 0) {
        setNoImage(!chosenMap.mapUrl);

        setCanvasAreas({
          Arrangement: chosenMap?.checkpoints
            ?.map(
              (item) =>
                !item.route && [
                  item.pixelCoordinates,
                  item.pixelCoordinates,
                  item.id,
                  item.name,
                ]
            )
            .filter((item) => item),
          Location: locations.map((loc) => [
            loc.pixelCoordinates,
            loc.pixelCoordinates,
            loc.id,
          ]),
          route: chosenMap.checkpoints
            ?.map(
              (item) =>
                item.route && {
                  ...JSON.parse(item.route),
                  id: item.id,
                  type: MAPLOCATIONENUM.ROUTE,
                }
            )
            .filter((item) => item),
          dashedRoute: [],
          ...populateSharedZones(chosenMap),
          ...populateWorkingAreas(chosenMap),
        });
        getRobots(id).then((robots) => setRobots(robots));
      }
    },
    [locations, chosenMap]
  );

  const handleLayerChange = useCallback((id) => {
    setChosenLayer((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : item
      )
    );
    setChosenRobot({ id: null, name: null });
  }, []);

  const handleRobotClick = useCallback((robot) => {
    setChosenRobot({ id: robot.id, name: robot.name });
    setChosenLayer((prev) =>
      prev.map((item) =>
        item.name === LAYER ? { ...item, checked: true } : item
      )
    );
  }, []);

  useEffect(() => {
    chosenClient && handleFetch(chosenClient, mapsData);
  }, [chosenClient, handleFetch, mapsData]);

  useEffect(() => {
    let visibleData = {};
    visibleData.Arrangement = [];
    if (!!chosenLayer.find((item) => item.name === LAYER && item.checked)) {
      if (!chosenRobot.id) visibleData = { ...canvasAreas };
    }
    if (
      !!chosenLayer.find(
        (item) => item.name === MAPLOCATIONENUM.CHECKPOINT && item.checked
      )
    ) {
      visibleData.Arrangement = canvasAreas.Arrangement;
    }
    if (
      !!chosenLayer.find(
        (item) => item.name === MAPLOCATIONENUM.LOCATION && item.checked
      )
    ) {
      visibleData.Location = canvasAreas.Location;
    }
    if (
      !!chosenLayer.find(
        (item) => item.name === MAPLOCATIONENUM.SHAREDZONE && item.checked
      )
    ) {
      Object.entries(canvasAreas).forEach((area) => {
        if (area[0].startsWith(POLYGONENUMS.SHAREDZONE))
          visibleData[area[0]] = area[1];
      });
    }
    if (
      !!chosenLayer.find(
        (item) => item.name === MAPLOCATIONENUM.WORKINGAREA && item.checked
      )
    ) {
      Object.entries(canvasAreas).forEach((area) => {
        if (area[0].startsWith(POLYGONENUMS.WORKINGAREA))
          visibleData[area[0]] = area[1];
      });
    }
    if (chosenRobot.id) {
      Object.entries(canvasAreas).forEach((area) => {
        if (area[0].includes(chosenRobot.id)) visibleData[area[0]] = area[1];
      });
    }

    setVisibleCanvasItems((prev) => ({
      prev: prev.curr,
      curr: visibleData,
    }));
  }, [canvasAreas, chosenRobot, chosenLayer, chosenrouteInd]);

  const handlePageChange = useCallback(
    (num) => {
      if (robots.length > showRobotsPage * 3 || num < 0) {
        setShowRobotsPage((prev) =>
          prev === 1 && num < 0 ? prev : prev + num
        );
      }
    },
    [showRobotsPage, robots]
  );

  const highlightRoute = useCallback(
    (route) => {
      if (route) {
        const allAreas = [route?.startZone, route?.endZone, ...route?.road];

        const keys = [];
        const allCanvasAreas = Object.keys(canvasAreas)
          .filter((item) => item.includes("_"))
          .map((item) => item.split("_"));
        allAreas.forEach((area) => {
          allCanvasAreas.forEach((arr) => {
            if (arr.includes(area.id)) keys.push(arr.join("_"));
          });
        });

        setVisibleCanvasItems((prev) => {
          let newVisibleitems = {
            ...prev.curr,
            highlightRoute: route,
          };

          keys.forEach((key) => {
            newVisibleitems = {
              ...newVisibleitems,
              [key + "_added"]: canvasAreas[key],
            };
          });

          return { prev: prev.curr, curr: newVisibleitems };
        });
      } else {
        setVisibleCanvasItems((prev) => ({
          prev: null,
          curr: prev.prev,
        }));
      }
    },
    [canvasAreas]
  );

  const determineRobotNameWithWorkignArea = useCallback(
    (workinAreaId) =>
      chosenMap?.workingAreas?.find((area) => area?.id === workinAreaId)?.robot
        ?.name,
    [chosenMap]
  );

  return {
    robots,
    chosenRobot,
    setCanvasAreas,
    visibleCanvasItems: visibleCanvasItems.curr,
    chosenLayer,
    noImage,
    LAYERS,
    handleFetch,
    handleLayerChange,
    handleRobotClick,
    canvasAreas,
    setChosenRouteInd,
    chosenrouteInd,
    showRobotsPage,
    setShowRobotsPage,
    handlePageChange,
    highlightRoute,
    determineRobotNameWithWorkignArea,
    LAYER,
  };
};
