import React from "react";
import { LoaderContainer, LoaderSign, LoaderText } from "./components";

const Loader = ({
  fontSize,
  height,
  padding,
  noText,
  text,
  noMargin,
  textAlign,
  textColor,
  position,
}) => {
  return (
    <LoaderContainer style={{ height, padding, textAlign, position }}>
      <LoaderSign noMargin={noMargin} />
      {!noText && (
        <LoaderText
          dangerouslySetInnerHTML={{ __html: text || "Loading, Please, Wait" }}
          style={{ fontSize }}
          textColor={textColor}
        ></LoaderText>
      )}
    </LoaderContainer>
  );
};

export default Loader;
