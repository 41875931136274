import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { setParams } from "../utils/setParams";

export const useApplications = () => {
  const [isAppModalOpen, setIsAppModalOpen] = useState(false);
  const [chosenApp, setChosenApp] = useState({});
  const { locations } = useSelector((state) => state.maps);

  const setChosenAppFunc = useCallback(
    (item) => {
      const paramObj = setParams(item, locations);
      setChosenApp({
        ...item,
        application: item,
        parameters: paramObj,
      });
      setIsAppModalOpen(true);
    },
    [locations]
  );

  return {
    isAppModalOpen,
    setIsAppModalOpen,
    setChosenAppFunc,
    chosenApp,
    setChosenApp,
  };
};
