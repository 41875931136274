import React from "react";
import ICONS from "../../constants/icons";
import {
  NoTasksComponentWrapper,
  StyledAddNewTaskBtn,
  StyledNoTaskSubtitle,
  StyledNoTaskTitle,
} from "./components";

const NoTasksComponent = ({ handleAddNewTask }) => {
  return (
    <NoTasksComponentWrapper>
      <img
        onClick={() => handleAddNewTask()}
        src={ICONS.AssignmentIcon}
        alt="icon"
      />
      <StyledNoTaskTitle onClick={() => handleAddNewTask()}>
        Create Your First Task
      </StyledNoTaskTitle>
      <StyledNoTaskSubtitle>
        Looks like it's time to add a tasks!<br></br> Click the 'Add new Task'
        button to get started!
      </StyledNoTaskSubtitle>
      <StyledAddNewTaskBtn onClick={() => handleAddNewTask()}>
        Add New Task
      </StyledAddNewTaskBtn>
    </NoTasksComponentWrapper>
  );
};

export default NoTasksComponent;
