const STRINGS = {
  // url vars
  url_id: "id",
  url_apptab: "apptab",
  url_release_state: "release_state",
  // errors
  something_went_wrong: "Something went wrong",
  invalid_credentials: "Invalid Credentials",
  required: "Required",
  too_short: "Too Short",
  invalid: "Invalid",
  invalid_email_format: "Invalid Email Format",
  no_match: "NO match",
  user_is_not_verified: "User is not verified",

  email_already_exists: "Email Already Exists",
  version_is_not_valid: "Version is not valid",
  // header
  header_learning_center: "Learning Center",
  header_community: "Community",
  header_FAQ: "FAQ",
  header_waiting_list: "Waiting List",
  header_podcast: "Podcast",
  header_contact_us: "Contact Us",
  // sidebar
  sidebar_dashboard: "dashboard",
  sidebar_robots: "Robots",
  sidebar_scheduler: "Scheduler",
  sidebar_admin: "Admin",

  // general
  username: "username",
  sign_up: "sign up",
  log_in: "log in",
  unity_binding_popup_info:
    "If popup does not open please click the button below",
  unity_binding_success: "You were succesfully logged in to the system",
  go_back_to_simulator:
    "Please go back to the simulator and continue your journey",
  cancel: "Cancel",
  okay: "okay",
  status: "status",
  free: "free",
  paid: "paid",
  name: "Name",
  email: "Email",
  role: "role",
  not_applicable: "N/A",
  or: "or",
  password: "password",
  firstname: "Firstname",
  lastname: "Lastname",
  next: "next",
  recover_password: "recover password",
  edit: "edit",
  delete: "Delete",
  warning: "Warning!",
  save: "Save",
  skip: "skip",
  loading: "loading",
  draft: "draft",
  requirements: "requirements",
  upload: "Upload",
  attention: "attention",
  and: " and ",
  // placeholders
  please_type_your_email: "Please type your email",
  please_type_your_firstname: "Please type your firstname",
  please_type_your_lastname: "Please type your lastname",
  please_type_your_username: "Please type your username",

  please_type_your_password: "Please type your password",
  please_type_your_password_again: "Please type your password again",
  select_role: "Select Role",
  app_name: "App name",
  app_Name: "App Name",
  application_type: "Application type",
  short_description: "Short Description",
  full_description: "Full Description",
  type_your_pass: "Type your password",
  physical_address: "Physical address",
  developer_name: "Developer name",
  website_address: "Website address",
  about_yourself: "About yourself",
  // authorizations
  forgot_password: "Forgot password",
  reset_your_pass: "Reset your password",
  forgot_your_password: "Forgot your password",
  thats_okay_happens:
    "Thats okay, it happens! Click on the button below to reset your password.",
  create_an_account: "Create an account",
  welcome_to_orchestra: "Welcome to Orchestra",
  signup_with_gmail: "Signup with Gmail Account",
  Login_with_gmail: "Log in with Gmail Account",
  ray_a: "Ra-Ya",
  set_password: "Create Your Account Password",
  repeat_password: "repeat password",
  ur_dev_platform: "Unlimited Robotics Developers Platform",
  verify_your_email: "Verify Your email",
  we_have_sent_confirmation: "We have sent you a confirmation email to",
  please_verfiy_your_account:
    "please verify your account by tapping on the button inside the email",
  you_wont_create_app:
    "You wont be able to create an app until your email has been verified",
  user_verification: "User Verification",
  resend_email: "Resend Email",
  // app status
  app_status_pending_review: "pending review",
  app_status_in_review: "in review",
  app_status_live: "live",
  app_status_blocked: "blocked",
  app_status_cancelled: "cancelled",
  // invite users modal
  invite_new_users: "invite new users",
  // send_invitation: "Send Invitation",
  app_permissions: "app permisions",
  join_app: "Join App",
  // page titles
  create_an_app: "create an app",
  app_registration: "app registration",
  app_releases: "app releases",
  welcome_to_ur: "welcome to UR developers center",
  welcome_aboard: "Welcome aboard!",
  // dashboard
  complete_your_profile_info: "complete your profile info",
  create_your_first_app: "create your first app",
  knowledge_center: "knowledge center",
  explore_our_demos: "explore our demos",
  edit_profile: "Edit profile",
  lets_start: "Lets start !",
  read_more: "Read More",
  view_demos: "View Demos",
  my_apps: "My Apps",
  add_new_app: "Add New App",
  explore_demos_subtitle:
    "Get inspired and obtain ideas on how to develop your next app by taking a look at our examples",
  knowledge_center_subtitle:
    "It may sound complicated at first, but this is why we created the developers’ help center with the all-you-need-to-know documentation",

  // profile
  personal_info_title: "Personal information",
  personal_info_subtitle: "Manage and edit your personal information",
  developer_page_title: "Developer page",
  developer_page_subtitle:
    "Manage the information show on your developer page on martkerplace",
  developer_icon: "Developer Icon",
  name_note: "It should be clear and not include any price or rank",
  email_note: "Contact Administration to edit email",
  address_note:
    "Please provide a  current, valid physical address if you sell paid apps or in-app products.",
  nickname_note:
    "This is how your developer name will appear on Martketplace. ",
  website_note: "Enter the URL of your official website if you have one",
  about_note: "Please tell us about yourself",
  icon_note: `Developer icon as it's shown on the marketplace.
  Don’t use badges or text that suggest store
  ranking or price`,
  // profile incomplete
  profile_incomplete: "Profile incomplete",
  finish_profile: "Please, finish setting up your profile",
  you_can_not_invite_yourself: "You can't invite yourself",

  //   terms
  terms_of_use: "Terms of Use",
  privacy_policy: "Privacy Policy",
  data_protection: "Data Protection",
  terms_and_conditions: "Terms and Conditions",
  //Admin
  admin: "Admin",
  environment_setup: "Environment Setup",
  add_new_client: "Add new client",
  logo: "Logo",
  client_name: "Client Name",
  client_phonenumber: "Phone number",
  client_email: "Client Email",
  client_address: "Client Address",
  create_new_client: "Create New Client",
  create_new_location: "Create New Location",
  create: "Create",
  upload_logo: "Upload logo",
  add_new_location: "Add new location",
  location: "Location",
  location_name: "Location Name",
  coordinate: "Coordinate",
  direction: "Direction",
  owner: "Owner",
  alias_name: "Alias Name",
  technical_name: "Technical Name",
  parameter_name: "Parameter Name",
  parameter: "Parameter",
  add_new_application: "Add new application",
  application: "Application",
  back_to_all_applications: "Back to all applications",
  edit_client: "Edit Client",
  update_client: "Update",
  edit_location: "Edit Location",
  edit_application: "Edit Application",
  create_new_application: "Create New Application",
  robots_list: "Robots list",
  add_robot: "Add Robot",
  switch_to_map: "Switch to Map view",
  switch_to_list: "Switch to list view",
  robot_name: "Robot name",
  id: "ID",
  current_task: "Current task",
  battery: "Battery",
  details: "Details",
  view_details: "View details",
  back_to_robots_list: "Back to Robots list",
  back_to_assignments: "Back to Assignments",
  assign_new_robot: "Assign new robot",
  edit_robot_info: "Edit Robot information",
  assignments: "Assignments",
  add_assignment: "Add assignment",
  create_new_assignment: "Create New Assignment",
  edit_assignment: "Edit Assignment",
  //user-management
  user_management: "User Management",
  user_administration: "User Administration",

  invite_team_member: "Invite team member",
  send_invitation: "Send Invitation",
  add_new_team_member: "Add New Team Member",
  modal_text:
    "An email will be sent to the provided email address with instructions to set up the account and create a new password.",
  new_member_added: "New Team Member Added Successfully!",
  request_sent_to_email:
    "A request to join the system has been sent to the email address provided.",
  please_inform_text:
    "Please inform [user.name] to check their email and complete the setup process.",
  //location
  map: "Map",
  network_signal: "Network signal",
  no_current_task: "No current task",
  completed_tasks: "Completed Tasks",
  streaming: "Streaming",
  first_value_info:
    "The first value will be automatically chosen as the default",

  //calendar
  calendar: "Calendar",

  //analytics
  analytics: "Analytics",
  add_content: "Add content",

  //analytics-log
  analytics_log: "Analytics log",
  add_event: "Add event",
  date_time: "Date/time",
  event_name: "Event name",
  robot: "Robot",
  parameters: "Parameters",
  add_new_event: "Add new event",
  edit_event: "Edit event",
  created_at: "Date",

  //battery config
  update_robot_now: "Update robot now",
  sufficient_battery: "Sufficient battery",
  low_battery: "Low battery",
  critical_battery: "Critical battery",
  battery_levels: "Battery levels",
  charging_method: "Charging method",
  inactivity_time: "Inactivity time",
};

export default STRINGS;
