import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appsData: [],
};

export const applications = createSlice({
  name: "applications",
  initialState,
  reducers: {
    setAppsData: (state, action) => {
      state.appsData = action.payload;
    },
  },
});
export const { setAppsData } = applications.actions;

export default applications.reducer;
