import React, { useState } from "react";
import { InfoModalTypes } from "../../../constants/types";
import {
  StyledEditBox,
  EditItem,
  StyledEditIcon,
} from "../../admin-page/environment-setup/components";
import * as moment from "moment";

import {
  AssignmentCardWrapper,
  CardHeader,
  CardHeaderText,
  CardRow,
  CardRowContent,
  CardRowText,
  HeaderStatusText,
  HeaderStatusWrapper,
  StatusToggleIcon,
  StyledCardName,
  StyledCardNameWrapper,
  StyledExpiredText,
  StyledUnderline,
} from "./components";
import { Link } from "react-router-dom";
import URLS from "../../../constants/urls";
import ICONS from "../../../constants/icons";
import { ASSIGNMENTSTATUSENUM, ROLESENUM } from "../../../enums";
import InfoModal from "../../../components/info-modal/InfoModal";
import OutsideClickHandler from "react-outside-click-handler";
import { useSelector } from "react-redux";

const AssignmentCard = ({
  item,
  index,
  handleToggle,
  handleEdit,
  handleDelete,
  handleDuplicate,
}) => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const { currentRole } = useSelector((state) => state.clients);
  return (
    <Link to={`${URLS.assignments}/${item.id}`}>
      <AssignmentCardWrapper
        key={item.id}
        opacity={item.status === ASSIGNMENTSTATUSENUM.Active ? 1 : 0}
      >
        <CardHeader>
          <CardHeaderText>Assignment status</CardHeaderText>
          <HeaderStatusWrapper>
            {item.status === "ended" ? (
              <StyledExpiredText>Expired</StyledExpiredText>
            ) : (
              <>
                <HeaderStatusText>
                  {item.status === "active"
                    ? ASSIGNMENTSTATUSENUM.Active.charAt(0).toUpperCase() +
                      ASSIGNMENTSTATUSENUM.Active.slice(1)
                    : ASSIGNMENTSTATUSENUM.Off.charAt(0).toUpperCase() +
                      ASSIGNMENTSTATUSENUM.Off.slice(1)}
                </HeaderStatusText>
                <StatusToggleIcon
                  style={{ width: "36px" }}
                  src={
                    item.status === "active"
                      ? ICONS.ToggleActive
                      : ICONS.ToggleOff
                  }
                  alt="ToggleIcon"
                  onClick={(e) => {
                    e.preventDefault();
                    if (currentRole !== ROLESENUM.VIEWER) {
                      handleToggle(item.id);
                    }
                  }}
                />
              </>
            )}
          </HeaderStatusWrapper>
        </CardHeader>
        <StyledUnderline />
        <StyledCardNameWrapper>
          <StyledCardName>{item.name}</StyledCardName>

          {currentRole !== ROLESENUM.VIEWER && (
            <StatusToggleIcon
              style={{ marginRight: "10px" }}
              src={ICONS.VerticalWhiteDots}
              onClick={(e) => {
                e.preventDefault();
                setShowEdit((prev) => !prev);
              }}
            />
          )}
          {showEdit && (
            <StyledEditBox style={{ top: "60px" }}>
              <OutsideClickHandler onOutsideClick={() => setShowEdit(false)}>
                <EditItem
                  onClick={(e) => {
                    e.preventDefault();
                    setShowEdit(false);
                    handleEdit(item);
                  }}
                >
                  <StyledEditIcon src={ICONS.PencilDraw} alt="Pencil" />
                  Edit
                </EditItem>
                {/* <Link to={`${URLS.assignments}/${item.id}`}>
                  <EditItem>
                    <StyledEditIcon src={ICONS.Document} alt="Pencil" />
                    Details
                  </EditItem>
                </Link> */}
                <EditItem
                  onClick={(e) => {
                    e.preventDefault();
                    handleDuplicate(item, index);
                  }}
                >
                  <StyledEditIcon src={ICONS.DoubleEmptyPaper} alt="Pencil" />
                  Duplicate
                </EditItem>
                <EditItem
                  onClick={(e) => {
                    e.preventDefault();
                    setIsDeleteModal(true);
                  }}
                >
                  <StyledEditIcon src={ICONS.DeleteIcon} alt="DeleteIcon" />
                  Delete
                </EditItem>
              </OutsideClickHandler>
            </StyledEditBox>
          )}
          <InfoModal
            style={{ maxWidth: "52.6rem" }}
            isOpen={isDeleteModal}
            setIsDeleteModal={setIsDeleteModal}
            type={InfoModalTypes.WARNING}
            itemId={item.id}
            title="Are you sure you want to delete this assignment?"
            noOuterClickClosing
            cancelAction={() => {
              setIsDeleteModal(false);
            }}
            submitAction={() => {
              handleDelete(item.id);
              setIsDeleteModal(false);
              setShowEdit(false);
            }}
            cancelText={"Cancel"}
            submitText={"Yes Delete"}
            disabled={false}
          />
        </StyledCardNameWrapper>

        <StyledUnderline />
        <CardRow>
          <CardRowText>Number of tasks</CardRowText>
          <CardRowContent>
            {item?.tasks?.length ? item?.tasks?.length : 0}
          </CardRowContent>
        </CardRow>
        <StyledUnderline />

        <CardRow>
          <CardRowText>Status</CardRowText>
          <CardRowContent style={{ background: "#2fd4531a", color: "#19672a" }}>
            {item.progress}
          </CardRowContent>
        </CardRow>
        <StyledUnderline />

        <CardRow style={{ height: "57px", gap: "20px" }}>
          <CardRowText>When?</CardRowText>
          {!item.disposable && (
            <CardRowContent
              style={{ height: "57px", display: "flex", alignItems: "center" }}
            >
              {"Every " +
                item.weekDays
                  ?.map((item) => (item.chosen ? " " + item.day : ""))
                  ?.filter((item) => item) +
                " - " +
                item.time}{" "}
              {item?.occurance?.timezone || ""}
            </CardRowContent>
          )}
          {item.disposable && (
            <CardRowContent
              style={{ height: "57px", display: "flex", alignItems: "center" }}
            >
              {moment(item.executionTime).format("dddd, MMM Do") +
                "-" +
                item.time}{" "}
              {moment(item.executionTime).format("Z")}
            </CardRowContent>
          )}
        </CardRow>
      </AssignmentCardWrapper>
    </Link>
  );
};

export default AssignmentCard;
