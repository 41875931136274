import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { StyledOption, StyledSelect } from "./components";
import {
  ErrorMessage,
  StyledParInput,
} from "../../pages/robots/tasks-in-assignments/components";
import { locationToAppData } from "../../utils/locationToAppData";

const endTimeOptions = [
  { name: "No End Time", value: -1 },
  { name: "30 Minutes", value: 30 },
  { name: "One Hour", value: 60 },
  { name: "Two Hours", value: 120 },
  { name: "Three Hours", value: 180 },
  { name: "Custom" },
];

const locationsSortFunction = (a, b) => {
  const nameA = a.name;
  const nameB = b.name;

  const isANumber = /^\d/.test(nameA);
  const isBNumber = /^\d/.test(nameB);

  if (!isANumber && isBNumber) {
    return -1; // Text comes before numbers
  }
  if (isANumber && !isBNumber) {
    return 1; // Numbers come after text
  }

  // If both are numbers (or start with numbers), sort numerically
  if (isANumber && isBNumber) {
    return (
      parseFloat(nameA.match(/^\d+/)[0]) - parseFloat(nameB.match(/^\d+/)[0])
    );
  }

  // Otherwise, sort alphabetically
  return nameA.localeCompare(nameB);
};

const ParamFields = ({
  param,
  item,
  handleParametersChange,
  isError,
  errorDivRef,
  isCustom,
}) => {
  const { locations } = useSelector((state) => state.maps);

  const sortedLocations = useMemo(() => {
    const copyLocations = locations.slice();
    return copyLocations.sort(locationsSortFunction);
  }, [locations]);

  const paramFields = useMemo(() => {
    switch (param.type) {
      case "location":
        const pixelCoordinates = [
          item?.parameters[param.parameter]?.x,
          item?.parameters[param.parameter]?.y,
          item?.parameters[param.parameter]?.angle,
        ];
        return (
          <>
            <StyledSelect
              onClick={(e) => e.stopPropagation()}
              style={{ width: "100%" }}
              value={pixelCoordinates}
              disabled={item.disabled}
              onChange={(e) => {
                handleParametersChange(
                  locationToAppData(
                    locations.find(
                      (el) => el.pixelCoordinates.toString() === e.target.value
                    )
                  ),

                  item.id,
                  param.parameter,
                  param.id
                );
              }}
            >
              <>
                <StyledOption value={null}>Choose location</StyledOption>
                {param?.customLocs &&
                ![...param.customLocs].find((item) => item.id === "allLocID")
                  ? [...param.customLocs]
                      .map((loc) => {
                        let location = locations.find(
                          (item) => item.id === loc.id
                        );
                        return location || null;
                      })
                      .filter((item) => item)
                      .map((loc) => (
                        <StyledOption key={loc.id} value={loc.pixelCoordinates}>
                          {loc.name}
                        </StyledOption>
                      ))
                  : sortedLocations.map((loc) => (
                      <StyledOption key={loc.id} value={loc.pixelCoordinates}>
                        {loc.name}
                      </StyledOption>
                    ))}
              </>
            </StyledSelect>
            {isError &&
              (!item?.parameters[param.parameter] ||
                !item?.parameters[param.parameter].x ||
                !item?.parameters[param.parameter]?.y ||
                !item?.parameters[param.parameter]?.angle) && (
                <>
                  <ErrorMessage
                    ref={(el) => {
                      if (!errorDivRef?.current) errorDivRef.current = el;
                    }}
                  >
                    * This field can not be empty
                  </ErrorMessage>
                </>
              )}
          </>
        );

      case "text":
        return (
          <>
            <StyledSelect
              onClick={(e) => e.stopPropagation()}
              style={{ width: "100%" }}
              disabled={item.disabled}
              value={item.parameters[param.parameter]}
              onChange={(e) => {
                handleParametersChange(
                  e.target.value,
                  item.id,
                  param.parameter,
                  param.id
                );
              }}
            >
              <>
                <StyledOption value={""}>Choose Object</StyledOption>
                {param?.value?.split(",").map((obj, index) => (
                  <StyledOption key={index} value={obj.trim()}>
                    {obj.trim()}
                  </StyledOption>
                ))}
              </>
            </StyledSelect>
            {isError && !item?.parameters[param.parameter] && (
              <ErrorMessage
                ref={(el) => {
                  if (!errorDivRef?.current) errorDivRef.current = el;
                }}
              >
                * This field can not be empty
              </ErrorMessage>
            )}
          </>
        );

      case "key_value":
        return (
          <>
            <StyledSelect
              onClick={(e) => e.stopPropagation()}
              style={{ width: "100%" }}
              disabled={item.disabled}
              value={item.parameters[param.parameter]}
              onChange={(e) => {
                handleParametersChange(
                  e.target.value,
                  item.id,
                  param.parameter,
                  param.id
                );
              }}
            >
              <>
                <StyledOption value={""}>Choose Object</StyledOption>
                {param?.list?.map((obj, index) => (
                  <StyledOption key={index} value={obj.value}>
                    {obj.key}
                  </StyledOption>
                ))}
              </>
            </StyledSelect>
            {isError && !item?.parameters[param.parameter] && (
              <ErrorMessage
                ref={(el) => {
                  if (!errorDivRef?.current) errorDivRef.current = el;
                }}
              >
                * This field can not be empty
              </ErrorMessage>
            )}
          </>
        );

      case "freeText":
        return (
          <>
            <StyledParInput
              onClick={(e) => e.stopPropagation()}
              type="text"
              style={{ width: "100%" }}
              value={item.parameters[param.parameter]}
              onChange={(e) => {
                handleParametersChange(
                  e.target.value,
                  item.id,
                  param.parameter,
                  param.id
                );
              }}
              disabled={item.disabled}
            />

            {isError && !item.parameters[param.parameter] && (
              <ErrorMessage
                ref={(el) => {
                  if (!errorDivRef?.current) errorDivRef.current = el;
                }}
              >
                * Invalid input
              </ErrorMessage>
            )}
          </>
        );

      case "endtime":
        return (
          <>
            <StyledSelect
              onClick={(e) => e.stopPropagation()}
              style={{ width: "100%" }}
              disabled={item.disabled}
              value={isCustom ? "Custom" : item.parameters[param.parameter]}
              onChange={(e) => {
                handleParametersChange(
                  +e.target.value,
                  item.id,
                  param.parameter,
                  param.id
                );
              }}
            >
              <>
                {endTimeOptions.map((obj, index) => (
                  <StyledOption key={index} value={obj.value}>
                    {obj.name}
                  </StyledOption>
                ))}
              </>
            </StyledSelect>
          </>
        );
      default:
        return null;
    }
  }, [
    param,
    item,
    errorDivRef,
    isCustom,
    isError,
    handleParametersChange,
    locations,
    sortedLocations,
  ]);

  return paramFields;
};

export default ParamFields;
