import { useState, useEffect, useRef, useCallback } from "react";

export default function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = useCallback(
    (event) => {
      if (!ref.current) return;
      const allowedComponents = [
        ref.current,
        ...document.getElementsByClassName("clickAllow"),
      ];
      if (
        !allowedComponents.some(
          (item) => item.contains && item.contains(event.target)
        )
      ) {
        setIsComponentVisible(false);
      }
    },
    [ref]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return { ref, isComponentVisible, setIsComponentVisible };
}
