import axios from "axios";

const moveRobotLinear = async (robotName, distance) => {
  const res = await axios.post(`/robots/${robotName}/move-linear`, {
    distance,
  });
  return res.data;
};

export default moveRobotLinear;
