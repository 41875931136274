import styled from "styled-components";

export const ModalForm = styled.div`
  padding: 36px;
`;

export const StyledModalName = styled.h3`
  font-weight: 500;
  font-size: 24px;
  color: #0686d8;
  margin-left: 15px;
`;
export const InputWrapper = styled.div`
  margin-top: 30px;
`;
