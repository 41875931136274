import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
`;

export const Arrow = styled.div`
  cursor: pointer;
  padding: 0 10px;
  font-size: 1.5em;
`;

export const Option = styled.div`
  padding: 0 20px;
  font-size: 1em;
  width: 100%;
  text-align: center;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  color: ${(props) => (props.active ? "#0096C6" : "#08183F")};
  cursor: pointer;
`;
