export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const getChangedIndexes = (original, updated) => {
  return updated
    .map((item, index) => ({
      ...item,
      newIndex: index,
      originalIndex: original.findIndex(
        (updatedItem) => updatedItem.id === item.id
      ),
    }))
    .filter(({ originalIndex, newIndex }) => originalIndex !== newIndex);
};
