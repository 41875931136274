import React, { useState } from "react";
import { Arrow, HeaderContainer, Option } from "./components";
import ICONS from "../../constants/icons";
import { StyledUnderLine } from "../../pages/map/components";

const HeadeWithArrows = ({ options, handleOptionClick = () => {} }) => {
  const [visibleIndex, setVisibleIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleNext = () => {
    setVisibleIndex((prevIndex) => (prevIndex + 1) % options.length);
  };

  const handlePrev = () => {
    setVisibleIndex(
      (prevIndex) => (prevIndex - 1 + options.length) % options.length
    );
  };

  const getVisibleOptions = () => {
    const firstOption = options[visibleIndex];
    const secondOption = options[(visibleIndex + 1) % options.length];
    return [firstOption, secondOption];
  };
  const visibleOptions = getVisibleOptions();

  return (
    <HeaderContainer>
      <Arrow onClick={handlePrev}>
        <img
          src={ICONS.ArrowDown}
          alt=""
          style={{ transform: "rotate(90deg)" }}
        />
      </Arrow>
      {visibleOptions.map((option, index) => (
        <>
          <StyledUnderLine />
          <Option
            key={option}
            active={selectedOption === option}
            onClick={() => {
              setSelectedOption(option);
              handleOptionClick(option);
            }}
          >
            {option}
          </Option>
        </>
      ))}
      <Arrow onClick={handleNext}>
        <img
          src={ICONS.ArrowDown}
          alt=""
          style={{ transform: "rotate(-90deg)" }}
        />
      </Arrow>
    </HeaderContainer>
  );
};

export default HeadeWithArrows;
