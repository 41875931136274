import React from "react";
import ICONS from "../../constants/icons";
import {
  IconsContainer,
  IconsContainerWrapper,
  IconWrapper,
  StyledIcon,
} from "../../pages/robots/tasks-in-assignments/components";

const EditBox = ({ handleEdit, handleDelete, width, hideDelete }) => {
  return (
    <React.Fragment>
      <IconsContainerWrapper
        style={{ width: "100%" }}
        className="icons-container"
      >
        <IconsContainer>
          <IconWrapper
            width={width}
            background={"rgba(6, 134, 216, 0.1)"}
            onClick={handleEdit}
          >
            <StyledIcon src={ICONS.PencilDraw} />
          </IconWrapper>
          {!hideDelete && (
            <IconWrapper
              width={width}
              background={"rgba(212, 47, 57, 0.1)"}
              onClick={handleDelete}
            >
              <StyledIcon src={ICONS.DeleteIcon} />
            </IconWrapper>
          )}
        </IconsContainer>
      </IconsContainerWrapper>
    </React.Fragment>
  );
};

export default EditBox;
