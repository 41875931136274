import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddLocationModal from "../../../components/add-location-modal/AddLocationModal";

import ICONS from "../../../constants/icons";
import STRINGS from "../../../constants/strings";
import {
  BackDrop,
  ClientsTable,
  ComponentHeader,
  ComponentTitle,
  ComponentWrapper,
  EditItem,
  HeaderStyledButton,
  StyledEditBox,
  StyledEditIcon,
  TableHeader,
  TableHeaderItems,
  TableRow,
  TableRowItem,
  VertMenuWrapper,
} from "../environment-setup/components";
import InfoModal from "../../../components/info-modal/InfoModal";
import { InfoModalTypes } from "../../../constants/types";
import {
  setChosenBuilding,
  setChosenFloor,
  setLocations,
} from "../../../redux/maps";
import deleteLocation from "../../../APIs/locations/deleteLocation";
import useMaps from "../../../hooks/useMaps";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getChangedIndexes, reorder } from "../../../utils/locationsReorder";
import updateLocationsOrders from "../../../APIs/locations/updateLocationsOrders";
import SearchableSelect from "../../../components/searchable-select/SearchableSelect";
import getLocationsByMap from "../../../APIs/locations/getLocationsByMap";
const LocationTable = () => {
  const [showEdit, setShowEdit] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  // eslint-disable-next-line no-unused-vars
  const _ = useMaps({});

  const [newLocationForm, setNewLocationForm] = useState({
    name: "",
    x: 0,
    y: 0,
    location: [0, 0, 0],
    direction: 0,
    owners: [],
  });

  const {
    locations,
    floorOptions,
    buildingOptions,
    chosenBuilding,
    chosenFloor,
    chosenMap,
  } = useSelector((state) => state.maps);
  const [locationsData, setLocationsData] = useState(locations);

  const dispatch = useDispatch();

  const headerArr = [
    STRINGS.location_name,
    STRINGS.coordinate,
    STRINGS.direction,
    STRINGS.owner,
  ];

  const handleDelete = async (id) => {
    await deleteLocation(id);
    const filteredArr = locationsData.filter((item) => {
      return item.id !== id;
    });
    dispatch(setLocations(filteredArr));
    setShowEdit(false);
  };

  const handleEdit = (item) => {
    setCurrentModalData({
      ...item,
      x: item.pixelCoordinates[0],
      y: item.pixelCoordinates[1],
      location: item.pixelCoordinates,
    });
    setIsOpen(true);
  };

  const handleAddNewLocation = () => {
    setIsOpen(true);
    setNewLocationForm({
      name: "",
      x: "",
      y: "",
      location: ["", ""],
      direction: "",
      owners: [],
    });
    setCurrentModalData({});
  };

  useEffect(() => {
    if (chosenMap) {
      getLocationsByMap(chosenMap.id).then((data) => {
        const sortedArr = data.sort((a, b) => b.order - a.order);
        setLocationsData(sortedArr);
      });
    }
  }, [chosenMap]);

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      locationsData,
      result.source.index,
      result.destination.index
    );

    const changedItems = getChangedIndexes(locationsData, items);
    const lastChangedItem = changedItems[changedItems.length - 2];
    let arr = [];
    if (result.source.index < result.destination.index) {
      changedItems.forEach((el, index) => {
        if (index === changedItems.length - 1) {
          arr.push({ ...el, order: lastChangedItem.order });
        } else {
          arr.push({ ...el, order: el.order + 1 });
        }
      });
    }
    if (result.source.index > result.destination.index) {
      changedItems.forEach((el, index) => {
        if (index === 0) {
          arr.push({ ...el, order: changedItems[1].order });
        } else {
          arr.push({ ...el, order: el.order - 1 });
        }
      });
    }
    const updatedOrdersItems = items.map((item) => {
      const found = arr.find((el) => el.id === item.id);
      return found || item;
    });

    setLocationsData(updatedOrdersItems);

    await updateLocationsOrders(arr);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <ComponentWrapper>
        <ComponentHeader>
          <ComponentTitle>{`${STRINGS.location}`}</ComponentTitle>
          <HeaderStyledButton
            onClick={handleAddNewLocation}
          >{`${STRINGS.add_new_location}`}</HeaderStyledButton>
        </ComponentHeader>
        <div style={{ display: "flex", gap: "20px" }}>
          <div
            style={{
              width: "20.4rem",
              marginTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <SearchableSelect
              value={chosenBuilding}
              width="20.4rem"
              handleChange={(val) => dispatch(setChosenBuilding(val))}
              options={buildingOptions}
            />
          </div>
          <div style={{ width: "20.4rem", marginTop: "10px" }}>
            <SearchableSelect
              value={chosenFloor}
              width="20.4rem"
              options={floorOptions}
              handleChange={(val) => dispatch(setChosenFloor(val))}
            />
          </div>
        </div>
        <AddLocationModal
          newLocationForm={newLocationForm}
          setNewLocationForm={setNewLocationForm}
          isOpen={isOpen}
          currentModalData={currentModalData}
          setIsOpen={setIsOpen}
          locations={locations}
          locationsData={locationsData}
          setLocationsData={setLocationsData}
          setLocations={(data) => dispatch(setLocations(data))}
        />
        <ClientsTable>
          <TableHeader>
            {headerArr.map((title, index) => (
              <TableHeaderItems key={index}>{title}</TableHeaderItems>
            ))}
          </TableHeader>
          <Droppable droppableId={"droppable"}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ padding: "10px 0 150px 0" }}
              >
                {locationsData.map((item, index) => (
                  <Draggable
                    key={item?.id}
                    index={index}
                    draggableId={item?.id?.toString()}
                  >
                    {(provided) => (
                      <TableRow
                        key={item.order}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <TableRowItem style={{ textAlign: "center" }}>
                          {item.name}
                        </TableRowItem>
                        <TableRowItem>{`${item.pixelCoordinates.slice(
                          0,
                          2
                        )}`}</TableRowItem>
                        <TableRowItem>{`${item.direction}°`}</TableRowItem>
                        <TableRowItem>{`${
                          item.owners[0]?.username || ""
                        }`}</TableRowItem>

                        <VertMenuWrapper>
                          <img
                            src={ICONS.MenuVertical}
                            alt="edit"
                            onClick={() =>
                              setShowEdit((prev) => !prev && item.id)
                            }
                          />
                          {showEdit === item.id && (
                            <React.Fragment>
                              <StyledEditBox>
                                <EditItem
                                  onClick={() => {
                                    setShowEdit(false);
                                    handleEdit(item);
                                  }}
                                >
                                  <StyledEditIcon
                                    src={ICONS.PencilDraw}
                                    alt="Pencil"
                                  />
                                  Edit
                                </EditItem>
                                <EditItem
                                  onClick={() => setIsDeleteModal(true)}
                                >
                                  <StyledEditIcon
                                    src={ICONS.DeleteIcon}
                                    alt="DeleteIcon"
                                  />
                                  Delete
                                </EditItem>
                              </StyledEditBox>
                              <InfoModal
                                isOpen={isDeleteModal}
                                setIsDeleteModal={setIsDeleteModal}
                                type={InfoModalTypes.ATTENTION}
                                itemId={item.id}
                                title="Are you sure to delete ?"
                                // content="We are processing your information. Please be patient, it might take a while."
                                noOuterClickClosing
                                cancelAction={() => setIsDeleteModal(false)}
                                submitAction={() => {
                                  handleDelete(item.id);
                                  setIsDeleteModal(false);
                                  setShowEdit(false);
                                }}
                                cancelText={"No"}
                                submitText={"Yes"}
                                disabled={false}
                              />
                              <BackDrop
                                onClick={() => setShowEdit(false)}
                              ></BackDrop>
                            </React.Fragment>
                          )}
                        </VertMenuWrapper>
                      </TableRow>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </ClientsTable>
      </ComponentWrapper>
    </DragDropContext>
  );
};

export default LocationTable;
