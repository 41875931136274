import React, { useState } from "react";
import {
  setElementCoordinates,
  setLeft,
  setTooltipChildHeight,
  setTooltipChildWidth,
  setTooltipStyles,
  setTooltipText,
  setTooltipType,
  setTop,
} from "../../redux/tooltip";
import { useDispatch } from "react-redux";

const GetElementCoordinates = ({
  text,
  children,
  styles,
  type,
  top,
  left,
  disabled,
}) => {
  const dispatch = useDispatch();
  const [childrenRef, setChildrenRef] = useState();
  const handleMouseEnter = () => {
    if (disabled) {
      return;
    }
    if (childrenRef) {
      dispatch(
        setElementCoordinates({
          x: childrenRef.getClientRects()[0].x,
          y: childrenRef.getClientRects()[0].y,
        })
      );

      dispatch(setTooltipText(text));
      dispatch(setTooltipStyles(styles));
      dispatch(setTooltipChildWidth(childrenRef.offsetWidth));
      dispatch(setTooltipChildHeight(childrenRef.offsetHeight));
      type && dispatch(setTooltipType(type));
      dispatch(setTop(top));
      dispatch(setLeft(left));
    }
  };
  const handleMouseLeave = () => {
    dispatch(setTooltipText(null));
    dispatch(setElementCoordinates(null));
    dispatch(setTooltipType("top"));
  };
  return (
    <div
      ref={(el) => {
        if (!el) return;
        setChildrenRef(el);
      }}
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
    >
      {children}
    </div>
  );
};

export default GetElementCoordinates;
