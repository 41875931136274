import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  setBuildingOptions,
  setChosenBuilding,
  setChosenFloor,
  setChosenMap,
  setChosenMapUrl,
  setFloorOptions,
  setIsMapLoading,
  setMapsData,
} from "../redux/maps";
import { useDispatch } from "react-redux";
import { mapsDataManipulation } from "../components/maps-management/multiMapsDataManipulation";
import { getMap } from "../APIs";

const useMaps = ({ onlyRobotId }) => {
  const { mapsData, chosenFloor, chosenBuilding } = useSelector(
    (state) => state.maps
  );
  const { chosenClient } = useSelector((state) => state.clients);
  const { robotsData } = useSelector((state) => state.robots);
  const dispatch = useDispatch();

  const buildingOptions = useMemo(
    () => mapsData.map((el) => el.building),
    [mapsData]
  );

  const floorOptions = useMemo(
    () =>
      mapsData
        .find((el) => el.building === chosenBuilding)
        ?.floors?.map((el) => el.floor)
        ?.reverse(),
    [chosenBuilding, mapsData]
  );

  useEffect(() => {
    if (chosenClient) {
      dispatch(setIsMapLoading(true));
      getMap(chosenClient).then((data) => {
        const response = mapsDataManipulation(data.maps);
        dispatch(setMapsData(response));

        const buildings = response.map((el) => el.building);
        dispatch(setChosenBuilding(buildings[0]));
        dispatch(setIsMapLoading(false));
      });
    }
  }, [dispatch, chosenClient]);

  useEffect(() => {
    const foundBuilding = mapsData.find((el) => el.building === chosenBuilding);
    const foundFloor = foundBuilding?.floors?.find(
      (el) => el.floor === chosenFloor
    );
    dispatch(setChosenMap(foundFloor));
    dispatch(setChosenMapUrl(foundFloor?.mapUrl));
  }, [chosenFloor, dispatch, mapsData, chosenBuilding]);

  useEffect(() => {
    if (!chosenFloor) {
      floorOptions && dispatch(setChosenFloor(floorOptions[0]));
      floorOptions && dispatch(setFloorOptions(floorOptions));
    }
  }, [dispatch, floorOptions, chosenFloor]);

  useEffect(() => {
    dispatch(setBuildingOptions(buildingOptions));
  }, [dispatch, buildingOptions]);

  useEffect(() => {
    if (onlyRobotId) {
      try {
        const robot = robotsData.find((robot) => robot.robotId === onlyRobotId);
        const mapName = robot?.full_status?.localization?.map_name;
        if (mapName) {
          const [building, floor] = mapName.split("__");
          const foundBuilding = mapsData.find((el) => el.building === building);
          const foundFloor = foundBuilding?.floors?.find(
            (el) => el.floor === floor
          );
          if (foundBuilding && foundFloor) {
            dispatch(setChosenBuilding(building));
            dispatch(setChosenFloor(floor));
            dispatch(setChosenMap(foundFloor));
            dispatch(setChosenMapUrl(foundFloor?.mapUrl));
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [onlyRobotId, robotsData, dispatch, mapsData]);

  return {};
};

export default useMaps;
