import React, { useContext, useMemo } from "react";
import { StyledNumberWidgetWrapper } from "./components";
import { ResponsiveContext } from "../../../../contexts/ResponsiveContext";

const NumberWidget = ({ analytics, config }) => {
  const { isMobile } = useContext(ResponsiveContext);

  const number = useMemo(() => {
    if (config.unit === "distance") {
      return analytics.number + "mi";
    } else {
      return analytics.number;
    }
  }, [analytics, config]);
  return (
    <StyledNumberWidgetWrapper isMobile={isMobile}>
      {number}
    </StyledNumberWidgetWrapper>
  );
};

export default NumberWidget;
