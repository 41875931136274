import React from "react";
import {
  ButtonsWrapper,
  CloseIconWrapper,
  StyledIncomingCallWrapper,
  StyledTitle,
} from "../components";
import ICONS from "../../../constants/icons";
import StyledButtonComp from "../../styled-button/StyledButton";

const EndCallModalContent = ({ handleSubmit, handleCancel }) => {
  return (
    <StyledIncomingCallWrapper>
      <CloseIconWrapper>
        <img src={ICONS.BlackCross} alt="Close" />
      </CloseIconWrapper>
      <StyledTitle>Are You Sure You Want To End The Call?</StyledTitle>

      <ButtonsWrapper>
        <StyledButtonComp
          background={"rgba(213, 47, 57, 0.1)"}
          color={"#D52F39"}
          style={{ fontSize: "16px", fontWeight: "600", gap: "10px" }}
          onClick={handleSubmit}
        >
          End call
        </StyledButtonComp>
        <StyledButtonComp
          background={"rgba(6, 134, 216, 0.1)"}
          color={" #0686D8"}
          style={{ fontSize: "16px", fontWeight: "600", gap: "10px" }}
          onClick={handleCancel}
        >
          Cancel
        </StyledButtonComp>
      </ButtonsWrapper>
    </StyledIncomingCallWrapper>
  );
};

export default EndCallModalContent;
