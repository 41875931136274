import axios from "axios";

const updateTaskMessage = async (clientNmae, robotName, status, message) => {
  const res = await axios.put(
    `task-archive/${clientNmae}/${robotName}/update-task`,
    {
      status,
      message,
    }
  );
  return res.data;
};

export default updateTaskMessage;
