import moment from "moment";
import { ASSIGNMENTPROGRESSTEXTENUM, ROBOTTASKSTATUSENUM } from "../enums";

const NumToDay = {
  w0: "Sun",
  w1: "Mon",
  w2: "Tue",
  w3: "Wed",
  w4: "Thu",
  w5: "Fri",
  w6: "Sat",
};

export const parseAssignment = (assignment, todayTasks) => {
  if (!assignment.disposable) {
    const time =
      assignment?.occurance?.hour + ":" + assignment?.occurance?.minutes;
    const frequency = assignment?.occurance?.weekDays.split(",");

    const weekDays = new Array(7).fill(0).map((day, index) => {
      return {
        day: NumToDay["w" + index],
        chosen: frequency?.includes(index.toString()),
      };
    });
    return {
      ...assignment,
      time,
      weekDays,
      progress: ASSIGNMENTPROGRESSTEXTENUM.WAITING,
    };
  }

  if (assignment.disposable) {
    let progress;

    if (todayTasks) {
      const { oldTasks } = todayTasks;

      oldTasks?.forEach((element) => {
        if (element?.assignment?.id === assignment.id) {
          if (
            [
              ROBOTTASKSTATUSENUM.INPROGRESS,
              ROBOTTASKSTATUSENUM.SENTTOROBOT,
              ROBOTTASKSTATUSENUM.DEFAULT,
            ].includes(element.status)
          )
            progress = ASSIGNMENTPROGRESSTEXTENUM.INPROGRESS;
        }
      });
    }

    if (!progress) {
      if (moment(assignment.executionTime).isBefore(new Date()))
        progress = ASSIGNMENTPROGRESSTEXTENUM.DONE;
      else progress = ASSIGNMENTPROGRESSTEXTENUM.WAITING;
    }

    return {
      ...assignment,
      time: assignment.isCreated
        ? moment(assignment.executionTime).format("HH:mm")
        : assignment.time,
      executionTime: assignment.executionTime,
      progress,
    };
  }
};
