import React, { useContext } from "react";
import ICONS from "../../../constants/icons";
import { ShortcutsWrapper } from "../components";
import AssignCustomTask from "./AssignCustomTask";
import ShortcutApplication from "./ShortcutApplication";
import { useApplications } from "../../../hooks/useApplications";
import { ResponsiveContext } from "../../../contexts/ResponsiveContext";
import { useSelector } from "react-redux";

const icons = [
  // ICONS.SendToIcon,
  // ICONS.BringToIcon,
  ICONS.PatrolIcon,
  ICONS.PatrolIcon,
  ICONS.PatrolIcon,
  ICONS.AssignTaskIcon,
];

const Shortcuts = () => {
  const { isMobile } = useContext(ResponsiveContext);
  const { appsData } = useSelector((state) => state.applications);
  const {
    setChosenAppFunc,
    chosenApp,
    setChosenApp,
    isAppModalOpen,
    setIsAppModalOpen,
  } = useApplications();

  return (
    <React.Fragment>
      <ShortcutsWrapper isMobile={isMobile}>
        {appsData
          .filter((item) => item.shortcut)
          .map((item, index) => (
            <ShortcutApplication
              item={item}
              key={item.id}
              icon={icons[index]}
              chosenApp={chosenApp}
              setChosenApp={setChosenApp}
              setChosenAppFunc={setChosenAppFunc}
              isAppModalOpen={isAppModalOpen}
              setIsAppModalOpen={setIsAppModalOpen}
              shortcut
            />
          ))}
        <AssignCustomTask />
      </ShortcutsWrapper>
    </React.Fragment>
  );
};

export default Shortcuts;
