import React from "react";
import { Redirect } from "react-router-dom";
import URLS from "../../constants/urls";
import Loader from "../loader/Loader";
import { useSelector } from "react-redux";

const Auth = ({ allowedRoles, component: Component }) => {
  const {
    isLoggedIn: enabled,
    isClientLoading,
    currentRole,
    currentUser,
  } = useSelector((state) => state.clients);

  const authed = enabled || currentUser;
  const allowRoute = !allowedRoles || allowedRoles.includes(currentRole);
  return isClientLoading ? (
    <Loader height="100vh" />
  ) : !authed ? (
    <Redirect to={{ pathname: URLS.sign_in }} />
  ) : allowRoute ? (
    <Component />
  ) : (
    currentRole && <Redirect to={{ pathname: URLS.dashboard }} />
  );
};

export default Auth;
