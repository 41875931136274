import React, { useContext, useEffect, useState } from "react";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import "./Pie.css";
import { CustomLegendWrapper } from "./components";
import { ResponsiveContext } from "../../../../contexts/ResponsiveContext";
const colorScale = scaleOrdinal(schemeCategory10);
const PieWidget = ({ analytics, config }) => {
  const [pieData, setPieData] = useState([]);
  const [sumOfValues, setSumOfValues] = useState(0);
  const { isMobile } = useContext(ResponsiveContext);

  useEffect(() => {
    let sum = 0;
    const data = Object.entries(analytics.pieData).map(([name, value]) => {
      sum += value * 1;
      return {
        name,
        value: value * 1,
      };
    });
    setSumOfValues(sum);
    setPieData(data);
  }, [analytics]);

  const CustomLegend = ({ payload }) => (
    <CustomLegendWrapper isMobile={isMobile}>
      {payload.map((entry, index) => (
        <div
          key={index}
          style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
        >
          <div
            style={{
              width: "16px",
              height: "16px",
              borderRadius: "50%",
              backgroundColor: colorScale(index),
              marginRight: "8px",
            }}
          />
          <span>{entry.value}</span>
        </div>
      ))}
    </CustomLegendWrapper>
  );

  const CustomTooltip = ({ payload }) => {
    if (!payload || payload.length === 0) return null;

    const { name, value } = payload[0].payload;
    const percentage = ((value / sumOfValues) * 100).toFixed(2);

    return (
      <div className="custom-tooltip">
        <p className="label">{`${name}: ${percentage}%`}</p>
      </div>
    );
  };
  return (
    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <PieChart width={isMobile ? 280 : 300} height={isMobile ? 110 : 210}>
        <Pie
          data={pieData}
          cx={isMobile ? 50 : 100}
          cy={isMobile ? 50 : 100}
          outerRadius={isMobile ? 50 : 100}
          innerRadius={isMobile ? 25 : 50}
          fill="#8884d8"
          dataKey="value"
        >
          {pieData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colorScale(index)} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
        <Legend content={<CustomLegend />} />
      </PieChart>
    </div>
  );
};

export default PieWidget;
