import styled from "styled-components";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";

export const ModalHeader = styled.div`
  width: 100%;
  padding: 2.2rem 3rem 1.2rem 3.4rem;
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid; */
  border-color: ${COLORS.secondary.water_gray};
  box-shadow: 0px 5px 10px 0px #0093c60a;
  font-family: "AssistantSemiBold", "Ubuntu-Regular";
  font-size: 2rem;
  line-height: 3.2rem;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  align-items: center;
  background-color: ${(props) => props.headerBackground || "#0096c6"};
  color: #fff;
  border-radius: 16px 16px 0px 0px;
`;

export const ModalCloseX = styled.div`
  width: 6.2rem;
  height: 3.2rem;
  background-image: url(${ICONS.CloseWhite});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
`;

export const ModalFooter = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  border-top: 1px solid;
  border-color: ${COLORS.secondary.water_gray};
  box-shadow: 0px -12px 10px 0px #0093c60a;
  align-items: center;
`;
