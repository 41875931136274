import React from "react";
import NavList from "../outline-authorised/NavList";
import {
  SidebarHeaderWrapper,
  StyledLogoutWrapper,
  StyledSidebarContentWrapper,
  StyledSidebarWrapper,
} from "./components";
import { useSelector } from "react-redux";
import { HeaderLogo } from "../outline-authorised/components";
import { UnderLine } from "../add-assignment-modal/components";
import ICONS from "../../constants/icons";
import { logOut } from "../../redux/clients";
import URLS from "../../constants/urls";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const MobileSidebar = ({ isOpen, setShowMobileSidebar }) => {
  const { client, currentUser } = useSelector((state) => state.clients);
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <StyledSidebarWrapper isOpen={isOpen}>
      {isOpen && (
        <StyledSidebarContentWrapper>
          <SidebarHeaderWrapper>
            <HeaderLogo img={client?.logo} style={{ borderRadius: "unset" }} />
            <div>
              {!currentUser?.roles?.all &&
                currentUser?.firstName + " " + currentUser?.lastName}
            </div>
            <img
              src={ICONS.BlackCross}
              alt=""
              onClick={() => setShowMobileSidebar(false)}
            />
          </SidebarHeaderWrapper>
          <UnderLine style={{ margin: "20px 0" }} />
          <NavList setShowMobileSidebar={setShowMobileSidebar} />
          <StyledLogoutWrapper
            onClick={() => {
              dispatch(logOut());
              history.push(URLS.sign_in);
            }}
          >
            <div>
              <img src={ICONS.LogoutRed} alt="" />
              <span>Logout</span>
            </div>
          </StyledLogoutWrapper>
        </StyledSidebarContentWrapper>
      )}
    </StyledSidebarWrapper>
  );
};

export default MobileSidebar;
