import styled from "styled-components";
import ICONS from "../../constants/icons";

export const StyledAddParameter = styled.img`
  width: 50px;
  height: 40px;
`;

export const ApplicationNameWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 100px;
`;

export const StyledTitle = styled.h2`
  font-weight: 400;
  font-size: 32px;
  line-height: 118%;
  color: #000000;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const StyledSelect = styled.select`
  border-radius: 16px;
  width: 20.4rem;
  height: 6.4rem;
  padding: 0px 24px 0px 24px;
  appearance: none;
  background-image: url(${ICONS.ChevronDown});
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 18px;
  color: #030a1b;
  font-size: 16px;
  border: ${(props) => (props.disabled ? "unset" : "2px solid #c4c4c4")};
  background-color: ${(props) =>
    props.disabled ? "rgba(237, 237, 237, 0.5);" : "#ffffff;"};
`;

export const NumberInputsWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 20.4rem;
`;

export const ApplicationParametersWrapper = styled.div`
  max-height: 300px;
  width: 100%;
  overflow-y: auto;
`;

export const StyledRadioWrapper = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const StyledCheckBox = styled.input`
  width: 20px;
  height: 20px;
  border: 1.5px solid #f0f0f0;
  content: "";
  display: inline-block;
  margin: 0 16px 0 0;
  padding: 0;
  vertical-align: top;
  border-radius: 50%;
`;
