import React from "react";
import { StyledCloseWrapper } from "../../pages/robot-actions/components";
import COLORS from "../../constants/colors";
import { logOut } from "../../redux/clients";
import { useDispatch } from "react-redux";
import URLS from "../../constants/urls";
import Modal from "react-modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const customStyles = {
  overlay: {
    backgroundColor: `${COLORS.primary.oxford}80`,
    zIndex: "111",
  },
  content: {
    zIndex: "111",
    width: "385px",
    height: "120px",
    top: "50%",
    left: "50%",
    right: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    padding: "0 ",
    overflow: "hidden",
  },
};

const LogOutModal = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <Modal
      onRequestClose={() => setIsOpen(false)}
      isOpen={!!isOpen}
      style={customStyles}
      ariaHideApp={false}
    >
      <StyledCloseWrapper>
        <h1>Are you sure you want to exit?</h1>

        <div>
          <button
            onClick={() => {
              dispatch(logOut());
              history.push(URLS.sign_in);
            }}
          >
            Exit
          </button>
          <button onClick={() => setIsOpen(false)}>Cancel</button>
        </div>
      </StyledCloseWrapper>
    </Modal>
  );
};

export default LogOutModal;
