import React, { useCallback, useEffect, useState } from "react";
import { start, startConnect, hideCamera } from "./galene";
import { HeaderStyledButton, StreamingImg } from "./comopnents";
import getRobotCameraStream from "../../APIs/robots/getRobotCameraStream";
import Loader from "../loader/Loader";
import { CAMERAALIASES } from "../../enums";

const RobotVideos = ({
  currentRobot,
  chosenCamera,
  fromPopup,
  cameraList = [],
  setChosenCameraType,
}) => {
  const [videoRequested, setVideoRequested] = useState(false);
  const [galeneError, setGaleneError] = useState("");
  const [videoLoading, setVideoLoading] = useState(true);
  const [showButton, setShowButton] = useState(
    !fromPopup || (fromPopup && !navigator?.userActivation?.hasBeenActive)
  );
  const [cameraSwitch, setCameraSwitch] = useState(false);

  const videoLoaded = useCallback(() => {
    setVideoLoading(false);
    setShowButton(false);
    setCameraSwitch(true);
  }, []);

  useEffect(() => {
    currentRobot?.robotId &&
      start(currentRobot.robotId, setGaleneError, videoLoaded);
  }, [currentRobot?.robotId, videoLoaded]);

  const requestVideo = useCallback(
    async (e) => {
      setVideoLoading(true);
      try {
        if (currentRobot?.id && chosenCamera) {
          await getRobotCameraStream(currentRobot?.id, chosenCamera);
          await startConnect(
            chosenCamera,
            cameraList,
            videoLoaded,
            currentRobot?.id
          );
        }
      } catch (error) {
        setVideoRequested(false);
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chosenCamera, currentRobot?.id, cameraList.length, videoLoaded]
  );

  const showCameraFunc = useCallback(async (cameraName, robotId) => {
    try {
      await getRobotCameraStream(robotId, cameraName);
    } catch (error) {
      setVideoRequested(false);
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (
      fromPopup &&
      chosenCamera &&
      currentRobot?.id &&
      navigator?.userActivation?.hasBeenActive
    )
      setVideoRequested(true);
  }, [fromPopup, requestVideo, chosenCamera, currentRobot?.id]);

  useEffect(() => {
    if (!videoRequested && fromPopup && !chosenCamera && cameraList?.length)
      setChosenCameraType((prev) => prev || cameraList[0]);
  }, [
    videoRequested,
    fromPopup,
    chosenCamera,
    cameraList,
    setChosenCameraType,
  ]);

  useEffect(() => {
    if (galeneError === "Disconnected") {
      start(currentRobot?.robotId, setGaleneError, videoLoaded);
    }
  }, [galeneError, currentRobot?.robotId, videoLoaded]);

  useEffect(() => {
    localStorage.setItem("chosenCamera", chosenCamera);
    let localInterval = null;

    if (videoRequested && currentRobot?.id) {
      showCameraFunc(chosenCamera, currentRobot?.id);
      localInterval = setInterval(() => {
        getRobotCameraStream(currentRobot?.id, chosenCamera).then().catch();
      }, 10000);
    }
    return () => {
      if (chosenCamera !== localStorage.getItem("chosenCamera"))
        hideCamera(chosenCamera);
      clearInterval(localInterval);
    };
  }, [videoRequested, chosenCamera, showCameraFunc, currentRobot?.id]);

  useEffect(() => {
    if (videoRequested) requestVideo();
  }, [videoRequested, requestVideo]);

  useEffect(() => {
    let timeout;

    if (videoLoading) {
      timeout = setTimeout(() => {
        setChosenCameraType((prev) => {
          if (cameraList.length > 1) {
            if (prev === cameraList[0]) {
              localStorage.setItem("chosenCamera", cameraList[1]);
              return cameraList[1];
            } else {
              localStorage.setItem("chosenCamera", cameraList[0]);
              return cameraList[0];
            }
          }

          return prev;
        });
        requestVideo();
      }, 10000);
    }

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoLoading, requestVideo, cameraList.length, setChosenCameraType]);

  return (
    <StreamingImg>
      <div
        className={`video-container invisible ${fromPopup ? "fromPopup" : ""}`}
        id="video-container"
        style={
          videoRequested
            ? {
                width: "100%",
                height: "100%",
                display: "block",
              }
            : {}
        }
      >
        <div
          style={
            videoRequested
              ? {
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : {}
          }
          id="peers"
        ></div>
      </div>
      {showButton ? (
        <div
          style={{
            position: "absolute",
          }}
        >
          <div>
            <HeaderStyledButton
              type="submit"
              onClick={() => {
                setVideoRequested(true);
                setShowButton(false);
              }}
            >
              Robot's camera
            </HeaderStyledButton>
          </div>
        </div>
      ) : videoLoading ? (
        cameraSwitch ? (
          <div
            style={{
              position: "absolute",
              borderRadius: "10px",
              background: "rgba(217, 217, 217, 0.80)",
              padding: "10px",
            }}
          >
            <Loader
              text={`${CAMERAALIASES[chosenCamera]} is starting`}
              padding={0}
              noMargin
              textAlign="center"
              textColor="black"
              fontSize={18}
            />
          </div>
        ) : (
          <Loader
            text={`<span>
                Robot's camera stream is being processed. <br /> If loading
                takes more than 10 seconds please choose another camera
              </span>`}
            padding={0}
            textAlign="center"
            textColor="black"
            position="absolute"
          />
        )
      ) : null}
    </StreamingImg>
  );
};

export default RobotVideos;
