import styled from "styled-components";

export const StyledButton = styled.button`
  width: ${({ width }) => (width ? width : "auto")};
  height: ${({ height }) => height};
  border-radius: ${({ height, borderRadius }) =>
    borderRadius ? borderRadius : height ? `Calc(${height} / 2)` : "2.8rem"};
  border: ${({ borderColor }) =>
    borderColor ? `2px solid ${borderColor}` : `none`};
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "transparent"};
  box-shadow: ${({ shadow }) =>
    shadow
      ? "0 0.4rem 1rem 0 rgba(0, 147, 198, 0.18), 0 0.4rem 2.7rem 0 rgba(0, 147, 198, 0.42)"
      : "none"};
  font-family: ${({ fontFamily }) => fontFamily || "Ubuntu-Bold"};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1.6rem")};
  color: ${({ textColor, borderColor }) =>
    textColor ? textColor : borderColor ? borderColor : "#fff"};
  padding: ${({ padding }) => (padding ? padding : "0 4rem")};
  margin: ${({ margin }) => (margin ? margin : "0")};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  &:hover {
    transform: scale(1.02);
  }
  &:active {
    transform: scale(1);
  }
  text-transform: ${({ capitalize }) => (capitalize ? "capitalize" : "none")};
`;
