import styled from "styled-components";

export const AnalyticsHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  position: relative;
`;

export const AnalytisHeaderContainer = styled.div`
  display: flex;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 27px 0px rgba(0, 0, 0, 0.24);
`;
export const AnalyticsHeaderBtn = styled.button`
  border: unset;
  color: ${({ chosen }) => (chosen ? "#0686D8" : "#030A1B")};
  width: 120px;
  background: unset;
  text-align: center;
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
`;

export const TypeSelectorWrapper = styled.div`
  background: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 27px 0px rgba(0, 0, 0, 0.24);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 9999;
`;
export const StyledTypeRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;
export const StyledTypeText = styled.span`
  color: #030a1b;
  width: 100%;
  font-size: 16px;
  white-space: nowrap;
`;

export const WidgetModalWrapper = styled.div`
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 27px 0px rgba(0, 0, 0, 0.24);
  border-radius: 24px;
  padding: 24px;
`;

export const EditableWidgetHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const StyledWidgetType = styled.h3`
  color: #000;
  font-size: 24px;
`;

export const EditableWidgetContentWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`;
export const WidgetSelectorForm = styled.div`
  width: 313px;
  background: #fff;
  box-shadow: 0px 4px 27px 0px rgba(0, 0, 0, 0.24);
  border-radius: 16px;
  padding: 24px;
`;

export const StyledWidgetModalContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StyledWidgetContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
`;

export const WidgetWraper = styled.div`
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 27px 0px rgba(0, 0, 0, 0.24);
  border-radius: 24px;
  padding: ${({ isMobile }) => (isMobile ? "15px" : "24px")};
  margin-top: 20px;

  @media (min-width: 600px) {
    flex: 1 1 calc(50% - 10px); // Two boxes in a line
  }

  @media (min-width: 900px) {
    flex: 1 1 calc(33.33% - 10px); // Three boxes in a line
  }
`;
export const WidgetHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

export const StyledTitle = styled.h3`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  margin-top: ${({ isMobile }) => isMobile && "16px"};
`;

export const StyledThreeBtn = styled.img`
  margin-right: 0;
`;

export const StyledSubtitle = styled.p`
  color: #909491;
  font-size: 14px;
  text-align: center;
  margin-top: 25px;
`;

export const WidgetTypeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
`;
export const PreviewContainer = styled.div`
  background: #fff;
  padding: 24px;
  margin-top: 20px;
  width: 80%;
`;

export const AnalyticsDatesRange = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1e3455;
  font-size: 14px;
  margin-top: 20px;
  font-weight: 600;
`;
