import axios from "axios";

const getAgendaWeek = async (clientId, startDay, timezone) => {
  const res = await axios.get(
    `/clients/${clientId}/agenda-week/${startDay}/${encodeURIComponent(
      timezone
    )}`
  );
  return res.data;
};

export default getAgendaWeek;
