import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  usersData: [],
  editUserData: null,
  deleteUserData: null,
  roleFilter: "All",
  isSuccessModal: false,
};

export const userManagementSlice = createSlice({
  name: "robots",
  initialState,
  reducers: {
    setUsersData: (state, action) => {
      state.usersData = action.payload;
    },
    updateUsersData: (state, action) => {
      const data = { ...action.payload };
      if (state.usersData.find((item) => item.id === data.id)) {
        const updatedArray = state.usersData.map((item) =>
          item.id === data.id ? data : item
        );
        state.usersData = updatedArray;
      } else {
        state.usersData = [...state.usersData, data];
      }
    },
    handleDeleteUser: (state) => {
      state.usersData = state.usersData.filter(
        (item) => item.id !== state.deleteUserData.id
      );
    },
    setDeleteUserData: (state, action) => {
      state.deleteUserData = action.payload;
    },
    setEditUserData: (state, action) => {
      state.editUserData = action.payload;
    },
    setRoleFilter: (state, action) => {
      state.roleFilter = action.payload;
    },
    setIsSuccessModal: (state, action) => {
      state.isSuccessModal = action.payload;
    },
  },
});

export const {
  setUsersData,
  setEditUserData,
  updateUsersData,
  handleDeleteUser,
  setRoleFilter,
  setIsSuccessModal,
  setDeleteUserData,
} = userManagementSlice.actions;

export default userManagementSlice.reducer;
