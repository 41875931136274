import React, { useCallback, useEffect, useRef } from "react";

const MobileFormInputFocus = ({ children }) => {
  const childrenRef = useRef();
  const focusNextInput = useCallback((e) => {
    if (e.charCode === 13) {
      let inputs = Array.from(childrenRef?.current?.querySelectorAll("input"));
      const focusedIndexNum = inputs.findIndex(
        (el) => el === document.activeElement
      );
      inputs[focusedIndexNum + 1]?.focus();
    }
  }, []);

  useEffect(() => {
    let currentChildrenRef = childrenRef?.current;
    currentChildrenRef.addEventListener("keypress", (e) => focusNextInput(e));
    return () => {
      currentChildrenRef.removeEventListener("keypress", () =>
        focusNextInput()
      );
    };
  }, [focusNextInput]);

  return <div ref={childrenRef}>{children}</div>;
};

export default MobileFormInputFocus;
