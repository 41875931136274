import styled from "styled-components";

export const CustomModalContent = styled.div`
  background: #fff;
`;
export const StyledModalBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #08183f80;
  top: 0;
  left: 0;
`;
