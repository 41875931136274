import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import COLORS from "../../../constants/colors";
import ICONS from "../../../constants/icons";
import MapComponent from "../../robots/MapComponent";
import { CloseMapModalIcon } from "../components";
import {
  ModalContentWrapper,
  StyledHeaderLogo,
  StyledModalTitle,
} from "./components";

const foldIn = "unfoldIn 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards";
const foldOut =
  "unfoldOut 1s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards";

const MapModal = ({
  isOpen,
  setIsOpen,
  title,
  showIcons,
  showToolTip,
  onlyRobotId,
  handleClose = () => {},
}) => {
  const [modalAnimation, setModalAnimation] = useState(foldIn);

  const customStyles = {
    overlay: {
      backgroundColor: `${COLORS.primary.oxford}80`,
      zIndex: "111",
    },
    content: {
      zIndex: "111",
      top: 0,
      left: 0,
      height: "fit-content",
      right: "auto",
      transform: title ? "translate(50%, 35%)" : "translate(50%, 50%)",
      background: "unset",
      marginRight: "-50%",
      borderRadius: "16px",
      border: "none",
      padding: "0 ",
    },
  };
  useEffect(() => {
    setModalAnimation(foldIn);
  }, [isOpen]);

  const handleModalClose = () => {
    setTimeout(() => {
      setIsOpen(false);
      handleClose();
    }, 1000);
  };
  return (
    <Modal
      onRequestClose={() => {
        handleModalClose();
        setModalAnimation(foldOut);
      }}
      isOpen={!!isOpen}
      style={customStyles}
      ariaHideApp={false}
      portalClassName="clickAllow"
    >
      <ModalContentWrapper
        style={{ animation: `${modalAnimation}` }}
        onClick={(e) => e.stopPropagation()}
      >
        {title && (
          <>
            <StyledHeaderLogo src={ICONS.LocationIcon} />
            <StyledModalTitle>{title}</StyledModalTitle>
          </>
        )}
        <MapComponent
          mapWidth={window.innerWidth / 2}
          mapHeight={window.innerHeight / 2}
          pointWidth={0}
          robotWidth={20}
          showToolTip={showToolTip}
          showIcons={showIcons}
          onlyRobotId={onlyRobotId}
        />
        <CloseMapModalIcon
          src={ICONS.CloseGray}
          alt="close"
          onClick={() => {
            handleModalClose();
            setModalAnimation(foldOut);
          }}
        />
      </ModalContentWrapper>
    </Modal>
  );
};

export default MapModal;
