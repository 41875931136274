import styled, { keyframes } from "styled-components";

export const ProgressBarWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: calc(100% - 10px);
  left: 5px;
  border-radius: 8px;
  height: 2px;
`;

export const ProgressBarSec = styled.div`
  height: 3px;
  background-color: transparent;
  width: 100%;
  overflow: hidden;
`;

const indeterminateAnimation = keyframes` 
  0% {
    transform:  translateX(0) scaleX(0);
  }
  20% {
    transform:  translateX(0) scaleX(0.2);
  }
  100% {
    transform:  translateX(100%) scaleX(0.2);
  }
`;

export const ProgressBarValue = styled.div`
  width: 100%;
  height: 100%;
  background-color: #0686d8;
  animation: ${indeterminateAnimation} 1.3s infinite linear;
  transform-origin: 0% 50%;
`;
