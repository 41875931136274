import styled from "styled-components";

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 20px;
`;

export const StyledOverflowDiv = styled.div`
  color: #5c605d;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
