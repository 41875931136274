import axios from "axios";

const setPassword = async (vars) => {
  const res = await axios.put(`/auth/set-password?token=${vars?.token}`, {
    password: vars.password,
  });
  return res;
};

export default setPassword;
