const FONTS = {
  UbuntuBold: "Ubuntu-Bold",
  UbuntuLight: "Ubuntu-Light",
  UbuntuMedium: "Ubuntu-Medium",
  UbuntuRegular: "Ubuntu-Regular",
  AssistantBold: "AssistantBold",
  AssistantLight: "AssistantLight",
  AssistantRegular: "AssistantRegular",
  AssistantSemiBold: "AssistantSemiBold",
};

export default FONTS;
