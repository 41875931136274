import axios from "axios";

const resendVerification = async (email) => {
  const res = await axios.post(`/auth/resend-verification-email`, null, {
    params: {
      email: email,
    },
  });

  return res;
};

export default resendVerification;
