import styled from "styled-components";
import COLORS from "../../constants/colors";
import FONTS from "../../constants/fonts";
import ICONS from "../../constants/icons";

export const Title = styled.div`
  font-family: "AssistantLight", "Ubuntu-Light";
  font-size: 5.6rem;
  line-height: 7.6rem;
  margin-bottom: 1rem;
  width: 62.8rem;
  max-width: 100%;
  text-align: center;
  ${({ theme }) => theme.beforeDesktop`
    font-size: 3.2rem;
    line-height: 3.6rem;
    padding: 0 2rem;
    `}
`;

export const FormContentWrapper = styled.div`
  pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: ${({ leftAlign }) => (leftAlign ? "flex-start" : "center")};
  padding: 0 2rem;
  & * {
    pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};
  }

  & *.react-tel-input {
    height: 6.4rem;
    margin-top: 3.2rem;
  }
  & *.flag-dropdown {
    height: 6.2rem;
    width: 6.2rem;
    margin: 1px;
    border: none;
    border-radius: 1.6rem 0 0 1.6rem;
  }
  & *.selected-flag {
    border-radius: 1.6rem 0 0 1.6rem;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-right: 1rem;
  }
  & *.flag-dropdown.open {
    background: transparent;
  }
  & *.react-tel-input .flag-dropdown.open .selected-flag {
    border-radius: 1.6rem 0 0 1.6rem;
  }

  & * input.form-control {
    border: 1px solid #e5eae7;
    border-radius: 1.6rem;
    height: 6.4rem;
    width: 36.4rem;
    padding: 2.4rem 1.5rem 0 7.8rem;
  }
  & * input.form-control:focus {
    border-color: ${COLORS.secondary.ncs};
  }
  & *.special-label {
    display: block;
    background: transparent;
    left: 7.8rem;
    top: 0.4rem;
    line-height: 2.2rem;
    font-size: 1.2rem;
    font-family: "AssistantSemiBold", "Ubuntu-Medium";
    text-transform: capitalize;
    color: ${({ theme }) => theme.color.secondary.morning};
  }
`;

export const ForgotPassword = styled.div`
  font-size: 1.4rem;
  line-height: 2.4rem;
  cursor: pointer;
  color: ${({ theme }) => theme.color.secondary.morning};
  text-decoration: underline;
  user-select: none;
  &:hover {
    transform: scale(1.025);
  }
  &:active {
    transform: scale(1);
  }
`;

export const VerifyEmailImg = styled.div`
  width: 10rem;
  height: 11.4rem;
  background-image: url(${ICONS.VerifyEmailImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: 1.2rem;
`;

export const ChoiceDivider = styled.div`
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.color.secondary.oxford};
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  &::before {
    position: absolute;
    margin-top: 3px;
    content: "";
    display: block;
    height: 0.2rem;
    width: 15.6rem;
    left: -16.8rem;
    background-color: ${({ theme }) => theme.color.secondary.water_green};
    ${({ theme }) => theme.beforeDesktop`
      width: 10rem;
      left: -11.8rem;
    `}
  }
  &::after {
    position: absolute;
    margin-top: 3px;
    content: "";
    display: block;
    width: 15.6rem;
    height: 0.2rem;
    right: -16.8rem;
    background-color: ${({ theme }) => theme.color.secondary.water_green};
    ${({ theme }) => theme.beforeDesktop`
      width: 10rem;
      right: -11.8rem;
    `}
  }
`;

export const PageLoaderBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #ffffffcc;
  z-index: 4;
  pointer-events: none;
`;
export const GeneralError = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  min-height: 2rem;
  color: ${COLORS.secondary.rosso};
  font-family: ${FONTS.UbuntuMedium};
`;

export const SubTitle = styled.div`
  font-size: 1.4rem;
  width: 48.8rem;
  max-width: 100%;
  line-height: 2.4rem;
  text-align: center;
  color: ${({ theme }) => theme.color.secondary.morning};
  ${({ theme }) => theme.beforeDesktop`
    line-height: 2.8rem;
    padding: 1.6rem 2rem 0;
  `}
`;

export const ForgotPasswordImg = styled.div`
  width: 10rem;
  height: 11.4rem;
  background-image: url(${ICONS.ForgotPasswordImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: 1.2rem;
`;

export const AddMemberContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  width: 40%;
  padding: 66px 33px 120px;
  @media screen and (max-width: 1100px) {
    width: 80%;
  }
  @media screen and (max-width: 500px) {
    width: 90%;
  }
`;
