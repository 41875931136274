import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import addAssignmentException from "../APIs/assignments-exception/addAssignmentException";
import updateAssignmentException from "../APIs/assignments-exception/updateAssignmentException";
import addTask from "../APIs/tasks/addTask";
import updateTask from "../APIs/tasks/updateTask";
import deleteTask from "../APIs/tasks/deleteTask";
import { ROBOTTASKSTATUSENUM } from "../enums";
import {
  handleAssignmentDetailsUpdate,
  handleSortAgendaData,
  handleUpdateAssignmentFromAgenda,
  setDeleteTask,
  setEditTaskData,
} from "../redux/dashboard";
const useTasks = ({ assignmentDetails, setAssignmentsDetails }) => {
  const [isError, setIsError] = useState(false);

  const { chosenClient } = useSelector((state) => state.clients);
  const { sortedDataForAgenda } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();

  const handleTaskValidation = useCallback((data) => {
    if (!data.application) {
      setIsError(true);
    } else {
      const parArr = Object.entries(data.parameters).map(([item, values]) => {
        return {
          value: values,
          type: data.application.parameters.find((el) => el.parameter === item)
            .type,
        };
      });

      for (let i = 0; i <= parArr.length; i++) {
        const type = parArr[i]?.type;
        const value = parArr[i]?.value;
        if (type === "text" && !value) {
          setIsError(true);
          return false;
        }
        if (type === "freeText" && !value) {
          setIsError(true);
          return false;
        }
        if (type === "number" && !value) {
          setIsError(true);
          return false;
        }
        if (type === "key_value" && !value) {
          setIsError(true);
          return false;
        }
        if (type === "location" && (!value?.x || !value?.y)) {
          setIsError(true);
          return false;
        }
      }
      return true;
    }
  }, []);

  const handleResponse = useCallback(
    (data, responseData, exceptionId) => {
      let obj = {
        ...assignmentDetails,
        exceptionId: exceptionId || assignmentDetails?.exceptionId,
        tasks: assignmentDetails?.tasks?.map((item) =>
          item.id === data.id
            ? {
                ...item,
                disabled: !item.disabled,
                isEditMode: false,
                isCreated: true,
                id: responseData.id,
                status: ROBOTTASKSTATUSENUM.DEFAULT,
              }
            : item
        ),
      };
      setAssignmentsDetails(obj);
      dispatch(handleAssignmentDetailsUpdate(obj));
      dispatch(handleUpdateAssignmentFromAgenda(obj));
      dispatch(setEditTaskData(null));

      return obj;
    },
    [assignmentDetails, dispatch, setAssignmentsDetails]
  );

  const handleSave = useCallback(
    async (e, data, order) => {
      e.preventDefault();
      e.stopPropagation();
      const isValid = handleTaskValidation(data);
      if (isValid) {
        const body = {
          applicationId: data.application.id,
          clientId: chosenClient,
          liableRobotId: data?.chosenRobot?.id,
          order,
          parameters: {
            ...data.parameters,
            // target_goal: data?.parameters?.target_goal,
          },
        };
        if (data.isCreated) {
          await updateTask(data.id, body).then((responseData) => {
            handleResponse(data, responseData.data);
            setIsError(false);
          });
        } else {
          await addTask(assignmentDetails.id, body).then((responseData) => {
            handleResponse(data, responseData);
            setIsError(false);
          });
        }
      }
    },
    [assignmentDetails, chosenClient, handleTaskValidation, handleResponse]
  );

  const handleSaveException = useCallback(
    async (_, data, calendarDate) => {
      const assignment = handleResponse(data, data);

      if (assignment?.exceptionId) {
        await updateAssignmentException(assignment.exceptionId, { assignment });
      } else {
        // if there is not exception for this event
        const exception = await addAssignmentException({
          assignment,
          calendarDate,
        });

        handleResponse(data, data, exception?.id);
      }
    },
    [handleResponse]
  );

  const handleTaskDelete = useCallback(
    async (deleteTaskData, OTE, calendarDate) => {
      let obj = {
        ...assignmentDetails,
        tasks: assignmentDetails?.tasks?.filter(
          (item) => item.id !== deleteTaskData.id
        ),
      };

      if (obj.exceptionId)
        await updateAssignmentException(obj.exceptionId, {
          assignment: obj,
          deletedTaskId: deleteTaskData.id,
        });

      if (OTE && !obj.exceptionId) {
        const exception = await addAssignmentException({
          assignment: obj,
          calendarDate,
          deletedTaskId: deleteTaskData.id,
        });
        obj = { ...obj, exceptionId: exception?.id };
      } else if (!obj.exceptionId) {
        await deleteTask(deleteTaskData.id);
      }

      setAssignmentsDetails(obj);
      dispatch(handleAssignmentDetailsUpdate(obj));
      const updatedAgenda = sortedDataForAgenda.map((item) =>
        item.id === assignmentDetails.id ? obj : item
      );
      dispatch(handleSortAgendaData(updatedAgenda));
      dispatch(setDeleteTask(null));
    },
    [sortedDataForAgenda, assignmentDetails, dispatch, setAssignmentsDetails]
  );

  return {
    isError,
    setIsError,
    handleSave,
    handleTaskValidation,
    handleSaveException,
    handleTaskDelete,
  };
};
export default useTasks;
