import React, { useEffect } from "react";
import {
  StyledDay,
  StyledDayWrapper,
  StyledDaysWrapper,
  StyledNum,
} from "./components";
import moment from "moment/moment";
import { TimeAmount } from "../dashboard/components";
import ProgressBar from "../../components/porgressBar/ProgressBar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setChosenWeekDays } from "../../redux/dashboard";

const CalendarDays = ({ chosenDate }) => {
  const dispatch = useDispatch();
  const { isLoading, chosenWeekDays } = useSelector((state) => state.dashboard);
  useEffect(() => {
    const selectedDate = new Date(chosenDate);
    const dayOfWeek = selectedDate.getDay();
    const startOfWeek = new Date(selectedDate);
    startOfWeek.setDate(selectedDate.getDate() - dayOfWeek);
    const daysOfWeek = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + i);
      daysOfWeek.push(day.toISOString());
    }
    dispatch(setChosenWeekDays(daysOfWeek));
  }, [chosenDate, dispatch]);

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <StyledDaysWrapper>
        <div>
          <TimeAmount style={{ width: "70px" }}></TimeAmount>{" "}
        </div>
        {chosenWeekDays?.map((date) => (
          <StyledDayWrapper key={date}>
            <StyledDay
              isHighlighted={
                moment(date).format("LL") === moment(new Date()).format("LL")
              }
            >
              {moment(date).format("ddd")}
            </StyledDay>
            <StyledNum
              isHighlighted={
                moment(date).format("LL") === moment(new Date()).format("LL")
              }
            >
              <span>{moment(date).format("D")}</span>
            </StyledNum>
          </StyledDayWrapper>
        ))}
      </StyledDaysWrapper>
      {isLoading && <ProgressBar />}
    </div>
  );
};

export default CalendarDays;
