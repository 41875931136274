import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import COLORS from "../../../../constants/colors";
import ICONS from "../../../../constants/icons";
import { CloseMapModalIcon } from "../../components";
import { ModalContentWrapper } from "../components";
import AdjustMap from "./AdjustMap";

const foldIn = "unfoldIn 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards";
const foldOut =
  "unfoldOut 1s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards";

const AdjustMapModal = ({ isOpen, setIsOpen, title }) => {
  const [modalAnimation, setModalAnimation] = useState(foldIn);

  const customStyles = {
    overlay: {
      backgroundColor: `${COLORS.primary.oxford}80`,
      zIndex: "111",
    },
    content: {
      zIndex: "111",
      top: 0,
      left: 0,
      height: "fit-content",
      right: "auto",
      background: "unset",
      borderRadius: "16px",
      border: "none",
      padding: "0 ",
    },
  };
  useEffect(() => {
    setModalAnimation(foldIn);
  }, [isOpen]);

  const handleModalClose = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 1000);
  };

  return (
    <Modal
      onRequestClose={() => {
        handleModalClose();
        setModalAnimation(foldOut);
      }}
      isOpen={!!isOpen}
      style={customStyles}
      ariaHideApp={false}
      portalClassName="clickAllow"
    >
      <ModalContentWrapper
        style={{ animation: `${modalAnimation}` }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "#fff",
          }}
        >
          {isOpen && <AdjustMap />}
        </div>
        <CloseMapModalIcon
          src={ICONS.CloseGray}
          alt="close"
          onClick={() => {
            handleModalClose();
            setModalAnimation(foldOut);
          }}
        />
      </ModalContentWrapper>
    </Modal>
  );
};

export default AdjustMapModal;
