import styled from "styled-components";
import ICONS from "../../constants/icons";

export const StyledSelectType = styled.button`
  appearance: none;
  background-image: ${(props) =>
    props.showOptions
      ? `url(${ICONS.ChevronUp})`
      : `url(${ICONS.ChevronDown})`};
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: ${(props) => (props.showOptions ? "16px" : "11px")};
  color: ${(props) => props.color || "#030a1b"};
  font-size: 16px;
  border: ${(props) => (props.disabled ? "unset" : "2px solid #c4c4c4;")};
  background-color: ${(props) =>
    props.disabled ? "rgba(237, 237, 237, 0.5);" : "#ffffff;"};
  width: 100%;
  height: 50px;
  border: 2px solid #c4c4c4;
  border-radius: 16px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;
export const StyledOptionsBox = styled.div`
  position: absolute;
  width: 100%;
  background: #ffffff;
  border: 2px solid #c4c4c4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 5px 20px;
  margin-top: 1px;
  z-index: 10;
`;

export const StyledOptionType = styled.div`
  color: #0686d8;
  padding: 7px 0px;
  color: ${(props) => (props.chosen ? "#0686d8" : "#909491")};
  cursor: pointer;
`;
