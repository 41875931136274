import styled, { css } from "styled-components";

export const StyledDaysWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0px 24px;
  border-bottom: 0.5px solid rgba(144, 148, 145, 0.4);
  border-top: 0.5px solid rgba(144, 148, 145, 0.4);
`;

export const StyledDayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 30px;
  width: 100%;
`;

export const StyledDay = styled.span`
  font-size: 11px;
  text-transform: uppercase;
  color: ${(props) => (props.isHighlighted ? "rgb(0, 150, 198)" : "#70757a")};
`;

export const StyledNum = styled.span`
  background: ${(props) => (props.isHighlighted ? "rgb(0, 150, 198)" : "#fff")};
  border-radius: 100%;
  margin-top: 2px;
  padding: 7px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    color: #70757a;
    font-size: 25px;
    color: ${(props) => (props.isHighlighted ? "#fff" : "#70757a")};
  }
`;

export const StyledCalendarRow = styled.div`
  width: 100%;
  border-bottom: 0.5px solid rgba(144, 148, 145, 0.4);
  position: relative;
  display: flex;
`;

export const StyledEachDay = styled.div`
  width: 100%;
  padding: 5px;
  overflow: hidden;
  border-right: 0.5px solid rgba(144, 148, 145, 0.4);
  border-left: 0.5px solid rgba(144, 148, 145, 0.4);
  min-height: 70px;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
`;

export const StyledAssignmentPopupWrapper = styled.div`
  background: #fff;
  position: absolute;
  top: 0;
  z-index: 20;
  width: 550px;
  transition: none;
  ${
    ({ rightSide, transformLeft, isWed }) => {
      return !rightSide
        ? css`
            transform: translate(
              calc(-100% - 20px + ${isWed ? "100px" : "0px"}),
              -50%
            );
          `
        : css`
            transform: translate(${transformLeft}px, -50%);
          `;
    } /* @media screen and (max-width: 1300px) {
    width: 55%;
  } */
  }
`;

export const CalendarGifWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
`;
