import styled from "styled-components";
import COLORS from "../../constants/colors";
import ICONS from "../../constants/icons";

export const DrawerWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  width: ${(props) => (props?.mobile ? "100%" : "33%")};
  background: transparent;
  z-index: 111;
  /* @media screen and (max-width: 800px) {
    width: 100%;
  }
  @media screen and (max-width: 1300px) {
    width: 40%;
  }
  @media screen and (max-width: 1200px) {
    width: 45%;
  } */
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  > div {
    position: absolute;
    right: ${(props) => !props.calendar && "-600px"};
    width: ${(props) => !props.calendar && "43%"};
    height: ${(props) => (props.calendar ? "unset" : "100%")};
    background: #fff;
    transition: ${(props) => !props.calendar && "1s"};
    box-shadow: ${(props) =>
      props.leftSide
        ? "-5px 5px 10px 3px #888888"
        : "5px 5px 10px 3px #888888"};

    /* border-top-left-radius: 20px; */
  }

  ${(props) =>
    props.isOpen &&
    `> div {
    width: 100%;
    transition: ${!props.calendar && "0.5s"};
    right: 0;
    top: 0;
  }`}
`;

export const ModalHeaderright = styled.div`
  width: 100%;
  padding: 2.2rem 3rem 1.2rem 3.4rem;
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid; */
  border-color: ${COLORS.secondary.water_gray};
  box-shadow: 0px 5px 10px 0px #0093c60a;
  font-family: "AssistantSemiBold", "Ubuntu-Regular";
  font-size: 2rem;
  line-height: 3.2rem;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  align-items: center;
  background-color: #0096c6;
  color: #fff;
  /* border-radius: 16px 0 0px 0px; */
`;

export const ModalCloseXRight = styled.div`
  width: 6.2rem;
  height: 3.2rem;
  background-image: url(${ICONS.CloseWhite});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  position: absolute;
  right: 0;
`;

export const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(41, 47, 76, 0.5);
  z-index: 8;
`;

export const ArrowAndTimeWrapper = styled.div`
  display: flex;
  width: 40%;
  justify-content: flex-end;
  gap: 5px;
  @media screen and (max-width: 800px) {
    position: absolute;
    right: 4px;
    top: 5px;

    img {
      display: none;
    }
  }
`;
