import React from "react";
import {
  EditAssignmentButton,
  EditAssignmentButtonsWrapper,
  StyledChangesContentWrapper,
  StyledContentTitle,
  StyledModalTitleWrapper,
} from "../components";
import { useSelector } from "react-redux";

const DeleteTaskModal = ({ handleCancel, handleDelete }) => {
  const { deleteTaskData } = useSelector((state) => state.dashboard);
  return (
    <StyledChangesContentWrapper className="clickAllow">
      <StyledModalTitleWrapper>
        <StyledContentTitle>
          Are You Sure You Want To Delete -{" "}
          <strong>{deleteTaskData?.application?.aliasName}?</strong>
        </StyledContentTitle>
      </StyledModalTitleWrapper>
      <EditAssignmentButtonsWrapper style={{ marginTop: "20px" }}>
        <EditAssignmentButton
          background={"rgba(213, 47, 57, 0.1)"}
          color={"#D42F39"}
          onClick={() => handleDelete(deleteTaskData)}
        >
          Delete
        </EditAssignmentButton>
        <EditAssignmentButton
          background={"#fff"}
          color={"#0686D8"}
          border={"1px solid #0686D8"}
          onClick={handleCancel}
        >
          Cancel
        </EditAssignmentButton>
      </EditAssignmentButtonsWrapper>
    </StyledChangesContentWrapper>
  );
};

export default DeleteTaskModal;
