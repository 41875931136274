import { createContext, useEffect, useState } from "react";
import { ASSIGNMENTSTATUSENUM, ROBOTTASKSTATUSENUM } from "../enums";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  addAssignmentRedux,
  handleInProgressTasks,
  handleSortAgendaData,
  updateAssignmentDetailsStatuses,
  updateAssignmentRedux,
} from "../redux/dashboard";
import { useSelector } from "react-redux";
import socket from "../APIs/socket/socket";
import { setInprogressTasks } from "../redux/robots";

export const AssignmentsContext = createContext(null);
const assignmentTemplate = {
  id: uuidv4(),
  name: "",
  status: ASSIGNMENTSTATUSENUM.Active,
  assignment: true,
  disposable: true,
  time: moment(new Date()).format("HH:mm"),
  executionTime: new Date().toISOString(),
  tasks: [],
  occurance: {
    hour: new Date().getHours(),
    minutes: new Date().getMinutes(),
    timezone: "Asia/Jerusalem",
    weekDays: "",
  },
  weekDays: [
    {
      day: "Sun",
      chosen: false,
    },
    {
      day: "Mon",
      chosen: false,
    },
    {
      day: "Tue",
      chosen: false,
    },
    {
      day: "Wed",
      chosen: false,
    },
    {
      day: "Thu",
      chosen: false,
    },
    {
      day: "Fri",
      chosen: false,
    },
    {
      day: "Sat",
      chosen: false,
    },
  ],
};

export const AssignmentsProvider = ({ children }) => {
  const { agendaData } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const [assignmentsData, setAssignmentsData] = useState([]);
  useEffect(() => {
    const agendaArray = [
      ...agendaData?.immediateTasks?.map((immediateTask) => {
        let count = 0;
        agendaData?.immediateTasks.forEach((item) => {
          moment(immediateTask.executionTime).format("HH:mm") ===
            moment(item.executionTime).format("HH:mm") && count++;
        });
        return {
          ...immediateTask,
          executionTime: immediateTask.executionTime,
          width: (100 - count * 5) / count,
        };
      }),
      ...agendaData?.assignments?.map((assignment) => {
        let count = 0;
        agendaData?.assignments.forEach((item) => {
          moment(assignment.executionTime).format("HH:mm") ===
            moment(item.executionTime).format("HH:mm") && count++;
        });
        return {
          ...assignment,
          executionTime: assignment.executionTime,
          width: (100 - count * 5) / count,
        };
      }),
    ];

    const sortedAgendaArray = agendaArray.sort((a, b) => {
      return (
        moment(a.executionTime)
          .format("HH:mm")
          .replace(":", "")
          .replace(" ", "") -
        moment(b.executionTime)
          .format("HH:mm")
          .replace(":", "")
          .replace(" ", "")
      );
    });

    const tasksInProgress = sortedAgendaArray
      .map((item) => {
        if (item.status === ROBOTTASKSTATUSENUM.INPROGRESS) return item;

        const inProgressAssignmentTasks = [];
        if (item.agendaAssignmentStatus === ROBOTTASKSTATUSENUM.Failed) {
          item.tasks.forEach((task) => {
            if (task.status === ROBOTTASKSTATUSENUM.Failed)
              inProgressAssignmentTasks.push(task);
          });
        }
        return inProgressAssignmentTasks;
      })
      .filter((item) => item);

    dispatch(handleSortAgendaData(sortedAgendaArray));
    dispatch(handleInProgressTasks(tasksInProgress.flat()));
  }, [agendaData, dispatch]);

  useEffect(() => {
    socket.on("agenda_update", (data) => {
      dispatch(
        addAssignmentRedux({
          ...data,
          name: data?.application?.aliasName,
          executionTime: data.createdAt,
        })
      );

      dispatch(setInprogressTasks(data));
      dispatch(
        updateAssignmentDetailsStatuses({
          ...data,
          name: data?.application?.aliasName,
        })
      );
      dispatch(
        updateAssignmentRedux({
          ...data,
          name: data?.application?.aliasName,
        })
      );
    });

    return () => {
      socket.off("agenda_update");
    };
  }, [dispatch]);

  return (
    <AssignmentsContext.Provider
      value={{ assignmentsData, setAssignmentsData, assignmentTemplate }}
    >
      {children}
    </AssignmentsContext.Provider>
  );
};
