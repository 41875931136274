import styled from "styled-components";

export const StyledBtnAndSelectWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const VerticalLine = styled.div`
  width: 1px;
  height: 60px;
  background-color: rgba(212, 212, 212, 1);
  margin: 0 20px;
`;
export const BuildinModalContentWrapper = styled.div`
  padding: 24px;
`;

export const EditBoxWrapper = styled.div`
  background: #fff;
  position: absolute;
  border-radius: 8px;
`;
