import React from "react";
import {
  EditAssignmentButton,
  EditAssignmentButtonsWrapper,
  StyledChangesContentWrapper,
  StyledContentTitle,
  StyledModalTitle,
  StyledModalTitleWrapper,
} from "../components";

const DiscardChangesModalContent = ({ setIsOpen, handleSubmit }) => {
  return (
    <StyledChangesContentWrapper className="clickAllow">
      <StyledModalTitleWrapper>
        <StyledModalTitle>Unsaved Changes</StyledModalTitle>
        <StyledContentTitle style={{ marginTop: "15px" }}>
          Are you sure you want to discard? changes will not be saved.
        </StyledContentTitle>
      </StyledModalTitleWrapper>
      <EditAssignmentButtonsWrapper style={{ marginTop: "20px" }}>
        <EditAssignmentButton
          background={"rgba(0, 150, 198, 0.1)"}
          color={"#0686D8"}
          onClick={handleSubmit}
        >
          Discard
        </EditAssignmentButton>
        <EditAssignmentButton
          background={"#fff"}
          color={"#0686D8"}
          border={"1px solid #0686D8"}
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </EditAssignmentButton>
      </EditAssignmentButtonsWrapper>
    </StyledChangesContentWrapper>
  );
};

export default DiscardChangesModalContent;
